export enum RoundType{
  FUNCTIONAL_DOMAIN_ASSESSMENT="Functional/Domain Assessment",
  TECHNICAL_CODING_ASSESSMRENT="Technical/Coding Assessment",
  TECHNICAL_VIDEO_AUDIO_INTERVIEW="Technical Audio Video Interview",
  HR_AUDIO_VIDEO_INTERVIEW="HR Audio Video Interview",
  SENIOR_HR_AUDIO_VIDEO_INTERVIEW = "Senior HR Audio Video Interview",
  CONDITIONAL_OFFFER_LETTER_GENERATION="Conditional Offer Letter Generation",
  BACKGROUND_SCREENING="Background Screening",
  DRUG_SCREENING="Drug Screening",
  ONBORADING="Onboarding"
}