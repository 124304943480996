import React, { memo } from "react";
import { InterviewsCandidatesTableFilter,InterviewsFilter} from "../../../enums";
import Select from "react-select";
import {store, useTypedSelector} from "../../../store";
import {setInterviewType} from "../../../store/reducers/interviewsCandidatesReducer";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";

const interviewTypes = [
InterviewsFilter.ALL_INTERVIEW,    
InterviewsFilter.HR_AUDIO_VIDEO_INTERVIEW,
InterviewsFilter.TECHNICAL_VIDEO_AUDIO_INTERVIEW,
InterviewsFilter.SENIOR_HR_AUDIO_VIDEO_INTERVIEW
];

const interviewTypeOptions = interviewTypes.map(type=>({
    id: type,
    label : type,
    value : type
}))



const getInterviewType = store => store.interviews_candidates.filters.interviewType;
const FilterComponent = ({ activeTab }) => {
    const interviewType = useTypedSelector(getInterviewType);
    return (
    <>
      {activeTab === InterviewsCandidatesTableFilter.INTERVIEW_TYPE && (
        <div className="table-filter-popup__right__content">
          <Select
            options={interviewTypeOptions}
            value = {interviewType}
            onChange={(option)=>{
                store.dispatch(setInterviewType(option.value))
            }}
            styles={selectCustomStyle}
          />
        </div>
      )}
    </>
  );
};

export const CandidatesFilters = memo(FilterComponent);
