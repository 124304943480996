import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LiveCodingEnums} from "../../enums/live-coding/liveCodingEnums";
import {ILiveCoding} from "../../types/redux/live-coding";

const initialState: ILiveCoding = {
	setting: {
		theme: LiveCodingEnums.ThemeWhite,
		order: false,
		mode: LiveCodingEnums.ModeDefault,
		fontSize: "14",
		tabSpacing: "2",
		autoBrackers: true,
		minimap: true,
		codeCompletion: true,
	},
	language: {
		languagesList: [],
		current: {
			id: 63,
			name: "JavaScript (Node.js 12.14.0)",
			monaco: "javascript",
		},
	},
	settingPopup: false,
	terminalOpen: false,
	title: "Name of Assignment Title",
	usersOnline: [],
	errorsConsoleList: [],
	terminalHistory: [],
	errorsHistory: [],

}
export const liveCoding = createSlice({
	name: 'liveCoding',
	initialState: initialState,
	reducers: {
		setUsersOnlineLiveCoding:(state,action:PayloadAction<Array<any>>)=>{
			state.usersOnline=action.payload
		},
		setLanguagesListLiveCoding: (state, action: PayloadAction<Array<any>>) => {
			state.language.languagesList = action.payload
		},
		setErrorsListLiveCoding: (state, action: PayloadAction<Array<any>>) => {
			state.errorsConsoleList = action.payload
		},
		setTerminalHistoryLiveCoding: (state, action: PayloadAction<Array<any>>) => {
			state.terminalHistory = action.payload
		},
		setErrorsHistoryLiveCoding: (state, action: PayloadAction<Array<any>>) => {
			state.errorsHistory = action.payload
		},
		setCurrentLanguageLiveCoding: (state, action: PayloadAction<{ id: number, name: string, monaco: string }>) => {
			state.language.current = action.payload
		},
		setThemeLiveCoding: (state, action: PayloadAction<string>) => {
			state.setting.theme = action.payload
		},
		setTitleLiveCoding: (state, action: PayloadAction<string>) => {
			state.title = action.payload
		},
		setOrderLiveCoding: (state, action: PayloadAction<boolean>) => {
			state.setting.order = action.payload
		},
		setSettingPopupLiveCoding: (state, action: PayloadAction<boolean>) => {
			state.settingPopup = action.payload
		},
		setEditorModeLiveCoding: (state, action: PayloadAction<string>) => {
			state.setting.mode = action.payload
		},
		setAutoBrackersLiveCoding: (state, action: PayloadAction<boolean>) => {
			state.setting.autoBrackers = action.payload
		},
		setMinimapLiveCoding: (state, action: PayloadAction<boolean>) => {
			state.setting.minimap = action.payload
		},
		setCodeCompletionLiveCoding: (state, action: PayloadAction<boolean>) => {
			state.setting.codeCompletion = action.payload
		},
		setFontSizeLiveCoding: (state, action: PayloadAction<string>) => {
			state.setting.fontSize = action.payload;
		},
		setTabSpacingLiveCoding: (state, action: PayloadAction<string>) => {
			state.setting.tabSpacing = action.payload;
		},
		setTerminalOpenLiveCoding: (state, action: PayloadAction<boolean>) => {
			state.terminalOpen = action.payload;
		},
	},
})
export const {
	setUsersOnlineLiveCoding,
	setErrorsHistoryLiveCoding,
	setTerminalHistoryLiveCoding,
	setErrorsListLiveCoding,
	setLanguagesListLiveCoding,
	setCurrentLanguageLiveCoding,
	setTabSpacingLiveCoding,
	setFontSizeLiveCoding,
	setCodeCompletionLiveCoding,
	setMinimapLiveCoding,
	setAutoBrackersLiveCoding,
	setEditorModeLiveCoding,
	setTitleLiveCoding,
	setThemeLiveCoding,
	setOrderLiveCoding,
	setSettingPopupLiveCoding,
	setTerminalOpenLiveCoding,
} = liveCoding.actions

export default liveCoding.reducer
