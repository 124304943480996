import { memo } from "react";
import { store, useTypedSelector } from "../../../store";
import { setPopupWithListWorkflow } from "../../../store/reducers/positionWorkFlowReducer";
import { Link } from "react-router-dom";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const getPopupWithList=state=>state.position_workflow.popupWithList
const PopupWithList=()=>{

	const popupWithList=useTypedSelector(getPopupWithList)
	return		<div className="popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
					{popupWithList.title}
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => store.dispatch(setPopupWithListWorkflow({	title:'', open:false, id:-1, workflows:[]}))}
					className="popup__head__close"
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
					      strokeLinecap="round"
					      strokeLinejoin="round"/>
				</svg>
			</div>
			<div className="popup__body">
				<ul className='popup__numbered-list'>
					{
						popupWithList.workflows.map((item,index) => {
							return <li key={item.id} className='popup__numbered-list__item'>{index+1}. {item.name}</li>
						})
					}
				</ul>
			</div>
			<AuthGuard module='job-post' permission='edit'>
				<div className="popup__bottom end">
				<Link to={`/recruitment/position-workflow/${popupWithList.id}/edit`} onClick={()=>{store.dispatch(setPopupWithListWorkflow({	title:'', open:false, id:-1, workflows:[]}))}} className='popup__numbered-list__button'>
							Edit
						</Link>
				</div>
			</AuthGuard>
		</div>
	</div>
}
export default memo(PopupWithList)