import {memo, useEffect, useState} from "react";
import {store, useTypedSelector} from "../../../../store";
import {getEnv} from "@urecruits/api";
import React from "react";
import { CandidateDomainAssessmentFilterTabs } from "../../../../enums/candidate-domain-assessment/candidate-domain-assessment";
import CustomRange from "./filter/InputRange";
const {API_RECRUITMENT} = getEnv()

const getFiltersFunc = (state) => state.domain_assessment.filters
const FilterPopupManageDomainAssessment = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc)

	return <>
		 
		{
			activeTab === CandidateDomainAssessmentFilterTabs.Score && <div className='table-filter-popup__right__content'>
          <label className='table__range__label'>Score Range</label>
		  <CustomRange
                            values={filter.score}
                        />
      </div>
		} 

	</>
}
export default memo(FilterPopupManageDomainAssessment)
