import {store, useTypedSelector} from "../../../store";
import AsyncSelect from "react-select/async";
import Option from "../../Global/SelectOptions";
import {selectSearchFuncLocation} from "@urecruits/api";
import DatePicker from "react-datepicker";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import {CandidateOfferStatus, OfferLetterStatus, UsersOffersTableTabsEnums} from "../../../enums/usersOffers/UsersOffersEnums";
import {
	setDateSelectedUsersOffers,
	setLocationFilterUsersOffers,
	setOfferLetterStatusFilterUsersOffers,
	setCandidateActionsStatusFilterUsersOffers
} from "../../../store/reducers/usersOffersReducer";

const offerLetterStatusArray = [
	{
		offerLetterStatus: OfferLetterStatus.DRAFT,
		value: "draft",
		style: "table__status-block table__status-block--red jobs-table__status-button",
	},
	{
		offerLetterStatus: OfferLetterStatus.GENERATED,
		value: "generated",
		style: "table__status-block table__status-block--green jobs-table__status-button",
	},
	{
		offerLetterStatus: OfferLetterStatus.APPROVED,
		value: "approved",
		style: "table__status-block table__status-block--green jobs-table__status-button",
	},
	{
		offerLetterStatus: OfferLetterStatus.PENDING,
		value: "pending",
		style: "table__status-block table__status-block--yellow jobs-table__status-button",
	},
	{
		offerLetterStatus: OfferLetterStatus.REJECTED,
		value: "rejected",
		style: "table__status-block table__status-block--red jobs-table__status-button",
	},
	{
		offerLetterStatus: OfferLetterStatus.SENT,
		value: "sent",
		style: "table__status-block table__status-block--green jobs-table__status-button",
	},
	{
		offerLetterStatus: OfferLetterStatus.CLOSED,
		value: "closed",
		style: "table__status-block table__status-block--gray jobs-table__status-button",
	}
];

const candidateOfferStatusArray = [
	{
		offerLetterStatus: CandidateOfferStatus.ACCEPTED,
		value: "accepted",
		style: "table__status-block table__status-block--green jobs-table__status-button",
	},
	{
		offerLetterStatus: CandidateOfferStatus.PENDING,
		value: "pending",
		style: "table__status-block table__status-block--yellow jobs-table__status-button",
	},
	{
		offerLetterStatus: CandidateOfferStatus.REJECTED,
		value: "rejected",
		style: "table__status-block table__status-block--red jobs-table__status-button",
	},
];

const getFiltersFunc = state => state.offers_users.filters;

const FilterItemsUsersOffers = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc);
	return (
		<>
			{
				activeTab === UsersOffersTableTabsEnums.CANDIDATE_ADDRESS && (
					<div className="table-filter-popup__right__content">
						<AsyncSelect
							isMulti
							components={{
								Option,
							}}
							cacheOptions
							loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncLocation(inputValue) : selectSearchFuncLocation("")
							}
							defaultOptions
							value={filter.location}
							onChange={(option: any) => {
								store.dispatch(setLocationFilterUsersOffers(option));
							}}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							placeholder="Search by job location"
							styles={selectCustomStyle}
							id="locationSelect"
							instanceId="locationSelect"
						/>
					</div>
				)
			}
			{
				activeTab === UsersOffersTableTabsEnums.OFFER_LETTER_STATUS && <div className="table-filter-popup__right__content">
					{
						<ul className="filters-popup-mtm-buttons-list">
							{
								offerLetterStatusArray.map(item => {
									return <li
										className={`${filter.offerLetterStatus.find(x => x === item.value) ? item.style : `${item.style} inactive`}`}
										key={item.offerLetterStatus}
										onClick={() => store.dispatch(setOfferLetterStatusFilterUsersOffers(filter.offerLetterStatus.find(x => x === item.value) ? filter.offerLetterStatus.filter(x => x !== item.value) : [...filter.offerLetterStatus, item.value]))}
									>
										{item.offerLetterStatus}
									</li>;
								})
							}
						</ul>
					}
				</div>
			}
			{
				activeTab === UsersOffersTableTabsEnums.CANDIDATE_ACTION_STATUS && <div className="table-filter-popup__right__content">
					{
						<ul className="filters-popup-mtm-buttons-list">
							{
								<ul className="filters-popup-mtm-buttons-list">
									{
										candidateOfferStatusArray.map(item => {
											return <li
												className={`${filter.candidateActionsStatus.find(x => x === item.value) ? item.style : `${item.style} inactive`}`}
												key={item.offerLetterStatus}
												onClick={() => store.dispatch(setCandidateActionsStatusFilterUsersOffers(filter.candidateActionsStatus.find(x => x === item.value) ? filter.candidateActionsStatus.filter(x => x !== item.value) : [...filter.candidateActionsStatus, item.value]))}
											>
												{item.offerLetterStatus}
											</li>;
										})
									}
								</ul>
							}
						</ul>
					}
				</div>
			}
			{
				activeTab === UsersOffersTableTabsEnums.JOINING_DATE &&
				<div className="table-filter-popup__right__content">
					<div className="filters-popup-mtm-datepicker">
						<div className="filters-popup-mtm-datepicker__item">
							<label className="filters-popup-mtm-datepicker__label">From</label>
							<div className="filters-popup-mtm-datepicker__date">
								<DatePicker
									selected={filter.date.from}
									showYearDropdown={true}
									scrollableYearDropdown={true}
									yearDropdownItemNumber={70}
									onChange={(date: any) => {
										store.dispatch(setDateSelectedUsersOffers({from: date, to: filter.date.to}));
									}}
									dateFormat="MMMM d, yyyy"
									placeholderText="From"
									id={"birthFrom"}
								/>
							</div>
						</div>
						<div className="filters-popup-mtm-datepicker__item">
							<label className="filters-popup-mtm-datepicker__label">To</label>
							<div
								className={`${filter.date.from ? "filters-popup-mtm-datepicker__date" : "filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly"}`}>
								<DatePicker
									selected={filter.date.to}
									showYearDropdown={true}
									scrollableYearDropdown={true}
									yearDropdownItemNumber={70}
									onChange={(date: any) => {
										store.dispatch(setDateSelectedUsersOffers({to: date, from: filter.date.from}));
									}}
									readOnly={!filter.date.from}
									dateFormat="MMMM d, yyyy"
									minDate={filter.date.from}
									placeholderText="To"
								/>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
};

export default FilterItemsUsersOffers;

