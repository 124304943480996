import { memo, useEffect, useState } from "react";
import SingleItem from "../../Global/table/SingleMobileItem";
import React from "react";
import { getEnv } from "@urecruits/api";
import eye from "../../../image/icon/eye-on.svg";
import { DomainReviewAndScoreTabs } from "../../../enums/reviewAndScore/domainReviewAndScore";
import { setScoreTable } from "../../../store/reducers/domainReviewAndscoreReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchData from "../../../hook/http";

const { API_RECRUITMENT } = getEnv();

const ManageDomainReviewAndScoreMobileTable = ({ item }) => {
  const [openItem, setOpenItem] = useState(false);
  const [industryData, setIndustryData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/industry/label/`).then((data) => {
      if (data) {
        setIndustryData(data);
      }
    });

    fetchData(`${API_RECRUITMENT}/api/department/label/`).then((data) => {
      if (data) {
        setDepartmentData(data);
      }
    });
  }, []);

  const handleIndustry = (item) => {
    const label =
      industryData.length > 0 &&
      industryData.find((info) => info.id === item)?.label;
    return label;
  };

  const handleDepartment = (item) => {
    const label =
      departmentData.length > 0 &&
      departmentData.find((info) => info.id === item)?.label;
    return label;
  };

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => {
          setOpenItem((prev) => !prev);
        }}>
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{`${item?.assessmentName}`}</p>
        </div>
        <ArrowSvg />
      </div>
      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleItem
          title={DomainReviewAndScoreTabs.Job + ":"}
          text={item?.job}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={DomainReviewAndScoreTabs.Industry + ":"}
          text={handleIndustry(item?.industryId)}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={DomainReviewAndScoreTabs.Department + ":"}
          text={handleDepartment(item?.department)}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={DomainReviewAndScoreTabs.Candidates + ":"}
          text={item?.candidates}
          textStyle={"table-mobile__item__text table-mobile__item__text--green"}
        />
        <SingleItem
          title={DomainReviewAndScoreTabs.AverageScore + ":"}
          text={item.averageScore}
          textStyle={"table-mobile__item__text table-mobile__item__text--green"}
        />
        {
          <button
            className="table-mobile__item__action-button table-mobile__item__action-button--green"
            onClick={() => {
              dispatch(
                setScoreTable({
                  assessmentId: item?.domainId,
                  jobId: item?.id,
                  assessmentName: item?.assessmentName,
                })
              );
              navigate(
                `/recruitment/score-board/${item?.domainId}/${item?.id}`
              );
            }}>
            <img src={eye} alt="delete-icon" />
            View Candidate List
          </button>
        }
      </div>
    </div>
  );
};
export default memo(ManageDomainReviewAndScoreMobileTable);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
