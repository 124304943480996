export enum BackgroundJobsTableHeader{
  JOB_TITLE = "Job Title",
  JOB_LOCATION = "Job Location",
  OF_OPENINGS = "Openings",
  OF_SELECTED = "Verified from BGV",
  OF_SENT_BGV = "Sent for BGV",
  OF_CANDIDATE_YET_TO_SEND = "Yet to Send for BGV",
  OF_REJETED_FROM_BGV  = "Rejected from BGV",
}

export enum BackgroundJobsTableFilter{
  JOB_LOCATION = "Job Location"
}