import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EditorModals } from "../components/Popups/editor-modals";
import { store, useTypedSelector } from "../store";
import {
  setUserOffers,
  setOpenOfferId,
} from "../store/reducers/offerLetterReducer";
import PageLoader from "../components/Global/PageLoader";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import {
  OfferLetterStatus,
  OfferKeys,
  CandidateOfferStatus,
} from "../enums/usersOffers/UsersOffersEnums";
import OfferPreviewHeader from "../components/OfferPreview/OfferPreviewHeader";
import OfferPreview from "../components/OfferPreview/OfferPreview";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../hook/useSnackbar";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const getOffersFunc = (state) => state.offer_letter.userOffers;
const getOpenOfferIdFunc = (state) => state.offer_letter.openOfferId;
const OfferPreviewScreen = () => {
  const { ErrorElement, APIExecutor } = useSnackbar();
  const [modal, setModal] = useState<boolean | string>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { jobId, userId } = useParams();
  const [selectedOffer, setSelectedOffer] = useState(null);
  const navigate = useNavigate();
  const offers = useTypedSelector(getOffersFunc);
  const openOfferId = useTypedSelector(getOpenOfferIdFunc);
  const resent = offers?.length > 1;
  const { checkUserPermission } = useHasPermission();

  useEffect(() => {
    setIsLoading(true);
    checkUserPermission('offer','view') && APIExecutor(() =>
      setOffersPreviewHandler(jobId, userId).finally(() => setIsLoading(false))
    );
  }, [jobId, userId, checkUserPermission('offer','view')]);

  return (
    <>
      <AuthGuard module='offer' permission='view'>
      <ErrorElement />
        {!isLoading ? (
          <>
            <OfferPreviewHeader
              offers={offers}
              setModal={setModal}
              setSelectedOffer={setSelectedOffer}
            />

            {offers.map((offer) => (
              <OfferPreview
                offer={offer}
                key={offer.id}
                resent={resent}
                isOpen={offer.id === openOfferId}
                setModal={setModal}
                setSelectedOffer={setSelectedOffer}
              />
            ))}
            <EditorModals
              modal={modal}
              setModal={setModal}
              offerId={selectedOffer?.id}
              formData={selectedOffer}
              singleOfferDelete
            />
          </>
        ) : (
          <PageLoader />
        )}
      </AuthGuard>
    </>
  );
};

export default OfferPreviewScreen;

export const handleSendRequest = async (offerId, setModal) => {
  const sendSignatureRequest = async () => {
    await axios
      .get(
        `${API_RECRUITMENT}/api/offer/hellosign/send-signature-request/${offerId}`,
        config
      )
      .then(async ({ data }) => {
        await axios.patch(
          `${API_RECRUITMENT}/api/offer/${offerId}`,
          {
            [OfferKeys.LETTERSTATUS]: OfferLetterStatus.SENT,
            [OfferKeys.CANDIDATESTATUS]: CandidateOfferStatus.PENDING,
            [OfferKeys.HELLOSIGNSIGNATUREREQUEST_ID]:
              data.signature_request.signature_request_id,
          },
          config
        );
      });
  };

  sendSignatureRequest().finally(() => setModal(false));
};

export const setOffersPreviewHandler = async (jobId, userId) => {
  await axios(
    `${API_RECRUITMENT}/api/offer/offers/${jobId}/${userId}`,
    config
  ).then(({ data }) => {
    const offers = data.rows;
    store.dispatch(setUserOffers(offers));
    store.dispatch(setOpenOfferId(offers.toReversed()[0].id));
  });
};