import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../../store";
import play from "../../../image/icon/Vector.png";


const reviewAndScoreFun = state => state.review_and_score
const TBodyReviewAndScore = ({
	                       horizontalScrollState
                       }: { horizontalScrollState: boolean}) => {
	const table = useTypedSelector(reviewAndScoreFun)

	const navigate = useNavigate()
	return <tbody className="table__tbody">
	{
		table.tableItems?.map(item => {
			return <tr className="table__tr" key={item.id}>
				<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
					<p className='manage-team__text manage-team__text--black' style={{fontWeight: 900}}>{item.candidateName}</p>
				</td>
				{
					table.tabFilter.map(value => {
						if (value.id === 2) {
							return value.active &&
                  <td className="table__td actions manage-team__column__middle" key={value.displayName}>
                      <p className='manage-team__text manage-team__text--gray7'>
					 { item.jobTitle}
                      </p>
                  </td>
						}
						if (value.id === 3) {
							return value.active &&
                  <td className="table__td actions manage-team__column__middle" key={value.displayName}>
                      <p className='manage-team__text manage-team__text--green'>{item.assessmentType === "live-coding" ? "Live Coding" : "Take Home Task"}</p>
                  </td>
						}
						if (value.id === 5) {
							return value.active &&
                  <td className="table__td actions manage-team__column__middle" key={value.displayName}>
									<img src={play} alt="resend-icon" className="manageAssignment__table__icon 'manage-team__text manage-team__text--black" onClick={() => {
										navigate(`/live-coding/playback/${item?.playbackId}`, { state: item })
													}}/><p className='manage-team__text manage-team__text--black' style={{fontWeight: 900}}>Playback</p>
                  </td>
						}
					})
				}
			</tr>
		})
	}
	</tbody>
}

export default memo(TBodyReviewAndScore)