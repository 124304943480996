import {memo, useRef, useState} from "react";
import {JobStatus} from "../../../enums/jobs/jobsEnums";
import useClickOutside from "../../../hook/useClickOutside";
import {getEnv} from "@urecruits/api";
import {patchData} from "../../../hook/http";
import {useTypedSelector} from "../../../store";
import {setJobsHandler} from "../../../screen/JobsScreen";

const statusList = [JobStatus.PUBLISH, JobStatus.ON_HOLD, JobStatus.CLOSED]
const getStatus = (status) => {
	if (status === 'publish') return {
		currentStatus: JobStatus.PUBLISH,
		classname: 'table__status-block--green'
	}
	if (status === 'onhold') return {
		currentStatus: JobStatus.ON_HOLD,
		classname: 'table__status-block--orange'
	}
	if (status === 'closed') return {
		currentStatus: JobStatus.CLOSED,
		classname: 'table__status-block--gray'
	}
}
const tableFunc = state => state.jobs
const {API_RECRUITMENT} = getEnv()
const SelectStatus = ({status, id}) => {
	const [showStatusDropdown, setShowStatusDropdown] = useState(false)
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const {currentStatus, classname} = getStatus(status)
	useClickOutside(dropdownRef, setShowStatusDropdown)
	const {pagination, filters} = useTypedSelector(tableFunc)
	const patchJob = async (status, id) => {
		if (status === JobStatus.PUBLISH) {
			await patchData(`${API_RECRUITMENT}/api/job`, {id, status: 'publish'})
		}
		if (status === JobStatus.ON_HOLD) {
			await patchData(`${API_RECRUITMENT}/api/job`, {id, status: 'onhold'})
		}
		if (status === JobStatus.CLOSED) {
			await patchData(`${API_RECRUITMENT}/api/job`, {id, status: 'closed'})
		}
		setJobsHandler(pagination.currentPage, pagination.limit, filters)
		setShowStatusDropdown(false)
	}
	return <div className='table-dropdown-with-color-btn'>
		<div className={
			`${showStatusDropdown ? 'table-dropdown-with-color-btn__wrap active' :
				'table-dropdown-with-color-btn__wrap'}`
		}
		     ref={dropdownRef}>
			<button className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '
			        onClick={() => setShowStatusDropdown(prev => !prev)}>
				<p className={showStatusDropdown ? '' : classname}>{currentStatus}</p>
				<SVG/>
			</button>
			<ul className='table-dropdown-with-color-btn__list'>
				{
					statusList.map((roleValue, indexStatus) => {
						return <li className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__item'
						           key={indexStatus} onClick={() => patchJob(roleValue, id)}>
							{roleValue}
						</li>
					})
				}
			</ul>
		</div>
	</div>
}


const SVG = () => {
	return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.9999 5H3.41412C2.52321 5 2.07704 6.07714 2.70701 6.70711L7.2928 11.2929C7.68332 11.6834 8.31648 11.6834 8.70701 11.2929L13.2928 6.70711C13.9228 6.07714 13.4766 5 12.5857 5H7.9999Z"
			fill="#999EA5"/>
	</svg>
}
export default memo(SelectStatus)