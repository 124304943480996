export enum DomainAssessmentTabs{
	Name='Assessment Name',
	Industry='Industry',
	Department='Department',
	Time='Time Duration',
	Score='Passing Score',
	Instructions='Instructions',
	Actions='Actions'
}

export enum DomainAssessmentFilterTabs{
	Industry='Industry',
	Department='Department',
	Time='Time Duration',
	Score='Passing Score',
}