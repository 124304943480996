import { useEffect, useState } from 'react';
import AsyncSelect from "react-select/async";
import Option from "./Option";
// import { selectSearchFuncLocationLimit } from "@urecruits/api";
import { selectCustomStyle } from '../../styles/selectCustomStyle';
import {IFilterLimit} from '../../types/location-filter-types'
// const getFiltersFunc = (state) => state.jobs.filters;

const FilterLimit = ({filter,placeHolder,setLimitSearchfunction,isMulti=true}:IFilterLimit) => {

        const [hasMoreLocation, setHasMoreLocation] = useState(false);
        const [offsetLocation, setOffsetLocation] = useState(30);
        const [searchValueLocation, setSearchValueLocation] = useState(null);
        const [optionsLocation, setOptionsLocation] = useState<any[]>([]);

        const loadOptionsLocation = async (inputValue) => {
                setSearchValueLocation(inputValue)
                if (hasMoreLocation) {
                        try {
                                const response = await setLimitSearchfunction(`find=${inputValue && inputValue}&offset=${offsetLocation}`);
                                if (response.length > 0) {
                                        const newOptions = response.map((item) => ({ value: item.id, label: item.label,id:item.id }));
                                        setOffsetLocation(offsetLocation + 30);
                                        const data = [...optionsLocation, ...newOptions];
                                        setOptionsLocation(data)
                                        setHasMoreLocation(false);
                                        return data
                                } else {
                                        setHasMoreLocation(false);
                                        return optionsLocation
                                }
                        } catch (error) {
                                console.error('Error loading more options:', error);
                        }
                } else {
                        const data = await setLimitSearchfunction(`find=${inputValue && inputValue}`);
                        setOptionsLocation(data);
                        return (data);
                }
        };

        useEffect(() => {
                if (hasMoreLocation || optionsLocation?.length === 0) {
                        loadOptionsLocation(searchValueLocation !== null ? searchValueLocation : "");
                }
        }, [hasMoreLocation, optionsLocation?.length === 0]);

        return (
                <AsyncSelect
                        isMulti={isMulti}
                        components={{
                                Option
                        }}
                        cacheOptions
                        loadOptions={loadOptionsLocation}
                        defaultOptions={optionsLocation && optionsLocation}
                        options={optionsLocation && optionsLocation}
                        isLoading={hasMoreLocation}
                        
                        closeMenuOnSelect={false}
                        onBlur={() => {
                                setOffsetLocation(30);
                                loadOptionsLocation("")
                        }}
                        value={filter.value}
                        onChange={(option: any) => {
                                filter.onChangeValue(option)
                        }}
                        placeholder={placeHolder}
                        styles={selectCustomStyle}
                        id="locationSelect"
                        instanceId="locationSelect"
                        onMenuScrollToBottom={(e) => {
                                !hasMoreLocation && setHasMoreLocation(true)
                        }}
                />
        )
}

export default FilterLimit