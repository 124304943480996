export enum InterviewsFilter{
    ALL_INTERVIEW = "All Interviews",
    TECHNICAL_VIDEO_AUDIO_INTERVIEW="Technical Audio Video Interview",
    HR_AUDIO_VIDEO_INTERVIEW="HR Audio Video Interview",
    SENIOR_HR_AUDIO_VIDEO_INTERVIEW = "Senior HR Audio Video Interview",
}

export enum RoundStatus{
    PENDING ="Pending",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    REJECTED = "Rejected",
    TERMINATED = "Terminated",
    RESTARTED = 'Restarted',
  }