import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { styled } from "@mui/material/styles";
import React from "react";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    height: 36,
    padding: "0 26px 0 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

interface DropdownProps {
  items: string[] | number[] | { value: string; title: string }[];
  width: number | string;
  setValue: any;
  value: string | number;
}

export const Dropdown: React.FC<DropdownProps> = ({
  items,
  width,
  setValue,
  value = items[1],
}) => {
  const [currentItem, setPersonName] = useState<
    string | number | { title: string; value: string }
  >(value);

  const handleChange = (event: { target: { value: string } }) => {
    setPersonName(event.target.value);
  };

  useEffect(() => {
    if (!setValue) return;

    setValue(currentItem);
  }, [currentItem]);

  return (
    <div className="coding-assessments__footer-dropdown">
      <FormControl sx={{ m: 1, width }} variant="standard">
        <NativeSelect
          style={{ height: "36px" }}
          id="demo-customized-select-native"
          value={currentItem}
          onChange={handleChange}
          input={<BootstrapInput />}>
          {items.map((item) => (
            <option
              value={item?.value ? item?.value : item}
              key={item?.value ? item?.value : item}>
              {item?.title ? item?.title : item}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  );
};
