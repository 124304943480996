export enum UsersOffersTableTabsEnums {
	CANDIDATE_NAME = "Candidate Name",
	CTC = "CTC",
	CANDIDATE_ADDRESS = "Candidate Address",
	OFFER_LETTER_APPROVED_BY = "Offer Letter Approver",
	JOINING_DATE = "Joining Date",
	OFFER_LETTER_STATUS = "Offer Letter Status",
	CANDIDATE_ACTION_STATUS = "Candidate Action Status",
	OFFER = "Offer",
	ACTIONS = "Actions",
}

export enum OfferLetterStatus {
	NO_GENERATED = "no-generated",
	GENERATED = "generated",
	PENDING = "pending",
	APPROVED = "approved",
	REJECTED = "rejected",
	DRAFT = "draft",
	SENT = "sent",
	CLOSED = "closed"
}

export enum CandidateOfferStatus {
	ACCEPTED = "accepted",
	REJECTED = "rejected",
	PENDING = "pending",
}

export enum OfferKeys{
    HELLOSIGNTEMPLATEID ="hellosignTemplateId",
    LETTERSTATUS = "letterStatus",
    CANDIDATESTATUS = "candidateStatus",
	APPROVED_BY = "approvedBy",
	HELLOSIGNSIGNATUREREQUEST_ID = "hellosignSignatureRequestId"
}