import React from "react";
import Modal from "./Modal";
import { SaveTemplate } from "../Modals/save-template";
import { SignatureModal } from "../Modals/add-signature";
import { ApprovalModal } from "../Modals/approval";
import { SuccessModal } from "../Modals/success";
import { OfferApproved } from "../Modals/offer-approved";
import { OfferRejected } from "../Modals/offer-rejected";
import { DraftModal } from "../Modals/draft";
import { CloseOfferModal } from "../Modals/close-offer";
import { DeleteOfferModal } from "../Modals/delete-offer";
import { DeleteTemplateModal } from "../Modals/delete-template";
import { SelectScreeningModal } from "../Modals";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
interface props {
  modal: string | boolean;
  setModal: (param: any) => void;
  offerId?: string;
  setOpenTemplate?: (param: boolean) => void;
  formData?: any;
  singleOfferDelete?: any;
  modalData ? : any;
  refetchHandler ?: any
}

export const EditorModals: React.FC<props> = ({
  modal,
  setModal,
  offerId,
  setOpenTemplate,
  formData,
  singleOfferDelete,
  modalData,
  refetchHandler
}) => {
  const {checkUserPermission} = useHasPermission();
  return (
    <>
      {modal == "signature" && checkUserPermission('offer','edit') && (
        <Modal isOpen={!!modal} title={"Save & Send Offer"} setModal={setModal}>
          <div className="modal__body">
            <SignatureModal
              setOpenTemplate={setOpenTemplate}
              setModal={setModal}
            />
          </div>
        </Modal>
      )}

      {modal == "saveTemplate" && checkUserPermission('offer','edit') && (
        <Modal isOpen={!!modal} title={"Save & Send Offer"} setModal={setModal}>
          <div className="modal__body">
            <SaveTemplate setModal={setModal} offerId={offerId} />
          </div>
        </Modal>
      )}

      {modal === "sendForApproval" && checkUserPermission('offer','edit') && (
        <Modal isOpen={!!modal} title={"Save & Send Offer"} setModal={setModal}>
          <div className="modal__body">
            <ApprovalModal setModal={setModal} offerId={offerId} />
          </div>
        </Modal>
      )}

      {modal === "success" && (
        <Modal isOpen={!!modal} title={modalData?.title || ""} setModal={setModal} className={modalData?.title ? "" : "no-gap"}>
          <div className="modal__body">
            <SuccessModal setModal={setModal}  refetchHandler={refetchHandler} description={modalData?.description || "Successfully Action Completed"} bodyCSSClass={modalData?.title ? "description" :""}/>
          </div>
        </Modal>
      )}

      {modal === "offer-approved" && checkUserPermission('offer','edit') && (
        <Modal isOpen={!!modal} title="Approve the offer" setModal={setModal}>
          <div className="modal__body">
            <OfferApproved setModal={setModal} offerId={offerId} />
          </div>
        </Modal>
      )}

      {modal === "offer-rejected" && checkUserPermission('offer','edit') && (
        <Modal isOpen={!!modal} title="Reject the offer" setModal={setModal}>
          <div className="modal__body">
            <OfferRejected setModal={setModal} offerId={offerId} />
          </div>
        </Modal>
      )}

      {modal === "draft" && checkUserPermission('offer',['edit','add']) && (
        <Modal isOpen={!!modal} title="Send to Draft" setModal={setModal}>
          <div className="modal__body">
            <DraftModal setModal={setModal} formData={formData} />
          </div>
        </Modal>
      )}

      {modal === "close-offer" && checkUserPermission('offer','edit') && (
        <Modal
          isOpen={!!modal}
          title="Close the Current Offer"
          setModal={setModal}
        >
          <div className="modal__body">
            <CloseOfferModal setModal={setModal} data={formData} />
          </div>
        </Modal>
      )}

      {modal === "delete-offer" && checkUserPermission('offer','delete') && (
        <Modal isOpen={!!modal} title="Delete Offer" setModal={setModal}>
          <div className="modal__body">
            <DeleteOfferModal
              setModal={setModal}
              data={formData}
              singleOfferDelete={singleOfferDelete}
            />
          </div>
        </Modal>
      )}

      {modal === "delete-template" && checkUserPermission('offer','delete') && (
        <Modal isOpen={!!modal} title="Delete template" setModal={setModal}>
          <div className="modal__body">
            <DeleteTemplateModal
              setModal={setModal}
              templateId={formData.selectedTemplateId}
            />
          </div>
        </Modal>
      )}

      {modal === "screening" && (
        <Modal isOpen={!!modal} title="Send for Screening" setModal={setModal}>
          <div className="modal__body">
            <SelectScreeningModal
              setModal={setModal}
              modalData = {modalData}
            />
          </div>
        </Modal>
      )}  

    </>
  );
};
