import React,{memo} from "react";
import {store, useTypedSelector} from "../../../store";
import {DrugCandidatesFilterTabs} from "../../../enums";
import DatePicker from "react-datepicker";
import {setDateRangeFilter,setStatusFilter} from "../../../store/reducers/drugCandidatesReducer";
import {DSStatus} from "../../../enums"

const getFiltersFunc = state => state.drug_candidates.filters;
const DSStatusList = [
	{
		value : DSStatus.NOT_INITIATED,
		style : "table__status-block table__status-block--blue table__status-button"
	},
    {
		value : DSStatus.IN_PROGRESS,
		style : "table__status-block table__status-block--yellow table__status-button"
	},
	{
		value : DSStatus.REPORT_ON_HOLD,
		style : "table__status-block table__status-block--orange table__status-button"
	},
	{
		value : DSStatus.READY_FOR_REVIEW,
		style : "table__status-block table__status-block--green table__status-button"
	},
	{
		value : DSStatus.VERIFIED,
		style : "table__status-block table__status-block--green table__status-button"
	},
	{
		value : DSStatus.REJECTED,
		style : "table__status-block table__status-block--red table__status-button"
	},
]


const FiltersComponent = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc);


	return (
		<>
			{
				activeTab === DrugCandidatesFilterTabs.DATE_RANGE_OF_DS_COMPLETED && (
					<div className="table-filter-popup__right__content">
					<div className="filters-popup-mtm-datepicker">
						<div className="filters-popup-mtm-datepicker__item">
							<label className="filters-popup-mtm-datepicker__label">From</label>
							<div className="filters-popup-mtm-datepicker__date">
								<DatePicker
									selected={filter.date.from}
									showYearDropdown={true}
									scrollableYearDropdown={true}
									yearDropdownItemNumber={70}
									onChange={(date: any) => {
										store.dispatch(setDateRangeFilter({from: date, to: filter.date.to}));
									}}
									dateFormat="MMMM d, yyyy"
									placeholderText="From"
									id="dateFrom"
								/>
							</div>
						</div>
						<div className="filters-popup-mtm-datepicker__item">
							<label className="filters-popup-mtm-datepicker__label">To</label>
							<div
								className={`${filter.date.from ? "filters-popup-mtm-datepicker__date" : "filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly"}`}>
								<DatePicker
									selected={filter.date.to}
									showYearDropdown={true}
									scrollableYearDropdown={true}
									yearDropdownItemNumber={70}
									onChange={(date: any) => {
										store.dispatch(setDateRangeFilter({to: date, from: filter.date.from}));
									}}
									readOnly={!filter.date.from}
									dateFormat="MMMM d, yyyy"
									minDate={filter.date.from}
									placeholderText="To"
                                    id="dateTo"
								/>
							</div>
						</div>
					</div>
				</div>
				)
			}
            {
                activeTab === DrugCandidatesFilterTabs.CANDIDATE_DS_STATUS && (
					<div className="table-filter-popup__right__content">
					{
						<ul className="filters-popup-mtm-buttons-list">
							{
								DSStatusList.map(item => {
									return <li
										className={`${filter.status.find(x => x === item.value) ? item.style : `${item.style} inactive`}`}
										key={item.value}
										onClick={() => store.dispatch(setStatusFilter(filter.status.find(x => x === item.value) ? filter.status.filter(x => x !== item.value) : [...filter.status, item.value]))}
									>
										{item.value}
									</li>;
								})
							}
						</ul>
					}
				</div>
                )
            }
		</>
	);
};

export const CandidateFilters = memo(FiltersComponent);

