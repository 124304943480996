import { memo, useRef, useState } from "react";
import useClickOutside from "../../../../hook/useClickOutside";

const DropdownRoleMTM = ({role}: { role: Array<string> }) => {
	const [showRoleDropdown, setShowRoleDropdown] = useState(false)
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdownRef, setShowRoleDropdown)
	return role.length < 2 ?
		<p className='manage-team__text manage-team__text--black'>{role[0]}</p>
		:
		<div className='manage-team__dropdown'>
			<div className={`${showRoleDropdown ? 'manage-team__dropdown__wrap active' : 'manage-team__dropdown__wrap'}`}
			     ref={dropdownRef}>
				<button className='manage-team__text manage-team__text--black manage-team__dropdown__button'
				        onClick={() => setShowRoleDropdown(prev => !prev)}>
					<p>{role[0]}</p>
					<SVG/>
				</button>
				<ul className='manage-team__dropdown__list'>
					{
						role.map((roleValue, indexRole) => {
							return <li className='manage-team__text manage-team__text--black manage-team__dropdown__item'
							           key={indexRole}>
								{roleValue}
							</li>
						})
					}
				</ul>
			</div>
		</div>
}
export default memo(DropdownRoleMTM)

const SVG = () => {
	return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.9999 5H3.41412C2.52321 5 2.07704 6.07714 2.70701 6.70711L7.2928 11.2929C7.68332 11.6834 8.31648 11.6834 8.70701 11.2929L13.2928 6.70711C13.9228 6.07714 13.4766 5 12.5857 5H7.9999Z"
			fill="#999EA5"/>
	</svg>
}