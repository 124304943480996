export enum InterviewsCandidatesTableFilter{
    INTERVIEW_TYPE = "Interview Type"
  }

export enum InterviewsCandidatesTableHeader{
  CANDIDATE_NAME = "Candidate Name",
  TIME_DURATION = "Time Duration",
  SCORES_PERCENTAGE = "Scores Percentage",
  DATE_TIME = "Date & Time",
  INTERVIEWER_COMMENTS = "Interviewer Comments",
  SCOREBOARD = "Scoreboard",
  WORKFLOW_STATUS = "Workflow Status",
  ROUND_STATUS = "Round Status",
  ACTION = "Action",
}  