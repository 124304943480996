import {store} from "../store";

export const sortingFunc = (table, currentTab,setSortTypeFilter,setSortByFilter,setDataHandler) => {
	const tempType = table.filters.sortType === 'ASC' ? 'DESC' : "ASC"
	if (table.filters.sortBy === currentTab) {
		store.dispatch(setSortTypeFilter(tempType))
		setDataHandler(1, table.pagination.limit, {...table.filters, sortType: tempType})
	} else {
		store.dispatch(setSortTypeFilter('ASC'))
		store.dispatch(setSortByFilter(currentTab))
		setDataHandler(1, table.pagination.limit, {...table.filters, sortType: 'ASC', sortBy: currentTab})
	}
}