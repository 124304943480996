import {createSlice} from '@reduxjs/toolkit'
// eslint-disable-next-line no-duplicate-imports
import type {PayloadAction} from '@reduxjs/toolkit'
import {ReviewAndScoreTabs} from "../../enums/reviewAndScore/ReviewAndScoreEnums";
import {IReviewAndScore} from "../../types/redux/review-and-score";

export const defaultWorkflowFilters = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	assessmentType:""
}


const initialState: IReviewAndScore = {
	emptySearch: false,
	assignPopup:false,
	emptyTable: false,
	filters: defaultWorkflowFilters,
	tableItems: [],
	fixedTab: {
		id: 1,
		displayName: ReviewAndScoreTabs.CandidateName,
	},
	popupWithList:{
		title:'',
		open:false,
		id:-1,
		workflows:[]
	},
	tabFilter: [
		{
			id: 2,
			displayName: ReviewAndScoreTabs.JobTitle,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: ReviewAndScoreTabs.assessmentType,
			active: true,
			dbName: ''
		},
		{
			id: 5,
			displayName: ReviewAndScoreTabs.playbackId,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	deletePopup:{
		open:false,
		id:-1,
		title:''
	}
}

export const rs = createSlice({
	name: 'rs',
	initialState,
	reducers: {
		setDeletePopupReviewAndScore:(state, action: PayloadAction<{ open:boolean, id:number, title:string }>) => {
			state.deletePopup = action.payload
		},
		setAssignPopupReviewAndScore:(state, action: PayloadAction<boolean>) => {
			state.assignPopup = action.payload
		},
		setSortTypeFilterReviewAndScore: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setSortByFilterReviewAndScore: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setAssessmentTypeFilterReviewAndScore: (state, action: PayloadAction<string>) => {
			state.filters.assessmentType = action.payload
		},
		setSearchFilterReviewAndScore: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setLimitReviewAndScore: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1
		},
		setCurrentPageReviewAndScore: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setTotalCountReviewAndScore: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setItemsReviewAndScore: (state, action: PayloadAction<any>) => {
			state.tableItems = action.payload
		},
		setTableEmptyReviewAndScore: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setNoItemsSearchReviewAndScore: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setPopupWithListReviewAndScore: (state, action: PayloadAction<{title:string, open:boolean, id:number, workflows:Array<any>}>) => {
			state.popupWithList = action.payload
		},
	},
})


export const {
	setDeletePopupReviewAndScore,
	setAssignPopupReviewAndScore,
	setPopupWithListReviewAndScore,
	setTableEmptyReviewAndScore,
	setNoItemsSearchReviewAndScore,
	setItemsReviewAndScore,
	setTotalCountReviewAndScore,
	setCurrentPageReviewAndScore,
	setSortByFilterReviewAndScore,
	setSortTypeFilterReviewAndScore,
	setSearchFilterReviewAndScore,
	setLimitReviewAndScore,
	setAssessmentTypeFilterReviewAndScore
} = rs.actions

export default rs.reducer