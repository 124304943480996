import { store } from "../../store";
import {
	addActiveOptions,
	initialStatePopupWorkflowNew,
	removeInActiveOptions,
	setDays,
	setDaysAll,
	setDeadlineDomains, setDeadlineTakeHomeTask,
	setDefault,
	setDomainAssessment,
	setDurationsLiveCoding,
	setDurationsTakeHomeTask,
	setQuestionListLiveCoding,
	setQuestionListTakeHomeTask,
	setQuestionTypeLivecoding,
	setQuestionTypeTakeHomeTask,
	setReviewersSelectedPWPopup,
	setTime,
	setTitle
} from "../../store/reducers/positionWorkflowPopup";
import PWPopup from "../PositionWorkflow/Popup/PWPopup";
import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import fetchData from "../../hook/http";
import { getEnv } from "@urecruits/api";
import { CodingAssessmentType } from "../../enums";

const {API_RECRUITMENT} = getEnv()

const positionWorkflowPopupHOC = (WrappedComponent, setDefaultData, setData) => {
	return function (props) {
		const params = useParams()
		useLayoutEffect(() => {
			const id = params.id || props?.id
			setData(id)
		}, [params.id, props?.id])
		return <WrappedComponent {...props} returnDefault={setDefaultData}/>
	}
}

export const AddNewWorkflow = positionWorkflowPopupHOC(PWPopup, () => {
	store.dispatch(setDefault(initialStatePopupWorkflowNew))
}, (params) => {
})

export const EditWorkflow = positionWorkflowPopupHOC(PWPopup, () => {
	store.dispatch(setDefault(initialStatePopupWorkflowNew))
}, (params) => {
	fetchData(`${API_RECRUITMENT}/api/workflow/company/${params}`).then(res => {
		store.dispatch(setTitle(res.title))
		res.workflow.map(item => {
			store.dispatch(addActiveOptions(item))
			store.dispatch(removeInActiveOptions(item))
		})
		store.dispatch(setDays(res.availableDays))
		store.dispatch(setDaysAll(res.availableDays?.length === 7))
		store.dispatch(setTime(res.availableTime))
		store.dispatch(setDeadlineDomains(res.domainDeadline))
		store.dispatch(setDurationsLiveCoding(res.liveCodingTime))
		store.dispatch(setQuestionTypeLivecoding(CodingAssessmentType.LIVE_TASK))
		store.dispatch(setQuestionListLiveCoding(res.liveCodingId))
		store.dispatch(setDeadlineTakeHomeTask(res.takeHomeTaskDeadline))
		store.dispatch(setDurationsTakeHomeTask(res.takeHomeTaskTime))
		store.dispatch(setQuestionTypeTakeHomeTask(CodingAssessmentType.TAKE_HOME_TASK))
		store.dispatch(setQuestionListTakeHomeTask(res.takeHomeTaskId))
		store.dispatch(setDomainAssessment(res.domainId))
		store.dispatch(setReviewersSelectedPWPopup(res.domainReviewer))

	})
})

