import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../store";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import { useNavigate } from "react-router-dom";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { sortingFunc } from "../utils/sortingFuncForTables";
import {
  defaultFiltersJobsOffers,
  setCurrentPageJobsOffers,
  setEmptySearchJobsOffers,
  setEmptyTableJobsOffers,
  setLimitJobsOffers, setLocationFilterJobsOffers,
  setSearchValueJobsOffers,
  setSortByFilterJobsOffers,
  setSortTypeFilterJobsOffers,
  setTableItemsJobsOffers,
  setTotalCountJobsOffers,
} from "../store/reducers/jobsOffersReducer";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import { PaginationComponent, SearchFieldComponent, TheadItemComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import { CSSTransition } from "react-transition-group";
import { OffersJobsOrder } from "../components/HOCs/OrderPopupHOCs";
import TableEmpty from "../components/Global/table/TableEmpty";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TBodyInnerJobsOffers from "../components/JobsOffers/Table/TBodyInnerJobsOffers";
import MobileTableOffers from "../components/JobsOffers/MobileTable/MobileTableOffers";
import FilterItemsJobsOffers from "../components/JobsOffers/FilterPopup/FilterItemsJobsOffers";
import axios from "axios";
import { IJobsOffersTable } from "../types/redux/jobs-offers";
import useSnackbar from "../hook/useSnackbar";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const { API_RECRUITMENT } = getEnv();

const getTableOffersJobs = (state) => state.offers_jobs;
const getFiltersFunc = (state) => state.offers_jobs.filters;
const getFilterDataFunc = (state) => state.offers_jobs.filterInfo;
const getPaginationFunc = (state) => state.offers_jobs.pagination;


const FilterItems = (state) => {
  return <FilterItemsJobsOffers activeTab={state} />;
};

const FilterPopupInnerWrap = (setState) => {
  const filter = useTypedSelector(getFiltersFunc);
  const filterData = useTypedSelector(getFilterDataFunc);
  const pagination = useTypedSelector(getPaginationFunc);

  return (
    <>
      <AuthGuard module='job-post' permission='view' >
        <FilterWrapper
          setFilterPopup={setState}
          filterData={filterData}
          filters={filter}
          limit={pagination.limit}
          resetFunc={resetFilters}
          submitFunc={setJobsOffersHandler}
        >
          {FilterItems}
        </FilterWrapper>
      </AuthGuard>
    </>
  );
};

const JobsOffersScreen = () => {
  const navigate = useNavigate();

  const table = useTypedSelector(getTableOffersJobs);

  const [orderPopup, setOrderPopup] = useState(false);
  const [horizontalScrollState, setHorizontalScrollState] = useState(false);
  const { ErrorElement, APIExecutor } = useSnackbar();

  const orderPopupRef = useRef<null | HTMLButtonElement>(null);
  const tableRef = useRef(null);
  const { checkUserPermission } = useHasPermission();

  useLayoutEffect(() => {
    checkUserPermission(['job-post', 'offer'], 'view', 'AND') && APIExecutor(() =>
      setJobsOffersHandler(
        table.pagination.currentPage,
        table.pagination.limit,
        table.filters
      )
    );
  }, [checkUserPermission(['job-post', 'offer'], 'view', 'AND')]);

  const filterArray = [
    table.filters.searchValue,
    table.filters.sortBy,
    table.filters.sortType,
    table.pagination,
    table.filters.location,
  ];

  useTableClickAndDragScroll(tableRef);
  useClickOutside(orderPopupRef, setOrderPopup);

  const tdOrderCallback = useCallback(
    (value) =>
      sortingFunc(
        table,
        value,
        setSortTypeFilterJobsOffers,
        setSortByFilterJobsOffers,
        setJobsOffersHandler
      ),
    filterArray
  );

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValueJobsOffers(value));
    setJobsOffersHandler(1, table.pagination.limit, {
      ...table.filters,
      searchValue: value,
    });
  }, filterArray);

  //TODO: change link
  const emptyTableCallback = useCallback(() => navigate(""), []);

  return (
    <>
      <ErrorElement />
      <section className="offers-screen">
        <div className="table-screen-top">
          <h2>Offers</h2>
        </div>
        <AuthGuard module={['job-post', 'offer']} permission='view' option='AND' >
          <div className="table__wrapper">
            <div className="table__top">
              <div className="manage-team__top__left">
                <TopFilterButton>{FilterPopupInnerWrap}</TopFilterButton>
                <SearchFieldComponent
                  searchValue={table.filters.searchValue}
                  setSearchValue={setSearchCallback}
                  placeholder={"Search by job title"}
                />
              </div>
              <button
                className={`manage-team__top__svg ${orderPopup ? "active" : ""}`}
                ref={orderPopupRef}
              >
                <MemoOrderSVG setState={setOrderPopup} />
                {
                  <CSSTransition
                    in={orderPopup}
                    timeout={300}
                    classNames={"order-popup-mtm"}
                    unmountOnExit
                    mountOnEnter
                  >
                    <OffersJobsOrder setOrderPopup={setOrderPopup} />
                  </CSSTransition>
                }
              </button>
            </div>
            {table.noResultSearch || table.tableEmpty ? (
              table.tableEmpty ? (
                <TableEmpty
                  handler={emptyTableCallback}
                  cta={"Add offer"}
                  title={"Table is empty"}
                  desc={"JobsOffers table is empty, add your first offer"}
                />
              ) : (
                <NoResultsSearch
                  limit={table.pagination.limit}
                  resetFunc={resetFilters}
                />
              )
            ) : (
              <>
                <table
                  className="table offers-table"
                  ref={tableRef}
                  onScroll={(e: any) => {
                    if (e.target.scrollLeft > 10 && !horizontalScrollState)
                      setHorizontalScrollState(() => true);
                    else if (e.target.scrollLeft < 10 && horizontalScrollState)
                      setHorizontalScrollState(() => false);
                  }}
                >
                  <thead className="table__thead">
                    <td
                      className={`table__td sticky ${horizontalScrollState ? "moved" : ""
                        } table__td--thead offers-table__column__default`}
                    >
                      <TheadItemComponent
                        title={table.fixedTab.displayName}
                        dbName={""}
                      />
                    </td>
                    {table.tabFilter.map((item) => {
                      return (
                        item.active && (
                          <td
                            className={`table__td  table__td--thead offers-table__column__default`}
                            key={item.id}
                          >
                            <TheadItemComponent
                              title={item.displayName}
                              handler={item.dbName ? tdOrderCallback : null}
                              dbName={item.dbName}
                            />
                          </td>
                        )
                      );
                    })}
                  </thead>
                  <TBodyInnerJobsOffers
                    horizontalScrollState={horizontalScrollState}
                  />
                </table>
                <MobileTableOffers />
                <PaginationComponent
                  limit={table.pagination.limit}
                  currentPage={table.pagination.currentPage}
                  totalCount={table.pagination.totalCount}
                  setCurrentPage={setJobsOffersHandler}
                  setLimit={setLimitHandler}
                  filters={table.filters}
                />
              </>
            )}
          </div>
        </AuthGuard>
      </section>
    </>
  );
};

export default JobsOffersScreen;

//TODO: need to write func for get data
export const setJobsOffersHandler = async (
  page: number,
  limit: number,
  filters: any
) => {
  store.dispatch(setCurrentPageJobsOffers(page));

  await axios(
    `${API_RECRUITMENT}/api/offer/jobs/offers?limit=${limit}
		&offset=${(page - 1) * limit}
		&status=publish
		${filters.location.map((item) => `&locations=${item.value}`).join("")}
		&search=${encodeURIComponent(filters.searchValue)}`,
    config
  ).then((res) => {
    store.dispatch(setTotalCountJobsOffers(res.data.count));

    const items = transformArrayToTableData(res.data.rows);
    store.dispatch(setTableItemsJobsOffers(items));

    if (items.length == 0) {
      if (filters.searchValue === "" && filters.location.length === 0) {
        store.dispatch(setEmptyTableJobsOffers(true));
      } else {
        store.dispatch(setEmptySearchJobsOffers(true));
      }
    } else {
      store.dispatch(setEmptySearchJobsOffers(false));
      store.dispatch(setEmptyTableJobsOffers(false));
    }
  });
};

const transformArrayToTableData = (array: any): Array<IJobsOffersTable> => {
  return array.map((item) => {
    return {
      id: item.id,
      jobTitle: item.title,
      jobLocation:
        item.locations.length > 0
          ? `${item.locations[0].city}, ${item.locations[0].state}`
          : null,
      remoteLocations: item.remoteLocation,
      jobOpening: item.numberOpenings,
      recruiterApprovedCount: item.recruiterApprovedCount,
      recruiterPendingCount: item.recruiterPendingCount,
      recruiterSendCount: item.recruiterSendCount,
      candidateApprovedCount: item.candidateApprovedCount,
      candidateRejectedCount: item.candidateRejectedCount,
    };
  });
};

const setLimitHandler = (value: any) => {
  store.dispatch(setLimitJobsOffers(value));
};

const resetFilters = (pagination) => {
  store.dispatch(setLocationFilterJobsOffers([]));
  store.dispatch(setSortByFilterJobsOffers("id"));
  store.dispatch(setSortTypeFilterJobsOffers("ASC"));
  store.dispatch(setSearchValueJobsOffers(""));
  setJobsOffersHandler(1, pagination, defaultFiltersJobsOffers);
};