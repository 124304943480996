import { store, useTypedSelector } from "../../../store";
import { memo } from "react";
import {
  setPopupWithScheduleOn,
} from "../../../store/reducers/assignmentCandidateReducer";
import VideoMeetLoader from "../../../../assessment/live-coding/components/video-meeting/Loader/VideoMeetLoader";
const tableItemsFunc = (state) => state.assignment_candidate.tableItems;
const tableTabsFunc = (state) => state.assignment_candidate.tabFilter;

const AssignmentCandidateTBody = ({
  horizontalScrollState,
}: {
  horizontalScrollState: boolean;
}) => {
  const tableItems = useTypedSelector(tableItemsFunc);
  const tableTabs = useTypedSelector(tableTabsFunc);
  const renderStatusBlock = (round) => {
    const getStatusClass = (status) => {
      switch (status) {
        case 'completed':
          return 'table__status-block--green';
        case 'notStarted':
          return 'table__status-block--gray';
        case 'inProgress':
          return 'table__status-block--yellow';
        case 'rejected':
          return 'table__status-block--red';
        default:
          return null;
      }
    };
  
    const statusClass = getStatusClass(round?.status);
    return statusClass ? <p className={statusClass}>{round?.message}</p> : null;
  };
  
  const renderStatusMessage = (arr) => {
    const inProgressIndex = arr.indexOf('In Progress');
    const hasPending = arr.includes('Pending') && !arr.includes('In Progress');
    const hasRejected = arr.includes('Rejected');
    const allCompleted = !arr.includes('Pending') && !arr.includes('In Progress') && !arr.includes('Rejected');
  
    let  obj = { status: 'notStarted', message: 'Yet To be started' };
    if (allCompleted) {
      obj = { status: 'completed', message: `Completed ${arr?.length} rounds` };
    } else if (!hasPending && !hasRejected && !allCompleted) {
      obj = { status: 'notStarted', message: 'Yet to be started' };
    } else if (hasRejected) {
      obj = { status: 'rejected', message: 'Rejected' };
    } else if (inProgressIndex !== -1) {
      obj = { status: 'inProgress', message: `Round ${inProgressIndex + 1} in progress` };
    } else {
      obj = { status: 'notStarted', message: 'Yet To be started' };
    }
  
    return obj;
  };
  
  return (
    <>
      <tbody className="table__tbody">
          {tableItems?.length===0 && <VideoMeetLoader/>}
          {tableItems?.map((item) => {
            
          return (
            <tr className="table__tr" key={item.id}>
              {tableTabs.filter(i=>i.active).map((value) => {
                if (value.id === 1) {
                  return (
                      <td
                      className="table__td actions jobs-table__column__default"
                        key={value.displayName}>
                        <div className="jobs-table__locations">
                          <p className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                          {`${item?.name}`}
                          </p>
                        </div>
                      </td>
                  );
                }
                if (value.id === 2) {
                  return (
                      <td
                      className="table__td actions jobs-table__column__default"
                        key={value.displayName}>
                        <div className="jobs-table__locations">
                          <p className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                            {`${item?.email}`}
                          </p>
                        </div>
                      </td>
                  );
                }
                if (value.id === 3) {
                  return (
                      <td
                      className="table__td actions jobs-table__column__default"
                        key={value.displayName}>

                        <button
                          className="manage-team__text manage-team__text--green manage-team__text__button"
                          onClick={() =>
                            store.dispatch(
                              setPopupWithScheduleOn({
                                title: item.title,
                                open: true,
                                id: item.id,
                                data: item?.rounds,
                                ids: item?.userId,
                              })
                            )
                          }>
                          See all schedule
                        </button>
                      </td>
                  );
                }
                if (value.id === 4) {
                  const arr = item?.rounds?.map((tt) => tt?.status);
                  const obj = renderStatusMessage(arr);
                  return (
                    <td className="table__td actions manage-team__column__middle" key={value.displayName}>
                        {obj?.status && (
                          <div className='table-dropdown-with-color-btn' style={{maxWidth:"200px"}}>
                            <div className={'table-dropdown-with-color-btn__wrap'}>
                              <div className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
                                {renderStatusBlock(obj)}
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                  );
                }
              })}

            </tr>
          );
        })}
      </tbody>
    </>
  );
};
export default memo(AssignmentCandidateTBody);
