import React from "react";
import {
  createOffer,
  updateOffer,
} from "../../screen/GenerateOfferLetterScreen";
import { OfferLetterStatus } from "../../enums/usersOffers/UsersOffersEnums";

export const DraftModal = ({ setModal, formData }) => {
  const handleSendDraft = () => {
    const { jobId, userId, roundId, subscribeId, offerId, data, edit } =
      formData;
    data.letterStatus = OfferLetterStatus.DRAFT;
    if (edit) {
      updateOffer(offerId, data).then((data) => {
        setModal("success");
      });

      return;
    }

    createOffer(jobId, userId, roundId, subscribeId, data).then((data) => {
      setModal("success");
    });
  };
  return (
    <>
      <div className="modal__body__draft">
        <p className="modal__body__text">
          Are you sure you want to send this offer to draft? You can come back
          to this later.
        </p>
      </div>

      <div className="modal__actions">
        <button
          className="modal__actions__cancel__outlined"
          onClick={() => setModal(false)}
        >
          Cancel
        </button>
        <button className="modal__actions__send" onClick={handleSendDraft}>
          Send to Draft
        </button>
      </div>
    </>
  );
};
