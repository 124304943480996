import {memo} from "react";
import emptyTable from '../../images/icons/table/empty-table.svg'
import emptyFilter from '../../images/icons/table/empty-filter.svg'
import emptySearch from '../../images/icons/table/empty-search.svg'
import { ITableEmpty } from "../../types/table-types";

const TableEmpty=({ type, handler,desc,title,buttonText}: ITableEmpty)=>{
	const image = type === 'emptyFilter' ? emptyFilter : type === 'emptySearch' ? emptySearch : emptyTable;
	const defaultTitle = type === 'emptyFilter' || type === 'emptySearch' ? 'Ooops!' : 'Table is empty';
	const defaultDesc = type === 'emptyFilter' || type === 'emptySearch' ? "We couldn't find any results for your query" : '';

		return <div className='table-empty'>
			<img src={image} alt={'no-results'} className='table-empty__image'/>
			<h2 className='table-empty__title'>{title || defaultTitle}</h2>
			{ desc || defaultDesc ? <p className='table-empty__desc'>{ desc || defaultDesc }</p> : ''}
			{!!handler  ?
			<button className='button--filled table-empty__button' onClick={()=>handler()}>
				{buttonText}
			</button> : ''}
		</div>
}
export default memo(TableEmpty)