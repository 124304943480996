import {memo} from "react";

const TableCheckbox = ({id,checked,handler}) => {
	return <div className="table__checkbox">
		<input
			id={id}
			type="checkbox"
			checked={checked}
			onChange={handler}
		/>
		<label htmlFor={id}><span/></label>
	</div>
}
export default memo(TableCheckbox)

