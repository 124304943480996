import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	getConfig,
	PaginationComponent, TableEmptyComp, TheadItemComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { DomainAssessmentTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import { getEnv } from "@urecruits/api";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import {
	defaultFiltersDomainAssessmentScreen,
	setCurrentPageDomainAssessment,
	setEmptySearchDomainAssessment,
	setEmptyTableDomainAssessment,
	setLimitDomainAssessment,
	setScoreFilterDomainAssessment,
	setSearchValueDomainAssessment,
	setSortByDomainAssessment, setSortTypeDomainAssessment, setTableItemsCandidateDomainAssessment, setTotalCountDomainAssessment
} from "../store/reducers/candidateDomainAssessmentReducer";
import ManageCandidateDomainTBody from "../components/reviewAndScore/DomainAssessment/CandidateDomainAssessment/ManageCandidateDomainTBody";
import PopupWithInstruction from "../components/DomainAssessment/PopupWithInstruction";
import axios from "axios";
import FilterPopupManageDomainAssessment from "../components/reviewAndScore/DomainAssessment/CandidateDomainAssessment/FilterPopupManageDomainAssessment";
import ManageCandidateDomainMobileTBody from "../components/reviewAndScore/DomainAssessment/CandidateDomainAssessment/ManageCandidateDomainMobileTBody";

const {API_RECRUITMENT,API_ASSESSMENT} = getEnv()
const ids={assessmentId:null, jobId:null}
const getFilters = state => state.candidate_domain_assessment.filters
const tableFunc = state => state.candidate_domain_assessment
const getFilterDataFunc = state => state.candidate_domain_assessment.filterInfo
const getLimitFunc = state => state.candidate_domain_assessment.pagination.limit
const getPopupWithInstruction = (state) => state.candidate_domain_assessment.popupWithInstruction;
const getScoreTable=state=>state.domain_review_and_score.scoreTable

//TODO replace component
const FilterItems = state => <FilterPopupManageDomainAssessment activeTab={state}/>
const FilterPopupInnerWrap = (setState) => {
	const {assessmentId, jobId}= useParams()
	ids.assessmentId=assessmentId;
	ids.jobId=jobId;
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const limit = useTypedSelector(getLimitFunc)
	return <FilterWrapper setFilterPopup={setState}
	                      resetFunc={resetFunc}
	                      filters={filters}
	                      filterData={filtersData}
	                      submitFunc={setDomainAssessmentScreenHandler}
	                      limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}
const CandidateDomainScreen = () => {
	const popupWithInstruction = useTypedSelector(getPopupWithInstruction);
	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);
	const {assessmentId, jobId}= useParams()
	ids.assessmentId=assessmentId;
	ids.jobId=jobId;
	const {assessmentName} = useTypedSelector(getScoreTable)
	if(assessmentName === "" && assessmentId===null && jobId === null) {
		navigate("/recruitment/review-score")
	}
	const table = useTypedSelector(tableFunc)

	useLayoutEffect(() => {
		setDomainAssessmentScreenHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, [popupWithInstruction?.open]);


	useEffect(()=>{
		return ()=>{			
			store.dispatch(setTableItemsCandidateDomainAssessment([]))
		}
	},[])

//TODO add filter array
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueDomainAssessment(value))
		setDomainAssessmentScreenHandler(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);
//TODO replace callback
	const emptyTableCallback = useCallback(() => {
		navigate('/')
	}, [],);
useTableClickAndDragScroll(tableRef)

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeDomainAssessment, setSortByDomainAssessment, setDomainAssessmentScreenHandler), filterArray,);
	useClickOutside(orderPopupRef, setOrderPopup)
	return <section>
			{
			table.popupWithInstruction.open&&<PopupWithInstruction/>
			}
		<div className='table-screen-top'>
			<div className='table-screen-top__wrapper'>
				<div >
					<p style={{fontSize:"16px"}}>
						<span onClick={()=>{
							navigate("/recruitment/review-score")
						}} style={{color:"black",cursor:"pointer"}}>Review & Score </span>/
						{/* <span onClick={()=>{
							navigate("/recruitment/review-score")
						}} style={{color:"black",cursor:"pointer"}}> Domain Assessment </span>/ */}
						<span style={{color:"black",cursor:"not-allowed"}}> Scoreboard</span>
					</p>
					<h2>{assessmentName}</h2>
				</div>
			
			</div>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<TopFilterButton>
						{
							FilterPopupInnerWrap
						}
					</TopFilterButton>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup}/>
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<DomainAssessmentTableOrder setOrderPopup={setOrderPopup}/>
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptySearch ? 
						table.filters.score[0] > 0 ? <TableEmptyComp type="emptyFilter" buttonText="Reset Filter" desc="" handler={()=>resetFunc(table.pagination.limit)}/>:
						<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc}/>
							: <TableEmpty handler={emptyTableCallback} cta="" title={'No Scores Available'}
							              desc={'The scoreboard table is currently empty. Please check back later.'}/>
					)
					:
					<>
						<table className='table' ref={tableRef} onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">
							<td
								className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead jobs-table__column__default`}>
								<TheadItemComponent title={table.fixedTab.displayName} handler={null} dbName={''}/>
							</td>
							{
								table.tabFilter.map((item) => {
									return item.active &&
                      <td className={`table__td  table__td--thead jobs-table__column__default`}
                          key={item.id}>
                          <TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
                                              dbName={item.dbName}/>
                      </td>
								})
							}

							</thead>
							 <ManageCandidateDomainTBody horizontalScrollState={horizontalScrollState}/>
						</table>

						<div className='table-mobile'>
							{
								table.tableItems.map(item => {
									return <ManageCandidateDomainMobileTBody key={item.id} item={item}/>
								})
							}
						</div> 
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setDomainAssessmentScreenHandler}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
					</>
			}
		</div>
	</section>
}
export default memo(CandidateDomainScreen)

export const setDomainAssessmentScreenHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageDomainAssessment(page))
	const getData = async () => {	
		await axios(`${API_ASSESSMENT}/api/domain-result/all?jobId=${ids?.jobId}
		&assessmentId=${Number(ids?.assessmentId)}
		${filters.score.map(item => `&minScore=${item}`).join('')}
		&offset=${(page - 1) * limit}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}
		`, getConfig()).then(async res => {				
		if(res.data.result?.rows?.length===0){
			if (filters.score[0] > 0){
				store.dispatch(setEmptySearchDomainAssessment(true))
			}else{
				store.dispatch(setEmptyTableDomainAssessment(true))
			}
		}else{
			store.dispatch(setTotalCountDomainAssessment(res.data.result.count))
			const items =await transformArrayToTableData(res.data.result.rows)
			store.dispatch(setTableItemsCandidateDomainAssessment(items))
			if (items.length === 0) {
				if (filters.searchValue === '' || filters.score[0] === 0)
				{
					store.dispatch(setEmptySearchDomainAssessment(true))
				} else{
					store.dispatch(setEmptyTableDomainAssessment(true))
				}
			} else {
				store.dispatch(setEmptySearchDomainAssessment(false))
				store.dispatch(setEmptyTableDomainAssessment(false))
			}
		}
			
		})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitDomainAssessment(value))
}
const transformArrayToTableData = async (array: any) => {
	const dataArray=[]
	 array?.length > 0 && array.map(item => {		
		dataArray.push({
			id: item.id,
			candidateId: item?.candidateId,
			timeDuration: item?.duration,
			score: item?.totalScore,
			preview: item?.id,
		})
	})
	return dataArray
  };
  
const resetFunc = (pagination) => {
	store.dispatch(setScoreFilterDomainAssessment([0]))
	store.dispatch(setSearchValueDomainAssessment(''))
	store.dispatch(setSortByDomainAssessment('id'))
	store.dispatch(setSortTypeDomainAssessment('DESC'))
	setDomainAssessmentScreenHandler(1, pagination, defaultFiltersDomainAssessmentScreen)
}