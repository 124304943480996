import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import defaultUser from "../../image/temp-user.png";
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import { findActiveRound } from "../../utils";
import fetchData from "../../hook/http";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_ASSESSMENT,API_RECRUITMENT} = getEnv();
const token: string = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  };


const ReviewAndScoreList = ({position}) => {
	const [data,setData] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [isEmptyData,setIsEmptyData] = useState(false)

	useEffect(()=>{
		async function getInfo(){
			try{
			await fetchData(`${API_RECRUITMENT}/api/subscribe/scoreboard?limit=20&offset=0`).then(data=>{
				if(!data?.rows.length){
					setIsEmptyData(true)
				}else{
					setData(data?.rows);
				}
				setIsLoading(false)
			})
			}catch(error){
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}

		getInfo().then()
	},[])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">Review & Score</p>
                <div className="dashboard-list__links">
                    <Link to="/recruitment/scoreboard" className="dashboard-list__head__link">more</Link>
                </div>
			</div>
			{
				isLoading && <Stack height={"100%"}>
                    <Skeleton animation="wave" />
                </Stack>
			}
            {isEmptyData ?<DashboardEmptyDataComponent />
			:
				<div className="dashboard-list__list">
					{data.length>0 && 
					data.map(assessment=>{
						return <ShowListItem  assessment={assessment} key={assessment.id} /> 
					})
					}
				</div>
			}
		</div>
	);
};

export default memo(ReviewAndScoreList);

const ShowListItem = ({assessment})=>{
	const navigate = useNavigate()
	const roundData = assessment.round?.rounds && findActiveRound(assessment.round?.rounds)

	return <div className="dashboard-list__item" >
	<div className="dashboard-list__item__inner">
        <div className="dashboard-list__item__top">
            <img src={assessment.user?.avatar || defaultUser} alt="" className="dashboard-list__item__avatar"/>
            <p className="dashboard-list__item__username">{(assessment.user?.firstname || "" ) + " " + (assessment.user?.lastname || "")}</p>
        </div>
		<div className="dashboard-list__item__bottom">
			{roundData && 
                <p className="dashboard-list__item__dot">{roundData.title}</p>
            }
			<p className="dashboard-list__item__text">{assessment.totalScores || 0}%</p>
		</div>
	</div>
	{/* <div className="dashboard-list__item__arrow" onClick={()=>navigate("/recruitment/scoreboard")}>
		<ArrowRight/>
	</div> */}
</div>
}

// const ArrowRight = memo(() => {

// 	return (
// 		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
// 		</svg>
// 	);
// });