import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { getEnv } from "@urecruits/api";
import * as React from "react";
import { useState } from "react";
import { postData } from "../../api/fetchDataApi";
import FileUploadProgressBar from "../../components/FileUploadProgress/FileUploadProgressBar";
import fetchData from "../../hook/http";
import "../../styles/_mail-box-screen.scss";
import EnhancedTable from "../Table/S3StorageTable";
import { useLocation, useNavigate } from "react-router-dom";
import RichTextEditor from "../DomainAssessment/popup/RichTextEditor";
const { API_RECRUITMENT } = getEnv();

const style = {
  position: "absolute" as "absolute",
  bottom: "0",
  right: "4px",
  zIndex: "1200",
  width: {
    xs: "80%",
    sm: "60%",
    md: "50%",
    lg: "40%",
    xl: "35%",
  },
  height: {
    xs: "65%",
    sm: "60%",
    md: "70%",
    lg: "70%",
    xl: "60%",
  },
  borderRadius: "12px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const style1 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  height: "24%",
  borderRadius: "12px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  padding: "1.5rem",
};

const style2 = {
  position: "absolute" as "absolute",
  minWidth: "330px",
  minHeight: "520px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "75%",
  borderRadius: "12px",
  bgcolor: "#f0f0f0",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "1.5rem",
};

interface s3StorangeModalProps {
  open: boolean;
  handleClose: () => void;
  setAttachmentFile: any;
}

const SendMail = (data) => {
  const [open, setOpen] = React.useState(false);
  const [sendMailList, setSendMailList] = useState([]);
  const [subject, setSubject] = useState("");
  const [bodyData, setBodyData] = useState("");
  const [getData, setData] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [provider, setProvider] = useState("gmail");

  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });
  const [attachmentFiles, setAttachmentFile] = useState<
    { file: any; upload: boolean }[]
  >([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [errorMessage, setIsErrorMessage] = useState({
    title: "",
    message: "",
  });


  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);


  const [isFromInputEnable, setIsFromInputEnable] = useState(false);
  const [isS3StorageModalOpen, setIsS3StorageModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuPopupOpen = Boolean(anchorEl);

  React.useEffect(() => {
    if (queryParams.get('email')) {
      const email = decodeURIComponent(queryParams.get('email'))
      setOpen(true);
      setSendMailList(prev => {
        let arr = [...prev]
        if (!arr.includes(email)) {
          arr.push(email)
        }
        return arr;
      }
      )
      setIsFromInputEnable(prev => !prev)
      queryParams.delete('email')
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  }, [])

  const handleMaxFileSizeWarningModalClose = () => setIsWarningModalOpen(false);
  const handleS3StorageModalClose = () => {
    setIsS3StorageModalOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuPopupClose = () => {
    setAnchorEl(null);
  };
  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };
  const handleS3Popup = () => {
    handleMenuPopupClose();
    setIsS3StorageModalOpen(true);
  };
  const getProvider = async () => {
    const apiUrl = `${API_RECRUITMENT}/api/integrations/provider`;
    try {
      await fetchData(apiUrl).then((result) => {
        result.mailbox !== "" && setProvider(result.mailbox);
        data?.action === "draft" && fetchData(`${API_RECRUITMENT}/api/${result.mailbox !== "" ? result.mailbox : provider}/getById/${data?.draftId}`).then((result) => {
          setData(result)
          setBodyData(result?.data?.body)
          setSubject(result?.data?.subject)
          setSendMailList(result?.data?.to)
        })
      });
    } catch (error) {
      setToastNotification({
        message: error.message,
        state: true,
      });
    }
  };

  React.useEffect(() => {
    getProvider();
  }, []);

  const handleSendMail = async (body) => {
    setToastNotification({
      state: true,
      message: "Sending...",
    });

    try {
      let data
      if (provider === "gmail" && attachmentFiles?.length !== 0) {
        const file = attachmentFiles[0]
        const attachFormData = new FormData()
        attachFormData.append('upload', file?.file)
        data = await postData(`${API_RECRUITMENT}/api/file/private`, attachFormData)
        body?.append("s3File", data?.key)
      }
      const result = await postData(
        `${API_RECRUITMENT}/api/${provider}/send-mail`,
        body
      );
      setIsFromInputEnable(false);
      setSendMailList([]);
      setAttachmentFile([]);
      setSubject("");
      setBodyData("");
      handleMenuPopupClose();
      if (result) {
        data?.action === "draft" && deleteMailHandler([{ id: data?.draftId }], "send")
        setToastNotification({
          state: true,
          message: "Message Sent",
        });
      }
    } catch (error) {
      setToastNotification({
        state: true,
        message: "Something went wrong, Please try again",
      });
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    
    setIsFromInputEnable(false);
    setAttachmentFile([]);
    setSendMailList([]);
    handleMenuPopupClose();
    setSubject("");
    setBodyData("");
    setOpen(false);
  };

  const deleteMailHandler = async (mailList, action) => {
    const apiUrl = `${API_RECRUITMENT}/api/${provider}/delete-mail`;
    const body = {
      id: mailList.map((mail) => mail.id),
    };
    let result;
    try {
      await postData(apiUrl, body).then((result) => {
        action === "close" && setToastNotification({
          message: "Draft saved successfully",
          state: true,
        });
      });
    } catch (error) {
      setToastNotification({
        message: error.message,
        state: true,
      });
    }
    return result;
  };

  const sendMailHandler = async () => {
    if (sendMailList.length === 0) {
      setIsErrorMessage({
        title: "Error",
        message: "Please specify at least one recipient",
      });
      setIsWarningModalOpen(true);
      return;
    }
    if (bodyData === "") {
      const result = confirm(
        "Send this message without a subject or text in the body?"
      );
      if (!result) {
        return;
      }
    }
    const formData = new FormData();

    const body = {
      to: sendMailList.toString(),
      subject,
      body: bodyData,
      files: attachmentFiles,
      action: "send",
    };

    formData.append("to", body.to);
    formData.append("subject", body.subject);
    formData.append("body", body.body);
    formData.append("action", body.action);
    if (attachmentFiles.length > 0) {
      if (attachmentFiles[0].file?.key) {
        const newArray = attachmentFiles.map((file) => {
          return {
            fileName: file?.file?.fileName,
            key: file?.file?.key,
          };
        });

        formData.append("s3File", JSON.stringify(newArray));
      } else {
        attachmentFiles.map((file) => formData.append("files", file.file));
      }
    }
    handleSendMail(formData);
  };

  React.useEffect(() => {
    let result = [];
    result = attachmentFiles.filter(({ file }) => file.size / 1000000 > 25);
    if (attachmentFiles && result.length > 0) {
      setAttachmentFile([]);
      setIsErrorMessage({
        title: " Max File Size Error",
        message:
          "Maximum limit to upload the file is 25 mb, please upload less then 25 mb file",
      });
      setIsWarningModalOpen(true);
    } else {
      if (attachmentFiles.length > 0 && attachmentFiles[0]?.upload) {
        setIsFileUploaded(true);
        setTimeout(() => {
          setIsFileUploaded(false);
        }, 3800);
      }
    }
  }, [attachmentFiles]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <Snackbar
        open={toastNotification.state}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
      <div>
        {provider && <Button onClick={handleOpen} className="mail-box-create-button" style={{ color: data?.action === "draft" && "white", background: data?.action === "draft" && "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)" }}>
          Send Mail
        </Button>}
        <ErrorMessageModal
          open={isWarningModalOpen}
          handleClose={handleMaxFileSizeWarningModalClose}
          title={errorMessage.title}
          errorMessage={errorMessage.message}
        />
        <S3StorageModal
          open={isS3StorageModalOpen}
          handleClose={handleS3StorageModalClose}
          setAttachmentFile={setAttachmentFile}
        />
        {open && (
          <Box>
            <>
              {
                <Box sx={style}>
                  <div className="send-mail">
                    <div className="header">
                      <div>New Message</div>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="description">
                      <div className="description-input">
                        {!isFromInputEnable && (
                          <div
                            onClick={() => setIsFromInputEnable((prev) => !prev)}
                            className="disable">
                            Recipients
                          </div>
                        )}
                        {isFromInputEnable && <span>To</span>}
                        {isFromInputEnable && (
                          <input
                            style={{ padding: "0 38px" }}
                            value={sendMailList.toString()}
                            onChange={(e) => {
                              const result = e.target.value.split(",");
                              setSendMailList(result);
                            }}
                            type="text"
                            className="input"
                          />
                        )}
                      </div>
                      <div className="description-input">
                        <input
                          value={subject}
                          onClick={() => {
                            if (isFromInputEnable && sendMailList.length === 0)
                              setIsFromInputEnable(false);
                          }}
                          onChange={(event) => setSubject(event.target.value)}
                          type="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="body">
                      <RichTextEditor
                        placeholder={"Type something..."}
                        setValue={value => {
                          if (isFromInputEnable && sendMailList.length === 0)
                            setIsFromInputEnable(false);
                          setBodyData(value)
                        }}
                        value={bodyData} />
                      {/* <textarea
                    value={bodyData}
                    onClick={() => {
                      if (isFromInputEnable && sendMailList.length === 0)
                        setIsFromInputEnable(false);
                    }}
                    onChange={(e) => setBodyData(e.target.value)}
                  /> */}

                      <div
                        style={{
                          padding: "0 1rem",
                          display: "flex",
                          flexDirection: "column",
                          gap: ".5rem",
                        }}>
                        {attachmentFiles.map((file, index) => (
                          <div key={index} style={{ padding: "0 1rem" }}>
                            <FileUploadProgressBar
                              file={file.file}
                              isUpload={file.upload}
                              setAttachmentFile={setAttachmentFile}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="action">
                      <button
                        disabled={isFileUploaded}
                        className={`button ${isFileUploaded && "disable"}`}
                        onClick={sendMailHandler}>
                        Send
                      </button>
                      <input
                        onChange={(event) => {
                          if (event.target.files?.length > 0) {
                            const newFiles = Array.from(event.target.files).map(
                              (file) => {
                                return {
                                  file: file,
                                  upload: true,
                                };
                              }
                            );
                            setAttachmentFile(newFiles);
                          }
                        }}
                        hidden
                        type="file"
                        id="upload"
                        multiple
                      />
                      {/* <label htmlFor="upload"></label> */}
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          marginLeft: "10px",
                        }}
                        onClick={handleClick}>
                        <AttachFileIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={menuPopupOpen}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        onClose={handleMenuPopupClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}>
                        <MenuItem
                          sx={{ padding: "0px" }}
                          onClick={handleMenuPopupClose}>
                          {" "}
                          <label style={{ padding: "6px 16px" }} htmlFor="upload">
                            From Computer
                          </label>
                        </MenuItem>
                        {provider !== "gmail" && <MenuItem onClick={handleS3Popup}>From Files</MenuItem>}
                      </Menu>
                    </div>
                  </div>
                </Box>
              }
            </>
          </Box>
        )}
      </div>
    </>
  );
};

const ErrorMessageModal = ({
  open = false,
  handleClose,
  title,
  errorMessage,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1}>
          <div className="send-mail">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <h3>{title}</h3>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              style={{
                width: "80px",
                position: "absolute",
                bottom: "12px",
                right: "30px",
              }}
              onClick={handleClose}>
              Ok
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const S3StorageModal: React.FC<s3StorangeModalProps> = ({
  open = false,
  handleClose,
  setAttachmentFile,
}) => {
  const [selected, setSelected] = React.useState<any>([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFile = () => {
    const newFiles = selected.map((file) => {
      return {
        file: {
          fileName: file.fileName,
          key: file.key,
          size: file.size,
        },
        upload: true,
      };
    });
    setAttachmentFile(newFiles);
    setSelected([]);
    handleClose();
  };

  React.useEffect(() => {
    if (open && files.length === 0) {
      setLoading(true);
      fetchData(`${API_RECRUITMENT}/api/file/all-files`).then((data) => {
        setFiles(data.data);
        setLoading(false);
      });
    }
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setSelected([]);
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style2}>
          <EnhancedTable
            rows={files}
            selected={selected}
            setSelected={setSelected}
            loading={loading}
          />
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
            }}>
            <Button
              onClick={() => {
                handleClose();
                setSelected([]);
              }}
              variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={selected.length === 0}
              variant="contained"
              onClick={handleFile}
              className="button">
              Select
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SendMail;
