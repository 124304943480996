import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";
import { IColReorder } from "@urecruits/recruitments/types/global/global";
import { IScoreboard } from "../../types/redux/scoreboard";

export const defaultFiltersScoreboard = {
	searchValue: "",
	sortBy: "id",
	sortType: "ASC",
	jobId:0,
    totalScore:[0, 100],
};

const initialStateScoreboard: IScoreboard = {
  tableView: "table",
  noResultSearch: false,
  tableEmpty: false,
  filters: defaultFiltersScoreboard,
  pagination: {
    currentPage: 1,
    limit: 10,
    totalCount: 0,
  },
  colReorder: [],
  filterInfo: {
    tabs: ["Job Title","Total scores"],
  },
  workflowPopup:{
	title:"",
	open:false,
	workflows:[],
  }
};

export const scoreboard = createSlice({
	name: "scoreboard",
	initialState: initialStateScoreboard,
	reducers: {
		setTableEmptyScoreboard: (state, action: PayloadAction<boolean>) => {
			state.tableEmpty = action.payload;
		},
		setNoResultSearchScoreboard: (state, action: PayloadAction<boolean>) => {
			state.noResultSearch = action.payload;
		},
		setCurrentPageScoreboard: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload;
		},
		setLimitScoreboard: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1;
		},
		// setOrderScoreboard: (state, action: PayloadAction<Array<ITabsFilter>>) => {
		// 	state.tabFilter = action.payload;
		// },
		setOrderScoreboardColumns: (state, action: PayloadAction<Array<IColReorder>>) => {
			state.colReorder = action.payload;
		},
		setTotalCountScoreboard: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload;
		},
		setSortTypeFilterScoreboard: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload;
		},
		setSortByFilterScoreboard: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload;
		},
		setSearchValueScoreboard: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload;
		},
        setJobTitleScoreboard: (state, action: PayloadAction<number>)=>{
            state.filters.jobId = action.payload
        },
        setTotalScoresScoreboard: (state, action: PayloadAction<Array<number>>)=>{
            state.filters.totalScore = action.payload
        },
		setWorkflowPopupScoreboard:(state,action:PayloadAction<any>)=>{
			state.workflowPopup = action.payload;
		}
		
	},
});

export const {
	setTableEmptyScoreboard,
	setNoResultSearchScoreboard,
	setCurrentPageScoreboard,
	setLimitScoreboard,
	setOrderScoreboardColumns,
	setTotalCountScoreboard,
	setSortTypeFilterScoreboard,
	setSortByFilterScoreboard,
	setSearchValueScoreboard,
    setJobTitleScoreboard,
    setTotalScoresScoreboard,
	setWorkflowPopupScoreboard
} = scoreboard.actions;

export default scoreboard.reducer;