export enum AssignmentCandidateTabs{
	candidateName='Candidate Name',
	email='Email',
	scheduleOn='Schedule On',
	status='Status'
}

export enum AssignmentCandidateFilterTabs{
	name='Candidate Name',
}


export enum RoundStatus{
		Completed='Completed',
		IN_PROGRESS='In Progress',
		NOT_STARTED='Yet to schedule',
		REJECTED='Rejected'
}