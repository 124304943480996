import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import {
	IMTMRolesList,
	IMTMUser,
	ManageTeamMembersTable
} from "../../types/redux/manage-team-members";
import { ManageTeamMembersTableTabsEnums } from "../../enums/manage-team-members/ManageTeamMembersEnums";
import { IAsyncCheckBoxList, ITabsFilter } from "../../types/global/global";

export const defaultFiltersMTM={
	roles: [],
	education:[],
	dateOfJoining:{
		from:null,
		to:null
	},
	dateOfBirth:{
		from:null,
		to:null
	},
	location:[],
	position:[],
	department:[],
	searchValue: '',
	sortBy:'id',
	sortType:'DESC'
}
const initialStateMTM: ManageTeamMembersTable = {
	noUsersSearch:false,
	tableEmpty:false,
	filters:defaultFiltersMTM ,
	users: [],
	nameTab: {
		id: 1,
		displayName: ManageTeamMembersTableTabsEnums.NAME,
	},
	userTabsFilter: [
		{
			id: 2,
			displayName: ManageTeamMembersTableTabsEnums.ID,
			active: true,
			dbName:'id'
		},
		{
			id: 3,
			displayName: ManageTeamMembersTableTabsEnums.EMAIL,
			active: true,
			dbName:'officeEmail'
		},
		{
			id: 4,
			displayName: ManageTeamMembersTableTabsEnums.PHONE,
			active: true,
			dbName:'officePhone'
		},
		{
			id: 5,
			displayName: ManageTeamMembersTableTabsEnums.JOB_TITLE,
			active: true,
			dbName:'positionId'
		},
		{
			id: 6,
			displayName: ManageTeamMembersTableTabsEnums.DEPARTMENT,
			active: true,
			dbName:'department'
		},
		{
			id: 7,
			displayName: ManageTeamMembersTableTabsEnums.JOB_LOCATION,
			active: true,
			dbName:'locationId'
		},
		{
			id: 8,
			displayName: ManageTeamMembersTableTabsEnums.EDUCATION,
			active: true,
			dbName:'degree'
		},
		{
			id: 9,
			displayName: ManageTeamMembersTableTabsEnums.DATE_OF_JOINING,
			active: true,
			dbName:'dateJoining'
		},
		{
			id: 10,
			displayName: ManageTeamMembersTableTabsEnums.DATE_OF_BIRTH,
			active: true,
			dbName:'birthday'
		},
		{
			id: 11,
			displayName: ManageTeamMembersTableTabsEnums.ROLE,
			active: true,
			dbName:''
		},
		{
			id: 12,
			displayName: ManageTeamMembersTableTabsEnums.ADDRESS,
			active: true,
			dbName:'currentCity'
		},
		{
			id: 13,
			displayName: ManageTeamMembersTableTabsEnums.STATUS,
			active: true,
			dbName:'profileStatus'
		},
		{
			id: 14,
			displayName: ManageTeamMembersTableTabsEnums.ACTIONS,
			active: true,
			dbName:''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	deletePopupInfo:{
		open:false,
		name:'',
		id:-1
	},
	resendMail:{
		open:false,
		id:-1,
	},
	filterInfo: {
		rolesList: [],
		tabs: [
			ManageTeamMembersTableTabsEnums.ROLE,
			ManageTeamMembersTableTabsEnums.JOB_TITLE,
			ManageTeamMembersTableTabsEnums.DEPARTMENT,
			ManageTeamMembersTableTabsEnums.JOB_LOCATION,
			ManageTeamMembersTableTabsEnums.EDUCATION,
			ManageTeamMembersTableTabsEnums.DATE_OF_JOINING,
			ManageTeamMembersTableTabsEnums.DATE_OF_BIRTH,
		],
	},
	createMemberPopup:false,
	editMemberPopup:{
		open:false,
		id:null,
	}
}

export const mtm = createSlice({
	name: 'mtm',
	initialState: initialStateMTM,
	reducers: {
		setTableEmptyMTM:(state,action:PayloadAction<boolean>)=>{
			state.tableEmpty=action.payload
		},
		setCurrentPageMTM: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setResendPopupMTM: (state, action: PayloadAction<{open:boolean,id:number}>) => {
			state.resendMail = action.payload
		},
		setCreateMemberPopupMTM: (state, action: PayloadAction<boolean>) => {
			state.createMemberPopup = action.payload
		},
		setEditMemberPopupMTM: (state, action: PayloadAction<{ open: boolean, id: number }>) => {
			state.editMemberPopup = action.payload
		},
		setLimitMTM: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload,
				state.pagination.currentPage = 1
		},
		setSearchValueMTM: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderMTM: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.userTabsFilter = action.payload
		},
		setTotalCountMTM: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setUsersMTM: (state, action: PayloadAction<Array<IMTMUser>>) => {
			state.users = action.payload
		},
		setRolesListMTM: (state, action: PayloadAction<Array<IMTMRolesList>>) => {
			state.filterInfo.rolesList = action.payload
		},
		setRolesSelectedMTM: (state, action: PayloadAction<Array<IMTMRolesList>>) => {
			state.filters.roles = action.payload
		},
		setEducationalSelectedMTM: (state, action: PayloadAction<Array<string>>) => {
			state.filters.education = action.payload
		},
		setBirthSelectedMTM:(state,action:PayloadAction<{ from:string|null, to:string|null }>)=>{
			state.filters.dateOfBirth=action.payload
		},
		setDateJoinSelectedMTM:(state,action:PayloadAction<{ from:string|null, to:string|null }>)=>{
			state.filters.dateOfJoining=action.payload
		},
		setDeletePopupMTM:(state,action:PayloadAction<{open:boolean, name:string, id:number}>)=>{
			state.deletePopupInfo=action.payload
		},
		setPositionFilterMTM:(state,action:PayloadAction<Array<IAsyncCheckBoxList>>)=>{
			state.filters.position=action.payload
		},
		setLocationFilterMTM:(state,action:PayloadAction<Array<IAsyncCheckBoxList>>)=>{
			state.filters.location=action.payload
		},
		setDepartmentFilterMTM:(state,action:PayloadAction<Array<IAsyncCheckBoxList>>)=>{
			state.filters.department=action.payload
		},
		setSortByFilterMTM:(state,action:PayloadAction<string>)=>{
			state.filters.sortBy=action.payload
		},
		setSortTypeFilterMTM:(state,action:PayloadAction<string>)=>{
			state.filters.sortType=action.payload
		},
		setNoUsersSearchMTM:(state,action:PayloadAction<boolean>)=>{
			state.noUsersSearch=action.payload
		},
	},
})


export const {
	setTableEmptyMTM,
	setResendPopupMTM,
	setNoUsersSearchMTM,
	setSortTypeFilterMTM,
	setSortByFilterMTM,
	setDepartmentFilterMTM,
	setLocationFilterMTM,
	setPositionFilterMTM,
	setDeletePopupMTM,
	setDateJoinSelectedMTM,
	setBirthSelectedMTM,
	setEducationalSelectedMTM,
	setRolesSelectedMTM,
	setRolesListMTM,
	setCurrentPageMTM,
	setLimitMTM,
	setTotalCountMTM,
	setSearchValueMTM,
	setOrderMTM,
	setUsersMTM,
	setCreateMemberPopupMTM,
	setEditMemberPopupMTM
} = mtm.actions

export default mtm.reducer