import React, { memo } from "react";

const ItemNotAvailable = ({ text }: {text: string}) => {
    return (
        <div className="table_disable_row" >
            <div className="table_disable_row__chip">{text}</div>
        </div>

    )
}

export default memo(ItemNotAvailable)
