import { InterviewsFilter, RoundStatus } from "../../enums";
import { findFirstInterview, findLastInterview, findRejectedInterview } from "./searchRound";

const interviewTypes = [
  InterviewsFilter.HR_AUDIO_VIDEO_INTERVIEW,
  InterviewsFilter.TECHNICAL_VIDEO_AUDIO_INTERVIEW,
  InterviewsFilter.SENIOR_HR_AUDIO_VIDEO_INTERVIEW,
];

export const isInterview = (round) => {
  return interviewTypes.includes(round?.title);
};

export const isRoundNotCompleted = (round) => {
  return round?.status !== RoundStatus.COMPLETED;
};

export const isRoundInProgress = (round) => {
  return round?.status === RoundStatus.IN_PROGRESS;
};

export const ifAllInterviewsPending = (rounds) =>{
    
    const firstInterview = findFirstInterview(rounds);
    
    return  firstInterview?.status === RoundStatus.PENDING ;
}

export const ifAnyInterviewInProgress = (rounds) =>{

    const firstInterview = findFirstInterview(rounds);
    const lastInterview  = findLastInterview(rounds);

    return firstInterview?.status !== RoundStatus.PENDING && lastInterview?.status !== RoundStatus.COMPLETED;
}

export const ifAllInterviewsCompleted = (rounds)=>{

    const lastInterview  = findLastInterview(rounds);
    return lastInterview?.status === RoundStatus.COMPLETED;

}

export const ifAnyInterviewRejected  = (rounds) =>{
    if(!Array.isArray(rounds)) return null;

    const rejectedInterview = findRejectedInterview(rounds);
    return rejectedInterview ? true : false;
}