import type {PayloadAction} from '@reduxjs/toolkit'
// eslint-disable-next-line no-duplicate-imports
import {createSlice} from '@reduxjs/toolkit'
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";
import {ICandidateDomainAssessment, ICandidateDomainAssessmentTableItems} from "../../types/redux/candidate-domain-assessment";
import {CandidateDomainAssessmentFilterTabs, CandidateDomainAssessmentTabs} from "../../enums/candidate-domain-assessment/candidate-domain-assessment";


export const defaultFiltersDomainAssessmentScreen = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	score:[0]
}
const candidateDomainAssessmentReducer:ICandidateDomainAssessment = {
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersDomainAssessmentScreen,
	tableItems: [],
	openPreview:{
		isOpen:false,
		id:null
	},
	popupWithInstruction:{
		open:false,
		id:-1,
		instruction:'',
		title:null,
	},
	fixedTab: {
		id: 1,
		displayName: CandidateDomainAssessmentTabs.Name,
	},
	tabFilter: [
		{
			id: 2,
			displayName: CandidateDomainAssessmentTabs.Time,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: CandidateDomainAssessmentTabs.Score,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: CandidateDomainAssessmentTabs.Date,
			active: true,
			dbName: ''
		},
		{
			id: 5,
			displayName: CandidateDomainAssessmentTabs.Preview,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				CandidateDomainAssessmentFilterTabs.Score
			],
	}
}

export const CandidateDomainAssessmentReducer = createSlice({
	name: 'domain_assessment',
	initialState: candidateDomainAssessmentReducer,
	reducers: {
		setLimitDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setSearchValueDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderDomainAssessment: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setSortByDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setEmptyTableDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setEmptySearchDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setTableItemsCandidateDomainAssessment: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
		setTotalCountDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setOpenPreview: (state, action: PayloadAction<any>) => {
			state.openPreview = action.payload
		},
		setCurrentPageDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setScoreFilterDomainAssessment: (state, action: PayloadAction<Array<any>>) => {
			state.filters.score = action.payload
		},
	},
})

export const {	
	setSortTypeDomainAssessment,
	setLimitDomainAssessment,
	setSortByDomainAssessment,
	setOrderDomainAssessment,
	setSearchValueDomainAssessment,
	setEmptyTableDomainAssessment,
	setEmptySearchDomainAssessment,
	setTableItemsCandidateDomainAssessment,
	setTotalCountDomainAssessment,
	setCurrentPageDomainAssessment,
	setScoreFilterDomainAssessment,
	setOpenPreview,
} = CandidateDomainAssessmentReducer.actions

export default CandidateDomainAssessmentReducer.reducer