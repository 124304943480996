import { useEffect, useRef } from "react";
import React from "react";

interface Props {
  children?: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  closeBtn?: boolean;
  onOpen?: boolean;
  title?: string;
  setModal?: any
}

export const Modal: React.FC<Props> = (props) => {
  const { children, className, closeBtn = true, isOpen = null, title, setModal } = props;

  const modalRef = useRef<HTMLDialogElement | null>(null);

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;

      if (isOpen) {
        (modalElement as any).showModal();
      }

      return ()=>{
        (modalElement as any).close();
      }
    
  }, [isOpen]);


  return (
    <dialog ref={modalRef} onKeyDown={handleKeyDown} className={`modal ${className ? className : ''} `}>
      <div className="modal__header" >
        <div className="modal__header__title">{title}</div>
        <div className="modal__header__close-icon" onClick={handleCloseModal}><CloseIcon /></div>      
      </div>
        {children}   
    </dialog> 
  );

};

export default Modal;


const CloseIcon = () => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" name="close">
  <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>); 