import { useState, useEffect } from "react";
import { default as ReactSelect } from "react-select";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import {
  OfferKeys,
  OfferLetterStatus,
} from "../../enums/usersOffers/UsersOffersEnums";
const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

export const ApprovalModal = ({ setModal, offerId }) => {
  const [userOption, setUserOption] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getApproverList = async () => {
      await axios(`${API_RECRUITMENT}/api/company/get-lead-users`, config).then(
        (res) => {
          if (res.status == 200) {
            setUserOption(
              res.data.map((item) => {
                return {
                  value: `${item.id}`,
                  label: `${item.firstname} ${item.lastname}, ${item.email}`,
                };
              })
            );
          }
        }
      );
    };
    getApproverList();
  }, []);
  const handleSendForApproval = () => {
    if (!selectedId) {
      setError(true);
      return;
    }

    const patchData = async () => {
      await axios
        .patch(
          `${API_RECRUITMENT}/api/offer/${offerId}`,
          {
            [OfferKeys.APPROVED_BY]: selectedId,
            [OfferKeys.LETTERSTATUS]: OfferLetterStatus.PENDING,
          },
          config
        )
        .then(({ data }) => {
          setModal("success");
        });
    };
    patchData().then();
  };
  return (
    <>
      <div className="modal__body__approval">
        <p className="modal__body__text">
          Are you sure you want to submit this offer for approval? This action
          cannot be undone.
        </p>
      </div>

      <div className="modal__body__approval">
        <strong className="modal__body__text">Approver</strong>
        <ReactSelect
          options={
            userOption.length > 0 ? userOption : [{ value: "", label: "" }]
          }
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          onChange={(currentValue: any) => {
            setSelectedId(currentValue.value);
          }}
          placeholder={`Select Approver`}
          styles={selectCustomStyle}
          id="name"
          instanceId="name"
        />
        {error && !selectedId && (
          <p className={`modal__body__error-message ${error ? "active" : ""}`}>
            Required field
          </p>
        )}
      </div>
      <div className="modal__actions">
        <button
          className="modal__actions__cancel__outlined"
          onClick={() => setModal(false)}
        >
          Cancel
        </button>
        <button
          className={`modal__actions__send ${!selectedId ? "disabled" : ""}`}
          onClick={handleSendForApproval}
          disabled={!!error}
        >
          Send For Approval
        </button>
      </div>
    </>
  );
};
