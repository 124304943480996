import React from "react";
import { Range, getTrackBackground } from "react-range";
import { useDispatch } from "react-redux";
import { setScoreFilterDomainAssessment } from "../../../../../store/reducers/candidateDomainAssessmentReducer";

const STEP = 1;
const MIN = 0;
const MAX = 100; 
const COLORS = ["#099C73", "#ccc"];

const CustomRange = ({ values }) => {
  const dispatch = useDispatch();

  return (
    <div className="range__wrapper">
      <Range
        step={STEP}
        min={MIN}
        max={MAX}
        values={values}
        onChange={(newValues) =>
          dispatch(setScoreFilterDomainAssessment(newValues))
        }
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className="range__line__wrapper"
          >
            <div
              ref={props.ref}
              className="range__line"
              style={{
                background: getTrackBackground({
                  values,
                  colors: COLORS,
                  min: MIN,
                  max: MAX,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, index, isDragged }) => (
          <div
            {...props}
            className="range__track"
            style={{ display: "flex" }}
          >
            <div
              className="range__track__rigth"
              style={{ backgroundColor: isDragged ? "#548BF4" : "#CCC" }}
            />
            <div
              className="range__track__left"
              style={{ backgroundColor: isDragged ? "#548BF4" : "#CCC" }}
            />
          </div>
        )}
      />
      <output className="range__input__wrapper" id="output">
      {">"}
        
        <input
          className="range__input"
          type={"number"}
          value={values[0]}
          max={MAX}
          min={MIN}
          disabled
          onChange={(e) => {
            const inputValue = +e.target.value;
            const newValues = [inputValue, values[1]];
            dispatch(setScoreFilterDomainAssessment(newValues));
          }}
        />
      </output>
    </div>
  );
};

export default CustomRange;
