import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IOfferLetter} from "../../types/redux/offer-letter";

const initialOfferState: IOfferLetter = {
  offer: {},
  userOffers: [],
  openOfferId: null,
};

const offerSlice = createSlice({
  name: "offer-letter",
  initialState: initialOfferState,
  reducers: {
    setOffer: (state, action: PayloadAction<any>) => {
      state.offer = action.payload;
    },
    setUserOffers: (state, action: PayloadAction<any>) => {
      state.userOffers = action.payload;
    },
    setOpenOfferId: (state, action: PayloadAction<any>) => {
      state.openOfferId = action.payload;
    },
  },
});

export const { setOffer, setUserOffers, setOpenOfferId } = offerSlice.actions;
export default offerSlice.reducer;