import { ICandidatesInterviews } from "../../types/redux/interviews";
import { InterviewsFilter, InterviewsCandidatesTableFilter } from "../../enums";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAsyncCheckBoxList } from "../../types/global/global";
import { IColReorder } from "@urecruits/recruitments/types/global/global";

export const defaultFilters = {
  interviewType: InterviewsFilter.ALL_INTERVIEW,
  searchValue: "",
  sortBy: "id",
  sortType: "DESC",
};

const initialState: ICandidatesInterviews = {
  candidates: [],
  colReorder: [],
  filters: defaultFilters,
  pagination: {
    currentPage: 1,
    limit: 10,
    totalCount: 0,
  },
  filterInfo: {
    tabs: [InterviewsCandidatesTableFilter.INTERVIEW_TYPE],
  },
};

const interviewsCandidatesSlice = createSlice({
  name: "interviews_candidates",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSortTypeFilter: (state, action: PayloadAction<string>) => {
      state.filters.sortType = action.payload;
    },
    setSortByFilter: (state, action: PayloadAction<string>) => {
      state.filters.sortBy = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.filters.searchValue = action.payload;
    },
    setTableItems: (state, action: PayloadAction<any>) => {
      state.candidates = action.payload;
    },
    setOrderColumns: (state, action: PayloadAction<Array<IColReorder>>) => {
      state.colReorder = action.payload;
    },
    setInterviewType: (state, action: PayloadAction<string>) => {
      state.filters.interviewType = action.payload;
    },
  },
});

export const {
  setCurrentPage,
  setLimit,
  setTotalCount,
  setSortTypeFilter,
  setSortByFilter,
  setSearchValue,
  setTableItems,
  setOrderColumns,
  setInterviewType,
} = interviewsCandidatesSlice.actions;

export default interviewsCandidatesSlice.reducer;
