import React, { memo } from "react";
import Pagination from "../Pagination";
import SearchField from "../../SearchField/SearchField";
import TableEmpty from "../TableEmpty";
import TopFilterButton from "../Filter/TopFilterButton";
import FilterWrapper from "../Filter/FilterWrapper";
import ItemNotAvailable from "../ItemNotAvailable";
import SmallLoader from "../SmallLoader";

const TableCardList = ({ loading, rowData, columns, emptyTable, noUsersSearch, disableRow, searchField, pagination, tableListCard, filters }: any) => {

    const actions = columns.find(column => column.field === 'actions');

    const FilterPopupInnerWrap = (setState) => {
        if (!filters) return;
        const { filter, filterData, filterItems, resetFilters, setIsFilterSubmit } = filters;
        return (
            <FilterWrapper
                setFilterPopup={setState}
                filterData={filterData}
                resetFunc={resetFilters}
                pagination={pagination}
                setIsFilterSubmit={setIsFilterSubmit}
            >
                {filterItems}
            </FilterWrapper>
        );
    };

    return (
        <>

            <div className="table-card-list-container">
                {
                    loading ? <SmallLoader /> :
                        emptyTable && emptyTable.type === 'emptyTable' ?
                            <TableEmpty
                                type={emptyTable.type}
                                handler={emptyTable.handler}
                                buttonText={emptyTable.buttonText}
                                title={emptyTable.title}
                                desc={emptyTable.desc}
                            />
                            :
                            <>
                                <div className="table-card-filter">
                                    {filters ?
                                        <TopFilterButton>
                                            {FilterPopupInnerWrap}
                                        </TopFilterButton>
                                        : ''}
                                    {
                                        searchField ? <SearchField
                                            searchValue={searchField.searchValue}
                                            setSearchValue={searchField.setSearchValue}
                                            placeholder={searchField.placeholder}
                                        />
                                            : ''}

                                </div>
                                {
                                    loading ? <SmallLoader /> :
                                        emptyTable ?
                                            <TableEmpty
                                                type={emptyTable.type}
                                                handler={emptyTable.handler}
                                                buttonText={emptyTable.buttonText}
                                                title={emptyTable.title}
                                                desc={emptyTable.desc}
                                            />
                                            : <>
                                                <div className="table-card-list">
                                                    <div className="table-card-list__inner">
                                                        {
                                                            rowData.map((item) =>
                                                                <div className="table-card-list__card" key={item.id}>
                                                                    {disableRow && disableRow.getDisableStatus(item) ?
                                                                        <ItemNotAvailable text={disableRow.text} />
                                                                        : ''}
                                                                    {tableListCard({ row: item, columns, actions: actions ? actions.setActions : '' })}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {pagination ?
                                                    <Pagination
                                                        limit={pagination.limit}
                                                        currentPage={pagination.currentPage}
                                                        totalCount={pagination.totalCount}
                                                        setCurrentPage={pagination.setCurrentPage}
                                                        setLimit={pagination.setLimit}
                                                    />
                                                    : ''}
                                            </>}
                            </>
                }
            </div>

        </>
    )
}

export default memo(TableCardList)
