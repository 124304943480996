import DetailsStep from "../components/GenerateOfferLetter/Steps/DetailsStep";
import { GenerateOfferTabs } from "../enums/generate-offer/generate-offer";
import { useTypedSelector } from "../store";
import Progress from "../components/GenerateOfferLetter/Progress";
import OfferDetailsStep from "../components/GenerateOfferLetter/Steps/OfferDetailsStep";
import OfferBodyStep from "../components/GenerateOfferLetter/Steps/OfferBodyStep";
import axios from "axios";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";

import { getEnv } from "@urecruits/api";
import { useParams, useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";
import { OfferLetterStatus } from "../enums/usersOffers/UsersOffersEnums";
import draftIcon from "../image/icon/red_edit_ic.svg";
import { EditorModals } from "../components/Popups/editor-modals";
import { Link } from "react-router-dom";
import { setOfferHandler } from "./OfferViewScreen";
import AsideModal from "../components/GenerateOfferLetter/AsideModal";
import useSnackbar from "../hook/useSnackbar";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const progressWidthArr = [
  {
    id: 1,
    value: "30%",
  },
  {
    id: 2,
    value: "75%",
  },
  {
    id: 3,
    value: "100%",
  },
];

const { API_RECRUITMENT } = getEnv();
const getScreenData = (state) => state.generate_offer;
const getOfferFunc = (state) => state.offer_letter.offer;

const GenerateOfferLetterScreen = ({ edit = false }) => {
  const screenData = useTypedSelector(getScreenData);
  const navigate = useNavigate();
  const { ErrorElement, APIExecutor } = useSnackbar();
  const { jobId, userId, roundId, subscribeId, offerId } = useParams();
  const [modal, setModal] = useState<string | boolean>(false);
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<
    number | boolean
  >(null);
  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const offer = useTypedSelector(getOfferFunc);
  const { checkUserPermission } = useHasPermission();

  const OfferSchema = z.object({
    candidateFirstname: z.string().trim().min(1, { message: "Required field" }),
    candidateLastname: z.string().trim().min(1, { message: "Required field" }),
    candidateEmail: z.string().email(),
    candidatePhone: z.string().trim().min(5, { message: "Required field" }),
    ctc: z.string().trim().min(1, { message: "Required field" }),
    candidateAddress: z.string().trim().min(1, { message: "Required field" }),
    jobTitle: z.string().trim().min(1, { message: "Required field" }),
    companyName: z.string().trim().min(1, { message: "Required field" }),
    jobLocation: z.object({
      id: z.number(),
    }),
    joiningDate: z.coerce.date(),
    hellosignTemplate: z.object({
      id: z.number(),
    }),
    signingTitle: z.string().trim(),
    signingSubject: z.string().trim(),
    signingMessage: z.string().trim(),
  });
  type OfferSchemaType = z.infer<typeof OfferSchema>;

  const { handleSubmit, ...form } = useForm<OfferSchemaType>({
    resolver: zodResolver(OfferSchema.required()),
  });

  useEffect(() => {
    setCurrentTab(GenerateOfferTabs.CANDIDATE_DETAILS);
  }, []);

  useEffect(() => {
    setOfferHandler(offerId);
  }, [offerId]);

  const onSubmit = (data) => {
    data.letterStatus = OfferLetterStatus.GENERATED;
    if (edit && checkUserPermission('offer', 'edit')) {
      APIExecutor(() =>
        updateOffer(offerId, data).then((resData) =>
          navigate(
            `/recruitment/offers/preview/${resData.jobId}/${resData.userId}`
          )
        )
      );

      return null;
    }
    checkUserPermission('offer', 'add') && APIExecutor(() =>
      createOffer(jobId, userId, roundId, subscribeId, data).then((resData) =>
        navigate(
          `/recruitment/offers/preview/${resData.jobId}/${resData.userId}`
        )
      )
    );
  };

  useLayoutEffect(() => {
    (!form.getValues("candidateFirstname") ||
      !form.getValues("candidateLastname") ||
      !form.getValues("candidateEmail") ||
      !form.getValues("candidatePhone") ||
      !form.getValues("jobTitle") ||
      !form.getValues("companyName")) &&
      checkUserPermission('offer', ['edit','view']) && APIExecutor(() => formFillUpHandler(userId, jobId, offerId, form, edit));
  }, [checkUserPermission('offer', ['edit','view'])]);

  return (
    <>
      <ErrorElement />
      <AuthGuard module='offer' permission={['add', 'edit']}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="generate-offer">
            <div className="generate-offer__head">
              <div className="generate-offer__head__left">
                <p className="generate-offer__head__headline">
                  Generate Offer Letter
                </p>
              </div>

              <div className="generate-offer__head__right">
                <Link
                  className="generate-offer__head__cancel"
                  to={`/recruitment/offers/${jobId ? jobId : offer.jobId}`}
                >
                  Cancel
                </Link>

                <button
                  className="generate-offer__head__action"
                  type="button"
                  onClick={() => setModal("draft")}
                >
                  <img src={draftIcon} alt="draft icon" />
                  Send to Draft
                </button>
                <div>
                  <button className="generate-offer__head__generate button--filled">
                    Preview
                  </button>
                  {Object.keys(form.formState.errors).length > 0 && (
                    <p className="generate-offer__head__right__error-message">
                      Please fill required fields
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Progress
              array={[
                GenerateOfferTabs.CANDIDATE_DETAILS,
                GenerateOfferTabs.OFFER_DETAILS,
                GenerateOfferTabs.OFFER_BODY,
              ]}
              progressWidthArr={progressWidthArr}
              currentScreen={currentTab}
            />
            {GenerateOfferTabs.CANDIDATE_DETAILS === currentTab && (
              <DetailsStep {...form} setCurrentTab={setCurrentTab} />
            )}
            {GenerateOfferTabs.OFFER_DETAILS === currentTab && (
              <OfferDetailsStep {...form} setCurrentTab={setCurrentTab} />
            )}
            {GenerateOfferTabs.OFFER_BODY === currentTab && (
              <OfferBodyStep
                {...form}
                key={Date.now()}
                setIsCreateTemplate={setIsCreateTemplate}
                setModal={setModal}
                setSelectedTemplateId={setSelectedTemplateId}
                setCurrentTab={setCurrentTab}
              />
            )}
          </section>
        </form>
      </AuthGuard>
      {isCreateTemplate && (
        <AuthGuard module='offer' permission='add' >
          <AsideModal setIsCreateTemplate={setIsCreateTemplate} />
        </AuthGuard>
      )}
      <AuthGuard module='offer' permission='edit' >
        <EditorModals
          modal={modal}
          setModal={setModal}
          formData={{
            jobId,
            userId,
            roundId,
            subscribeId,
            offerId,
            data: form.getValues(),
            edit,
            selectedTemplateId,
          }}
        />
      </AuthGuard>
    </>
  );
};

export default GenerateOfferLetterScreen;

export const formFillUpHandler = async (userId, jobId, offerId, form, edit) => {
  if (edit) {
    const { data: offerData } = await axios(
      `${API_RECRUITMENT}/api/offer/${offerId}`,
      config
    );
    const jobLocation = offerData.location
      ? {
        value: `${offerData.location?.city}, ${offerData.location?.state}`,
        label: `${offerData.location?.city}, ${offerData.location?.state}`,
        id: offerData.location.id,
      }
      : null;

    const joiningDate = offerData.joiningDate
      ? new Date(offerData.joiningDate)
      : null;

    const hellosignTemplate = await setTemplateHandler(
      offerData.hellosignTemplateId
    );
    form.reset({
      ...offerData,
      joiningDate,
      jobLocation,
      hellosignTemplate,
    });
    return null;
  }

  const { data: jobData } = await axios(
    `${API_RECRUITMENT}/api/job/company/${jobId}`,
    config
  );

  const { data: candidateData } = await axios(
    `${API_RECRUITMENT}/api/candidate/${userId}`,
    config
  );

  const jobLocation = {
    value: `${jobData.locations?.[0].city}, ${jobData.locations?.[0].state}`,
    label: `${jobData.locations?.[0].city}, ${jobData.locations?.[0].state}`,
    id: jobData.locations?.[0].id,
  };
  form.reset({
    jobTitle: jobData.title,
    companyName: jobData.company.name,
    jobLocation,
    candidateFirstname: candidateData.user.firstname,
    candidateLastname: candidateData.user.lastname,
    candidateEmail: candidateData.user.email,
    candidatePhone: candidateData.user.phone,
    candidateAddress: candidateData.location
      ? candidateData.location.city + ", " + candidateData.location.state
      : "",
  });
};

export const createOffer = async (
  jobId: string,
  userId: string,
  roundId: string,
  subscribeId: string,
  data: any
) => {
  const dataBody = {
    ...data,
    jobId,
    userId,
    roundId,
    subscribeId,
    locationId: data.jobLocation?.id,
    hellosignTemplateId: data.hellosignTemplate?.id ?? null,
  };

  const getData = await axios.post(
    `${API_RECRUITMENT}/api/offer`,
    dataBody,
    config
  );

  return getData.data;
};

export const updateOffer = async (offerId: string, data: any) => {
  const dataBody = {
    ...data,
    locationId: data.jobLocation?.id,
    hellosignTemplateId: data.hellosignTemplate?.id ?? null,
  };

  const patchData = await axios.patch(
    `${API_RECRUITMENT}/api/offer/${offerId}`,
    dataBody,
    config
  );

  return patchData.data;
};

export const setTemplateHandler = async (templateId) => {

  if (!templateId) return null;
  const response = await axios(`${API_RECRUITMENT}/api/offer/hellosign/template/${templateId}`, config)
    .then(({ data }) => data);

  return {
    value: response.id,
    label: response.templateName,
    id: response.id,
  };
}

