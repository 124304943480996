import AsyncSelect from "react-select/async";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { setJobTitleScoreboard, setTotalScoresScoreboard } from "../../store/reducers/scoreboardReducer";
import Option from "../Global/SelectOptions";
import { store, useTypedSelector } from "../../store";
import { CustomRangeComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv();
const token: any = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};
const JobFilter = () => {
  const filters = useTypedSelector((state) => state.scoreboard.filters);
  const getJobs = async (inputValue) => {
    try {
      const response = await axios(`${API_RECRUITMENT}/api/company/jobs`, {
        ...config,
        params: {
          search: inputValue,
        },
      });

      const jobOptions = response?.data?.rows.map((job) => ({
        value: job.id,
        label: job.title,
      }));

      return jobOptions || [];
    } catch (error) {
      console.error("Error fetching jobs:", error);
      return [];
    }
  };

  return (
    <div className="table-filter-popup__right__content">
      <AsyncSelect
        isMulti
        components={{
          Option,
        }}
        cacheOptions
        loadOptions={(inputValue) =>
          getJobs(inputValue).then((options) => options)
        }
        defaultOptions
        value={filters.jobId}
        onChange={(option: any) => {
          store.dispatch(setJobTitleScoreboard(option));
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        placeholder="Search by Job"
        styles={selectCustomStyle}
        id="locationSelect"
        instanceId="locationSelect"
      />
    </div>
  );
};


const TotalScoreFilter = ()=>{
  const filters = useTypedSelector((state) => state.scoreboard.filters);

  return <div className="table-filter-popup__right__content">
  <label className="table__range__label">Experience<span>(years)</span></label>
  <CustomRangeComponent rangeValue={filters.totalScore} max={100} min={0} setData={(state) => {
    store.dispatch(setTotalScoresScoreboard(state));
  }}/>
</div>
}
const Filter = ({ filterType }) => {
  switch (filterType) {
    case "Job Title":
      return <JobFilter />;

    case "Total scores":
      return <TotalScoreFilter />
    default:
      return null;
  }
};

export default Filter;
