// eslint-disable-next-line no-duplicate-imports
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {JobsOffersTableTabsEnums} from "../../enums/jobsOffers/JobsOffersEnums";
import {IJobsOffersTable} from "../../types/redux/jobs-offers";
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";

export const defaultFiltersJobsOffers = {
	location: [],
	searchValue: "",
	sortBy: "id",
	sortType: "DESC",
};

//TODO: nt reset offers[]
const initialStateJobsOffers: IJobsOffersTable = {
	noResultSearch: false,
	tableEmpty: false,
	offers: [],
	filters: defaultFiltersJobsOffers,
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
	fixedTab: {
		id: 1,
		displayName: JobsOffersTableTabsEnums.JOB_TITLE,
	},
	tabFilter: [
		{
			id: 2,
			displayName: JobsOffersTableTabsEnums.JOB_LOCATION,
			active: true,
			dbName: "",
		},
		{
			id: 3,
			displayName: JobsOffersTableTabsEnums.OF_OPENINGS,
			active: true,
			dbName: "",
		},
		{
			id: 4,
			displayName: JobsOffersTableTabsEnums.PENDING_CANDIDATE,
			active: true,
			dbName: "",
		},
		{
			id: 5,
			displayName: JobsOffersTableTabsEnums.OF_OFFER_LETTER_ACCEPTED,
			active: true,
			dbName: "",
		},
		{
			id: 6,
			displayName: JobsOffersTableTabsEnums.OF_OFFER_LETTER_YET_TO_ACCEPT_BY_CANDIDATE,
			active: true,
			dbName: "",
		},
		{
			id: 7,
			displayName: JobsOffersTableTabsEnums.OF_OFFER_LETTER_YET_TO_SEND,
			active: true,
			dbName: "",
		},
		{
			id: 8,
			displayName: JobsOffersTableTabsEnums.OF_REJECTED_THE_OFFER_LETTER_FROM_THE_CANDIDATE,
			active: true,
			dbName: "",
		},
	],
	filterInfo: {
		tabs: [
			JobsOffersTableTabsEnums.JOB_LOCATION,
		],
	},
};

export const jobsOffers = createSlice({
  name: "jobsOffers",
  initialState: initialStateJobsOffers,
  reducers: {
    setTableEmptyJobsOffers: (state, action: PayloadAction<boolean>) => {
      state.tableEmpty = action.payload;
    },
    setNoResultSearchJobsOffers: (state, action: PayloadAction<boolean>) => {
      state.noResultSearch = action.payload;
    },
    setCurrentPageJobsOffers: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimitJobsOffers: (state, action: PayloadAction<number>) => {
      (state.pagination.limit = action.payload),
        (state.pagination.currentPage = 1);
    },
    setOrderJobsOffers: (state, action: PayloadAction<Array<ITabsFilter>>) => {
      state.tabFilter = action.payload;
    },
    setTotalCountJobsOffers: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSortTypeFilterJobsOffers: (state, action: PayloadAction<string>) => {
      state.filters.sortType = action.payload;
    },
    setSortByFilterJobsOffers: (state, action: PayloadAction<string>) => {
      state.filters.sortBy = action.payload;
    },
    setSearchValueJobsOffers: (state, action: PayloadAction<string>) => {
      state.filters.searchValue = action.payload;
    },
    setLocationFilterJobsOffers: (
      state,
      action: PayloadAction<Array<IAsyncCheckBoxList>>
    ) => {
      state.filters.location = action.payload;
    },
    setEmptyTableJobsOffers: (state, action: PayloadAction<boolean>) => {
      state.tableEmpty = action.payload;
    },
    setEmptySearchJobsOffers: (state, action: PayloadAction<boolean>) => {
      state.noResultSearch = action.payload;
    },
    setTableItemsJobsOffers: (state, action: PayloadAction<any>) => {
		state.offers = action.payload;
	},
  },
});

export const {
  setTableEmptyJobsOffers,
  setNoResultSearchJobsOffers,
  setCurrentPageJobsOffers,
  setLimitJobsOffers,
  setTotalCountJobsOffers,
  setSortTypeFilterJobsOffers,
  setSortByFilterJobsOffers,
  setSearchValueJobsOffers,
  setOrderJobsOffers,
  setLocationFilterJobsOffers,
  setEmptyTableJobsOffers,
  setEmptySearchJobsOffers,
  setTableItemsJobsOffers,
} = jobsOffers.actions;

export default jobsOffers.reducer;