import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import fetchData from "../../../hook/http";
import { getEnv } from "@urecruits/api";
import { Question } from "./question";
import clockIc from "../../../../assessment/live-coding/assets/icons/clock_ic.svg";
import VideoMeetLoader from "../../../../assessment/live-coding/components/video-meeting/Loader/VideoMeetLoader";
import { useTypedSelector } from "../../../store";
import { setOpenPreview } from "../../../store/reducers/domainAssessmentReducer";
import { useDispatch } from "react-redux";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT,API_ASSESSMENT} = getEnv();

const getOpenPreview=state=>state.domain_assessment.openPreview


export const QuestionModal = () => {
  const {checkUserPermission} = useHasPermission()
  const openPreview=useTypedSelector(getOpenPreview)
  const [data, setData] = useState<any>([]);
  const [industryData, setIndustryData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch=useDispatch()

  useEffect(() => {
    checkUserPermission('assessment','view') && fetchData(`${API_ASSESSMENT}/api/domain-questions/${openPreview?.id}`).then((info) => {
      setLoading(false)
      setData(info);
    });
  }, []);

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/industry/label/`)
      .then((data) => {
        if (data) {
          setIndustryData(data); 
        }
      });

	  fetchData(`${API_RECRUITMENT}/api/department/label/`)
      .then((data) => {
        if (data) {
          setDepartmentData(data); 
        }
      });
  }, []);

  const handleIndustry = (item) => {
    const label = industryData.length > 0 && industryData.find((info) => info.id === item)?.label;
    return label;
  };

  const handleDepartment = (item) => {
    const label = departmentData.length > 0 && departmentData.find((info) => info.id === item)?.label;
    return label;
  };

  return (
    <AuthGuard module='assessment' permission='view'>
      {!loading ? (
        <div className="question_popup">
          <div className="question_popup__step">
            <div className="question_popup__head">
              <p className="question_popup__head__headline">{data?.name}</p>

              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => dispatch(setOpenPreview(false))}
                className="question_popup__head__close">
                <path
                  d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                  stroke="#C1C5CB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="sub-content">
              {data?.industryId && (
                <>
                  {handleIndustry(data?.industryId)}
                  <div className="dot"></div>
                </>
              )}
              {data?.departmentId && (
                <>
                  {handleDepartment(data?.departmentId)}
                  <div className="dot"></div>
                </>
              )}
              {data?.passing && (
                <>
                  {`> ${data?.passing}`}
                  <div className="dot"></div>
                </>
              )}
              {data?.duration && (
                <>
                  <img
                    src={clockIc}
                    alt="clock ic"
                    style={{
                      minWidth: "12px",
                      width: "12px",
                      marginRight: "6px",
                    }}
                  />
                  {`${data?.duration}`}
                </>
              )}
            </span>
            <div className="question_popup__step__body">
              <div className="question_popup__step__body__header">
                <div className="question_popup__step__body__header__question-title">
                  <p>Questions</p>
                </div>
                <div className="question_popup__step__body__header__score-title">
                  <p>Score</p>
                </div>
              </div>
              <hr />

              {data?.questions?.map((info, index) => {
                return (
                  <div
                    className="question_popup__step__body__container"
                    key={index}>
                    <div className="question_popup__step__body__container__question">
                      <Question item={info} />
                      {index < data?.questions?.length - 1 && <hr />}
                    </div>
                    <div className="question_popup__step__body__container__score">
                      {info?.score}/10
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="question_popup__bottom end"></div>
          </div>
        </div>
      ) : (
        <VideoMeetLoader />
      )}
    </AuthGuard>
  );
};

