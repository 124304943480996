// eslint-disable-next-line no-duplicate-imports
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IJobsBackgroundTable} from "../../types/redux/background-screening";
import {IAsyncCheckBoxList} from "../../types/global/global";
import { IColReorder } from "@urecruits/recruitments/types/global/global";
import { BackgroundJobsTableFilter } from "../../enums";

export const defaultFilters = {
	locations: [],
	searchValue: "",
	sortBy: "id",
	sortType: "DESC",
};

//TODO: nt reset offers[]
const initialStateBackgroundJobs: IJobsBackgroundTable = {
	jobs: [],
  colReorder:[],
	filters: defaultFilters,
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
  filterInfo: {
		tabs: [
			BackgroundJobsTableFilter.JOB_LOCATION,
		],
	},
};

const backgroundJobsSlice = createSlice({
  name: "backgroundJobs",
  initialState: initialStateBackgroundJobs,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSortTypeFilter: (state, action: PayloadAction<string>) => {
      state.filters.sortType = action.payload;
    },
    setSortByFilter: (state, action: PayloadAction<string>) => {
      state.filters.sortBy = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.filters.searchValue = action.payload;
    },
    setLocationFilter: (
      state,
      action: PayloadAction<Array<IAsyncCheckBoxList>>
    ) => {
      state.filters.locations = action.payload;
    },
    setTableItems: (state, action: PayloadAction<any>) => {
		state.jobs = action.payload;
	},
  setOrderColumns: (state, action: PayloadAction<Array<IColReorder>>) => {
    state.colReorder = action.payload;
  },
  },
});

export const {
  setCurrentPage,
  setLimit,
  setTotalCount,
  setSortTypeFilter,
  setSortByFilter,
  setSearchValue,
  setLocationFilter,
  setTableItems,
  setOrderColumns
} = backgroundJobsSlice.actions;

export default backgroundJobsSlice.reducer;