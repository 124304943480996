import { IManageCandidate, IManageCandidateTableItems } from "../../types/redux/manage-candidate";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAsyncCheckBoxList, ITabsFilter } from "../../types/global/global";
import { ManageCandidateFilters, ManageCandidateTabs } from "../../enums/manage-candidate/ManageCandidateEnums";

export const defaultFiltersManageCandidate = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	location: [],
	education: [],
	skills: [],
	experience: [0,50],
	salary_year: [0,1000000],

}
const initialStateMC: IManageCandidate = {
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersManageCandidate,
	isApplied:false,
	tableItems: [],
	fixedTab: {
		id: 1,
		displayName: ManageCandidateTabs.NAME,
	},
	tabFilter: [
		{
			id: 2,
			displayName: 'Job Title',
			active: false,
			dbName: 'locationId'
		},
		{
			id: 3,
			displayName: ManageCandidateTabs.Location,
			active: true,
			dbName: 'locationId'
		},
		{
			id: 4,
			displayName: ManageCandidateTabs.Experience,
			active: true,
			dbName: 'experience'
		},
		{
			id: 5,
			displayName: ManageCandidateTabs.Salary,
			active: true,
			dbName: 'preferencesExpectedCTC'
		},
		{
			id: 6,
			displayName: ManageCandidateTabs.Phone,
			active: true,
			dbName: ''
		},
		{
			id: 7,
			displayName: ManageCandidateTabs.Email,
			active: true,
			dbName: ''
		},
		{
			id: 8,
			displayName: ManageCandidateTabs.Status,
			active: true,
			dbName: ''
		},
		{
			id: 9,
			displayName: ManageCandidateTabs.AssignTask,
			active: false,
			dbName: ''
		},
		{
			id: 11,
			displayName: ManageCandidateTabs.Actions,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				ManageCandidateFilters.Location,
				ManageCandidateFilters.Experience,
				ManageCandidateFilters.Salary,
				ManageCandidateFilters.Education,
				ManageCandidateFilters.Key_Skills,
			],
	},
	resendPopup:{
		open:false,
		id:null
	},
	assignmentList:[],
	showAssignPopup:{
		userName:null,
		open:false,
		userId:null
	}
}
export const manageCandidate = createSlice({
	name: 'jobs_matched',
	initialState: initialStateMC,
	reducers: {
		setIsAppliedManageCandidate: (state, action: PayloadAction<boolean>)=>{
			state.isApplied = action.payload;
		},
		setResendPopupManageCandidate: (state, action: PayloadAction<{open: boolean;id: null | number;}>) => {
			state.resendPopup = action.payload;
		},
		setAssignmentsListManageCandidate: (state, action: PayloadAction<any[]>) => {
			state.assignmentList = action.payload;
		},
		setShowAssignPopupManageCandidate: (state, action: PayloadAction<{ open?: boolean; jobId?: number; userId?: number; }>) => {
			state.showAssignPopup = { ...state.showAssignPopup, ...action.payload };
		},
		setExperienceManageCandidate: (state, action: PayloadAction<Array<number>>) => {
			state.filters.experience = action.payload
		},
		setLocationManageCandidate: (state, action: PayloadAction<Array<IAsyncCheckBoxList>>) => {
			state.filters.location = action.payload
		},
		setSkillsFilterManageCandidate: (state, action: PayloadAction<Array<string>>) => {
			state.filters.skills = action.payload
		},
		setOrderManageCandidate: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setSearchValueManageCandidate: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setSortByManageCandidate: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeManageCandidate: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setLimitManageCandidate: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setSalaryYearManageCandidate: (state, action: PayloadAction<Array<number>>) => {
			state.filters.salary_year = action.payload
		},
		setEducationalManageCandidate: (state, action: PayloadAction<Array<string>>) => {
			state.filters.education = action.payload
		},
		setCurrentPageManageCandidate: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setTotalCountManageCandidate: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setTableItemsManageCandidate: (state, action: PayloadAction<Array<IManageCandidateTableItems>>) => {
			state.tableItems = action.payload
		},
		setEmptySearchManageCandidate: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setEmptyTableManageCandidate: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
	},
})
export const {
	setEmptyTableManageCandidate,
	setEmptySearchManageCandidate,
	setTableItemsManageCandidate,
	setTotalCountManageCandidate,
	setCurrentPageManageCandidate,
	setEducationalManageCandidate,
	setSalaryYearManageCandidate,
	setExperienceManageCandidate,
	setLocationManageCandidate,
	setSkillsFilterManageCandidate,
	setOrderManageCandidate,
	setSearchValueManageCandidate,
	setSortByManageCandidate,
	setSortTypeManageCandidate,
	setLimitManageCandidate,
	setIsAppliedManageCandidate,
	setResendPopupManageCandidate,
	setAssignmentsListManageCandidate,
	setShowAssignPopupManageCandidate
} = manageCandidate.actions

export default manageCandidate.reducer
