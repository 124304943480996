import React from "react";
import { Range, getTrackBackground } from "react-range";
import { useDispatch } from "react-redux";
import { setDepartmentDomainAssessment, setIndustryDomainAssessment, setScoreFilterDomainAssessment, setTimeFilterDomainAssessment } from "../../../store/reducers/domainAssessmentReducer";


const STEP = 1;
const MIN = 0;
const COLORS = ["#099C73", "#ccc"];

const CustomRange = (
    { values, MAX }
) => {
    const dispatch = useDispatch();
    return (
        <div
            className="range__wrapper"
        >
            <Range
                allowOverlap
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(values) => dispatch(setScoreFilterDomainAssessment(values))}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        className='range__line__wrapper'
                    >
                        <div
                            ref={props.ref}
                            className='range__line'
                            style={{
                                background: getTrackBackground({
                                    values,
                                    colors: COLORS,
                                    min: MIN,
                                    max: MAX
                                }),
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ props, index, isDragged }) => {
                    return (
                        <div
                            {...props}
                            className='range__track'
                        >
                            <div
                                className='range__track__rigth'
                                style={{ backgroundColor: isDragged ? "#548BF4" : "#CCC" }}
                            />
                            <div
                                className='range__track__left'
                                style={{ backgroundColor: isDragged ? "#548BF4" : "#CCC" }}
                            />
                        </div>
                    );
                }}
            />
            <output className='range__input__wrapper' id="output">
                <input
                    className='range__input'
                    type={'number'} value={values} max={100} min={1} onChange={e => {
                        const values = +e.target.value > 100 ? [100] : [+e.target.value];
                        dispatch(setScoreFilterDomainAssessment(values))
                    }} />
            </output>
        </div>
    );
}

export default CustomRange;