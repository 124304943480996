import {configureStore} from "@reduxjs/toolkit";
import mtm from "./reducers/manageTeamMembersReducer";
import pw from "./reducers/positionWorkFlowReducer";
import rs from "./reducers/reviewAndScoreReducer";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import pwPopup from "./reducers/positionWorkflowPopup";
import jobs from "./reducers/jobsTableReducer";
import jobsDetailView from "./reducers/jobDetailViewReducerMatched";
import manageCandidate from "./reducers/manageCandidate";
import candidateProfile from "./reducers/candidateProfileScreen";
import DomainAssessment from "./reducers/domainAssessmentReducer";
import liveCoding from "./reducers/liveCodingReducer";
import candidateScoreboard from "./reducers/candidateScoreboardScreenReducer";
import jobsOffers from "./reducers/jobsOffersReducer";
import usersOffers from "./reducers/usersOffersReducer";
import generateOffer from "./reducers/generateOfferLetterScreen";
import domainReviewAndScore from "./reducers/domainReviewAndscoreReducer";
import CandidateDomainAssessmentReducer from "./reducers/candidateDomainAssessmentReducer";
import answerSheetReducer from "./reducers/AnswerSheetReducer";
import manageAssignment from "./reducers/manageAssignmentReducer";
import assignmentCandidate from "./reducers/assignmentCandidateReducer";
import offerLetter from "./reducers/offerLetterReducer";
import backgroundJobs from "./reducers/backgroundJobsReducer"; 
import backgroundCandidates from "./reducers/backgroundCandidatesReducer";
import interviewsJobs from "./reducers/interviewsJobsReducer";
import interviewsCandidates from "./reducers/interviewsCandidatesReducer";
import drugJobs from "./reducers/drugJobsReducer";
import drugCandidates from "./reducers/drugCandidatesReducer";
import scoreboard from "./reducers/scoreboardReducer";

export const store = configureStore({
	reducer: {
		team_members: mtm,
		position_workflow: pw,
		review_and_score: rs,
		position_workflow_popup: pwPopup,
		jobs: jobs,
		jobs_matched: jobsDetailView,
		manage_candidate: manageCandidate,
		candidate_screen: candidateProfile,
		domain_assessment: DomainAssessment,
		candidate_domain_assessment: CandidateDomainAssessmentReducer,
		live_coding: liveCoding,
		candidate_scoreboard: candidateScoreboard,
		scoreboard,
		offers_jobs: jobsOffers,
		offers_users: usersOffers,
		generate_offer: generateOffer,
		domain_review_and_score: domainReviewAndScore,
		answer_sheet: answerSheetReducer,
		manage_assignment: manageAssignment,
		assignment_candidate: assignmentCandidate,
		offer_letter:offerLetter,
		background_jobs : backgroundJobs,
		background_candidates : backgroundCandidates,
		interviews_jobs : interviewsJobs,
		interviews_candidates : interviewsCandidates,
		drug_jobs : drugJobs,
		drug_candidates : drugCandidates,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false
	  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useTypedSelector:TypedUseSelectorHook<RootState>=useSelector