import {useEffect} from "react";

const useClickOutside=(ref,setState)=>{
	const clickOutside = (e: any) => {
		if (!ref?.current?.contains(e.target)) {
			setState(false)
		}
	}

	useEffect(() => {
		document.addEventListener('click', clickOutside,{ capture: true })
		return () => document.removeEventListener('click', clickOutside,{ capture: true })
	}, []);
}
export default useClickOutside