import {memo, useEffect, useState} from 'react'
import {useTypedSelector} from "../../../store";


const MultiSelectListItem = ({ item,setInputValue,getReduxDataFunc,dispatchFunc}:{item:any,setInputValue:any,getReduxDataFunc:(value:any)=>Array<any>,dispatchFunc:any }) => {
  const [active, setActive] = useState(false)
  const selectedSkills=useTypedSelector(getReduxDataFunc)

  const onClickHandler = () => {
    if (!(selectedSkills.find(x => x === item.name))) {
      setActive(!active)
      dispatchFunc([...selectedSkills,item.name])
    } else {
      setActive(false)
      dispatchFunc(selectedSkills.filter(x => x !== item.name))
    }
    setInputValue('')
  }

  useEffect(() => {
    if (selectedSkills.find(x => x === item.name)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [selectedSkills])

  return (
    <div
      className={`multi-select__list__item ${active ? 'active' : ''}`}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
    >
      {item.name}
    </div>
  )
}

export default memo(MultiSelectListItem)