import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";

const convertToBytes = (size, unit) => {
  if (unit?.toLowerCase() === "kb") {
    return size * 1024;
  } else if (unit?.toLowerCase() === "mb") {
    return size * 1024 * 1024;
  } else {
    return size;
  }
};

const calculateFileSize = (file) => {
  let size = file?.size;
  let sizeType = new Array("Bytes", "KB", "MB", "GB");
  let i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  let exactSize = Math?.round(size * 100) / 100 + "" + sizeType[i];
  return exactSize;
};

const FileUplaodProgressBar = ({ file, setAttachmentFile, isUpload }) => {
  const [progress, setProgress] = React.useState(0);
  const handleFileRemove = () => {
    setAttachmentFile((prev) => {
      const newFile = prev?.map(({ file }) => {
        return {
          file,
          upload: false,
        };
      });
      return newFile?.filter((fileName) => fileName?.file !== file);
    });
  };

  React.useEffect(() => {
    if (file && isUpload) {
      let progressNo = 1,
        progress;
      if (file?.name) {
        if (file?.size >= 1000000) progress = 14;
        else if (file?.size >= 500000) progress = 18;
        else progress = 25;
      } else if (file?.fileName) {
        const fileSize = convertToBytes(
          file?.size?.substring(0, file?.size?.length - 2),
          file?.size?.substring(file?.size?.length - 2)
        );

        if (fileSize >= 1000000) progress = 14;
        else if (fileSize >= 500000) progress = 18;
        else progress = 25;
      }
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
            setProgress(0);
          }
          const diff = progress;
          return Math.min(oldProgress + diff, 100);
        });
        progressNo++;
      }, 500);
      if (progress === 100) clearInterval(timer);
      return () => {
        clearInterval(timer);
      };
    }
  }, [file]);
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#d9d9d9",
        opacity: ".8",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
      {file && file?.name && `${file?.name}(${calculateFileSize(file)})`}
      {file && file?.fileName && `${file?.fileName}(${file?.size})`}

      {file && progress !== 0 && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: "150px", height: "20px" }}
        />
      )}
      {file && (
        <IconButton onClick={handleFileRemove}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

export default React.memo(FileUplaodProgressBar);
