import {memo, useState} from "react";
import save from '../../../image/icon/save_ic.svg'
import reset from '../../../image/icon/reset_ic.svg'
import {selectMobileMenuStyle} from "../../../styles/selectMobileMenuStyle";
import {default as ReactSelect} from 'react-select'
import closeIc from '../../../image/icon/Close.svg'

const FilterWrapper = ({setFilterPopup,resetFunc,submitFunc,filterData,limit,filters,children}:
	                             {
																 setFilterPopup: (value: boolean) => void,
		                             resetFunc:(limit:number)=>void,
		                             submitFunc:(page:number,limit:number,filters:any)=>void,
		                             limit:number,
		                             filterData:any,
		                             filters:any,
		                             children:any
	                             }
) => {
	const [activeTab, setActiveTab] = useState(filterData.tabs[0]);

	//filter states

	const submitAll = () => {
		submitFunc(1,limit,filters)
		setFilterPopup(false)
	}

	const resetAll = () => {
		setFilterPopup(false)
		resetFunc(limit)
	}


	return <div className='table-filter-popup'>
		<div className='table-filter-popup__top'>
			<p className='table-filter-popup__top__title'>Filter by</p>
			<img src={closeIc} alt={'close'} className='table-filter-popup__top__img' onClick={()=>setFilterPopup(false)}/>
		</div>
		<div className='table-filter-popup__left'>
			<div className='table-filter-popup__left__buttons'>
				<button className='table-filter-popup__left__button' onClick={e => {
					e.preventDefault()
					submitAll()
				}}>
					<img src={save} alt='save' className='table-filter-popup__icon'/>
					Submit All
				</button>
				<button className='table-filter-popup__left__button' onClick={e => {
					e.preventDefault()
					resetAll()
				}}>
					<img src={reset} alt='save' className='table-filter-popup__icon'/>
					Reset all
				</button>
			</div>
			<ul className='table-filter-popup__left__list'>
				{
					filterData.tabs.map(item => {
						return <li key={item}
						           className={`${activeTab === item ? 'table-filter-popup__left__item active' : 'table-filter-popup__left__item'}`}
						           onClick={() => setActiveTab(item)}>
							{item}
						</li>
					})
				}
			</ul>
		</div>
		<div className='table-filter-popup__right'>
			<div className='table-filter-popup__right__mobile-select'>
				<ReactSelect
					// @ts-ignore
					options={filterData.tabs.map((item)=>{return{label:item,value:item}})}
					value={filterData.tabs.map((item)=>{return{label:item,value:item}}).find(x=>x.label===activeTab)}
					onChange={(option: any) => {
						setActiveTab(option.label)
					}}
					hideSelectedOptions={false}
					styles={selectMobileMenuStyle}
					id="mobileSelect"
					instanceId="mobileSelect"
				/>
			</div>
			{
				children(activeTab)
			}
		</div>
		<div className='table-filter-popup__bottom'>
			<button className='table-filter-popup__left__button' onClick={e => {
				e.preventDefault()
				resetAll()
			}}>
				<img src={reset} alt='save' className='table-filter-popup__icon'/>
				Reset all
			</button>
			<button className='button--filled table-filter-popup__bottom__button-last' onClick={e => {
				e.preventDefault()
				submitAll()
			}}>
				Show Results
			</button>
		</div>
	</div>
}
export default memo(FilterWrapper)