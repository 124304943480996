import { memo } from "react"

const WorkflowPopup=({workflow,onClose}:{workflow:{title:string,workflows:any[]},onClose:()=>void})=>{

	return		<div className="popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
					{workflow.title}
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => {onClose()}}
					className="popup__head__close"
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
					      strokeLinecap="round"
					      strokeLinejoin="round"/>
				</svg>
			</div>
			<div className="popup__body">
				<ul className='popup__numbered-list'>
					{
						workflow.workflows.map((item,index) => {
							return <li key={index} className='popup__numbered-list__item'>{index+1}. {item.name}</li>
						})
					}
				</ul>
			</div>
		</div>
	</div>
}
export default memo(WorkflowPopup)