export enum WorkFlowEnums {
	Functional_Domain_Assessment = 'Functional/Domain Assessment',
	Live_Task_Coding_Assessment = 'Live Task/Coding Assessment',
	Take_Home_Coding_Assessment = 'Take Home/Coding Assessment',
	Technical_Audio_Video_Interview="Technical Audio Video Interview",
  HR_Audio_Video_Interview="HR Audio Video Interview",
  Senior_HR_Audio_Video_Interview = "Senior HR Audio Video Interview",
	Conditional_Offer_Letter_Generation = 'Conditional Offer Letter Generation',
	Background_Screening = "Background Screening",
	Drug_Screening = "Drug Screening",
	Onboarding = 'Onboarding',
	Technical_Coding_Assessment="Technical/Coding Assessment",
	Third_Party_Assessment="3rd Party Assessment / Personality / EQ Test"
  }

export enum WorkFlowErrorsEnum {
	assessments = 'assessments',
	reviewers = 'reviewers',
	deadline = 'deadline',
	questionType = 'questionType',
	questionList = 'questionList',
	timeDuration = 'timeDuration'
}

export enum WorkflowTabs {
	Title = 'Workflow Title',
	Workflow = 'Workflow',
	CreatedBy = 'Created by',
	CreatedOn = 'Created On',
	Actions = 'Actions'
}

