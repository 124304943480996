import React,{ useState } from "react";

export const SignatureModal = ({ setOpenTemplate, setModal }) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleAddSignature = () => {
        if(!isChecked){
            return;
        }  
        setOpenTemplate(true); 
        setModal(false);         
    };



    return (
        <>
                <div className="modal__body__instruction">
                    <strong className="modal__body__instruction__head">Attach an email link of my signed lease</strong>
                    <div className="modal__body__instruction__content">
                       <input type="checkbox" />
                       <p className="modal__body__instruction__content__text">By clicking this box, I agree to receive an email link to my signed offer sent via email.</p> 
                    </div>
                </div>

                <div className="modal__body__instruction">
                    <strong className="modal__body__instruction__head">Please agree to follow the terms before sharing the offer <span style={{color:"#099C73"}}>*</span></strong>
                    <div className="modal__body__instruction__content">
                       <input type="checkbox"  onChange={()=>setIsChecked(prevState=> !prevState)}/>
                       <p className="modal__body__instruction__content__text">By using this offer, I understand and agree that while uRrecruits has drafted this offer to comply with 'here text' requirements, there may be specific circumstances that require additional revisions in order to comply with local law, and the parties to this offer may have rights or obligations under a law that are not addressed in this offer or the other materials provided on our platform. uRecruits is not responsible for any subsequent changes to this offer, including whether those changes comply with applicable law. uRecruits is not responsible for the legality of any “custom rules” I include in this offer. uRecruits is providing access to self-help services and is not acting as my attorney. uRecruits cannot provide any legal advice regarding my use of this offer.</p> 
                    </div>
                </div>

            <div className="modal__actions">
                <button className="modal__actions__cancel__outlined" onClick={()=>setModal(false)}>Cancel</button>
                <button onClick={handleAddSignature} className={`modal__actions__send ${!isChecked ? 'disabled' : ''}`} disabled={!isChecked}>Add Signatures</button>
            </div>
        </>
    );
}
