
export enum ManageCandidateTabs {
	NAME = 'Name',
	Location = 'Location',
	Experience = 'Experience',
	Salary = 'Salary',
	Phone = 'Phone',
	Email = 'Email',
	Resume = 'Resume, Updated on',
	Actions = 'Actions',
	AssignTask='Assigned Task',
	Status = 'Status'
}

export enum ManageCandidateFilters {
	Location = 'Location',
	Experience = 'Experience',
	Salary = 'Salary',
	Notice_Period = 'Notice Period',
	Education = 'Education',
	Key_Skills = 'Key Skills',
}

export enum ManageCandidateEnumsButtons {
	Candidate = 'Candidate',
	Experience = 'Experience Details',
	Educational = 'Educational Details',
	Also = 'Also match'
}

export enum CandidateScoreboardEnumsButtons {
	Candidate = 'Candidate',
	Rounds = 'Details of Rounds ',
	Extra = 'Workflow Rounds'
}

export enum InterviewStatusesEnums{
	Pending='Pending',
	InProgress='InProgress',
	Completed='Completed',
	Rejected='Rejected'
}