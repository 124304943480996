import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { DomainAssessmentReviewAndScoreTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import { getEnv } from "@urecruits/api";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import {
	defaultFiltersDomainReviewAndScoreScreen,
	setCurrentPageDomainAssessment,
	setEmptySearchDomainAssessment,
	setEmptyTableDomainAssessment,
	setFiltersInfoDomainAssessment,
	setJobIdDomainAssessment,
	setLimitDomainAssessment,
	setSearchValueDomainAssessment,
	setSortByDomainAssessment, setSortTypeDomainAssessment, setTableItemsDomainAssessment, setTotalCountDomainAssessment
} from "../store/reducers/domainReviewAndscoreReducer";
import ManageDomainAssessmentTBody from "../components/reviewAndScore/DomainAssessment/ManageDomainReviewAndScoreTBody";
import PopupWithInstruction from "../components/DomainAssessment/PopupWithInstruction";
import axios from "axios";
import FilterPopupDomainAssessmentReview from "../components/reviewAndScore/DomainAssessment/filter/FilterPopupDomainAssessmentReview";
import ManageDomainReviewAndScoreMobileTable from "../components/reviewAndScore/DomainAssessment/ManageReviewMobileTBody";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { DomainAssessmentFilterTabs } from "../enums/reviewAndScore/domainReviewAndScore";

const token: any = localStorage.getItem('token')
const config = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${token}`,
	},
}
const { API_RECRUITMENT, API_ASSESSMENT } = getEnv()

const getFilters = state => state.domain_review_and_score.filters
const tableFunc = state => state.domain_review_and_score
const getFilterDataFunc = state => state.domain_review_and_score.filterInfo
const getLimitFunc = state => state.domain_review_and_score.pagination.limit
const getPopupWithInstruction = (state) => state.domain_review_and_score.popupWithInstruction;

//TODO replace component
const FilterItems = state => <FilterPopupDomainAssessmentReview activeTab={state} />
const FilterPopupInnerWrap = (setState) => {
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const limit = useTypedSelector(getLimitFunc)
	return <FilterWrapper setFilterPopup={setState}
		resetFunc={resetFunc}
		filters={filters}
		filterData={filtersData}
		submitFunc={setDomainAssessmentReviewScreenHandler}
		limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}
const DomainAssessmentReviewAndScoreScreen = () => {
	const { checkUserPermission } = useHasPermission()
	const popupWithInstruction = useTypedSelector(getPopupWithInstruction);
	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);
	let filtersData = useTypedSelector(getFilterDataFunc)

	const table = useTypedSelector(tableFunc)
	useLayoutEffect(() => {
		if (!checkUserPermission('job-post', 'view')) {
			const tabs = filtersData?.tabs?.filter(i => i !== DomainAssessmentFilterTabs.Job) || filtersData?.filter(i => i !== DomainAssessmentFilterTabs.Job) || []
			store.dispatch(setFiltersInfoDomainAssessment(tabs))
		}
	}, [filtersData.tabs?.length])
	useLayoutEffect(() => {
		setDomainAssessmentReviewScreenHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, [popupWithInstruction?.open]);

	//TODO add filter array
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueDomainAssessment(value))
		setDomainAssessmentReviewScreenHandler(1, table.pagination.limit, { ...table.filters, searchValue: value })
	}, filterArray);
	//TODO replace callback
	const emptyTableCallback = useCallback(() => {
		navigate('/')
	}, [],);
	useTableClickAndDragScroll(tableRef)

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeDomainAssessment, setSortByDomainAssessment, setDomainAssessmentReviewScreenHandler), filterArray,);
	useClickOutside(orderPopupRef, setOrderPopup)
	return (
		<AuthGuard module={['assessment', 'job-post']} permission='view' option="AND">
			<section>
				{table.popupWithInstruction.open && <PopupWithInstruction />}
				<div className="table__wrapper">
					<div className="table__top">
						<div className="manage-team__top__left">
							<AuthGuard module={['assessment', 'job-post']} permission='view' option="AND">
								{!!filtersData.tabs?.length && <TopFilterButton>{FilterPopupInnerWrap}</TopFilterButton>}
							</AuthGuard>
							<AuthGuard module='assessment' permission='view'>
								<SearchFieldComponent
									searchValue={table.filters.searchValue}
									setSearchValue={setSearchCallback}
									placeholder={"Search Job title"}
								/>
							</AuthGuard>
						</div>
						<button
							className={`manage-team__top__svg ${orderPopup ? "active" : ""}`}
							ref={orderPopupRef}>
							<MemoOrderSVG setState={setOrderPopup} />
							{
								<CSSTransition
									in={orderPopup}
									timeout={300}
									classNames={"order-popup-mtm"}
									unmountOnExit
									mountOnEnter>
									<DomainAssessmentReviewAndScoreTableOrder setOrderPopup={setOrderPopup} />
								</CSSTransition>
							}
						</button>
					</div>
					{table.emptySearch || table.emptyTable ? (
						table.emptySearch ? (
							<NoResultsSearch
								limit={table.pagination.limit}
								resetFunc={resetFunc}
							/>
						) : (
							<TableEmpty
								handler={emptyTableCallback}
								cta=""
								title={"Table is empty"}
								desc={"Review and score table is empty"}
							/>
						)
					) : (
						<>
							<table
								className="table"
								ref={tableRef}
								onScroll={(e: any) => {
									if (e.target.scrollLeft > 10 && !horizontalScrollState)
										setHorizontalScrollState(() => true);
									else if (e.target.scrollLeft < 10 && horizontalScrollState)
										setHorizontalScrollState(() => false);
								}}>
								<thead className="table__thead">
									<td
										className={`table__td sticky ${horizontalScrollState ? "moved" : ""
											} table__td--thead jobs-table__column__middle`}>
										<TheadItemComponent
											title={table.fixedTab.displayName}
											handler={null}
											dbName={""}
										/>
									</td>
									{table.tabFilter.map((item) => {
										return (
											item.active && (
												<td
													className={`table__td  table__td--thead jobs-table__column__default`}
													key={item.id}>
													<TheadItemComponent
														title={item.displayName}
														handler={item.dbName ? tdOrderCallback : null}
														dbName={item.dbName}
													/>
												</td>
											)
										);
									})}
								</thead>
								<ManageDomainAssessmentTBody
									horizontalScrollState={horizontalScrollState}
								/>
							</table>

							<div className='table-mobile'>
								{
									table.tableItems.map(item => {
										return <ManageDomainReviewAndScoreMobileTable key={item.id} item={item} />
									})
								}
							</div>
							<PaginationComponent
								limit={table.pagination.limit}
								currentPage={table.pagination.currentPage}
								totalCount={table.pagination.totalCount}
								setCurrentPage={setDomainAssessmentReviewScreenHandler}
								setLimit={setLimitHandler}
								filters={table.filters}
							/>
						</>
					)}
				</div>
			</section>
		</AuthGuard>
	);
}
export default memo(DomainAssessmentReviewAndScoreScreen)

export const setDomainAssessmentReviewScreenHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageDomainAssessment(page))
	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/company/jobs/workflow?limit=${limit}
		${filters.jobId.map(item => `&jobId=${item.value}`).join('')}
		&offset=${(page - 1) * limit}
		&search=${encodeURIComponent(filters.searchValue)}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`, config).then(async res => {
			store.dispatch(setTotalCountDomainAssessment(res.data.count))
			const items = await transformArrayToTableData(res.data.rows)
			if (items.length === 0) {
				if (filters.searchValue !== '' ||
					filters.jobId?.length !== 0 ) {
						store.dispatch(setEmptySearchDomainAssessment(true))
					} else {
						store.dispatch(setEmptyTableDomainAssessment(true))
				}
			} else {
				store.dispatch(setTableItemsDomainAssessment(items))
				store.dispatch(setEmptySearchDomainAssessment(false))
				store.dispatch(setEmptyTableDomainAssessment(false))
			}
		})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitDomainAssessment(value))
}
const transformArrayToTableData = async (array: any): Promise<Array<any>> => {
	const transformedData: Array<any> = [];

	const fetchDataForItem = async (item: any) => {
		if (item?.workflow?.domainId) {
			try {
				const res = await axios(`${API_ASSESSMENT}/api/domain-questions/${item?.workflow?.domainId}`, config);
				const result = await axios(`${API_ASSESSMENT}/api/domain-result/all?jobId=${item.id}&assessmentId=${Number(item?.workflow?.domainId)}
		  `, config)

				const info = {
					id: item.id,
					assessmentName: res.data.name,
					job: item.title,
					industryId: res.data.industryId,
					department: res.data.departmentId,
					domainId: item?.workflow?.domainId,
					averageScore: result?.data?.averageTotalScore,
					candidates: result?.data?.result?.count,
				};
				transformedData.push(info);
			} catch (error) {
				// Handle errors if necessary
			}
		}
	};

	await Promise.all(array?.map((item) => fetchDataForItem(item)))

	return transformedData;
};

const resetFunc = (pagination) => {
	store.dispatch(setJobIdDomainAssessment([]))
	store.dispatch(setSearchValueDomainAssessment(''))
	store.dispatch(setSortByDomainAssessment('id'))
	store.dispatch(setSortTypeDomainAssessment('DESC'))
	setDomainAssessmentReviewScreenHandler(1, pagination, defaultFiltersDomainReviewAndScoreScreen)
}