import { useEffect, useState } from 'react';
import { validateEmail } from '../../hook/validateEmail';
import PhoneInput from "react-phone-number-input";
import { validatePhoneNumber } from '../../hook/validatePhoneNumber';
import { useNavigate } from 'react-router-dom';
import { getEnv, GlobalConstants } from '@urecruits/api';
import fetchData, { postData } from '../../hook/http';
const { phoneValidationLength } = GlobalConstants();

const { API_RECRUITMENT } = getEnv()
async function checkEmail(email) {
    return await fetchData(`${API_RECRUITMENT}/api/user/${email}`)
}

const CreateCandidatePopup = ({ onClose }) => {
    const navigate = useNavigate()

    const [candidateEmail, setCandidateEmail] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [candidatePhone, setCandidatePhone] = useState<string>('')
    const [successSend, setSuccessSend] = useState<string>('')

    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailValidationError, setEmailValidationError] = useState('');
    const [somethingWentWrong, setSomethingWentWrong] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [activeError, setActiveError] = useState(false);

    const validate = () => {
        let hasError = false
        if (!validateEmail(candidateEmail)) {
            setEmailError(true)
            hasError = true
        }
        if (!firstName) {
            setFirstNameError(true)
            hasError = true
        }
        if (!lastName) {
            setLastNameError(true)
            hasError = true
        }
        if (!validatePhoneNumber(candidatePhone)) {
            setPhoneError(true)
            hasError = true
        }
        return !hasError;
    }

    const checkCandidateEmail = (e) => {
        const mail = e.target.value?.trim()
        if (mail) {
            checkEmail(mail).catch(error => {
                if (error?.response?.status && error?.response?.status == 409) {
                    setEmailValidationError("Email already exists!")
                } else {
                    setEmailValidationError("")
                }
            })
        }
    }

    const OnInvite = () => {
        setEmailError(false)
        setEmailValidationError("")
        setPhoneError(false)
        setFirstNameError(false)
        setLastNameError(false)
        if (validate()) {
            const data = {
                firstname: firstName,
                lastname: lastName,
                email: candidateEmail,
                phone: candidatePhone
            }
            setLoading(true)
            postData(`${API_RECRUITMENT}/api/candidate/invite`, data)
                .then(data => {
                    setSuccessSend("Successfully sent invitation.")
                    setTimeout(() => { onClose() }, 2000)
                })
                .catch((error) => {
                    setSomethingWentWrong("Failed to send invitation, try again later.")
                })
                .finally(() => setLoading(false))
        } else {
            setSomethingWentWrong("Fill the require field to process further.")
        }
    }

    useEffect(() => {
        if (candidateEmail !== '' && candidatePhone !== '' && firstName !== '' && lastName !== '' && validate() && !emailError && !emailValidationError && !phoneError && !firstNameError && !lastNameError) {
            setActiveError(false)
        } else {
            setActiveError(true)
        }
    }, [candidateEmail, candidatePhone, firstName, lastName])
    return (
        <div className="popup-right-side">

            <div className="popup-right-side__inner">
                <div
                    className="popup-right-side__top"
                    onClick={onClose}
                >
                    <h5 className="popup-right-side__top__title">{"Invite Candidate"}</h5>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="popup-right-side__top__close-button"
                    >
                        <path
                            d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                            stroke="#C1C5CB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <form
                    className="popup-right-side__form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        OnInvite();
                    }}
                >
                    <div className="manage-team-popup__form__item">
                        <label className="manage-team-popup__form__label" htmlFor="email">
                            Candidate Email<span> *</span>
                        </label>
                        <input
                            className="manage-team-popup__form__input" id="email" autoComplete="do-not-autofill"
                            value={candidateEmail}
                            placeholder="Enter email"
                            onChange={(e) => {
                                setCandidateEmail(e.target.value);
                                if (e.target.value) {
                                    setEmailError(false)
                                    validateEmail(e.target.value) ? setEmailValidationError("") : setEmailValidationError("Invalid email format.")
                                } else {
                                    setEmailError(true)
                                }
                            }}
                            onBlur={checkCandidateEmail}
                        />
                        {
                            (emailError || emailValidationError) &&
                            <p className="manage-team-popup__form__error-message">{emailError ? "This is required field" : emailValidationError}</p>
                        }
                    </div>
                    <div className='manage-team-popup__form__inner'>
                        <div className="manage-team-popup__form__item manage-team-popup__form__item--small">
                            <label className="manage-team-popup__form__label" htmlFor="firstName">
                                First Name<span> *</span>
                            </label>
                            <input
                                className="manage-team-popup__form__input" id="firstName" autoComplete="do-not-autofill"
                                value={firstName}
                                placeholder="Enter first name"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setFirstName(e.target.value);
                                        setFirstNameError(false);
                                    } else {
                                        setFirstName("");
                                        setFirstNameError(true)
                                    }
                                }}
                            />
                            {
                                firstNameError &&
                                <p className="manage-team-popup__form__error-message">This is required field</p>
                            }
                        </div>
                        <div className="manage-team-popup__form__item manage-team-popup__form__item--small">
                            <label className="manage-team-popup__form__label" htmlFor="lastName">
                                Last Name<span> *</span>
                            </label>
                            <input
                                className="manage-team-popup__form__input" id="lastName" autoComplete="do-not-autofill"
                                value={lastName}
                                placeholder="Enter last name"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setLastName(e.target.value);
                                        setLastNameError(false);
                                    } else {
                                        setLastName("");
                                        setLastNameError(true);
                                    }
                                }}
                            />
                            {
                                lastNameError &&
                                <p className="manage-team-popup__form__error-message">This is required field</p>
                            }
                        </div>
                        <div className="manage-team-popup__form__item">
                            <label className="manage-team-popup__form__label" htmlFor="phone">
                                Phone<span> *</span>
                            </label>
                            <PhoneInput
                                international
                                defaultCountry="US"
                                id={"phone"}
                                value={candidatePhone}
                                className={"default"}
                                onChange={(value: any) => {
                                    if (value) {
                                        setCandidatePhone(value);
                                        !validatePhoneNumber(value) || value.length > phoneValidationLength ? setPhoneError(true) : setPhoneError(false);
                                    } else {
                                        setCandidatePhone("");
                                        setPhoneError(true);
                                    }
                                }}
                            />
                            {
                                phoneError &&
                                <p className="manage-team-popup__form__error-message">This is required field</p>
                            }
                        </div>
                    </div>
                    <div className="popup-right-side__buttons">
                        {successSend !== "" && (
                            <p className="manage-team-popup__form__success-message">
                                {successSend}
                            </p>
                        )}
                        {somethingWentWrong !== "" && (
                            <p className="manage-team-popup__form__success-message manage-team-popup__form__success-message--error">
                                {somethingWentWrong}
                            </p>
                        )}

                        <button
                            className="popup-right-side__buttons__cancel button--empty"
                            type="reset"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className={(activeError || loading) ? ' button--filled button--filled-disable' : ' button--filled'}
                            type="submit"
                            disabled={activeError || loading}
                        >
                            Invite
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateCandidatePopup