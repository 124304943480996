import InterviewTable from "../components/Dashboard/InterviewTable";

import greetingImg from "../image/greeting_img.svg";
import CalendarDayView from "../components/ScreeningAndHiring/CalendarDayView/CalendarView";
import OffersList from "../components/Dashboard/OfferList";
import BackgroundScreeningList from "../components/Dashboard/BackgroundScreeningList";
import DrugScreeningList from "../components/Dashboard/DrugScreeningList";
import { getEnv } from "@urecruits/api";
import { useEffect, useState } from "react";
import fetchData from "../hook/http";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";


const { API_RECRUITMENT } = getEnv()

const ScreeningHiringDashboard = () => {
	const [data, setData] = useState(null);
	const [timeOfDay, setTimeOfDay] = useState('');

	useEffect(() => {
		const getCurrentTimeOfDay = () => {
			const currentDate = new Date();
			const currentHour = currentDate.getHours();

			if (currentHour >= 5 && currentHour < 12) {
				setTimeOfDay('Morning');
			} else if (currentHour >= 12 && currentHour < 17) {
				setTimeOfDay('Afternoon');
			} else if (currentHour >= 17 && currentHour < 20) {
				setTimeOfDay('Evening');
			} else {
				setTimeOfDay('Night');
			}
		};

		getCurrentTimeOfDay();

		const intervalId = setInterval(getCurrentTimeOfDay, 60000);

		return () => clearInterval(intervalId);
	}, []);
	useEffect(() => {
		fetchData(`${API_RECRUITMENT}/api/user`).then(
			(info) => {
				const fullName = `${info?.firstname} ${info?.lastname}`
				setData(fullName)
			}
		);
	}, []);

	return (
		<div className="dashboard">
			<h2 className="dashboard__headline">Screening & Hiring Home </h2>
			<div className="dashboard__inner screening">
				<div className="dashboard__top">
					<div className="dashboard__top__left">
						<div className="greeting">
							<div className="greeting__left">
								<p className="greeting__tagline">Good {timeOfDay} dear {data}!</p>
								<p className="greeting__headline">Check how screening & hiring updates</p>
							</div>
							<div className="greeting__right">
								<img src={greetingImg} alt="greeting" className="greeting__right__img" />
							</div>
						</div>
						<AuthGuard module='job-post' permission={'view'} >
							<InterviewTable />
						</AuthGuard>
					</div>
					<div className="dashboard__top__right">
						<AuthGuard module='job-post' permission={'view'} >
							<CalendarDayView />
						</AuthGuard>
					</div>
				</div>
				<div className="dashboard__bottom">
					<AuthGuard module={['offer','job-post']} permission={'view'} option="AND">
						<OffersList position={"left"} />
					</AuthGuard>
					<AuthGuard module={['background','job-post']} permission={'view'} option="AND">
						<BackgroundScreeningList position={"middle"} />
					</AuthGuard>
					<AuthGuard module={['drug','job-post']} permission={'view'} option="AND">
						<DrugScreeningList position={"right"} />
					</AuthGuard>
				</div>
			</div>
		</div>
	);
};

export default ScreeningHiringDashboard;