import {memo} from "react";

const SingleItem = ({title, text, textStyle}: { title: string, text: string, textStyle: string }) => {
	return <div className='table-mobile__item__wrap'>
		<p className='table-mobile__item__title'>{title}</p>
		<p className={textStyle}>{text}</p>
	</div>
}
export default memo(SingleItem)

export const ButtonItem = ({title, text, textStyle}: { title: string, text: any, textStyle: string }) => {
	return <div className='table-mobile__item__wrap'>
		<p className='table-mobile__item__title'>{title}</p>
		<div className={textStyle}>{text}</div>
	</div>
}
 