import HelloSign from "hellosign-embedded";
import {useEffect} from "react";



const HellosignScreen = () => {
	const client = new HelloSign();

	useEffect(() => {
		client.open("https://embedded.hellosign.com/prep-and-send/embedded-request?cached_params_token=4dcfa02062605692dd51cb15c0f94d5c", {
			clientId: "f7936db2d10d97599d15ca1514dbecbf",
      skipDomainVerification: true,   
		});
	}, []);

	return (
		<h1>HellosignScreen</h1>
	);
};

export default HellosignScreen;