import {memo, useState} from 'react'
  // @ts-ignore
import deleteIc from '../../../image/icon/delete_image_popup_ic.svg'
import {getEnv} from "@urecruits/api";
import {store, useTypedSelector} from "../../../store";
import {deleteData} from "../../../hook/http";
import {setDeletePopupReviewAndScore} from "../../../store/reducers/reviewAndScoreReducer";
import {setItemsReviewAndScores} from "../../../screen/ReviewAndScoreScreen";
import React from 'react';
const gettableInfo=state => state.position_workflow.deletePopup
const {API_RECRUITMENT} = getEnv()
const limitFunc=state=>state.position_workflow.pagination.limit
const filtersFunc=state=>state.position_workflow.filters
const DeletePopupReviewAndScore = () => {
	const [errorNotification, setErrorNotification] = useState('')
	const popup = useTypedSelector(gettableInfo)
	const filters=useTypedSelector(filtersFunc)
	const limit=useTypedSelector(limitFunc)
	const onSubmit = () => {
		deleteData(`${API_RECRUITMENT}/api/workflow`, {
			id: popup.id,
		}).then((res) => {
				if(res==='') {
					setErrorNotification('Something went wrong, try again later')
					return
				}
				store.dispatch(setDeletePopupReviewAndScore({open: false, title: '', id: -1}))
				setItemsReviewAndScores(1,limit,filters)
			}, error => {
				setErrorNotification(error)
			}
		)
	}

	return (
		<div className="popup">
			<div className="popup__step">
				<div className="popup__head">
					<p className="popup__head__headline">
						Delete Review And Score
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						onClick={() => store.dispatch(setDeletePopupReviewAndScore({open: false, title: '', id: -1}))}
						className="popup__head__close"
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						      strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
				<div className="popup__body">
					<img src={deleteIc} alt="" className="popup__body__image"/>
					<p className="popup__body__text">
						Are you sure you want to delete <span>{popup.title}</span>?
					</p>
				</div>
				<div className="popup__bottom end">
					<button
						onClick={() => store.dispatch(setDeletePopupReviewAndScore({open: false, title: '', id: -1}))}
						className="popup__bottom__cancel button--empty"
					>
						Cancel
					</button>
					<button
						className="popup__bottom__publish button--filled"
						onClick={() => onSubmit()}
					>
						Delete
					</button>
					{
						errorNotification.length > 0 && (
							<p className="error-message">{errorNotification}</p>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default memo(DeletePopupReviewAndScore)