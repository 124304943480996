import { useNavigate, useParams } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import avatar from "../image/icon/avatar.svg";
import { IAnswer } from "../types/global/global";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT} = getEnv();

const token: string = localStorage.getItem("token");
const config = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token}`,
	},
};

const UserInformationScreen = () => {
	const {checkUserPermission} = useHasPermission()
	const [data, setData] = useState<any>();

	const navigate = useNavigate();
	const query = useParams();

	useLayoutEffect(() => {
		checkUserPermission('job-post','view') && axios.get(`${API_RECRUITMENT}/api/subscribe/summary/${query.jobId}/${query.userId}`, config)
			.then(res => {
				!res.data && navigate("/");

				if (res.status === 200) {
					setData(res.data);
				}
			});
	}, [query]);


	return (
		<>
			{
				data && (
					<section className="user-information">

						<div className="user-information__inner">
							<div className="user-information__head">
								<p className="user-information__head__headline">Personal information</p>
							</div>
							<div className="summary-personal-info">
								<div className="summary-personal-info__left">
									<div className="summary-personal-info__left__avatar">
										<img src={data.user.avatar || avatar} alt="avatar"/>
									</div>
									<p
										className="summary-personal-info__left__name">
										{`${data.user.firstname} ${data.user.middlename} ${data.user.lastname}`}
									</p>
									<p className="summary-personal-info__left__text">
										{data.user.position}
									</p>
									{
										data.user.experience &&
										<p className="summary-personal-info__left__text">{`${data.user.experience} years of experience`}</p>
									}
									<div className="summary-personal-info__left__industry-list">
										{
											data.user?.candidate?.industries.map((item, key) => {
												return <p className="summary-personal-info__left__text industry" key={key}>{item.label}</p>;
											})
										}
									</div>
								</div>
								<div className="summary-personal-info__right">
									<div
										className={!!data.user?.location ? "summary-personal-info__right__row disabled" : "summary-personal-info__right__row "}>
										<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18 8.33301C18 14.1663 10.5 19.1663 10.5 19.1663C10.5 19.1663 3 14.1663 3 8.33301C3 6.34388 3.79018 4.43623 5.1967 3.02971C6.60322 1.62318 8.51088 0.833008 10.5 0.833008C12.4891 0.833008 14.3968 1.62318 15.8033 3.02971C17.2098 4.43623 18 6.34388 18 8.33301Z"
												stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
											<path
												d="M10.5 10.833C11.8807 10.833 13 9.71372 13 8.33301C13 6.9523 11.8807 5.83301 10.5 5.83301C9.11929 5.83301 8 6.9523 8 8.33301C8 9.71372 9.11929 10.833 10.5 10.833Z"
												stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
										{
											!!data.user?.candidate?.location?.city ? `${data.user?.candidate.location.city}, ${data.user?.candidate.location.state}` : "Empty"
										}
									</div>
									<div className="summary-personal-info__right__row">
										<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M3.83268 3.33301H17.166C18.0827 3.33301 18.8327 4.08301 18.8327 4.99967V14.9997C18.8327 15.9163 18.0827 16.6663 17.166 16.6663H3.83268C2.91602 16.6663 2.16602 15.9163 2.16602 14.9997V4.99967C2.16602 4.08301 2.91602 3.33301 3.83268 3.33301Z"
												stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M18.8327 5L10.4993 10.8333L2.16602 5" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
											      strokeLinejoin="round"/>
										</svg>
										{
											data.user.email
										}
									</div>
									<div className="summary-personal-info__right__row">
										<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18.8332 14.1004V16.6004C18.8341 16.8325 18.7866 17.0622 18.6936 17.2749C18.6006 17.4875 18.4643 17.6784 18.2933 17.8353C18.1222 17.9922 17.9203 18.1116 17.7005 18.186C17.4806 18.2603 17.2477 18.288 17.0165 18.2671C14.4522 17.9884 11.989 17.1122 9.82486 15.7087C7.81139 14.4293 6.10431 12.7222 4.82486 10.7087C3.41651 8.53474 2.54007 6.05957 2.26653 3.48374C2.2457 3.2533 2.27309 3.02104 2.34695 2.80176C2.4208 2.58248 2.53951 2.38098 2.6955 2.21009C2.8515 2.0392 3.04137 1.90266 3.25302 1.80917C3.46468 1.71569 3.69348 1.66729 3.92486 1.66707H6.42486C6.82928 1.66309 7.22136 1.80631 7.528 2.07002C7.83464 2.33373 8.03493 2.69995 8.09153 3.10041C8.19705 3.90046 8.39274 4.68601 8.67486 5.44207C8.78698 5.74034 8.81125 6.0645 8.74478 6.37614C8.67832 6.68778 8.52392 6.97383 8.29986 7.20041L7.24153 8.25874C8.42783 10.345 10.1552 12.0724 12.2415 13.2587L13.2999 12.2004C13.5264 11.9764 13.8125 11.8219 14.1241 11.7555C14.4358 11.689 14.7599 11.7133 15.0582 11.8254C15.8143 12.1075 16.5998 12.3032 17.3999 12.4087C17.8047 12.4658 18.1744 12.6697 18.4386 12.9817C18.7029 13.2936 18.8433 13.6917 18.8332 14.1004Z"
												stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
										{
											data.user.phone
										}
									</div>
									{
										data.user?.candidate?.currentCtc ? <div className="summary-personal-info__right__ctc">
											<p className="summary-personal-info__right__ctc-title">CTC</p>
											<p className="summary-personal-info__right__ctc-text">{`$${data.user.candidate.currentCtc}`}</p>
										</div> : null
									}
								</div>
							</div>
							<div className="user-information__head">
								<p className="user-information__head__headline">{data.currentScreen}</p>
							</div>
							<div className="summary">
								{
									data.summary.map(item => {
										return item.question.filter(x => x.userAnswer !== null).length > 0 &&
											<div className="summary__item" key={item.id}>
												<p className="summary__title">{item.name}</p>
												{
													item.question.filter(x => x.userAnswer !== null).map((value, index) => {
														return <div className="summary__question" key={value.id}>
															<p className="summary__question__title">{`${index + 1}. ${value.name}`}</p>
															{
																value.type === "yesno" &&
																<p className="summary__question__answer">{value.userAnswer ? "Yes" : "No"}</p>
															}
															{
																(value.type === "onefromlist" || value.type === "dropableselect") &&
																<p className="summary__question__answer">{(value.userAnswer as IAnswer).name}</p>
															}
															{
																value.type === "multiplechoice" && <p className="summary__question__answer">
																	{
																		(
																			value.userAnswer as Array<IAnswer>).map((arrayItem, index) => {
																				return (value.userAnswer as Array<IAnswer>).length === index + 1 ? arrayItem.name : arrayItem.name + " / ";
																			},
																		)
																	}
																</p>
															}
															{
																value.type === "freetextfield" &&
																<p className="summary__question__answer">{value.userAnswer}</p>
															}
														</div>;
													})
												}
											</div>;
									})
								}
							</div>
						</div>
					</section>
				)
			}
		</>
	);
};

export default UserInformationScreen;