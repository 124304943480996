import { memo, useState } from "react";
import {
	closestCenter,
	DndContext, DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToWindowEdges } from "@dnd-kit/modifiers";
import DNDOrderPopupWrapper from "./DNDOrderPopupWrapper";
import { store, useTypedSelector } from "../../../../store";
import { ITabsFilter } from "../../../../types/global/global";


const OrderPopupInner =
	({setOrderPopup, stateFunc, dispatchFunc, fixedTabFunc}:
		 {
			 fixedTabFunc: (value: any) => ITabsFilter,
			 setOrderPopup: (value: boolean) => void,
			 stateFunc: (value: any) => ITabsFilter[],
			 dispatchFunc: (payload?: ITabsFilter[]) => { payload: ITabsFilter[], type: string }
		 }) => {
		const table = useTypedSelector(stateFunc)
		const [orderArray, setOrderArray] = useState(table);
		const fixedTab = useTypedSelector(fixedTabFunc)

		const sensors = useSensors(
			useSensor(TouchSensor),
			useSensor(PointerSensor),
			useSensor(KeyboardSensor, {
				coordinateGetter: sortableKeyboardCoordinates,
			})
		);

		const onSortEnd = ({active, over}: DragEndEvent) => {
			setOrderArray(prev => arrayMove(prev, prev.indexOf(prev.find(x => x.id === active.id)), prev.indexOf(prev.find(x => x.id === over.id))))
		}
		return <div className='order-popup-mtm'>
			{fixedTab  && <div className='order-popup-mtm__item inactive'>
				<div className="custom-checkbox">
					<input
						type="checkbox"
						className="custom-checkbox__input"
						id={fixedTab?.id + fixedTab?.displayName}
						name={fixedTab.id + fixedTab.displayName}
						checked={true}
					/>
					<label htmlFor={fixedTab.id + fixedTab.displayName} className="custom-checkbox__label">
						<div className="custom-checkbox__item">
							<span/>
						</div>
						<p className="custom-checkbox__text">{fixedTab.displayName} </p>
					</label>
				</div>
				<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd"
					      d="M1.6001 8.49967C1.6001 8.13148 1.89857 7.83301 2.26676 7.83301H13.4668C13.835 7.83301 14.1334 8.13148 14.1334 8.49967C14.1334 8.86786 13.835 9.16634 13.4668 9.16634H2.26676C1.89857 9.16634 1.6001 8.86786 1.6001 8.49967Z"
					      fill="#DFE2E6"/>
					<path fillRule="evenodd" clipRule="evenodd"
					      d="M1.6001 4.49967C1.6001 4.13148 1.89857 3.83301 2.26676 3.83301H13.4668C13.835 3.83301 14.1334 4.13148 14.1334 4.49967C14.1334 4.86786 13.835 5.16634 13.4668 5.16634H2.26676C1.89857 5.16634 1.6001 4.86786 1.6001 4.49967Z"
					      fill="#DFE2E6"/>
					<path fillRule="evenodd" clipRule="evenodd"
					      d="M1.6001 12.4997C1.6001 12.1315 1.89857 11.833 2.26676 11.833H13.4668C13.835 11.833 14.1334 12.1315 14.1334 12.4997C14.1334 12.8679 13.835 13.1663 13.4668 13.1663H2.26676C1.89857 13.1663 1.6001 12.8679 1.6001 12.4997Z"
					      fill="#DFE2E6"/>
				</svg>
			</div>}
			<div className='order-popup-mtm__droppable-area'>
				<DndContext
					collisionDetection={closestCenter}
					sensors={sensors}
					onDragEnd={onSortEnd}
					modifiers={[restrictToWindowEdges, restrictToParentElement]}
				>
					<SortableContext items={orderArray}>
						{
							orderArray.map((item) => {
								return <DNDOrderPopupWrapper item={item} setOrderArray={setOrderArray} key={item.id}/>
							})
						}
					</SortableContext>
				</DndContext>
			</div>
			<button className='order-popup-mtm__submit' onClick={(e) => {
				e.preventDefault()
				store.dispatch(dispatchFunc(orderArray))
				setOrderPopup(false)
			}}>
				Submit
			</button>
		</div>
	}

export default memo(OrderPopupInner)