import { memo, useState } from 'react';
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../../../store";
import { patchData } from "../../../hook/http";
import { setRejectPopupJobs } from "../../../store/reducers/jobsTableReducer";
import { setJobsHandler } from "../../../screen/JobsScreen";
import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const gettableInfo=state => state.jobs
const {API_RECRUITMENT} = getEnv()

const RejectPopupJobs = () => {
	const [errorNotification, setErrorNotification] = useState('')

	const table = useTypedSelector(gettableInfo)

	const onSubmit = () => {
		patchData(`${API_RECRUITMENT}/api/job`, {
			id: table.rejectPopup.id, status: 'rejected'
		}).then((res) => {
				if(res==='') {
					setErrorNotification('Something went wrong, try again later')
					return
				}
				store.dispatch(setRejectPopupJobs({open: false, name: '', id: -1}))
				setJobsHandler(table.pagination.currentPage,table.pagination.limit,table.filters)
			}, error => {
				setErrorNotification(error)
			}
		)
	}

	return (
		<AuthGuard module='job-post' permission='edit'>
		<div className="popup">
			<div className="popup__step large">
				<div className="popup__head">
					<p className="popup__head__headline">
						Reject the job
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						onClick={() => store.dispatch(setRejectPopupJobs({open: false, name: '', id: -1}))}
						className="popup__head__close"
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						      strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
				<div className="popup__body">
					<p className="popup__body__text">
						Please write some reasons to the executor about the declining job.
					</p>
					<textarea className='popup__body__area'/>
				</div>
				<div className="popup__bottom end">
					<button
						className="popup__bottom__cancel button--empty"
						onClick={() => {
							store.dispatch(setRejectPopupJobs({open: false, name: '', id: -1}))
						}}
					>
						Cancel
					</button>
					<button
						className="popup__bottom__publish button--filled"
						onClick={() => onSubmit()}
					>
						Reject
					</button>
					{
						errorNotification.length > 0 && (
							<p className="error-message">{errorNotification}</p>
						)
					}
				</div>
			</div>
		</div>
		</AuthGuard>
	)
}

export default memo(RejectPopupJobs)