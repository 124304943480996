import { memo, useState } from "react";
import SingleItem, { ButtonItem } from "../../Global/table/SingleMobileItem";
import { AssignmentCandidateTabs } from "../../../enums/assignment-candidate/assignmentCandidate";
import { store } from "../../../store";
import { setPopupWithScheduleOn } from "../../../store/reducers/assignmentCandidateReducer";


const AssignmentCandidateMobileTable = ({ item }) => {
  const [openItem, setOpenItem] = useState(false);
  const renderStatusBlock = (round) => {
    const getStatusClass = (status) => {
      switch (status) {
        case "completed":
          return "table__status-block--green";
        case "notStarted":
          return "table__status-block--gray";
        case "inProgress":
          return "table__status-block--yellow";
        case "rejected":
          return "table__status-block--red";
        default:
          return null;
      }
    };

    const statusClass = getStatusClass(round?.status);
    return statusClass ? (
      <p
        className={statusClass}
        style={{ maxWidth: "fit-content", padding: "4px 1rem" }}>
        {round?.message}
      </p>
    ) : null;
  };

  const renderStatusMessage = (arr) => {
    const inProgressIndex = arr.indexOf("In Progress");
    const hasPending = arr.includes("Pending") && !arr.includes("In Progress");
    const hasRejected = arr.includes("Rejected");
    const allCompleted =
      !arr.includes("Pending") &&
      !arr.includes("In Progress") &&
      !arr.includes("Rejected");

    let obj = { status: "notStarted", message: "Yet To be started" };
    if (allCompleted) {
      obj = { status: "completed", message: `Completed ${arr?.length} rounds` };
    } else if (!hasPending && !hasRejected && !allCompleted) {
      obj = { status: "notStarted", message: "Yet to be started" };
    } else if (hasRejected) {
      obj = { status: "rejected", message: "Rejected" };
    } else if (inProgressIndex !== -1) {
      obj = {
        status: "inProgress",
        message: `Round ${inProgressIndex + 1} in progress`,
      };
    } else {
      obj = { status: "notStarted", message: "Yet To be started" };
    }

    return obj;
  };
  const arr = item?.rounds?.map((tt) => tt?.status);

  const obj = renderStatusMessage(arr);

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => {
          setOpenItem((prev) => !prev);
        }}>
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{`${item?.name}`}</p>
        </div>
        <ArrowSvg />
      </div>
      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleItem
          title={AssignmentCandidateTabs.email + ":"}
          text={`${item?.email}`}
          textStyle={"table-mobile__item__text"}
        />

        <ButtonItem
          title={AssignmentCandidateTabs.status + ":"}
          text={renderStatusBlock(obj)}
          textStyle={"table-mobile__item__text"}
        />

        <ButtonItem
          title={AssignmentCandidateTabs.scheduleOn + ":"}
          text={
            <button
              className="manage-team__text manage-team__text--green manage-team__text__button"
              onClick={() =>
                store.dispatch(
                  setPopupWithScheduleOn({
                    title: item.title,
                    open: true,
                    id: item.id,
                    data: item?.rounds,
                    ids: item?.userId,
                  })
                )
              }>
              See all schedule
            </button>
          }
          textStyle={"table-mobile__item__text"}
        />
      </div>
    </div>
  );
};
export default memo(AssignmentCandidateMobileTable);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
