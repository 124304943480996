import {default as ReactSelect} from 'react-select';
import {IPagination} from "../../types/table-types";
import {selectSmallStyle} from "../../styles/selectSmallStyle";
import {DOTS, usePagination} from '../../hook/usePagination'
import {memo} from "react";
import {useMobilePagination} from "../../hook/useMobilePagination";

const selectOption = [
	{value: 100, label: '100'},
	{value: 50, label: '50'},
	{value: 20, label: '20'},
	{value: 10, label: '10'},
	{value: 5, label: '5'},
	{value: 3, label: '3'},
]

const Pagination = ({currentPage, setCurrentPage, showOption, setLimit, limit, totalCount,filters}: IPagination) => {
	const paginationRange = usePagination(totalCount, limit, 2, currentPage,);
	const paginationRangeMobile =useMobilePagination(totalCount, limit, 1, currentPage,);
	return (
		<div className="table__bottom">
			<div className="table__bottom__left">
				<p className="table__bottom__text">
					{`Showing results ${(currentPage-1)*limit + 1}-${currentPage * limit>totalCount?totalCount:currentPage * limit} of ${totalCount}`}
				</p>
			</div>
			{
				<div className="table__bottom__center">
            <ul className="table__pagination">
                <li onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1,limit,filters)}
                    className={`table__pagination__prev ${currentPage > 1 ? '' : 'disabled'}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 8L10 12L14 16" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </li>
							{paginationRange?.map((pageNumber, index) => {
								if (pageNumber === DOTS) {
									return <li key={'dots' + index} className='table__pagination__dots'>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M8.65823 14.632C8.85823 14.632 9.02623 14.704 9.16223 14.848C9.30623 14.984 9.37823 15.152 9.37823 15.352C9.37823 15.552 9.30223 15.724 9.15023 15.868C9.00623 16.004 8.84223 16.072 8.65823 16.072C8.47423 16.072 8.30623 16.004 8.15423 15.868C8.01023 15.724 7.93823 15.552 7.93823 15.352C7.93823 15.152 8.00623 14.984 8.14223 14.848C8.28623 14.704 8.45823 14.632 8.65823 14.632ZM11.9981 14.632C12.1981 14.632 12.3661 14.704 12.5021 14.848C12.6461 14.984 12.7181 15.152 12.7181 15.352C12.7181 15.552 12.6421 15.724 12.4901 15.868C12.3461 16.004 12.1821 16.072 11.9981 16.072C11.8141 16.072 11.6461 16.004 11.4941 15.868C11.3501 15.724 11.2781 15.552 11.2781 15.352C11.2781 15.152 11.3461 14.984 11.4821 14.848C11.6261 14.704 11.7981 14.632 11.9981 14.632ZM15.3379 14.632C15.5379 14.632 15.7059 14.704 15.8419 14.848C15.9859 14.984 16.0579 15.152 16.0579 15.352C16.0579 15.552 15.9819 15.724 15.8299 15.868C15.6859 16.004 15.5219 16.072 15.3379 16.072C15.1539 16.072 14.9859 16.004 14.8339 15.868C14.6899 15.724 14.6179 15.552 14.6179 15.352C14.6179 15.152 14.6859 14.984 14.8219 14.848C14.9659 14.704 15.1379 14.632 15.3379 14.632Z" fill="#343B43"/>
									</svg>
									</li>;
								}
								return (
									<li key={pageNumber as number + index}
									    className={`table__pagination__item ${pageNumber === currentPage ? 'active' : ''}`}
									    onClick={() => setCurrentPage(pageNumber as number,limit,filters)}
									>
										{pageNumber}
									</li>
								);
							})}
                <li onClick={() => currentPage < Math.ceil(totalCount / limit) && setCurrentPage(currentPage + 1,limit,filters)}
                    className={`table__pagination__next ${currentPage === Math.ceil(totalCount / limit) ? 'disabled' : ''}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 8L14 12L10 16" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>

                </li>
            </ul>
            <ul className="table__pagination mobile">
                <li onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1,limit,filters)}
                    className={`table__pagination__prev ${currentPage > 1 ? '' : 'disabled'}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 8L10 12L14 16" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </li>
			        {paginationRangeMobile?.map((pageNumber, index) => {
				        if (pageNumber === DOTS) {
					        return <li key={'dots' + index} className='table__pagination__dots'>
						        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							        <path d="M8.65823 14.632C8.85823 14.632 9.02623 14.704 9.16223 14.848C9.30623 14.984 9.37823 15.152 9.37823 15.352C9.37823 15.552 9.30223 15.724 9.15023 15.868C9.00623 16.004 8.84223 16.072 8.65823 16.072C8.47423 16.072 8.30623 16.004 8.15423 15.868C8.01023 15.724 7.93823 15.552 7.93823 15.352C7.93823 15.152 8.00623 14.984 8.14223 14.848C8.28623 14.704 8.45823 14.632 8.65823 14.632ZM11.9981 14.632C12.1981 14.632 12.3661 14.704 12.5021 14.848C12.6461 14.984 12.7181 15.152 12.7181 15.352C12.7181 15.552 12.6421 15.724 12.4901 15.868C12.3461 16.004 12.1821 16.072 11.9981 16.072C11.8141 16.072 11.6461 16.004 11.4941 15.868C11.3501 15.724 11.2781 15.552 11.2781 15.352C11.2781 15.152 11.3461 14.984 11.4821 14.848C11.6261 14.704 11.7981 14.632 11.9981 14.632ZM15.3379 14.632C15.5379 14.632 15.7059 14.704 15.8419 14.848C15.9859 14.984 16.0579 15.152 16.0579 15.352C16.0579 15.552 15.9819 15.724 15.8299 15.868C15.6859 16.004 15.5219 16.072 15.3379 16.072C15.1539 16.072 14.9859 16.004 14.8339 15.868C14.6899 15.724 14.6179 15.552 14.6179 15.352C14.6179 15.152 14.6859 14.984 14.8219 14.848C14.9659 14.704 15.1379 14.632 15.3379 14.632Z" fill="#343B43"/>
						        </svg>
					        </li>;
				        }
				        return (
					        <li key={pageNumber as number + index}
					            className={`table__pagination__item ${pageNumber === currentPage ? 'active' : ''}`}
					            onClick={() => setCurrentPage(pageNumber as number,limit,filters)}
					        >
						        {pageNumber}
					        </li>
				        );
			        })}
                <li onClick={() => currentPage < Math.ceil(totalCount / limit) && setCurrentPage(currentPage + 1,limit,filters)}
                    className={`table__pagination__next ${currentPage === Math.ceil(totalCount / limit) ? 'disabled' : ''}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 8L14 12L10 16" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>

                </li>
            </ul>
        </div>
			}
			<div className="table__bottom__right">
				<p className="table__bottom__text">Show</p>
				<div className="table__bottom__select">
					<ReactSelect
						options={showOption||selectOption}
						closeMenuOnSelect={true}
						isSearchable={false}
						onChange={(currentValue: any) => {
							setLimit(currentValue.value)
							setCurrentPage(1,currentValue.value,filters)
						}}
						value={limit ?
							showOption?showOption.find(x => x.value === limit) :selectOption.find(x => x.value === limit)
							: null}
						styles={selectSmallStyle}
						id="itemsOnPageSelect"
						instanceId="itemsOnPageSelect"
					/>
				</div>
				<p className="table__bottom__text">on page</p>
			</div>
		</div>
	)
}

export default memo(Pagination)