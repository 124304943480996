import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import Pagination from "../Pagination";
import SearchField from "../../SearchField/SearchField";
import { CSSTransition } from "react-transition-group";
import MemoOrderSVG from "../MemoOrderSVG";
import CommonTheadItem from "../CommonTheadItem";
import MobileTable from "../MobileTable/MobileTable";
import TopFilterButton from "../Filter/TopFilterButton";
import FilterWrapper from "../Filter/FilterWrapper";
import OrderPopupInner from "../OrderPopup/OrderPopupInner";
import ItemNotAvailable from "../ItemNotAvailable";
import TableEmpty from "../TableEmpty";
import SmallLoader from "../SmallLoader";

const TableView = ({ rowData, columns, sort, loading, emptyTable, disableRow, store, useTypedSelector, storeName, searchField, pagination, rearrangeColumns, filters }: any) => {


    const columnOrderArray = rearrangeColumns ? useTypedSelector(store => store[storeName].colReorder) : columns;

    const actions = columns.find(column => column.field === 'actions');

    // const table = { emptyTable: false, noUsersSearch: false };

    const [orderPopup, setOrderPopup] = useState(false);
    const [horizontalScrollState, setHorizontalScrollState] = useState(false);

    const orderPopupRef = useRef<null | HTMLButtonElement>(null);
    const tableRef = useRef(null);

    const orderCols = store && useTypedSelector(store => store[storeName].colReorder);

    useEffect(() => {
        rearrangeColumns && !orderCols.length && store.dispatch(rearrangeColumns.setColumnOrder(columns.map((col, index) => {
            return {
                id: index,
                headerName: col.headerName,
                visibility: col.visibility,
                pinnable: col.pinnable
            }
        })));
    }, []);

    const FilterPopupInnerWrap = (setState) => {
        if (!filters) return;
        const { filter, filterData, filterItems, resetFilters, setIsFilterSubmit } = filters;
        return (
            <FilterWrapper
                setFilterPopup={setState}
                filterData={filterData}
                resetFunc={resetFilters}
                pagination={pagination}
                setIsFilterSubmit={setIsFilterSubmit}
            >
                {filterItems}
            </FilterWrapper>
        );
    };

    return (

        <div className="table__wrapper">
            {
                loading ? <SmallLoader /> :
                    emptyTable && emptyTable.type === 'emptyTable' ?
                        // true ?
                        <TableEmpty
                            type={emptyTable.type}
                            handler={emptyTable.handler}
                            buttonText={emptyTable.buttonText}
                            title={emptyTable.title}
                            desc={emptyTable.desc}
                        />
                        :
                        <>
                            <div className="table__top">
                                <div className="table__top__left">
                                    {filters ?
                                        <TopFilterButton>
                                            {FilterPopupInnerWrap}
                                        </TopFilterButton>
                                        : ''}
                                    {
                                        searchField ? <SearchField
                                            searchValue={searchField.searchValue}
                                            setSearchValue={searchField.setSearchValue}
                                            placeholder={searchField.placeholder}
                                        />
                                            : ''}

                                </div>
                                {rearrangeColumns ?
                                    <button className={`table__top__svg ${orderPopup ? "active" : ""}`} ref={orderPopupRef}>
                                        <MemoOrderSVG setState={setOrderPopup} />
                                        {
                                            <CSSTransition in={orderPopup} timeout={300} classNames={"order-popup-mtm"} unmountOnExit mountOnEnter>
                                                <OrderPopupInner columnOrderArray={columnOrderArray} setColumnOrder={(orderedColumnsArray) => store.dispatch(rearrangeColumns.setColumnOrder(orderedColumnsArray))} setOrderPopup={setOrderPopup} />
                                            </CSSTransition>
                                        }
                                    </button>
                                    : ''}

                            </div>
                            {
                                loading ? <SmallLoader /> :
                                    emptyTable ?
                                        <TableEmpty
                                            type={emptyTable.type}
                                            handler={emptyTable.handler}
                                            buttonText={emptyTable.buttonText}
                                            title={emptyTable.title}
                                            desc={emptyTable.desc}
                                        />
                                        : <>
                                            <table className="table" ref={tableRef} onScroll={(e: any) => {
                                                if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true);
                                                else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false);
                                            }}>
                                                <thead className="table__thead">
                                                    {
                                                        columnOrderArray.map((orderItem) => {
                                                            const item = columns.find(column => column.headerName == orderItem.headerName);

                                                            return orderItem.visibility && item &&
                                                                <td
                                                                    className={`table__td table__td--thead ${item.pinnable ? ` sticky ${horizontalScrollState ? "moved" : ""}  table__column__middle` : ' table__column__default '} ${item.className}`}
                                                                    key={item.field}>
                                                                    <CommonTheadItem
                                                                        title={item.headerName}
                                                                        field={item.field ?? null}
                                                                        handler={(item.sort && sort) ?? null}
                                                                     />
                                                                </td>
                                                        })
                                                    }
                                                </thead>
                                                <tbody className='table__tbody'>
                                                    {
                                                        !!rowData.length && rowData.map(row => {
                                                            return (
                                                                <Fragment key={row.id}>

                                                                    <tr className="table__tr" key={row.id}>
                                                                        {disableRow && disableRow.getDisableStatus(row) ?
                                                                            <ItemNotAvailable text={disableRow.text} />
                                                                            : ''}
                                                                        {
                                                                            columnOrderArray.map(orderItem => {
                                                                                const column = columns.find(col => col.headerName == orderItem.headerName);
                                                                                if (!column) { return; }
                                                                                if (orderItem.visibility) {
                                                                                    if (column.field === 'actions' && actions && actions.setActions) {
                                                                                        return (
                                                                                            <td className={`table__td ${column.pinnable ? `sticky ${horizontalScrollState ? "moved" : ""} table__column__middle` : 'table__column__default'} ${column.className}`} key={column.field}>
                                                                                                <div className="table__actions" >
                                                                                                    {
                                                                                                        actions.setActions.map((action, index) => {
                                                                                                            const actionObj = action.getAction ? action.getAction(row) : action;
                                                                                                            if (!actionObj) return;
                                                                                                            const { svg, title, icon, onClick: onActionClick, className } = actionObj

                                                                                                            return (<div
                                                                                                                className={`table__actions__item ${className}`}
                                                                                                                onClick={(event) => {
                                                                                                                    onActionClick(row, event)
                                                                                                                }}
                                                                                                                key={title}
                                                                                                            >
                                                                                                                {svg ?? <img src={icon} alt={title} />}
                                                                                                            </div>)
                                                                                                        })
                                                                                                    }
                                                                                                </div></td>)
                                                                                    }
                                                                                    return (
                                                                                        <td className={`table__td table__text ${column.pinnable ? `sticky ${horizontalScrollState ? "moved" : ""} table__column__middle` : 'table__column__default'} ${column.className}`} key={column.field}>
                                                                                            {column.renderCell ? column.renderCell(row[`${column?.field}`], row) || '' : row[column?.field] || ''}
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }

                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <MobileTable columns={columns} rowData={rowData} disableRow={disableRow} actions={actions ? actions.setActions : ''} />
                                            {pagination ?
                                                <Pagination
                                                    limit={pagination.limit}
                                                    currentPage={pagination.currentPage}
                                                    totalCount={pagination.totalCount}
                                                    setCurrentPage={pagination.setCurrentPage}
                                                    setLimit={pagination.setLimit}
                                                />
                                                : ''}

                                        </>
                            }
                        </>
            }
        </div>



    )
}

export default memo(TableView)
