import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {memo, useEffect, useState} from "react";
import {IPositionOption} from "../../../types/redux/position-workflow";
import DNDItem from "./DNDItem";

const DNDWorkflowsWrap = ({item}: { item: IPositionOption }) => {
	const {
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging
	} = useSortable({id: item.id});
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
		opacity:isDragging?0:''
	};

	const [transitionState, setTransitionState] = useState(false);
	useEffect(() => {
		if (transform) {
			if (!transitionState) setTransitionState(true)
		} else if (transitionState) setTransitionState(false)
	}, [transform]);


	return <div ref={setNodeRef} style={style}>
		<DNDItem listeners={listeners} item={item} transitionState={transitionState}/>
	</div>
}

export default memo(DNDWorkflowsWrap)