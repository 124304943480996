import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AxiosPromise } from "axios";

const useSnackbar = () => {
  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });

  type AsyncFn = () => AxiosPromise<any>;
  type ErrorMessage = string | undefined;

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleToastNotificationClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const ErrorElement = () => (
    <Snackbar
      open={toastNotification.state}
      onClose={handleToastNotificationClose}
      autoHideDuration={6000}
      message={toastNotification.message}
      action={action}
      sx={{
        "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
          background:
            "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
          color: "white",
        },
      }}
    />
  );

  const APIExecutor = async (callbackFn: any) => {
    try {
      return await callbackFn();
    } catch (err) {
      setToastNotification({
        state: true,
        message: err?.response?.data?.message || "An error occured",
      });
    }
  };

  return { ErrorElement, APIExecutor };
};

export default useSnackbar;
