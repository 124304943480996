export enum ManageAssignmentTabs{
	jobTitle='Job Title',
	candidates='Candidates',
	assessmentType='Assessment Type',
	Actions='Actions'
}

export enum ManageAssignmentFilterTabs{
	assessmentType='Assessment Type',
	jobId='Job Title',
}