import {memo, useEffect, useState} from "react";
import {store, useTypedSelector} from "../../../../store";
import AsyncSelect from "react-select/async";
import Option from "../../../Global/SelectOptions";
import {getEnv, selectSearchFuncDepartment, selectSearchFuncIndustry} from "@urecruits/api";
import {selectCustomStyle} from "../../../../styles/selectCustomStyle";
import React from "react";
import { setJobIdDomainAssessment } from "../../../../store/reducers/domainReviewAndscoreReducer";
import { DomainAssessmentFilterTabs } from "../../../../enums/reviewAndScore/domainReviewAndScore";
const {API_RECRUITMENT} = getEnv()
import axios from "axios"
const token: any = localStorage.getItem('token')
	const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
}
const getFiltersFunc = (state) => state.domain_review_and_score.filters
const FilterPopupManageDomainAssessment = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc)
	const getJobs = async (inputValue) => {
		try {
		  const response = await axios(`${API_RECRUITMENT}/api/company/jobs`, {
			...config,
			params: {
			  search: inputValue,
			},
		  });
	
		  const jobOptions = response?.data?.rows.map((job) => ({
			value: job.id, 
			label: job.title, 
		  }));
	
		  return jobOptions || [];
		} catch (error) {
		  console.error("Error fetching jobs:", error);
		  return [];
		}
	  };
	

	return <>
		 {
			activeTab === DomainAssessmentFilterTabs.Job &&
			<div className='table-filter-popup__right__content'>
			<AsyncSelect
				isMulti
				components={{
								  Option,
							  }}
				cacheOptions
				loadOptions={(inputValue) =>
					getJobs(inputValue).then((options) => options)
				  }
				defaultOptions
				value={filter.jobId}
				onChange={(option: any) => {	
								  store.dispatch(setJobIdDomainAssessment(option))
							  }}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				placeholder='Search by Job'
				styles={selectCustomStyle}
				id="locationSelect"
				instanceId="locationSelect"
			/>
		</div>
		} 
	</>
}
export default memo(FilterPopupManageDomainAssessment)
