export enum DetailViewJobMatched{
	NAME='Name',
	PERCENT='Percent of matching',
	Location='Location',
	Experience='Experience',
	Salary='Expected Salary',
	Phone='Phone',
	Email='Email',
	Resume='Resume, Updated on',
	Status='Status',
	Actions='Actions',
	Score='Total Scores'
}

export enum DetailViewJobFilters{
	Location='Location',
	Experience='Experience',
	Salary='Expected Salary',
	Notice_Period='Notice Period',
	Education='Education',
	Key_Skills='Key Skills',
	Percentage='Percentage',
	Status='Status',
}

export enum RoundStatuses{
	Pending='Pending',
	Progress='In Progress',
	Completed='Completed',
	Rejected='Rejected',
	Restarted='Restarted',
	Terminated = "Terminated"
}