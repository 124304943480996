import { memo, useState } from "react";
import SingleItem from "../Global/table/SingleMobileItem";
import { useNavigate } from "react-router-dom";
import { ManageCandidateTabs } from "../../enums/manage-candidate/ManageCandidateEnums";
import eye from '../../image/icon/eye-on.svg';
import defaultAvatar from "../../image/icon/avatar.svg";
import resend from '../../image/icon/resent invite_ic.svg';
import fileIcon from '../../image/icon/file_ic.svg';
import { store, useTypedSelector } from "../../store";
import { setResendPopupManageCandidate, setShowAssignPopupManageCandidate } from "../../store/reducers/manageCandidate";

const isAppliedFun = state => state.manage_candidate.isApplied
const hasAssessmentPackage = false


const ManageCandidateMobileTable = ({ item }) => {
	const [openItem, setOpenItem] = useState(false);
	const isApplied = useTypedSelector(isAppliedFun)
	const navigate = useNavigate()
	return <div className='table-mobile__item'>
		<div className={`table-mobile__top ${openItem ? 'active' : ''}`} onClick={() => {
			setOpenItem(prev => !prev)
		}}>
			<div className='table-mobile__top__left'>
				<img src={item.avatar || defaultAvatar} alt='avatar' className='table-mobile__avatar' />
				<p className='table-mobile__top__name'>{`${item.firstname} ${item.middlename ? item.middlename : ''} ${item.lastname}`}</p>
			</div>
			<ArrowSvg />
		</div>
		<div className={`table-mobile__item__body ${openItem ? 'open' : ''}`}>
			{isApplied && item.job && <SingleItem title={'Job Title' + ':'} text={`${item.job?.title || ''} `} textStyle={'table-mobile__item__text'} />}
			<SingleItem title={ManageCandidateTabs.Location + ':'} text={item.location} textStyle={'table-mobile__item__text'} />
			<SingleItem title={ManageCandidateTabs.Experience + ':'} text={(item.experience || item.experience === 0) ? item.experience + ' years' : ''} textStyle={'table-mobile__item__text'} />
			<SingleItem title={ManageCandidateTabs.Salary + ':'} text={item.salary ? '$' + item.salary : ''} textStyle={'table-mobile__item__text table-mobile__item__text--green'} />
			<SingleItem title={ManageCandidateTabs.Phone + ':'} text={item.phone} textStyle={'table-mobile__item__text table-mobile__item__text--green'} />
			<SingleItem title={ManageCandidateTabs.Email + ':'} text={item.email} textStyle={'table-mobile__item__text table-mobile__item__text--green'} />
			{hasAssessmentPackage && <SingleItem title={ManageCandidateTabs.AssignTask + ':'} text={item.assignTask?.name || '-'} textStyle={'table-mobile__item__text table-mobile__item__text--green'} />}

			{
				<div className="table-mobile__item__body__action">
					<button className={`table-mobile__item__action-button table-mobile__item__action-button${item.status === 'Pending' ? '--half' : ''}`} onClick={() => {
						const url = item.job && isApplied ? `/recruitment/candidate-profile/${item.userId}/${item.job.id}` : `/recruitment/candidate-profile/${item.userId}`
						navigate(url)
					}}>
						<img src={eye} alt="delete-icon" />
						View Profile
					</button>
					{item.status === 'Pending' && 
						<button className='table-mobile__item__action-button table-mobile__item__action-button--half' onClick={() => {
							store.dispatch(setResendPopupManageCandidate({ id: item.id, open: true }))
						}}>
							<img src={resend} alt="resend-icon"/>
							Resend Activation Mail
						</button>
						}
					{hasAssessmentPackage && item.status !== 'Pending' && <button className='table-mobile__item__action-button table-mobile__item__action-button--half' onClick={() => {
						store.dispatch(setShowAssignPopupManageCandidate({ userName: item.firstname + " " + item.lastname, open: true, userId: item.userId }))
					}}>
						<img src={fileIcon} alt="assign-assignment-icon" />
						Assign Assignment
					</button>}
				</div>
			}
		</div>
	</div>
}
export default memo(ManageCandidateMobileTable)
const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)" />
			</clipPath>
		</defs>
	</svg>
}

