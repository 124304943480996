import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEnv } from "@urecruits/api";
import { useTypedSelector } from "../../../store";
import { useDispatch } from "react-redux";
import { setScoreTable } from "../../../store/reducers/domainReviewAndscoreReducer";
import VideoMeetLoader from "../../../../assessment/live-coding/components/video-meeting/Loader/VideoMeetLoader";
import fetchData from "../../../hook/http";
import { ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT}=getEnv()

const tableItemsFunc=state=>state.domain_review_and_score.tableItems
const tableTabsFunc=state=>state.domain_review_and_score.tabFilter
const ManageDomainAssessmentTBody = ({horizontalScrollState}: { horizontalScrollState: boolean }) => {
	const tableItems=useTypedSelector(tableItemsFunc)
	const tableTabs=useTypedSelector(tableTabsFunc)
const navigate=useNavigate()
const [industryData, setIndustryData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const dispatch=useDispatch()



  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/industry/label/`)
      .then((data) => {
        if (data) {
          setIndustryData(data); 
        }
      });

	  fetchData(`${API_RECRUITMENT}/api/department/label/`)
      .then((data) => {
        if (data) {
          setDepartmentData(data); 
        }
      });
  }, []);

  const handleIndustry = (item : any) => {
    const label = industryData.length > 0 && industryData.find((info:any) => info?.id === item)?.label;
    return label;
  };

  const handleDepartment = (item) => {
    const label = departmentData.length > 0 && departmentData.find((info:any) => info.id === item)?.label;
    return label;
  };

	return (
	<>
		{tableItems && tableItems?.length > 0?<tbody className="table__tbody">
		{
			tableItems.map((item,index) => {				
				return <tr className="table__tr" key={index}>
					<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
						<div className='manage-team__name' onClick={()=>{
							dispatch(setScoreTable({assessmentId:item?.domainId,jobId:item?.id,assessmentName:item?.assessmentName}))
							navigate(`/recruitment/score-board/${item?.domainId}/${item?.id}`)
						}}>
							<ShowToolTipComponent text="Click to see Candidates answers" position="right">
							<p className='manage-team__name__text manage-team__text--green' style={{cursor:"pointer"}}>{`${item?.assessmentName}`}</p>
							</ShowToolTipComponent>
						</div>
					</td>
					{
						tableTabs?.filter(i=>i.active).map((value,index) => {
							if (value.id === 2) {
								return <td className="table__td actions jobs-table__column__default" key={index}>
								<div className='jobs-table__locations'>
								  <p className='manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow'>
										{`${item?.job}`}
								  </p>
								</div>
							  </td>
							}
							if (value.id === 3) {
							return (
								<td className="table__td actions jobs-table__column__default" key={index}>
								<div className='jobs-table__locations'>
									<p className='manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow'>
									{item?.industryId ? handleIndustry(item.industryId) : 'N/A'}
									</p>
								</div>
								</td>
							);
							}
							if (value.id === 4) {
								return <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>

	                        {
		                       <p className='manage-team__text--gray7 manage-team__text '>{item.department && handleDepartment(item.department)}</p>
	                        }
                        </div>
                    </td>
							}
							if (value.id === 5) {
								return <td className="table__td actions jobs-table__column__default" key={value.displayName}>
								<div className='jobs-table__locations'>
	                        {
		                        <p className='manage-team__text--gray7 manage-team__text '>{item?.candidates}</p>
	                        }
                        </div>
                    </td>
							}
							if (value.id === 6) {
								return <td className="table__td actions jobs-table__column__default" key={value.displayName}>
                        <div className='jobs-table__locations'>
                            <p className='manage-team__text--gray7 manage-team__text '>{item?.averageScore?.toFixed(1)}</p>
                        </div>
                    </td>
							}
						})
					}
				</tr>
			})
		}
		</tbody>:<VideoMeetLoader/>
		}
	</>)
}
export default memo(ManageDomainAssessmentTBody)

