import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import { IAsyncCheckBoxList, ITabsFilter } from "../../types/global/global";
import { IAssignmentCandidate } from '../../types/redux/assignment-candidate';
import { AssignmentCandidateFilterTabs, AssignmentCandidateTabs } from '../../enums/assignment-candidate/assignmentCandidate';

export const defaultFiltersAssignmentCandidateScreen = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	candidateId:[]
}
const initialStateAssignmentCandidate:IAssignmentCandidate = {
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersAssignmentCandidateScreen,
	tableItems: [],
	candidateId:[],
	popupWithScheduleOn:{
		open:false,
		id:-1,
		data:'',
		title:null,
		ids:null
	},
	tabFilter: [
		{
			id: 1,
			displayName: AssignmentCandidateTabs.candidateName,
			active: true,
			dbName: ''
		},
		{
			id: 2,
			displayName: AssignmentCandidateTabs.email,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: AssignmentCandidateTabs.scheduleOn,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: AssignmentCandidateTabs.status,
			active: true,
			dbName: ''
		},
		
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				AssignmentCandidateFilterTabs.name,
			],
	}
}

export const assignmentCandidate = createSlice({
	name: 'manage-assignment',
	initialState: initialStateAssignmentCandidate,
	reducers: {
		setLimitAssignmentCandidate: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setSearchValueAssignmentCandidate: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderAssignmentCandidate: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setSortByAssignmentCandidate: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeAssignmentCandidate: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setEmptyTableAssignmentCandidate: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setEmptySearchAssignmentCandidate: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setTableItemsAssignmentCandidate: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
		setTotalCountAssignmentCandidate: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setCurrentPageAssignmentCandidate: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setPopupWithScheduleOn: (state, action: PayloadAction<{open: boolean, id: number, data:any, title:any, ids:any}>) => {
			state.popupWithScheduleOn = action.payload
		},
		setCandidateIdsAssignmentCandidate: (state, action: PayloadAction<IAsyncCheckBoxList[]>) => {
			state.filters.candidateId = action.payload
		},
		setCandidateIds: (state, action: PayloadAction<any[]>) => {			
			state.candidateId = action.payload
		},
	},
})

export const {	
	setSortTypeAssignmentCandidate,
	setLimitAssignmentCandidate,
	setSortByAssignmentCandidate,
	setOrderAssignmentCandidate,
	setSearchValueAssignmentCandidate,
	setEmptyTableAssignmentCandidate,
	setEmptySearchAssignmentCandidate,
	setTableItemsAssignmentCandidate,
	setTotalCountAssignmentCandidate,
	setCurrentPageAssignmentCandidate,
	setPopupWithScheduleOn,
	setCandidateIdsAssignmentCandidate,
	setCandidateIds
} = assignmentCandidate.actions

export default assignmentCandidate.reducer