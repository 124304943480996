import React from "react";
import { Link } from "react-router-dom";
import SuccessImage from "../../image/icon/success_image.svg";

export const SuccessModal =({setModal, refetchHandler,description,bodyCSSClass})=>{
    return (
        <>
        <div className="modal__body__success">
        <img src={SuccessImage} alt="Success" className="modal__body__success__image"/>
        <p className={`modal__body__success__text ${bodyCSSClass}`}>
            {description}
        </p>
    </div>
    <div className="modal__actions center">
        <button
            className="modal__actions__send"
            onClick={() => {
                setModal(false);
                refetchHandler(true);
            }}
        >
            Got it!
        </button>
    </div>
    </>
    )
}