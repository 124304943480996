import React,{memo} from "react";
import {store, useTypedSelector} from "../../../store";
import {DrugJobsTableFilter} from "../../../enums";
import AsyncSelect from "react-select/async";
import Option from "../../Global/SelectOptions";
import {selectSearchFuncLocation} from "@urecruits/api";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import { setLocationFilter,setCurrentPage } from "../../../store/reducers/drugJobsReducer";
import { useScrollLocations } from "../../../hook";

const getFiltersFunc = state => state.drug_jobs.filters;

const FiltersComponent = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc);
	const {hasMoreLocation,loadOptionsLocation,setHasMoreLocation,setOffsetLocation,optionsLocation,searchValueLocation} = useScrollLocations();

	return (
		<>
			{
				activeTab === DrugJobsTableFilter.JOB_LOCATION && (
					<div className="table-filter-popup__right__content">
						<AsyncSelect
							isMulti
							components={{
								Option,
							}}
							cacheOptions
							loadOptions={loadOptionsLocation}
							defaultOptions = {optionsLocation && optionsLocation}
							value={(filter.locations || searchValueLocation) ?? null}
							onChange={(option: any) => {
								store.dispatch(setCurrentPage(1));
								store.dispatch(setLocationFilter(option));
							}}
							onBlur={() => {
								setOffsetLocation(30);
								loadOptionsLocation("")
							}}
							onMenuScrollToBottom={(e) => {
								!hasMoreLocation && setHasMoreLocation(true)
							}}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							placeholder="Search by job location"
							styles={selectCustomStyle}
							id="locationSelect"
							instanceId="locationSelect"
						/>
					</div>
				)
			}
		</>
	);
};

export const JobsFilters = memo(FiltersComponent);

