
import {memo} from "react";
import { IColReorder } from "../../../types/table-types";

const DNDOrderPopupItem =
	({listeners, item, setOrderArray}: {
		listeners: any, item: IColReorder,
		setOrderArray:
			(value: Array<IColReorder> | ((item: Array<IColReorder>) => Array<IColReorder>)) => void
	}) => {
		return <>
			<div className="custom-checkbox">
				<input
					type="checkbox"
					className="custom-checkbox__input"
					id={item.id + item.headerName}
					name={item.id + item.headerName}
					checked={item.visibility}
					onChange={
						() => {
							return setOrderArray(prev => [
							...[],
							...prev.map(value =>
								value.id === item.id ?
									{...{}, ...prev.find(x => x.id === item.id), visibility: !item.visibility}
									: value
							)
						])}}
				/>
				<label htmlFor={item.id + item.headerName} className="custom-checkbox__label">
					<div className="custom-checkbox__item">
						<span/>
					</div>
					<p className="custom-checkbox__text">{item.headerName} </p>
				</label>
			</div>
			<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...listeners}>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M1.6001 8.49967C1.6001 8.13148 1.89857 7.83301 2.26676 7.83301H13.4668C13.835 7.83301 14.1334 8.13148 14.1334 8.49967C14.1334 8.86786 13.835 9.16634 13.4668 9.16634H2.26676C1.89857 9.16634 1.6001 8.86786 1.6001 8.49967Z"
				      fill="#DFE2E6"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M1.6001 4.49967C1.6001 4.13148 1.89857 3.83301 2.26676 3.83301H13.4668C13.835 3.83301 14.1334 4.13148 14.1334 4.49967C14.1334 4.86786 13.835 5.16634 13.4668 5.16634H2.26676C1.89857 5.16634 1.6001 4.86786 1.6001 4.49967Z"
				      fill="#DFE2E6"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M1.6001 12.4997C1.6001 12.1315 1.89857 11.833 2.26676 11.833H13.4668C13.835 11.833 14.1334 12.1315 14.1334 12.4997C14.1334 12.8679 13.835 13.1663 13.4668 13.1663H2.26676C1.89857 13.1663 1.6001 12.8679 1.6001 12.4997Z"
				      fill="#DFE2E6"/>
			</svg>
		</>
	}
export default memo(DNDOrderPopupItem)