import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICandidateScreenExtra, ICandidateScreenInfo } from "../../types/redux/candidate-screen";


export const initialStateManageCandidateScreen: ICandidateScreenInfo = {
			name: '',
			avatar: '',
			degree: '',
			experienceYears: '',
			location: '',
			email: '',
			phone: '',
			skills: [],
			experience: [],
			educational: [],
			position: '',
			cvKey: '',
			cvName: ''
}

export const candidateProfile = createSlice({
	name: 'candidate',
	initialState: {
		info:initialStateManageCandidateScreen,
		downloaded:false,
		extra:[],
		schedulePopup: false,
		calendarPopup: {
			open: false,
			startDate: null,
			endDate: null
		},
	},
	reducers: {
		setScheduleMeetingPopupCandidateScreenProfile: (state, action: PayloadAction<boolean>) => {
			state.schedulePopup = action.payload
		},
		setCalendarPopupCandidateScreenProfile: (state, action: PayloadAction<any>) => {
			state.calendarPopup = action.payload
		},
		setDataCandidateScreenProfile: (state, action: PayloadAction<ICandidateScreenInfo>) => {
			state.info=action.payload
		},
		setDownloadedCandidateScreenProfile: (state, action: PayloadAction<boolean>) => {
			state.downloaded=action.payload
		},
		setDataCandidateScreenProfileExtra: (state, action: PayloadAction<Array<ICandidateScreenExtra>>) => {
			state.extra=action.payload
		},
	}
})
export const {
	setDataCandidateScreenProfile,
	setDataCandidateScreenProfileExtra,
	setDownloadedCandidateScreenProfile,
	setCalendarPopupCandidateScreenProfile,
	setScheduleMeetingPopupCandidateScreenProfile
} = candidateProfile.actions

export default candidateProfile.reducer
