import {store, useTypedSelector} from "../../store";
import DatePicker from 'react-datepicker'
import {setDateSelectedJobs, setStatusFilterJobs} from "../../store/reducers/jobsTableReducer";
import {JobStatus, JobTabs} from "../../enums/jobs/jobsEnums";
import {memo} from "react";
const getFiltersFunc=state => state.jobs.filters
const statusArray=[
	{
		status:JobStatus.PUBLISH,
		value:'publish',
		style:'table__status-block table__status-block--green jobs-table__status-button'
	},
	{
		status:JobStatus.ON_HOLD,
		value:'onhold',
		style:'table__status-block table__status-block--orange jobs-table__status-button'
	},
	{
		status:JobStatus.Pending,
		value:'approval',
		style:'table__status-block table__status-block--yellow jobs-table__status-button'
	},
	{
		status:JobStatus.DRAFT,
		value:'draft',
		style:'table__status-block table__status-block--red jobs-table__status-button'
	},
	{
		status:JobStatus.REJECTED,
		value:'rejected',
		style:'table__status-block table__status-block--red jobs-table__status-button'
	},
	{
		status:JobStatus.CLOSED,
		value:'closed',
		style:'table__status-block table__status-block--gray jobs-table__status-button'
	},

]
const JobsFilterItems=({activeTab})=>{
	const filter = useTypedSelector(getFiltersFunc)
	return <>
		{
			activeTab === JobTabs.STATUS &&<div className='table-filter-popup__right__content'>
				{
					<ul className='filters-popup-mtm-buttons-list'>
						{
							statusArray.map(item => {
								return <li
									className={`${filter.status.find(x => x === item.value) ? item.style : `${item.style} inactive`}`}
									key={item.status}
									onClick={() => store.dispatch(setStatusFilterJobs(filter.status.find(x => x === item.value) ? filter.status.filter(x => x !== item.value) : [...filter.status, item.value]))}
								>
									{item.status}
								</li>
							})
						}
					</ul>
				}
      </div>
		}
		{
			activeTab === JobTabs.DATE &&
      <div className='table-filter-popup__right__content'>
          <div className='filters-popup-mtm-datepicker'>
              <div className='filters-popup-mtm-datepicker__item'>
                  <label className="filters-popup-mtm-datepicker__label">From</label>
                  <div className='filters-popup-mtm-datepicker__date'>
                      <DatePicker
                          selected={filter.date.from}
                          showYearDropdown={true}
                          scrollableYearDropdown={true}
                          yearDropdownItemNumber={70}
                          onChange={(date: any) => {
														store.dispatch(setDateSelectedJobs({from: date, to: filter.date.to}))
													}}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          placeholderText="From"
                          id={'birthFrom'}
                      />
                  </div>
              </div>
              <div className='filters-popup-mtm-datepicker__item'>
                  <label className="filters-popup-mtm-datepicker__label">To</label>
                  <div
                      className={`${filter.date.from ? 'filters-popup-mtm-datepicker__date' : 'filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly'}`}>
                      <DatePicker
                          selected={filter.date.to}
                          showYearDropdown={true}
                          scrollableYearDropdown={true}
                          yearDropdownItemNumber={70}
                          onChange={(date: any) => {
														store.dispatch(setDateSelectedJobs({to: date, from: filter.date.from}))
													}}
                          readOnly={!filter.date.from}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          minDate={filter.date.from}
                          placeholderText="To"
                      />
                  </div>
              </div>
          </div>
      </div>
		}
	</>
}

export default memo(JobsFilterItems)