import noRes from '../../../image/icon/table/no-results.svg'
import {memo} from "react";

const NoResultsSearch = ({limit,resetFunc}:{limit:number,resetFunc:(value:number)=>void}) => {

	return <div className='table-empty'>
		<img src={noRes} alt={'no-results'} className='table-empty__image'/>
		<h2 className='table-empty__title'>Ooops!</h2>
		<p className='table-empty__desc'>We couldn't find any results for your query</p>
		<button className='button--filled table-empty__button' onClick={()=>resetFunc(limit)}>
			Reset search
		</button>
	</div>
}
export default memo(NoResultsSearch)