import { getEnv } from "@urecruits/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { store, useTypedSelector } from "../store";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import {
  defaultFiltersUsersOffers,
  setCandidateActionsStatusFilterUsersOffers,
  setDateSelectedUsersOffers,
  setEmptySearchCandidatesOffers,
  setEmptyTableCandidatesOffer,
  setLimitUsersOffers,
  setLocationFilterUsersOffers,
  setOfferLetterStatusFilterUsersOffers,
  setPageHeaderTitle,
  setSearchValueUsersOffers,
  setSortByFilterUsersOffers,
  setSortTypeFilterUsersOffers,
  setTableItemsCandidatesOffer,
  setTotalCountCandidates,
  setCurrentPageUsersOffers
} from "../store/reducers/usersOffersReducer";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import {
  PaginationComponent,
  SearchFieldComponent,
  TheadItemComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import { CSSTransition } from "react-transition-group";
import { OffersUsersOrder } from "../components/HOCs/OrderPopupHOCs";
import TableEmpty from "../components/Global/table/TableEmpty";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TBodyInnerUsersOffers from "../components/UsersOffers/Table/TBodyInnerUsersOffers";
import {
  UsersOffersTableTabsEnums,
  OfferLetterStatus
} from "../enums/usersOffers/UsersOffersEnums";
import FilterItemsUsersOffers from "../components/UsersOffers/FilterPopup/FilterItemsUsersOffers";
import axios from "axios";
import MobileTableUsersOffers from "../components/UsersOffers/MobileTable/MobileTableUsersOffers";
import { EditorModals } from "../components/Popups/editor-modals";
import useSnackbar from "../hook/useSnackbar";
import { findActiveRound } from "../utils";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const { API_RECRUITMENT } = getEnv();
const paramData = { jobId: null };
const getTableOffersUsers = (state) => state.offers_users;
const getFiltersFunc = (state) => state.offers_users.filters;
const getFilterDataFunc = (state) => state.offers_users.filterInfo;
const getPaginationFunc = (state) => state.offers_users.pagination;

const FilterItems = (state) => {
  return <FilterItemsUsersOffers activeTab={state} />;
};

const FilterPopupInnerWrap = (setState) => {
  const filter = useTypedSelector(getFiltersFunc);
  const filterData = useTypedSelector(getFilterDataFunc);
  const pagination = useTypedSelector(getPaginationFunc);
  const { id } = useParams();
  paramData.jobId = id;

  return (
    <FilterWrapper
      setFilterPopup={setState}
      filterData={filterData}
      filters={filter}
      limit={pagination.limit}
      resetFunc={resetFilters}
      submitFunc={setUsersOffersHandler}
    >
      {FilterItems}
    </FilterWrapper>
  );
};

const JobsOffersScreen = () => {
  const navigate = useNavigate();
  const { ErrorElement, APIExecutor } = useSnackbar();
  const table = useTypedSelector(getTableOffersUsers);

  const [orderPopup, setOrderPopup] = useState(false);
  const [horizontalScrollState, setHorizontalScrollState] = useState(false);
  const [modal, setModal] = useState<string | boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const orderPopupRef = useRef<null | HTMLButtonElement>(null);
  const tableRef = useRef(null);

  const { checkUserPermission } = useHasPermission();

  useLayoutEffect(() => {
    APIExecutor(() =>
      setUsersOffersHandler(
        table.pagination.currentPage,
        table.pagination.limit,
        table.filters
      )
    );
    setHeaderHandler(paramData.jobId);
  }, []);

  const filterArray = [
    table.filters.searchValue,
    table.filters.sortBy,
    table.filters.sortType,
    table.pagination,
    table.filters.location,
    table.filters.offerLetterStatus,
    table.filters.candidateActionsStatus,
    table.filters.date.from,
    table.filters.date.to,
  ];

  useTableClickAndDragScroll(tableRef);
  useClickOutside(orderPopupRef, setOrderPopup);

  const tdOrderCallback = useCallback(
    (value) =>
      sortingFunc(
        table,
        value,
        setSortTypeFilterUsersOffers,
        setSortByFilterUsersOffers,
        setUsersOffersHandler
      ),
    filterArray
  );

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValueUsersOffers(value));
    setUsersOffersHandler(1, table.pagination.limit, {
      ...table.filters,
      searchValue: value,
    });
  }, filterArray);

  //TODO: change link
  const emptyTableCallback = useCallback(() => navigate("/job/create"), []);

  return (
    <>
      <ErrorElement />
      <AuthGuard module={['job-post', 'offer']} permission='view' option='AND' >
        <div className="users-offers">
          <div className="users-offers__head">
            <ul className="users-offers__breadcrumbs">
              <li className="users-offers__breadcrumbs__item">
                <Link
                  to="/recruitment/offers"
                  className="users-offers__breadcrumbs__link"
                >
                  Offers
                </Link>
              </li>
              <li className="users-offers__breadcrumbs__item">
                <p className="users-offers__breadcrumbs__link">
                  {table.jobTitle}
                </p>
              </li>
            </ul>
            <p className="users-offers__head__title">{table.jobTitle}</p>
            {/* <p className="users-offers__head__location">
          United kingdom, London, Edinburgh, Manchester
        </p> */}
          </div>
          <div className="table__wrapper users-offers__table-wrapper">
            <div className="table__top">
              <div className="manage-team__top__left">
                <TopFilterButton>{FilterPopupInnerWrap}</TopFilterButton>
                <SearchFieldComponent
                  searchValue={table.filters.searchValue}
                  setSearchValue={setSearchCallback}
                  placeholder={"Search by name"}
                />
              </div>
              <button
                className={`manage-team__top__svg ${orderPopup ? "active" : ""}`}
                ref={orderPopupRef}
              >
                <MemoOrderSVG setState={setOrderPopup} />
                {
                  <CSSTransition
                    in={orderPopup}
                    timeout={300}
                    classNames={"order-popup-mtm"}
                    unmountOnExit
                    mountOnEnter
                  >
                    <OffersUsersOrder setOrderPopup={setOrderPopup} />
                  </CSSTransition>
                }
              </button>
            </div>

            {table.noResultSearch || table.tableEmpty ? (
              table.tableEmpty ? (
                <TableEmpty
                  handler={checkUserPermission('offer','add') ? emptyTableCallback : ()=>navigate("/")}
                  cta={checkUserPermission('offer','add') ? "Add offer" : "Go to Dashboard"}
                  title={"Table is empty"}
                  desc={`Users offers table is empty ${checkUserPermission('offer','add') ? ', add your first offer.' : '.'}`}
                />
              ) : (
                <NoResultsSearch
                  limit={table.pagination.limit}
                  resetFunc={resetFilters}
                />
              )
            ) : (
              <>
                <table
                  className="table offers-table"
                  ref={tableRef}
                  onScroll={(e: any) => {
                    if (e.target.scrollLeft > 10 && !horizontalScrollState)
                      setHorizontalScrollState(() => true);
                    else if (e.target.scrollLeft < 10 && horizontalScrollState)
                      setHorizontalScrollState(() => false);
                  }}
                >
                  <thead className="table__thead">
                    <td
                      className={`table__td sticky ${horizontalScrollState ? "moved" : ""
                        } table__td--thead offers-table__column__default`}
                    >
                      <TheadItemComponent
                        title={table.fixedTab.displayName}
                        dbName={""}
                      />
                    </td>
                    {table.tabFilter.map((item) => {
                      return (
                        item.active && UsersOffersTableTabsEnums.ACTIONS ===
                              item.displayName ? checkUserPermission('offer',['view','edit','delete']) &&
                          <td
                            className={`${UsersOffersTableTabsEnums.ACTIONS ===
                                item.displayName
                                ? "table__td  table__td--thead offers-table__column__large"
                                : "table__td  table__td--thead offers-table__column__default"
                              }`}
                            key={item.id}
                          >
                            <TheadItemComponent
                              title={item.displayName}
                              handler={item.dbName ? tdOrderCallback : null}
                              dbName={item.dbName}
                            />
                          </td>
                        :
                        <td
                            className={`${UsersOffersTableTabsEnums.ACTIONS ===
                                item.displayName
                                ? "table__td  table__td--thead offers-table__column__large"
                                : "table__td  table__td--thead offers-table__column__default"
                              }`}
                            key={item.id}
                          >
                            <TheadItemComponent
                              title={item.displayName}
                              handler={item.dbName ? tdOrderCallback : null}
                              dbName={item.dbName}
                            />
                          </td>
                      );
                    })}
                  </thead>
                  <TBodyInnerUsersOffers
                    horizontalScrollState={horizontalScrollState}
                    setModal={setModal}
                    setSelectedOffer={setSelectedOffer}
                  />
                </table>

                <div className="table-mobile">
                  {table.users.map((item) => {
                    return (
                      <MobileTableUsersOffers
                        key={item.id}
                        item={item}
                        setSelectedOffer={setSelectedOffer}
                        setModal={setModal}
                      />
                    );
                  })}
                </div>

                <PaginationComponent
                  limit={table.pagination.limit}
                  currentPage={table.pagination.currentPage}
                  totalCount={table.pagination.totalCount}
                  setCurrentPage={setUsersOffersHandler}
                  setLimit={setLimitHandler}
                  filters={table.filters}
                />
              </>
            )}
          </div>
          <EditorModals
            modal={modal}
            setModal={setModal}
            offerId={selectedOffer?.offerId}
            formData={selectedOffer}
          />
        </div>
      </AuthGuard>
    </>
  );
};

export default JobsOffersScreen;

//TODO: need to write func for get data
export const setUsersOffersHandler = async (
  page: number,
  limit: number,
  filters: any
) => {
  store.dispatch(setCurrentPageUsersOffers(page));
  await axios(
    `${API_RECRUITMENT}/api/offer/candidate-offers/${paramData.jobId
    }?limit=${limit}&offset=${(page - 1) * limit
    }${filters.candidateActionsStatus
      .map((item) => `&candidateStatus=${item}`)
      .join("")}${filters.offerLetterStatus
        .map((item) => `&letterStatus=${item}`)
        .join("")}&search=${encodeURIComponent(
          filters.searchValue
        )}&joiningDateFrom=${filters.date.from ? encodeURIComponent(filters.date.from) : ""
    }&joiningDateTo=${filters.date.to ? encodeURIComponent(filters.date.to) : ""
    }`,

    config
  ).then((res) => {
    store.dispatch(setTotalCountCandidates(res.data.count));
    const items = transformArrayToTableData(res.data.rows);
    store.dispatch(setTableItemsCandidatesOffer(items));

    if (items.length == 0) {
      if (filters.searchValue === "") {
        store.dispatch(setEmptyTableCandidatesOffer(true));
      } else {
        store.dispatch(setEmptySearchCandidatesOffers(true));
      }
    } else {
      store.dispatch(setEmptySearchCandidatesOffers(false));
      store.dispatch(setEmptyTableCandidatesOffer(false));
    }
  });
};

export const setHeaderHandler = (jobId: number) => {
  const getData = async () => {
    await axios(`${API_RECRUITMENT}/api/job/${jobId}`, config).then((res) => {
      store.dispatch(setPageHeaderTitle(res.data.title));
    });
  };

  getData().then();
};

const transformArrayToTableData = (array: any): Array<any> => {
  return array.map((item) => {
    const latestOffer = item.offer;
    let joiningDate = latestOffer?.joiningDate;
    if (!joiningDate) joiningDate = "-";
    else joiningDate = new Date(joiningDate).toLocaleDateString("en-GB");

    const approver = latestOffer?.approver;
    let round;
    if (item?.round?.rounds) {
      round = findActiveRound(item?.round?.rounds)
      // console.log(round)
    }
    return {
      id: item.id,
      userName: item.user.firstname + " " + item.user.lastname,
      jobId: item.jobId,
      userId: item.userId,
      letterStatus: latestOffer?.letterStatus || OfferLetterStatus.NO_GENERATED,
      candidateStatus: latestOffer?.candidateStatus,
      candidateCTC: latestOffer?.ctc,
      offerId: latestOffer?.id,
      roundId: item.roundId,
      round: round,
      subscribeId: item.id,
      joiningDate: joiningDate,
      approverName: approver
        ? approver.firstname + " " + approver.lastname
        : "-",
      approverAuthId: approver ? approver.authId : null,
    };
  });
};

const setLimitHandler = (value: any) => {
  store.dispatch(setLimitUsersOffers(value));
};

const resetFilters = (pagination) => {
  store.dispatch(setSortByFilterUsersOffers("id"));
  store.dispatch(setSortTypeFilterUsersOffers("ASC"));
  store.dispatch(setSearchValueUsersOffers(""));
  store.dispatch(setLocationFilterUsersOffers([]));
  store.dispatch(setOfferLetterStatusFilterUsersOffers([]));
  store.dispatch(setCandidateActionsStatusFilterUsersOffers([]));
  store.dispatch(setDateSelectedUsersOffers({ from: null, to: null }));
  setUsersOffersHandler(1, pagination, defaultFiltersUsersOffers);
};
