import { memo, useEffect, useState } from "react";
import SingleItem from "../../../Global/table/SingleMobileItem";
import { getEnv } from "@urecruits/api";
import eye from "../../../../image/icon/eye-on.svg";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { CandidateDomainAssessmentTabs } from "../../../../enums/candidate-domain-assessment/candidate-domain-assessment";
import fetchData from "../../../../hook/http";
import VideoMeetLoader from "../../../../../assessment/live-coding/components/video-meeting/Loader/VideoMeetLoader";

const { API_RECRUITMENT, API_ASSESSMENT } = getEnv();

const ManageCandidateDomainMobileTBody = ({ item }) => {
  const [openItem, setOpenItem] = useState(false);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [loading, setLoading] = useState(true);
  const [tableItems, setTableItems] = useState(null);

  setTimeout(() => {
    setLoading(false);
  }, 5000);
  const handleTableValues = async () => {
    const obj = {
      id: item.id,
      candidate: await handleCandidate(item?.candidateId),
      timeDuration: item?.timeDuration || "",
      score: item?.score || "",
      preview: item?.id,
      createdAt: await handleDate(item?.candidateId),
    };
    setTableItems(obj);
  };

  useEffect(() => {
    handleTableValues();
  }, [item]);

  const handleDate = async (candidateId) => {
    try {
      if (candidateId !== undefined) {
        const res = await fetchData(
          `${API_ASSESSMENT}/api/test-manager/get?jobId=${jobId}&assessmentType=Domain%20Assessment&candidateId=${candidateId}`
        );
        if (res?.createdAt) {
          const date = new Date(res?.createdAt);
          const formattedDate = date.toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return formattedDate;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCandidate = async (id) => {
    try {
      if (id !== undefined) {
        const res = await fetchData(
          `${API_RECRUITMENT}/api/candidate/public-candidate/user/${id}`
        );
        const fullName = `${res?.user?.firstname} ${res?.user?.lastname}`;
        return fullName;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <VideoMeetLoader />
      ) : (
        <div className="table-mobile__item">
          <div
            className={`table-mobile__top ${openItem ? "active" : ""}`}
            onClick={() => {
              setOpenItem((prev) => !prev);
            }}>
            <div className="table-mobile__top__left">
              <p className="table-mobile__top__name">{`${tableItems?.candidate}`}</p>
            </div>
            <ArrowSvg />
          </div>
          <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
            <SingleItem
              title={CandidateDomainAssessmentTabs.Time + ":"}
              text={tableItems?.timeDuration}
              textStyle={
                "table-mobile__item__text table-mobile__item__text--green"
              }
            />
            <SingleItem
              title={CandidateDomainAssessmentTabs.Score + ":"}
              text={tableItems?.score ? tableItems?.score : 0}
              textStyle={
                "table-mobile__item__text table-mobile__item__text--green"
              }
            />
            <SingleItem
              title={CandidateDomainAssessmentTabs.Date + ":"}
              text={tableItems?.timeDuration}
              textStyle={
                "table-mobile__item__text table-mobile__item__text--green"
              }
            />
            <div className="table-mobile__item__body__action">
            {
              <button
                className="table-mobile__item__action-button table-mobile__item__action-button--green"
                onClick={() => {
                  navigate(
                    `/recruitment/answer-sheet/${tableItems?.id}/${tableItems?.candidate && tableItems?.candidate}`
                  );
                }}>
                <img src={eye} alt="delete-icon" />
                View Answer sheet
              </button>
            }
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default memo(ManageCandidateDomainMobileTBody);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
