import { getEnv } from "@urecruits/api";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManageAssignmentTabs } from "../../enums/manage-assignment/manageAssignment";
import bin from "../../image/icon/delete_ic.svg";
import { store } from "../../store";
import { setOpenPreview, setPopupWithAssessmentType, setPopupWithDelete } from "../../store/reducers/manageAssignmentReducer";
import SingleItem, { ButtonItem } from "../Global/table/SingleMobileItem";

const { API_RECRUITMENT } = getEnv();

const ManageAssignmentMobileTable = ({ item }) => {
  const navigate=useNavigate()
  const [openItem, setOpenItem] = useState(false);

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => {
          setOpenItem((prev) => !prev);
        }}>
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{`${item?.title}`}</p>
        </div>
        <ArrowSvg />
      </div>
      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleItem
          title={ManageAssignmentTabs.candidates + ":"}
          text={`${item?.candidates}`}
          textStyle={"table-mobile__item__text"}
        />
       
        <ButtonItem
          title={ManageAssignmentTabs.assessmentType + ":"}
          text={
            item?.assessmentType &&
            item.assessmentType.map((type, index) => (
              <div
                key={index}
                className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                {`${index + 1}.${type}`}
                {item?.assessmentType?.length - 1 === index && (
                  <button
                    className="manage-team__text manage-team__text--green manage-team__text__button"
                    onClick={() =>
                      store.dispatch(
                        setPopupWithAssessmentType({
                          title: item.title,
                          open: true,
                          id: item.id,
                          data: item?.assessmentType,
                          ids: item?.assessmentIds,
                        })
                      )
                    }>
                    See more
                  </button>
                )}
              </div>
            ))
          }
          textStyle={"table-mobile__item__text table-mobile__item__text--green"}
        />
        <div className="table-mobile__item__body__action">
        {
        <button className='table-mobile__item__action-button table-mobile__item__action-button--half' onClick={() => {
          navigate(`/recruitment/manage-assignment/${item?.jobId}`)
          store.dispatch(setOpenPreview({open:true,title:item?.title}))
        }}>
           See all candidates
        </button>
			}
			{
				item.status !== 'approval' &&
        <button className='table-mobile__item__action-button table-mobile__item__action-button--half' onClick={() => {
            store.dispatch(
            setPopupWithDelete({
              open: true,
              id: item.id,
              title: item.title,
              errorMessage: item.hasOngoingAssignment ? 'You cannot delete this assignment because it is currently being used in an ongoing task assigned to candidates.':''
            })
          );
        }}>

            <img src={bin} alt="delete-icon"/>
            Delete
        </button>
			}
      </div>
      </div>
    </div>
  );
};
export default memo(ManageAssignmentMobileTable);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
