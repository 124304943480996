import { store } from "../../../store";
import { setCurrentTabOfferLetterScreen } from "../../../store/reducers/generateOfferLetterScreen";
import { GenerateOfferTabs } from "../../../enums/generate-offer/generate-offer";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";

const DetailsStep = ({
  register,
  trigger,
  getValues,
  setValue,
  clearErrors,
  formState: { errors },
  setCurrentTab,
}) => {
  const [stepValidate, setStepValidate] = useState(false);

  const moveToNextStep = async () => {
    const isValid = await trigger([
      "candidateFirstname",
      "candidateLastname",
      "candidateEmail",
      "candidatePhone",
      "ctc",
      "candidateAddress",
    ]);

    setStepValidate(!isValid);
    if (isValid) {
      setCurrentTab(GenerateOfferTabs.OFFER_DETAILS);
      clearErrors();
    }
  };

  return (
    <div className="step">
      <div className="step__head">
        <p className="step__head__headline">01. Details</p>
      </div>
      <div className="step__body">
        <div className="step__row">
          <div className="step__item">
            <p className="step__item__label">
              Candidate First Name<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="candidateFirstname"
              id="candidateFirstName"
              placeholder="Enter candidate first name"
              {...register("candidateFirstname", { required: true })}
            />
            {errors.candidateFirstname && (
              <p className="error-message">
                {errors.candidateFirstname.message}
              </p>
            )}
          </div>
          <div className="step__item">
            <p className="step__item__label">
              Candidate Last Name<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="candidateLastname"
              id="candidateLastName"
              placeholder="Enter candidate last name"
              {...register("candidateLastname", { required: true })}
            />
            {errors.candidateLastname && (
              <p className="error-message">
                {errors.candidateLastname.message}
              </p>
            )}
          </div>
        </div>
        <div className="step__row">
          <div className="step__item">
            <p className="step__item__label">
              Candidate Email<span> *</span>
            </p>
            <input
              className="step__item__input"
              name="candidateEmail"
              id="candidateEmail"
              placeholder="Enter candidate email"
              {...register("candidateEmail", {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
            />
            {errors.candidateEmail && (
              <p className="error-message">{errors.candidateEmail.message}</p>
            )}
          </div>
          <div className="step__item">
            <p className="step__item__label">
              Candidate Phone number<span> *</span>
            </p>
            <PhoneInput
              name="candidatePhone"
              international
              defaultCountry="US"
              value={getValues("candidatePhone")}
              onChange={(value) => {
                setValue("candidatePhone", value);
                clearErrors("candidatePhone");
              }}
            />
            {errors.candidatePhone && (
              <p className="error-message">{errors.candidatePhone.message}</p>
            )}
          </div>
        </div>
        <div className="step__row">
          <div className="step__item">
            <p className="step__item__label">
              CTC<span> *</span>
            </p>
            <input
              type="number"
              className="step__item__input"
              name="ctc"
              id="cts"
              placeholder="Enter candidate ctc"
              {...register("ctc", { required: true })}
            />
            {errors.ctc && (
              <p className="error-message">{errors.ctc.message}</p>
            )}
          </div>
        </div>
        <div className="step__row">
          <div className="step__item long">
            <p className="step__item__label">
              Candidate Address<span> *</span>
            </p>
            <input
              className="step__item__input"
              name="candidateAddress"
              id="address"
              placeholder="Enter candidate Adress"
              autoComplete="off"
              {...register("candidateAddress", { required: true })}
            />
            {errors.candidateAddress && (
              <p className="error-message">{errors.candidateAddress.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="step__bottom">
        <button
          className="step__bottom__next button--empty"
          onClick={moveToNextStep}
        >
          Next
        </button>
        {stepValidate && (
          <p className="error-message">Please fill in the required fields</p>
        )}
      </div>
    </div>
  );
};

export default DetailsStep;
