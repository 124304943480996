import type {PayloadAction} from '@reduxjs/toolkit'
// eslint-disable-next-line no-duplicate-imports
import {createSlice} from '@reduxjs/toolkit'
import {IJobDetailView} from "../../types/redux/job-detail-view";
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";
import {DetailViewJobFilters, DetailViewJobMatched} from "../../enums/detail-view-job/detail-view-job-matched";
import {IDomainAssessment, IDomainAssessmentTableItems} from "../../types/redux/domain-assessment";
import {DomainAssessmentTabs} from "../../enums/domain-assessment/domain-assessment";


export const defaultFiltersDomainAssessmentScreen = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	industry:[],
	department:[],
	time:[],
	score:[0]
}
const initialStateDomainAssessment:IDomainAssessment = {
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersDomainAssessmentScreen,
	tableItems: [],
	openPreview:{
		isOpen:false,
		id:null
	},
	popupWithInstruction:{
		open:false,
		id:-1,
		instruction:'',
		title:null,
	},
	popupWithDelete:{
		open:false,
		id:-1,
		title:null,
	},
	fixedTab: {
		id: 1,
		displayName: DomainAssessmentTabs.Name,
	},
	tabFilter: [
		{
			id: 2,
			displayName: DomainAssessmentTabs.Industry,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: DomainAssessmentTabs.Department,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: DomainAssessmentTabs.Time,
			active: true,
			dbName: ''
		},
		{
			id: 5,
			displayName: DomainAssessmentTabs.Score,
			active: true,
			dbName: ''
		},
		{
			id: 6,
			displayName: DomainAssessmentTabs.Instructions,
			active: true,
			dbName: ''
		},
		{
			id: 7,
			displayName: DomainAssessmentTabs.Actions,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				DomainAssessmentTabs.Industry,
				DomainAssessmentTabs.Department,
				DomainAssessmentTabs.Time,
				DomainAssessmentTabs.Score
			],
	}
}

export const DomainAssessment = createSlice({
	name: 'domain_assessment',
	initialState: initialStateDomainAssessment,
	reducers: {
		setLimitDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setSearchValueDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderDomainAssessment: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setSortByDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setEmptyTableDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setEmptySearchDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setTableItemsDomainAssessment: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
		setTotalCountDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setOpenPreview: (state, action: PayloadAction<any>) => {
			state.openPreview = action.payload
		},
		setCurrentPageDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setPopupWithInstruction: (state, action: PayloadAction<{open: boolean, id: number, instruction:any, title:any}>) => {
			state.popupWithInstruction = action.payload
		},
		setPopupWithDelete: (state, action: PayloadAction<{open: boolean, id: number, title:any}>) => {
			state.popupWithDelete = action.payload
		},
		setDepartmentDomainAssessment: (state, action: PayloadAction<IAsyncCheckBoxList[]>) => {
			state.filters.department = action.payload
		},
		setIndustryDomainAssessment: (state, action: PayloadAction<IAsyncCheckBoxList[]>) => {
			state.filters.industry = action.payload
		},
		setScoreFilterDomainAssessment: (state, action: PayloadAction<Array<any>>) => {
			state.filters.score = action.payload
		},
		setTimeFilterDomainAssessment: (state, action: PayloadAction<Array<string>>) => {
			state.filters.time = action.payload
		},
	},
})

export const {	
	setSortTypeDomainAssessment,
	setLimitDomainAssessment,
	setSortByDomainAssessment,
	setOrderDomainAssessment,
	setSearchValueDomainAssessment,
	setEmptyTableDomainAssessment,
	setEmptySearchDomainAssessment,
	setTableItemsDomainAssessment,
	setTotalCountDomainAssessment,
	setCurrentPageDomainAssessment,
	setPopupWithInstruction,
	setPopupWithDelete,
	setTimeFilterDomainAssessment,
	setScoreFilterDomainAssessment,
	setIndustryDomainAssessment,
	setDepartmentDomainAssessment,
	setOpenPreview
} = DomainAssessment.actions

export default DomainAssessment.reducer