import React from "react";
import deleteIc from '../../image/icon/delete_image_popup_ic.svg';
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { setUsersOffersHandler } from "../../screen/UsersOffersScreen";
import { setOffersPreviewHandler } from "../../screen/OfferPreviewScreen";
import { useTypedSelector } from "../../store";
const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
};
const getTableOffersUsers = (state) => state.offers_users;
export const DeleteOfferModal= ({setModal, data,singleOfferDelete}) => {
  

    const table = useTypedSelector(getTableOffersUsers);
    const handleDeleteOffer = ()=>{
      const {id:offerId, userId, jobId} = data;
      let endPoint = `${API_RECRUITMENT}/api/offer/`;
      if(singleOfferDelete){
        endPoint +=offerId;
      }
      else{
        endPoint += `candidate/offers/${userId}/${jobId}`;
      }

      const deleteOffer = async()=>{
        await axios.delete(endPoint,config)
        .then(()=>{
            if(singleOfferDelete){
                setOffersPreviewHandler(jobId, userId); 
            }
            else{
                setUsersOffersHandler(
                    table.pagination.currentPage,
                    table.pagination.limit,
                    table.filters
                  );
            }
        });
      }

      deleteOffer().finally(()=>{
          setModal(false)
      }); 

    }
   
    return(
    <>
    <div className="modal__body__delete">
    <img src={deleteIc} alt="" className="modal__body__delete__image"/>
    <p className="modal__body__delete__text">
    Are you Sure to delete <strong>{`${singleOfferDelete ? 'this Offer ?' : 'all previous offers including current offer of this user ?'}`}</strong>
        </p>
    </div>

    <div className="modal__actions">
     <button className="modal__actions__cancel__outlined" onClick={()=>setModal(false)} >Cancel</button>
     <button className="modal__actions__send" onClick={handleDeleteOffer}>Delete</button>
    </div>
    </>
   );
}