import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import DNDOrderPopupItem from "./DNDOrderPopupItem";
import {memo} from "react";
import { IColReorder } from "../../../types/table-types";


const DNDOrderPopupWrapper=({item,setOrderArray}:{item:IColReorder,setOrderArray:(value:Array<IColReorder>)=>void})=>{
	const {
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({id: item.id});
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	return <div ref={setNodeRef} style={style} className='order-popup-mtm__item'>
		<DNDOrderPopupItem listeners={listeners} item={item} setOrderArray={setOrderArray}/>
	</div>
}

export default memo(DNDOrderPopupWrapper)