import { useTypedSelector } from "../../../../store";
import MobileTableItem from "./MobileTableItem";
import { memo } from "react";
const getUsers=state=> state.team_members.users
const MobileTable=()=>{
	const users=useTypedSelector(getUsers)
	return<div className='table-mobile'>
		{
			users.map(item=>{
				return <MobileTableItem key={item.id} item={item} />
			})
		}
	</div>
}
export default memo(MobileTable)