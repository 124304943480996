import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getEnv } from "@urecruits/api";
import fetchData from "../../../hook/http";
const {API_ASSESSMENT}=getEnv()

const EventView = ({
  startTime = "",
  endTime = "",
  title = "",
  description = "",
  roomId = "",
  jobId,
  candidateId,
  disableEvent=false
}) => {  
  const [type, setType] = useState("");
  const [roomStatus, setRoomStatus] = useState(""); 
  const navigate = useNavigate();
  const fetchEvents = async () => {
    const result = await fetchData(
      `${API_ASSESSMENT}/api/calendar/get?candidateId=${candidateId}&jobId=${jobId}&description=${description}`
    ).then((a) => {
      setRoomStatus(a?.data?.status);
        // setRoomId(a?.data?.roomId);
        // setType(a?.data?.description);
    });
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  let url = '/';
  let state = {
    state: {
      candidateId: ` ${candidateId}`,
      jobId: `${jobId}`,
      roomStatus: `${roomStatus}`,
      type: `${description}`,
    },
  };
  useEffect(() => {
    if (
      candidateId
      //  && (roomStatus === "not_initialized") ||
      // roomStatus === "in-progress"
    ) {
      {(description==="Technical/Coding Assessment" || description==="") ? url = `/live-coding/online/${roomId}` : url = `/live-coding/interview/${roomId}`};
    }
  }, [roomStatus, roomId]);
  return (
    <div
      style={{
        maxHeight: "12vh",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        cursor: disableEvent ? "not-allowed" : "pointer",
        // cursor: roomStatus === "completed" ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        !disableEvent && navigate(url,state);
        // !disableEvent && roomStatus != "completed" && navigate(url,state);
      }}>
      <div className="right-line" style={{background:"#029CE2"}}></div>
      <p className="manage-team__text" style={{color:"#029CE2"}}>{`${startTime} - ${endTime}`}</p>
      <b className="event-title">{`${title}`}</b>
      <p className="manage-team__text">{`${description}`}</p>
    </div>
  );
};

export default EventView;
