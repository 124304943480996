import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	IGenerateOfferScreen,
	IGenerateOfferScreenDetails,
	IGenerateOfferScreenOfferBody,
	IGenerateOfferScreenOfferDetails,
} from "../../types/redux/generate-offer-screen";
import {GenerateOfferTabs} from "../../enums/generate-offer/generate-offer";

export const initialStateGenerateOfferLetterScreen: IGenerateOfferScreen = {
	currentTab: GenerateOfferTabs.CANDIDATE_DETAILS,
	detailsTab: {
		candidateFirstName: "",
		candidateLastName: "",
		candidateEmail: "",
		candidatePhoneNumber: "",
		cts: "",
		candidateAddress: [],
	},
	offerDetailsTab: {
		jobTitle: "",
		company: "",
		jobLocation: [],
		joiningDate: null,
		signer: [],
	},
	offerBodyTab: {
		richEditorBody: "",
	},
};

export const generateOffer = createSlice({
	name: "generateOffer",
	initialState: initialStateGenerateOfferLetterScreen,
	reducers: {
		setDetailsOfferLetterScreen: (state, action: PayloadAction<IGenerateOfferScreenDetails>) => {
			state.detailsTab = action.payload;
		},
		setOfferDetailsOfferLetterScreen: (state, action: PayloadAction<IGenerateOfferScreenOfferDetails>) => {
			state.offerDetailsTab = action.payload;
		},
		setOfferBodyDetailsOfferLetterScreen: (state, action: PayloadAction<IGenerateOfferScreenOfferBody>) => {
			state.offerBodyTab = action.payload;
		},
		setCurrentTabOfferLetterScreen: (state, action: PayloadAction<string>) => {
			state.currentTab = action.payload;
		},
	},
});

export const {
	setDetailsOfferLetterScreen,
	setOfferDetailsOfferLetterScreen,
	setOfferBodyDetailsOfferLetterScreen,
	setCurrentTabOfferLetterScreen,
} = generateOffer.actions;

export default generateOffer.reducer;