import {NumericFormat} from "react-number-format";
import {memo, useState} from "react";
import {getTrackBackground, Range} from "react-range";

const CustomRange = ({rangeValue, min, max, setData,dollar}) => {
	const [rangeMinError, setRangeMinError] = useState(false);
	const [rangeMaxError, setRangeMaxError] = useState(false);
	const [prevData, setPrevData] = useState(rangeValue);
	const [currentData, setCurrentData] = useState(rangeValue);
	return <div className="table__range">
		<div className='table__range__wrap'>
			<div className="table__range__head">
			<div className="table__range__left">
				<div className={`table__range__inner  ${rangeMinError ? "error" : ""}`}>
					{
						dollar&&<span className="table__range__currency">$</span>
					}
					<NumericFormat
						value={currentData[0]}
						onValueChange={(values) => {
							const validateResult = rangeValidate(values.floatValue, currentData, min, max, "min");

							if (validateResult) {
								setRangeMinError(true);
							} else {
								setRangeMinError(false);
								setPrevData((prev) => [values.floatValue, prev[1]]);
							}
							setCurrentData((prev) => [values.floatValue, prev[1]]);
						}}
						className="table__range__input"
						thousandSeparator=","
					/>
				</div>
			</div>
			<div className="table__range__right">
				<div className={`table__range__inner  ${rangeMaxError ? "error" : ""}`}>
					{
						dollar&&<span className="table__range__currency">$</span>
					}
					<NumericFormat
						value={currentData[1]}
						onValueChange={(values) => {
							const validateResult = rangeValidate(values.floatValue, currentData, min, max, "max");
							if (validateResult) {
								setRangeMaxError(true);
							} else {
								setRangeMaxError(false);
								setPrevData((prev) => [prev[0], values.floatValue]);
							}
							setCurrentData((prev) => [prev[0], values.floatValue]);
						}}
						className="table__range__input"
						thousandSeparator=","
					/>
				</div>
			</div>
		</div>
			<div className="table__range__body">
				<CustomRangeComp
					values={rangeMinError || rangeMaxError ? prevData : currentData}
					setPrevData={setPrevData}
					setValues={setCurrentData}
					maxValue={max}
					minValue={min}
				/>
			</div>
		</div>
		<button className={`table__range__submit ${rangeMaxError||rangeMinError?'disabled':''}`} onClick={()=>{
		if(!rangeMinError&&!rangeMaxError){
			setData(currentData)
		}
		}}>OK</button>
	</div>
}

export default memo(CustomRange)

const rangeValidate = (targetValue: number, currentRangeValues: Array<number>, minRangeValue: number, maxRangeValue: number, valueStatus: string): boolean => {
	if (valueStatus === 'min') {
		if (targetValue < minRangeValue || targetValue > maxRangeValue || currentRangeValues[1] < targetValue || !targetValue) {
			return true
		}
	} else {
		if (targetValue > maxRangeValue || targetValue < minRangeValue || currentRangeValues[0] > targetValue || !targetValue) {
			return true
		}
	}
	return false
}
const CustomRangeComp = ({values, setValues, maxValue, minValue, setPrevData}) => {
	return (
		<Range
			values={values}
			step={1}
			min={minValue}
			max={maxValue}
			onChange={values => {
				setValues(values)
				setPrevData(values)
			}}
			renderTrack={({props, children}) => (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div
					onMouseDown={props.onMouseDown}
					onTouchStart={props.onTouchStart}
					className="range"
				>
					<div
						ref={props.ref}
						className="range__scale"
						style={{
							background: getTrackBackground({
								values,
								colors: ['#ccc', '#099C73', '#ccc'],
								min: minValue,
								max: maxValue,
							}),
						}}
					>
						{children}
					</div>
				</div>
			)}
			renderThumb={({props, isDragged}) => (
				<div
					{...props}
					className="range__thumb"
				>
					<div
						className="range__thumb__icon left"
					/>
					<div
						className="range__thumb__icon"
					/>
				</div>
			)}
		/>
	)
}