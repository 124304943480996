// eslint-disable-next-line no-duplicate-imports
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAsyncCheckBoxList, ITabsFilter} from "../../types/global/global";
import {IUsersOffersTable} from "../../types/redux/users-offers";
import {UsersOffersTableTabsEnums} from "../../enums/usersOffers/UsersOffersEnums";

export const defaultFiltersUsersOffers = {
	searchValue: "",
	sortBy: "id",
	sortType: "DESC",
	location: [],
	offerLetterStatus: [],
	candidateActionsStatus: [],
	date: {
		from: null,
		to: null,
	},
};

const initialStateUsersOffers: IUsersOffersTable = {
	noResultSearch: false,
  jobTitle : "",
	tableEmpty: false,
	users: [],
	filters: defaultFiltersUsersOffers,
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
	fixedTab: {
		id: 1,
		displayName: UsersOffersTableTabsEnums.CANDIDATE_NAME,
	},
	tabFilter: [
		{
			id: 2,
			displayName: UsersOffersTableTabsEnums.CTC,
			active: true,
			dbName: "",
		},
		{
			id: 3,
			displayName: UsersOffersTableTabsEnums.OFFER_LETTER_APPROVED_BY,
			active: true,
			dbName: "",
		},
		{
			id: 4,
			displayName: UsersOffersTableTabsEnums.JOINING_DATE,
			active: true,
			dbName: "",
		},
		{
			id: 5,
			displayName: UsersOffersTableTabsEnums.OFFER_LETTER_STATUS,
			active: true,
			dbName: "",
		},
		{
			id: 6,
			displayName: UsersOffersTableTabsEnums.CANDIDATE_ACTION_STATUS,
			active: true,
			dbName: "",
		},
		{
			id: 7,
			displayName: UsersOffersTableTabsEnums.ACTIONS,
			active: true,
			dbName: "",
		},
	],
	filterInfo: {
		tabs: [
			UsersOffersTableTabsEnums.OFFER_LETTER_STATUS,
			UsersOffersTableTabsEnums.CANDIDATE_ACTION_STATUS,
			UsersOffersTableTabsEnums.JOINING_DATE,
		],
	},
};

export const usersOffers = createSlice({
  name: "usersOffers",
  initialState: initialStateUsersOffers,
  reducers: {
    setTableEmptyUsersOffers: (state, action: PayloadAction<boolean>) => {
      state.tableEmpty = action.payload;
    },
    setNoResultSearchUsersOffers: (state, action: PayloadAction<boolean>) => {
      state.noResultSearch = action.payload;
    },
    setCurrentPageUsersOffers: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimitUsersOffers: (state, action: PayloadAction<number>) => {
      (state.pagination.limit = action.payload),
        (state.pagination.currentPage = 1);
    },
    setOrderUsersOffers: (state, action: PayloadAction<Array<ITabsFilter>>) => {
      state.tabFilter = action.payload;
    },
    setTotalCountUsersOffers: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSortTypeFilterUsersOffers: (state, action: PayloadAction<string>) => {
      state.filters.sortType = action.payload;
    },
    setSortByFilterUsersOffers: (state, action: PayloadAction<string>) => {
      state.filters.sortBy = action.payload;
    },
    setSearchValueUsersOffers: (state, action: PayloadAction<string>) => {
      state.filters.searchValue = action.payload;
    },
    setLocationFilterUsersOffers: (
      state,
      action: PayloadAction<Array<IAsyncCheckBoxList>>
    ) => {
      state.filters.location = action.payload;
    },
    setOfferLetterStatusFilterUsersOffers: (
      state,
      action: PayloadAction<Array<string>>
    ) => {
      state.filters.offerLetterStatus = action.payload;
    },
    setCandidateActionsStatusFilterUsersOffers: (
      state,
      action: PayloadAction<Array<string>>
    ) => {
      state.filters.candidateActionsStatus = action.payload;
    },
    setDateSelectedUsersOffers: (
      state,
      action: PayloadAction<{ from: string | null; to: string | null }>
    ) => {
      state.filters.date = action.payload;
    },
    setTotalCountCandidates: (
      state,
      action: PayloadAction<number>
    ) => {
      state.pagination.totalCount = action.payload;
    },
    setTableItemsCandidatesOffer: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    setEmptyTableCandidatesOffer: (state, action: PayloadAction<any>) => {
      state.tableEmpty = action.payload;
    },
    setEmptySearchCandidatesOffers: (state, action: PayloadAction<any>) => {
      state.noResultSearch = action.payload;
    },
    setPageHeaderTitle: (state, action: PayloadAction<any>) => {
      state.jobTitle = action.payload;
    },
  },
});

export const {
  setTableEmptyUsersOffers,
  setNoResultSearchUsersOffers,
  setCurrentPageUsersOffers,
  setLimitUsersOffers,
  setOrderUsersOffers,
  setTotalCountUsersOffers,
  setSortTypeFilterUsersOffers,
  setSortByFilterUsersOffers,
  setSearchValueUsersOffers,
  setLocationFilterUsersOffers,
  setOfferLetterStatusFilterUsersOffers,
  setCandidateActionsStatusFilterUsersOffers,
  setDateSelectedUsersOffers,
  setTotalCountCandidates,
  setTableItemsCandidatesOffer,
  setEmptyTableCandidatesOffer,
  setEmptySearchCandidatesOffers,
  setPageHeaderTitle,
} = usersOffers.actions;

export default usersOffers.reducer;