import { store, useTypedSelector } from "../../../store";
import { useState } from "react";
import {
  setCurrentTabOfferLetterScreen,
  setOfferDetailsOfferLetterScreen,
} from "../../../store/reducers/generateOfferLetterScreen";
import { GenerateOfferTabs } from "../../../enums/generate-offer/generate-offer";
import AsyncSelect from "react-select/async";
import { getEnv } from "@urecruits/api";
import DatePicker from "react-datepicker";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import {useScrollLocations} from "../../../hook"

const OfferDetailsStep = ({
  register,
  trigger,
  getValues,
  setValue,
  clearErrors,
  formState: { errors },
  setCurrentTab,
}) => {
  const [stepValidate, setStepValidate] = useState(false);
  const [joiningDateTemp, setJoiningDateTemp] = useState();
  const [locationTemp, setLocationTemp] = useState(getValues("jobLocation"));
  const {hasMoreLocation,loadOptionsLocation,setHasMoreLocation,setOffsetLocation,optionsLocation,searchValueLocation} = useScrollLocations();

  const moveToNextStep = async () => {
    const isValid = await trigger([
      "jobTitle",
      "companyName",
      "jobLocation",
      "joiningDate",
    ]);

    setStepValidate(!isValid);
    if (isValid) {
      setCurrentTab(GenerateOfferTabs.OFFER_BODY);
      clearErrors();
    }
  };

  const moveToPrevStep = () => {
    setCurrentTab(GenerateOfferTabs.CANDIDATE_DETAILS);
  };

   

  return (
    <div className="step">
      <div className="step__head">
        <p className="step__head__headline">02. Offer Details</p>
      </div>
      <div className="step__body">
        <div className="step__row">
          <div className="step__item">
            <p className="step__item__label">
              Job Title<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="jobTitle"
              id="jobTitle"
              placeholder="Enter job title"
              {...register("jobTitle", { required: true })}
            />
            {errors.jobTitle && (
              <p className="error-message">{errors.jobTitle.message}</p>
            )}
          </div>
          <div className="step__item">
            <p className="step__item__label">
              Company<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="companyName"
              id="company"
              placeholder="Enter company name"
              {...register("companyName", { required: true })}
            />
            {errors.companyName && (
              <p className="error-message">{errors.companyName.message}</p>
            )}
          </div>
        </div>
        <div className="step__row">
          <div className="step__item long">
            <p className="step__item__label">
              Job location<span> *</span>
            </p>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptionsLocation}
              defaultOptions={optionsLocation && optionsLocation}
							options={optionsLocation && optionsLocation}
              name="jobLocation"
              value={(locationTemp || searchValueLocation) ?? null}
              onBlur={() => {
								setOffsetLocation(30);
								loadOptionsLocation("")
							}}
              onChange={(option: any) => {          
                setLocationTemp(option);
                setValue("jobLocation", option);
              }}
              styles={selectCustomStyle}
              onMenuScrollToBottom={(e) => {
								!hasMoreLocation && setHasMoreLocation(true)
							}}
            />
            {errors.jobLocation && (
              <p className="error-message">{errors.jobLocation.message}</p>
            )}
          </div>
        </div>
        <div className="step__row">
          <div className="step__item">
            <p className="step__item__label">
              Joining Date<span> *</span>
            </p>
            <div className="step__date">
              <DatePicker
                selected={joiningDateTemp || getValues("joiningDate")}
                name="joiningDate"
                showYearDropdown={true}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={70}
                autoComplete="off"
                onChange={(date: any) => {
                  setJoiningDateTemp(date);
                  setValue("joiningDate", date);
                }}
                dateFormat="MMMM d, yyyy"
                minDate={new Date()}
                placeholderText="Joining Date"
                id={"birthFrom"}
              />
              {errors.joiningDate && (
                <p className="error-message">{errors.joiningDate.message}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="step__bottom">
        <button className="step__bottom__prev" onClick={moveToPrevStep}>
          Back
        </button>
        <button
          className="step__bottom__next button--empty"
          onClick={moveToNextStep}
        >
          Next
        </button>
        {stepValidate && (
          <p className="error-message">Please fill in the required fields</p>
        )}
      </div>
    </div>
  );
};

const { API_RECRUITMENT } = getEnv();

const callbackFunc = async (searchValue) => {
  const token: string = localStorage.getItem("token");

  const req = await fetch(
    `${API_RECRUITMENT}/api/location/city/${searchValue}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json());
  return req.map((item) => {
    return {
      value: `${item.city}, ${item.state}`,
      label: `${item.city}, ${item.state}`,
      id: item.id,
    };
  });
};


export default OfferDetailsStep;
