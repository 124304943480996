import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { store, useTypedSelector } from "../../../store";
import edit from '../../../image/icon/edit_ic.svg';
import deleteIc from '../../../image/icon/delete_ic.svg';
import add from "../../../image/icon/workflow_add.svg";
import {
	setAssignPopupWorkflow,
	setDeletePopupWorkflow,
	setEditPopupWorkflow,
	setPopupWithListWorkflow
} from "../../../store/reducers/positionWorkFlowReducer";
import { AuthGuard, ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const teamMembersFunc = state => state.position_workflow

const TBodyWorkflow = ({
	horizontalScrollState
}: { horizontalScrollState: boolean }) => {

	const table = useTypedSelector(teamMembersFunc)
	const navigate = useNavigate()
	return <tbody className="table__tbody">
		{
			table.tableItems?.map(item => {
				return <tr className="table__tr" key={item.id}>
					<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
						<p className='manage-team__text manage-team__text--black' style={{ fontWeight: 900 }}>{item.title}</p>
					</td>
					{
						table.tabFilter.map(value => {
							if (value.id === 2) {
								return value.active &&
									<td className="table__td actions manage-team__column__middle" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray7'>Contains {item.workflow.length} workflows
											<button className='manage-team__text manage-team__text--green manage-team__text__button'
												onClick={() => store.dispatch(setPopupWithListWorkflow({
													title: item.title,
													open: true,
													id: item.id,
													workflows: item.workflow
												}))
												}>
												more
											</button>
										</p>
									</td>
							}
							if (value.id === 3) {
								return value.active &&
									<td className="table__td actions manage-team__column__middle" key={value.displayName}>
										<p className='manage-team__text manage-team__text--green'>{item.createdBy}</p>
									</td>
							}
							if (value.id === 4) {
								return value.active &&
									<td className="table__td actions manage-team__column__middle" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray5'>{item.createdAt}</p>
									</td>
							}
							if (value.id === 5) {
								return value.active &&
									<td className="table__td actions manage-team__column__middle" key={value.displayName}>
										<AuthGuard module={'job-post'} permission='add'>
											<ShowToolTipComponent text='Assign workflow'>
												<img src={add} alt="resend-icon" className="manageAssignment__table__icon" onClick={() => {
													store.dispatch(setAssignPopupWorkflow(true))
												}} />
											</ShowToolTipComponent>
										</AuthGuard>
										<AuthGuard module={'job-post'} permission='edit'>
											<ShowToolTipComponent text='Edit details'>
												<img src={edit} alt="resend-icon" className="manageAssignment__table__icon" onClick={() => {
													store.dispatch(setEditPopupWorkflow({id:item.id,open:true}))
													// navigate(`/recruitment/position-workflow/${item.id}/edit`)
												}} />
											</ShowToolTipComponent>
										</AuthGuard>
										<AuthGuard module={'job-post'} permission='delete'>
											<ShowToolTipComponent text='Delete'>
												<img src={deleteIc} alt="resend-icon" className="manageAssignment__table__icon" onClick={() => store.dispatch(setDeletePopupWorkflow({
													title: item.title,
													id: item.id,
													open: true
												}))} />
											</ShowToolTipComponent>
										</AuthGuard>
									</td>
							}
						})
					}
				</tr>
			})
		}
	</tbody>
}

export default memo(TBodyWorkflow)