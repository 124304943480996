export const selectCustomBottomStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '4px',
    borderColor: '#DFE2E6',
    fontSize: '14px',
    minHeight: '45px',
    width: '100%',
    boxShadow: state.isFocused ? '#ACD8D1' : '',
    ':hover': {
      borderColor: '#ACD8D1',
    },
    '> div:first-of-type': {
      display: 'flex',
      width: 'calc(100% - 73px)',
    },
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '4px',
    background: 'rgba(2,156,165,0.1)',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#2A2C33',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    justifyContent: 'flex-end',
    width: '73px',
    '>div': {
      width: '36px',
    },
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: '#015462',
    borderRadius: 0,
    cursor: 'pointer',
    paddingRight: '2px',
    paddingLeft: '6px',
    '&:hover': {
      backgroundColor: 'unset',
      color: '#2A2C33',
    },
    '>svg': {
      width: '14px',
      minWidth: '14px',
      height: '14px',
    },
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#737980',
    cursor: 'pointer',
    '&:hover': {
      color: '#2A2C33',
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#737980',
    cursor: 'pointer',
    '&:hover': {
      color: '#099C73',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    margin: '5px 0',
    color: '#343B43',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#fff' : '#fff',
    ':focus': {
      backgroundColor: '#fff',
    },
    ':hover': {
      backgroundColor: '#fff',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#999EA5',
    fontSize: '14px',
    paddingRight: 16,
    position: 'absolute',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '3px 16px',
    fontSize: '14px',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 20,
    top: '-45px',
    transform: 'translateY(calc(-100% + 28px))',
  }),
  menuList: (provided: any) => ({
    ...provided,
    '>div': {
      ':hover': {
        backgroundColor: '#029ca51a',
      },
    },
  }),
  singleValue: (provided: any) => ({
      ...provided, maxWidth: '90%'
    }
  )
}