import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { memo } from "react";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ]
}

const RichTextEditor = ({ value, setValue, placeholder,readOnly=false }) => {

  return (
    <ReactQuill
      theme="snow"
      value={value}
      modules={modules}
      onChange={(val) => {
        setValue(val)
      }}
      placeholder={placeholder}
      bounds={'#quill-container'}
      className="react-quill"
      readOnly={readOnly}
    >
        <div id="quill-container"/>
    </ReactQuill>
  )
}

export default memo(RichTextEditor)