export enum CandidateDomainAssessmentTabs{
	Name='Candidate Name',
	Time='Time Duration',
	Score='Score',
	Date='Date And Time',
	Preview='Preview'
}

export enum CandidateDomainAssessmentFilterTabs{
	Score='Score',
}