import {components} from 'react-select'

const Option = (props: any) => {

    return (
        <div>
            <components.Option {...props} className="manage-team-popup__form__checkbox">
                <input
                    id={props.value}
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label htmlFor={props.value}><span/></label>
                <p>{props.label}</p>
            </components.Option>
        </div>
    )
}
export default Option