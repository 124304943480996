import React from "react";
import EventView from "./EventView";
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { getEnv } from "@urecruits/api";
import axios from "axios";

const dayTimeList = [
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 PM",
];

const typesOfInterview = ["HR Audio Video Interview", "Technical/Coding Assessment", "Senior HR Audio Video Interview", "Technical Audio Video Interview","Meet-up","Live Task/Coding Assessment","Take Home/Coding Assessment"]

function getDaysArray(year, month) {
  var numDaysInMonth, daysInWeek, daysIndex, index, i, l, daysArray;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  index = daysIndex[new Date(year, month - 1, 1)?.toString()?.split(" ")[0]];
  daysArray = [];

  for (i = 0, l = numDaysInMonth[month - 1]; i < l; i++) {
    daysArray?.push({
      day: i < 9 ? `0${i + 1}` : `${i + 1}`,
      type: daysInWeek[index++],
      date: new Date(year, month - 1, i + 1),
      isToday: i + 1 === new Date().getUTCDate(),
    });
    if (index == 7) index = 0;
  }

  return daysArray;
}

const CalendarDayView = () => {
  const todayDate = new Date();
  const currentMonth = todayDate.getMonth() + 1;
  const currentYear = todayDate.getFullYear();
  const [eventLists, setEventLists] = useState([]);
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [loading, setLoading] = useState(false);
  const {API_RECRUITMENT,API_ASSESSMENT} = getEnv()
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });
  const daysList = getDaysArray(
    selectedYear,
    selectedMonth.toString()
  );
  const fetchEvents = async () => {
    try {
      setLoading(true);
      const token: any = localStorage.getItem('token')
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      const result = await axios.get(
        `${API_ASSESSMENT}/api/calendar/all-event?date=${selectedDate}`,config
      );
      if (result) {
        {
          setLoading(false);
          setEventLists(result?.data?.events.filter((item) => typesOfInterview?.includes(item?.description)));
        }
      }
    } catch (error) {
      setLoading(false);
      setToastNotification({
        state: true,
        message: error?.message,
      });
    }
  };

  const handleMiniCalendarClick = async(day) => {
   if(day?.target?.value !== null && day?.target?.value !== undefined){
    const date= new Date(day.target.value)?.getDate()
    const month= new Date(day.target.value)?.getMonth()
    const year= new Date(day.target.value)?.getFullYear()
    
    if(date){
      const newDataList=await getDaysArray(
        year.toString(),
        month + 1
      );

    setSelectedYear(year);
    setSelectedMonth(month + 1);
    if(newDataList){
      const newDate=newDataList?.find((data)=>{
        return data?.day==date
      })
      if(newDate){
        setSelectedDate(newDate?.date)
      }
    } 
    }
   }
  };

  useEffect(() => {
    if (selectedDate) fetchEvents();
  }, [selectedDate]);

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <Snackbar
        open={toastNotification.state}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
      <div
        className="calendar-container active">
        <div className="week-view">
          <div className="title">
            <h3>Schedule</h3>
            <input
              type="date"
              value={`${selectedDate.getFullYear()}-${selectedDate.getMonth() < 9 ? 0 : ''}${selectedDate.getMonth()+1}-${selectedDate.getDate() < 10 ? 0 : ''}${selectedDate.getDate()}`}
              onInputCapture={handleMiniCalendarClick}
              min="2000-01-01" 
              className="date-input"
            />
          </div>
          <div className="days-list">
            {daysList?.map((day) => (
              <div
                onClick={() => setSelectedDate(day?.date)}
                className={`day ${
                  (day?.day ===
                    (selectedDate.getDate() < 10
                      ? `0${selectedDate.getDate()}`
                      : `${selectedDate.getDate()}`) ||
                    (day?.isToday &&
                      day?.day ===
                        (selectedDate.getDate() < 10
                          ? `0${selectedDate.getDate()}`
                          : `${selectedDate.getDate()}`))) &&
                  "active"
                } `}
                key={day?.day}>
                {`${day?.day} ${day?.type}`}
              </div>
            ))}
          </div>
        </div>
        <div className="day-view">
          <div className="day-time-list">
            {dayTimeList.map((dayTime) => {
              const isEventExist:any = eventLists.filter(
                (event:any) => event?.startTime === dayTime
              );

              return (
                <div className="list" key={dayTime}>
                  <div className="day-time" key={dayTime} >
                    {dayTime}
                  </div>

                  {isEventExist.length > 0 && !loading && (
                    <div className="right">
                      <EventView
                        startTime={isEventExist[0]?.startTime}
                        description={isEventExist[0]?.description}
                        endTime={isEventExist[0]?.endTime}
                        title={isEventExist[0]?.title}
                        jobId={isEventExist[0]?.jobId}
                        roomId={isEventExist[0]?.roomId}
                        candidateId={isEventExist[0]?.candidateId ||isEventExist[0]?.id}
                        disableEvent={todayDate>=selectedDate}
                      />
                    </div>
                  )}
                  {loading && (
                    <div style={{ width: "70%" }}>
                      <Skeleton animation="wave" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default CalendarDayView;

