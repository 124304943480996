import {memo, useState} from "react";
import {default as ReactSelect} from "react-select";
import MenuList from "../../PositionWorkflow/SingleSelectOptionWorkflows";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import {store, useTypedSelector} from "../../../store";
import {setChangeWorkflowPopupJobs} from "../../../store/reducers/jobsTableReducer";
import { Provider } from "react-redux";
import {store as store1} from "../../../../recruitments/store/index";
import PWPopup from "../../../../recruitments/components/PositionWorkflow/Popup/PWPopup"
import { setTitle } from "../../../store/reducers/positionWorkflowPopup";
const getTableWorkflowsFunc=state=>state.jobs.workflowList
const ReactSelectWorkflowsJobTable = ({item}) => {
	const list=useTypedSelector(getTableWorkflowsFunc)
	const [openWorkflow,setOpenWorkflow]=useState(false)

	const handleDataFromChild = (data) => {
		setOpenWorkflow(data)
		store1.dispatch(setTitle(item?.jobTitle))
	  };
	return (
	<>
	{openWorkflow && (
        <Provider store={store1}>
          <PWPopup
            titleTop={"Add New Workflow"}
            returnDefault={() => {}}
		      	fromJob={handleDataFromChild}
          />
        </Provider>
      )}
	<ReactSelect
		options={list}
		components={{
			MenuList: (props) => (
			  <MenuList
				{...props}
				sendDataToParent={handleDataFromChild}
			  />
			),
		  }}
		value={item.positionWorkflow}
		onChange={(option: any) => {
			store.dispatch(setChangeWorkflowPopupJobs({open: true, title: option.label, id: option.value, jobId: item.id}))
		}}
		hideSelectedOptions={false}
		closeMenuOnSelect={false}
		placeholder=''
		styles={selectCustomStyle}
		id="locationSelect"
		instanceId="locationSelect"
	/>
	</>)
}

export default memo(ReactSelectWorkflowsJobTable)





