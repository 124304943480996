import { memo } from 'react'

const ActionPopupScoreboard = ({type,onSubmit,onCancel}) => {
	const titleName = type === 'reject' ? "Reject" : type === 'restart'&&'Restart'

	return (
		<div className="popup apply-popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
				{titleName} Candidate
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="popup__head__close"
					onClick={onCancel}
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round" />
				</svg>
			</div>
			<div className="popup__body">
				<p className="popup__body__text">
						If you {type === 'reject' ? 'reject' : type === 'restart' && 'restart the workflow for'} candidate, all of the hiring process of this candidate will {type === 'reject' ? 'stop' : type === 'restart' && 'be continue from where it was rejected last'}.
				</p>

			</div>
			<div className="popup__bottom end">
				<button
					className="popup__bottom__cancel button--empty"
					onClick={onCancel}
				>
					Cancel
				</button>
				<button
					className="popup__bottom__button button--filled"
					onClick={onSubmit}
				>
						{titleName}
				</button>
			</div>
		</div>
	</div>
		
	)
}

export default memo(ActionPopupScoreboard)