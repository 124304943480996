import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnswerSheet } from "../../types/redux/answer-sheet";

const initialStateAnswerSheet: IAnswerSheet = {
  jobId: null,
  assessmentId: null,
  candidateAnswer: null,
  totalScore:0,
  duration:"",
  totalQuestion:null,
  selectedQuestion:1,
  testData:null,
  isStartTest:false,
  allQuestionScore:0,
  applyPopup: false,
  successApplyPopup: false,
  modal: null,
  modalClose:false,
  isSingleView:false,
  popupWithInstruction:{
	open:false,
	id:-1,
	instruction:'',
},
}

export const answerSheetReducer = createSlice({
	name: 'answer_sheet',
	initialState: initialStateAnswerSheet,
	reducers: {
		setJobId: (state, action: PayloadAction<number>) => {
			state.jobId = action.payload
		},
		setAssessmentId: (state, action: PayloadAction<number>) => {
			state.assessmentId = action.payload
		},
		setTotalScore: (state, action: PayloadAction<any>) => {
			state.totalScore = action.payload
		},
		setDuration: (state, action: PayloadAction<any>) => {
			state.duration = action.payload
		},
		setTestData: (state, action: PayloadAction<any>) => {
			state.testData = action.payload
		},
		setTotalQuestions: (state, action: PayloadAction<any>) => {
			state.totalQuestion = action.payload
		},
		setSelectedQuestion: (state, action: PayloadAction<any>) => {			
			state.selectedQuestion = action.payload
		},
		setIsStartTest: (state, action: PayloadAction<boolean>) => {
			state.isStartTest = action.payload
		},
		setAllQuestionScore: (state, action: PayloadAction<any>) => {
			state.allQuestionScore = action.payload
		},
		changeSuccessSubmitTest: (state, action: PayloadAction<boolean>) => {
			state.successApplyPopup = action.payload;
		},
		changeSubmitTest: (state, action: PayloadAction<any>) => {
			state.applyPopup = action.payload;
		},
		setModal: (state, action: PayloadAction<any>) => {
			state.modal = action.payload;
		},
		setModalClose: (state, action: PayloadAction<any>) => {
			state.modalClose= action.payload;
		},
		setIsSingleView: (state, action: PayloadAction<boolean>) => {
			state.isSingleView= action.payload;
		},
		setCandidateAnswer: (state, action: PayloadAction<any>) => {
			state.candidateAnswer= action.payload;
		},
		setPopupWithInstruction: (state, action: PayloadAction<{open: boolean, id: number, instruction:any}>) => {
		state.popupWithInstruction = action.payload
		},
		resetAnswerSheet: (state) => {
			state.selectedQuestion = 1;
			state.totalQuestion = null;
			state.candidateAnswer = null;
			state.popupWithInstruction = {
			  open: false,
			  id: -1,
			  instruction: '',
			};
			state.isSingleView = false;
		  },
	},
})


export const {
	setCandidateAnswer,
	setAssessmentId,
	setDuration,
	setJobId,
	setTotalScore,
	setTestData,
	setSelectedQuestion,
	setTotalQuestions,
	setIsStartTest,
	changeSuccessSubmitTest,
	changeSubmitTest,
	setModal,
	setModalClose,
	setPopupWithInstruction,
	setIsSingleView,
	resetAnswerSheet
} = answerSheetReducer.actions

export default answerSheetReducer.reducer