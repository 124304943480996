import {CSSProperties, memo, useEffect, useState} from "react";

const Progress = ({
	                  array,
	                  currentScreen,
	                  progressWidthArr,
                  }: { array: Array<string>, currentScreen: string, progressWidthArr: Array<{ id: number, value: string }> }) => {
	const [activeId, setActiveId] = useState(1);

	useEffect(() => {
		setActiveId((array.findIndex((x) => x === currentScreen) + 1));
	}, [currentScreen]);

	return (
		<div className="progress">
			<div className={`progress__inner`} style={{
				"--offset": progressWidthArr.find(x => x.id === activeId)?.value || "0%",
			} as CSSProperties}>
				{
					array?.map((item, index) => {
						return <div
							className={`progress__item ${activeId - 1 > index ? "prev" : ""} ${activeId - 1 === index ? "active" : ""}`}
							key={index}
							style={array.length === 3 ? {
								width: "100%",
							} : {}}
						>
							<div className="progress__dot"></div>
							<div className="progress__name">{item}</div>
						</div>;
					})
				}
			</div>
		</div>
	);
};

export default memo(Progress);