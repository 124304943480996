import { memo, useState } from "react";
import SingleItem from "./SingleMobileItem";
import { IMobileTableItem } from "../../../types/table-types";
import ItemNotAvailable from "../ItemNotAvailable";

const MobileTableItem = ({ columns, row, disableRow, actions }: IMobileTableItem) => {
	const [openItem, setOpenItem] = useState(false);
	const titleColumn = columns.find(column => !!column.isMobileTitle);
	let classEvenOdd = 'odd';

	return (
		<div className="table-mobile__item">
			<div className={`table-mobile__top ${openItem ? "active" : ""}`} onClick={() => {
				setOpenItem(prev => !prev);
			}}>
				<div className="table-mobile__top__left">
					<p className="table-mobile__top__name">{titleColumn.mobileRenderCell ? titleColumn.mobileRenderCell(row[titleColumn.field], row) : row[titleColumn.field]}</p>
				</div>
				<ArrowSvg />
			</div>
			<div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
				<div className="table-mobile__item__body__data">
					{disableRow && disableRow.getDisableStatus(row) ?
						<ItemNotAvailable text={disableRow.text} />

						: ''}
					{
						columns.map(column => {
							if (column.field != titleColumn.field && column.field != 'actions') {
								const columnContent = column.mobileRenderCell ? column.mobileRenderCell(row[`${column.field}`], row) : (row[`${column.field}`])
								return (<SingleItem title={column.headerName + ":"} content={columnContent || ''}
									textStyle={"table-mobile__item__text"} key={column.field} />)
							}
						})
					}
				</div>
				<div className="table-mobile__item__body__action">
					{
						actions ? actions.map(action => {
							const actionObj = action.getAction ? action.getAction(row) : action;
							if (!actionObj) return;
							const { svg, title, icon, onClick: onActionClick, className } = actionObj

							classEvenOdd = classEvenOdd === 'odd' ? 'even' : 'odd'
							return (<button
								className={`table-mobile__item__action-button table-mobile__item__action-button--half ${classEvenOdd} ${className}`}
								onClick={(event) => {
									onActionClick(row, event)
								}}
								key={title}
							>
								{svg ?? <img src={icon} alt={title} />}
								{title}
							</button>)
						}) : ''
					}
				</div>

			</div>
		</div>
	);
};
export default memo(MobileTableItem);

const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)" />
			</clipPath>
		</defs>
	</svg>;
};