import {ManageTeamMembersTableTabsEnums} from "../../../../enums/manage-team-members/ManageTeamMembersEnums";
import {store, useTypedSelector} from "../../../../store";
import {
	setBirthSelectedMTM, setDateJoinSelectedMTM,
	setDepartmentFilterMTM,
	setEducationalSelectedMTM, setLocationFilterMTM, setPositionFilterMTM,
	setRolesSelectedMTM
} from "../../../../store/reducers/manageTeamMembersReducer";
import DatePicker from 'react-datepicker'
import {educationOption} from "../../../../utils/constants";
import AsyncSelect from "react-select/async";
import Option from "../../../Global/SelectOptions";
import {selectSearchFuncDepartment, selectSearchFuncHRPosition, selectSearchFuncLocation, selectSearchFuncLocationLimit} from "@urecruits/api";
import {selectCustomStyle} from "../../../../styles/selectCustomStyle";
import {memo} from "react";
import { FilterWithLimitComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const getFiltersFunc=state => state.team_members.filters
const getFilterDataFunc=state => state.team_members.filterInfo
const FilterItemsMTM=({activeTab})=>{
	const filter = useTypedSelector(getFiltersFunc)
	const filterData = useTypedSelector(getFilterDataFunc)
	return <>
		{
			activeTab === ManageTeamMembersTableTabsEnums.ROLE &&

      <div className='table-filter-popup__right__content'>
				{
					<ul className='filters-popup-mtm-buttons-list'>
						{
							filterData.rolesList.map(item => {
								return <li
									className={`${filter.roles.find(x => x.auth0_id === item.auth0_id) ? 'filters-popup-mtm-buttons-list__item active' : 'filters-popup-mtm-buttons-list__item'}`}
									key={item.auth0_id}
									onClick={() => store.dispatch(setRolesSelectedMTM(filter.roles.find(x => x.auth0_id === item.auth0_id) ? filter.roles.filter(x => x.auth0_id !== item.auth0_id) : [...filter.roles, item]))}
								>
									{item.role}
								</li>
							})
						}
					</ul>
				}
      </div>
		}
		{
			activeTab === ManageTeamMembersTableTabsEnums.EDUCATION &&

      <div className='table-filter-popup__right__content'>
				{
					<ul className='filters-popup-mtm-buttons-list'>
						{
							educationOption.map(item => {
								return <li
									className={`${filter.education.find(x => x === item.value) ? 'filters-popup-mtm-buttons-list__item active' : 'filters-popup-mtm-buttons-list__item'}`}
									key={item.value}
									onClick={() => store.dispatch(setEducationalSelectedMTM(filter.education.find(x => x === item.value) ? filter.education.filter(x => x !== item.value) : [...filter.education, item.value]))}
								>
									{item.value}
								</li>
							})
						}
					</ul>
				}
      </div>

		}
		{
			activeTab === ManageTeamMembersTableTabsEnums.DEPARTMENT &&
      <div className='table-filter-popup__right__content'>
          <AsyncSelect
              components={{
								Option,
							}}
              isMulti
              cacheOptions
              loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncDepartment(inputValue) : selectSearchFuncDepartment('')
							}
              hideSelectedOptions={false}
              defaultOptions
              value={filter.department}
              onChange={(option: any) => {
								store.dispatch(setDepartmentFilterMTM(option))
							}}
              closeMenuOnSelect={false}
              placeholder='Search by department'
              styles={selectCustomStyle}
              id="department"
              instanceId="department"
          />
      </div>
		}
		{
			activeTab === ManageTeamMembersTableTabsEnums.JOB_TITLE &&
      <div className='table-filter-popup__right__content'>
          <AsyncSelect
              components={{
								Option,
							}}
              isMulti
              cacheOptions
              loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncHRPosition(inputValue) : selectSearchFuncHRPosition('')
							}
              hideSelectedOptions={false}
              defaultOptions
              value={filter.position}
              onChange={(option: any) => {
								store.dispatch(setPositionFilterMTM(option))
							}}
              closeMenuOnSelect={false}
              placeholder="Select HR Position"
              styles={selectCustomStyle}
              id="positionSelect"
              instanceId="positionSelect"
          />
      </div>
		}
		{
			activeTab === ManageTeamMembersTableTabsEnums.JOB_LOCATION &&
      <div className='table-filter-popup__right__content'>
        <FilterWithLimitComponent filter={{value:filter.location,onChangeValue:(option:any)=>{store.dispatch(setLocationFilterMTM(option))}}} placeHolder="Select Location" setLimitSearchfunction={selectSearchFuncLocationLimit}/>
      </div>
		}
		{
			activeTab === ManageTeamMembersTableTabsEnums.DATE_OF_BIRTH &&
      <div className='table-filter-popup__right__content'>
          <div className='filters-popup-mtm-datepicker'>
              <div className='filters-popup-mtm-datepicker__item'>
                  <label className="filters-popup-mtm-datepicker__label">From</label>
                  <div className='filters-popup-mtm-datepicker__date'>
                      <DatePicker
                          selected={filter.dateOfBirth.from}
                          showYearDropdown={true}
                          scrollableYearDropdown={true}
                          yearDropdownItemNumber={70}
                          onChange={(date: any) => {
														store.dispatch(setBirthSelectedMTM({from: date, to: filter.dateOfBirth.to}))
													}}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          placeholderText="From"
                          id={'birthFrom'}
                      />
                  </div>
              </div>
              <div className='filters-popup-mtm-datepicker__item'>
                  <label className="filters-popup-mtm-datepicker__label">To</label>
                  <div
                      className={`${filter.dateOfBirth.from ? 'filters-popup-mtm-datepicker__date' : 'filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly'}`}>
                      <DatePicker
                          selected={filter.dateOfBirth.to}
                          showYearDropdown={true}
                          scrollableYearDropdown={true}
                          yearDropdownItemNumber={70}
                          onChange={(date: any) => {
														store.dispatch(setBirthSelectedMTM({to: date, from: filter.dateOfBirth.from}))
													}}
                          readOnly={!filter.dateOfBirth.from}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          minDate={filter.dateOfBirth.from}
                          placeholderText="To"
                      />
                  </div>
              </div>
          </div>
      </div>
		}
		{
			activeTab === ManageTeamMembersTableTabsEnums.DATE_OF_JOINING &&
      <div className='table-filter-popup__right__content'>
          <div className='filters-popup-mtm-datepicker'>
              <div className='filters-popup-mtm-datepicker__item'>
                  <label className="filters-popup-mtm-datepicker__label">From</label>
                  <div className='filters-popup-mtm-datepicker__date'>
                      <DatePicker
                          selected={filter.dateOfJoining.from}
                          showYearDropdown={true}
                          scrollableYearDropdown={true}
                          yearDropdownItemNumber={70}
                          onChange={(date: any) => {
														store.dispatch(setDateJoinSelectedMTM({from: date, to: filter.dateOfJoining.to}))
													}}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          placeholderText="From"
                          id={'birthFrom'}
                      />
                  </div>
              </div>
              <div className='filters-popup-mtm-datepicker__item'>
                  <label className="filters-popup-mtm-datepicker__label">To</label>
                  <div
                      className={`${filter.dateOfJoining.from ? 'filters-popup-mtm-datepicker__date' : 'filters-popup-mtm-datepicker__date filters-popup-mtm-datepicker__date--readonly'}`}>
                      <DatePicker
                          selected={filter.dateOfJoining.to}
                          showYearDropdown={true}
                          scrollableYearDropdown={true}
                          yearDropdownItemNumber={70}
                          onChange={(date: any) => {
														store.dispatch(setDateJoinSelectedMTM({from: filter.dateOfJoining.from, to: date}))
													}}
                          readOnly={!filter.dateOfJoining.from}
                          dateFormat="MMMM d, yyyy"
                          maxDate={new Date()}
                          minDate={filter.dateOfJoining.from}
                          placeholderText="To"
                      />
                  </div>
              </div>
          </div>
      </div>
		}</>
}

export default memo(FilterItemsMTM)