import React from "react";
import axios from 'axios';
import { getEnv } from "@urecruits/api";
import { OfferKeys,OfferLetterStatus } from "../../enums/usersOffers/UsersOffersEnums";
import {setUsersOffersHandler} from "../../screen/UsersOffersScreen";
import { useTypedSelector } from "../../store";

const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
};
const getTableOffersUsers = (state) => state.offers_users;
export const OfferApproved = ({setModal,offerId}) =>{

    const table = useTypedSelector(getTableOffersUsers);
    
    const handleApproveOffer = ()=>{
        const updateLetterStatus = async () =>{
            await axios.patch(`${API_RECRUITMENT}/api/offer/${offerId}`, 
            {[OfferKeys.LETTERSTATUS]:OfferLetterStatus.APPROVED},
            config);
        }
        updateLetterStatus().finally(()=>{
            setModal(false);
            setUsersOffersHandler(
                table.pagination.currentPage,
                table.pagination.limit,
                table.filters
              );
        });   
    }

    return(
        <>
        <div className="modal__body__offer-approved">
           <p className="modal__body__text">Are your sure, want to approve this offer </p>
        </div>
        
        <div className="modal__actions">
           <button className="modal__actions__cancel__text" onClick={()=>setModal(false)}>Cancel</button> 
           <button className="modal__actions__cancel__outlined" onClick={()=>{setModal("offer-rejected")}}>Reject</button>
           <button className="modal__actions__send" onClick={handleApproveOffer}>Approve</button> 
        </div>
        </>
   
    );
}