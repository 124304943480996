import { useTypedSelector } from "../../../../../store";
import { useDispatch } from "react-redux";
import { deleteData, patchData } from "../../../../../hook/http";
import { getEnv } from "@urecruits/api";
import { setPopupWithInstruction } from "../../../../../store/reducers/AnswerSheetReducer";
import PopupWithFeedback from "./feedback";
import { ShowDomainAnswersComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";


const { API_ASSESSMENT } = getEnv();
const getTableJobs = (store) => store.answer_sheet;

export const Question = ({ isDomainCompleted }) => {
  const {
    testData,
    candidateAnswer,
    popupWithInstruction,
  } = useTypedSelector(getTableJobs);

  const dispatch = useDispatch();
  const {checkUserPermission} = useHasPermission()

  const handleDelete = (id) => {
    deleteData(`${API_ASSESSMENT}/api/domain-result/${id}`, {})
      .then((res) => {
        res?.status && dispatch(setPopupWithInstruction({ open: false, id: -1, instruction: null }))
      })
  }

  const handleScore = (id, score) => {
    patchData(`${API_ASSESSMENT}/api/domain-result/${id}`, { score })
      .then((res) => {
        res?.status && dispatch(setPopupWithInstruction({ open: false, id: -1, instruction: null }))
      })
  }

  return (
    <>
      {popupWithInstruction?.open && <PopupWithFeedback />}
      {testData && testData.questions && candidateAnswer && candidateAnswer.candidateAnswers &&
        <ShowDomainAnswersComponent
          questions={testData.questions}
          candidateAnswers={candidateAnswer.candidateAnswers}
          {...( !isDomainCompleted && checkUserPermission('assessment','edit') ? {
            feedback: {
              setFeedbackPopup: (candidateAnswerId, instruction) => {
                dispatch(
                  setPopupWithInstruction({
                    open: true,
                    id: candidateAnswerId,
                    instruction: instruction || null,
                  })
                );
              },
              onDeleteFeedback: (candidateAnswerId) => {
                handleDelete(candidateAnswerId)
              }
            },
            editScore:{
              setScore: (candidateAnswerId, score) => {
                handleScore(candidateAnswerId, score)
              }
            }
          }          
          : '')}
        />
      }
    </>
  );
};
