import { memo, useEffect, useLayoutEffect, useState } from "react";
import defaultAvatar from '../image/icon/avatar.svg';
import DetailsRow from "../components/CandidateProfile/DetailsRow";
import phoneIC from '../image/icon/phone_candidate_profile_ic.svg';
import rejectIcon from '../image/icon/reject_job_ic.svg';
import resetIcon from '../image/icon/reset_ic.svg';
import emailIC from '../image/icon/email_candidate_profile_ic.svg';
import emailICAction from '../image/icon/email_candidate.svg';
import video from '../image/icon/video_candidate_profile.svg';
import cw from '../image/icon/cw_ic.svg';
import downloadcw from '../image/icon/download_ic.svg';
import fetchData, { patchData, postData } from "../hook/http";
import { getEnv } from "@urecruits/api";
import { useNavigate, useParams } from "react-router-dom";
import { store, useTypedSelector } from "../store";
import { ICandidateWorkflow } from "../types/redux/candidate-screen";
import {
	CandidateScoreboardEnumsButtons,
} from "../enums/manage-candidate/ManageCandidateEnums";
import {
	initialStateCandidateScoreboardScreen,
	setDataCandidateScoreboardScreen,
	setDownloadedCandidateScoreboardScreen, setScheduleMettingPopupScoreboard,
	setUpdateRoundPopupCandidateScoreboard,
	setWorkflowsScreenScoreboardProfile
} from "../store/reducers/candidateScoreboardScreenReducer";
import UpdatePopupCandidateScoreboard from "../components/CandidateScoreboard/UpdatePopupCandidateScoreboard";
import CalendarPopup from "../components/CalendarPopup/CalendarPopup";
import ScheduleMeeting from "../components/CandidateScoreboard/ScheduleMeeting";
import { RoundStatuses } from "../enums/detail-view-job/detail-view-job-matched";
import { RoundType } from "../enums";
import ActionPopupScoreboard from '../components/CandidateScoreboard/ActionPopupScoreboard';
import { AuthGuard, ShowToolTipComponent, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT } = getEnv();

const { API_RECRUITMENT } = getEnv()
const getDataFunc = state => state.candidate_scoreboard.info
const getCalendarFunc = state => state.candidate_scoreboard.calendarPopup
const getDataFuncExtra = state => state.candidate_scoreboard.workflows
const getDataFuncDownload = state => state.candidate_scoreboard.downloaded
const getUpdateRoundPopup = state => state.candidate_scoreboard.updateRoundPopup
const getSchedulePopupPopup = state => state.candidate_scoreboard.schedulePopup
const ManageCandidateProfileScreen = () => {

	const { checkUserPermission } = useHasPermission()
	const [display, setDisplay] = useState(CandidateScoreboardEnumsButtons.Candidate);
	const [roundId, setRoundId] = useState(-1);
	const [scoreboardId, setScoreboardId] = useState(-1);
	const [candidateId, setCandidateId] = useState(-1);
	const params = useParams()
	const { name } = useTypedSelector(getDataFunc)
	const updateRoundPopup = useTypedSelector(getUpdateRoundPopup)
	const { open } = useTypedSelector(getCalendarFunc)
	const schedulePopup = useTypedSelector(getSchedulePopupPopup)

	useLayoutEffect(() => {
		const getData = async () => {
			checkUserPermission('job-post', ["view", "edit"]) && fetchData(`${API_RECRUITMENT}/api/round/get-one/${params.id}/${params.jobid}`).then(res => {
				store.dispatch(setDataCandidateScoreboardScreen(transformData(res.round)))
				store.dispatch(setWorkflowsScreenScoreboardProfile(transformWorkflows(res.round?.rounds, res.round?.job?.workflow?.workflow, params.id, params.jobid, res?.round.id)))
				store.dispatch(setDownloadedCandidateScoreboardScreen(true))
				setRoundId(res.round?.id)
				setScoreboardId(res.subscribe?.id)
			})

		}
		try {
			getData()
		} catch (error) {
			console.log(error)
		}
		return () => {
			store.dispatch(setDataCandidateScoreboardScreen(initialStateCandidateScoreboardScreen))
			store.dispatch(setDownloadedCandidateScoreboardScreen(false))
			store.dispatch(setWorkflowsScreenScoreboardProfile([]))
		}
	}, [params.id]);


	return <AuthGuard module='job-post' permission='view'>
		<section>
			{
				schedulePopup && <ScheduleMeeting meetingData={{ candidateId: params.id, jobId: params.jobid, roundName: "Meet-up" }} setIsScheduleMeeting={(status) => store.dispatch(setScheduleMettingPopupScoreboard(status))} postApi={`${API_RECRUITMENT}/api/calendar/meet-up/book-appointment`} />
			}
			{open && <CalendarPopup />}
			{
				updateRoundPopup.open && <UpdatePopupCandidateScoreboard />
			}
			<div className='table-screen-top'>
				<div className='table-screen-top__wrapper' style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
					<h2 style={{ marginBottom: '20px' }}>Candidate Scoreboard</h2>
					<div className='candidate-profile-view__buttons-top'>
						<button
							className={`candidate-profile-view__buttons-top__item ${display === CandidateScoreboardEnumsButtons.Candidate ? 'active' : ''}`}
							onClick={() => setDisplay(CandidateScoreboardEnumsButtons.Candidate)}>{CandidateScoreboardEnumsButtons.Candidate}</button>
						<button
							className={`candidate-profile-view__buttons-top__item ${display === CandidateScoreboardEnumsButtons.Rounds ? 'active' : ''}`}
							onClick={() => setDisplay(CandidateScoreboardEnumsButtons.Rounds)}>{CandidateScoreboardEnumsButtons.Rounds}</button>
						<button
							className={`candidate-profile-view__buttons-top__item ${display === CandidateScoreboardEnumsButtons.Extra ? 'active' : ''}`}
							onClick={() => setDisplay(CandidateScoreboardEnumsButtons.Extra)}>{CandidateScoreboardEnumsButtons.Extra}</button>
					</div>
				</div>
			</div>
			<div className='candidate-profile-view'>
				{
					name && <div
						className={`candidate-profile-view__content ${display === CandidateScoreboardEnumsButtons.Extra ? 'disabled' : ""}`}>
						<Information display={display} roundId={roundId} />
						<DetailsRounds display={display} roundId={roundId} scoreboardId={scoreboardId} />
					</div>
				}
				<Workflows display={display} />
			</div>
		</section>
	</AuthGuard>
}

export default memo(ManageCandidateProfileScreen)

const Information = memo(({ display, roundId }: { display: string, roundId: number }) => {
	const {
		name,
		avatar,
		degree,
		experienceYears,
		location,
		email,
		phone,
		skills,
		position
	} = useTypedSelector(getDataFunc)
	const workflow = useTypedSelector(getDataFuncExtra)
	const params = useParams()
	const [rejectCandidate, setRejectCandidate] = useState(false)
	const [restart, setRestartCandidate] = useState(false)
	const isRejected = workflow.findLastIndex(round => round.status === RoundStatuses.Rejected) > workflow.findLastIndex(round => round.status === RoundStatuses.Restarted)
	const isWorkflowCompleted = workflow.findLastIndex(round => round.status === RoundStatuses.Completed) === workflow.length - 1;

	const handleRejectCandidate = () => {
		if (rejectCandidate) {
			const rounds = workflow.map(round => {
				return round.status === RoundStatuses.Progress ? { ...round, status: RoundStatuses.Rejected } : round
			})

			const workflowData = {
				id: roundId,
				jobId: params.jobid,
				userId: params.id,
				rounds
			}
			if (roundId && !isRejected) {
				patchData(`${API_RECRUITMENT}/api/round`, workflowData).then(data => {
					setRejectCandidate(false)
				})
			}
		}
	}

	const handleRestartWorkflow = () => {
		if (!!params.jobid && !!params.id){
			patchData(`${API_RECRUITMENT}/api/subscribe/restart/workflow`, {
				jobId: +params.jobid,
				userId: +params.id
			}).then(data => { setRestartCandidate(false) })
		}

	}
	const navigate = useNavigate()

	return <>
		{(rejectCandidate || restart) && <ActionPopupScoreboard type={rejectCandidate ? 'reject' : 'restart'} onCancel={() => { rejectCandidate ? setRejectCandidate(false) : setRestartCandidate(false) }} onSubmit={rejectCandidate ? handleRejectCandidate : handleRestartWorkflow} />}

		<div
			className={`candidate-profile-view__content__item candidate-profile-view__info ${display === CandidateScoreboardEnumsButtons.Candidate ? 'active' : ''}`}>
			<div className='candidate-profile-view__info__top'>
				<h5 className='candidate-profile-view__info__title'>{name}</h5>
				<AuthGuard module='job-post' permission='edit'>
					{!isWorkflowCompleted && <div className="candidate-profile-view__info__buttons">
						<button className='candidate-profile-view__info__button' onClick={() => setRejectCandidate(true)} disabled={isRejected}>
							{isRejected ?
								<span className="table__status-block table__status-block--red">
									Rejected
								</span>
								: <>
									<img src={rejectIcon} alt="Reject candidate icon" />
									<span>Reject</span>
								</>
							}
						</button>
						<button className='candidate-profile-view__info__button' onClick={() => setRestartCandidate(true)}>

							{
								isRejected &&
								<span className="table__status-block table__status-block--green">
									<img src={resetIcon} alt="Restart candidate icon" />
									<span>Restart</span>
								</span>
							}
						</button>
					</div>}
				</AuthGuard>
			</div>
			<div className='candidate-info'>
				<img src={avatar || defaultAvatar} alt='avatar' className='candidate-info__avatar' />
				<div className='candidate-info__content'>
					<p className='candidate-info__content__title'>{position}</p>
					<div className='candidate-profile-view__row'>
						{
							[
								{ title: degree, span: '' },
								{ title: experienceYears, span: '' },
								{ title: location, span: '' }].map(
									(value, index) => {
										return value.title && <DetailsRow key={index} item={value} />
									}
								)
						}
					</div>
					<div className='candidate-info__contacts'>
						<p className='candidate-info__contacts__item'>
							<img src={emailIC} alt='email' className='candidate-info__contacts__image' />
							{email}
						</p>
						<p className='candidate-info__contacts__item'>
							<img src={phoneIC} alt='email' className='candidate-info__contacts__image' />
							{phone}
						</p>
					</div>
					<div className='candidate-info__actions'>
						<DownloadCV />
						<button className='candidate-info__actions__item' onClick={() => { store.dispatch(setScheduleMettingPopupScoreboard(true)) }}>
						<ShowToolTipComponent text={`Schedule a meeting with ${name}`}>
							<img src={video} alt='action' className='candidate-info__actions__image' />
						</ShowToolTipComponent>
						</button>
							<button className='candidate-info__actions__item' onClick={() => navigate(`/recruitment/mail-box?email=${encodeURIComponent(email)}`)}>
						<ShowToolTipComponent text={`Send Mail to ${name}`} position="right">
							<img src={emailICAction} alt='action' className='candidate-info__actions__image' />
						</ShowToolTipComponent>
						</button>
					</div>
				</div>
			</div>
			{
				skills?.filter(x => x.name).length > 0 && <div className='candidate-profile-view__skills'>
					{
						skills.filter(x => x.name).map((item, index) => {
							return <p key={index} className='candidate-profile-view__skills__item'>
								{item.name}
							</p>
						}
						)
					}
				</div>
			}
		</div>
	</>
})
const Workflows = memo(({ display }: { display: string }) => {
	const workflows = useTypedSelector(getDataFuncExtra)
	const loaded = useTypedSelector(getDataFuncDownload)
	return loaded && workflows.length > 0 && <div
		className={`candidate-profile-view__content__item candidate-profile-view__extra ${display === CandidateScoreboardEnumsButtons.Extra ? 'active' : ''}`}>
		<div className='candidate-profile-view__extra__list'>
			<div className='candidate-profile-view__workflow-rounds__top'>
				<h5 className='candidate-profile-view__workflow-rounds__title'>{`${CandidateScoreboardEnumsButtons.Extra} ${workflows.filter(x => x.status === RoundStatuses.Completed).length} / ${workflows.length}`}</h5>
				<div className='candidate-profile-view__workflow-rounds__top-progress'><span
					style={{ maxWidth: 66 * workflows.filter(x => x.status === RoundStatuses.Completed).length / workflows.length + 'px' }} /></div>
			</div>
			{
				workflows.map((item, index) => {
					if (item.status === RoundStatuses.Restarted) {
						return <div className='candidate-profile-view__workflow-rounds-item' key={index}>
							<div className={`candidate-profile-view__workflow-rounds-item__status`}>
								<AcceptedSvg />
							</div>
							<div className='candidate-profile-view__workflow-rounds-item__list'>
								<p className='candidate-profile-view__workflow-rounds-item__title'>Restarted workflow</p>
							</div>
						</div>
					}
					return <div className='candidate-profile-view__workflow-rounds-item' key={index}>
						<div className={`candidate-profile-view__workflow-rounds-item__status ${item.status !== RoundStatuses.Completed ? 'white' : ''}`}>
							{
								item.status === RoundStatuses.Completed ? <AcceptedSvg /> : <p>{index + 1}</p>
							}
						</div>
						<div className='candidate-profile-view__workflow-rounds-item__list'>
							<p className='candidate-profile-view__workflow-rounds-item__title'>{item.status === RoundStatuses.Rejected ? <del>{item.title}</del> : item.title}</p>
							{
								item.date?.start && <p className='candidate-profile-view__workflow-rounds-item__time'><ClockSvg />{new Date(item.date?.start).toLocaleDateString('en', {
									month: 'short', day: 'numeric', year: "numeric", hour: 'numeric', minute: 'numeric'
								})}</p>
							}
						</div>
					</div>
				})
			}
		</div>
	</div>
})

const DetailsRounds = memo(({ display, roundId, scoreboardId }: { display: string, roundId: number, scoreboardId: number }) => {
	const workflows = useTypedSelector(getDataFuncExtra)

	const currentStep = workflows?.find(x => x.status === RoundStatuses.Pending || x.status === RoundStatuses.Progress)

	return workflows.length > 0 && <div
		className={`candidate-profile-view__content__item candidate-profile-view__details ${display === CandidateScoreboardEnumsButtons.Rounds ? 'active' : ''}`}>
		<h5 className='candidate-profile-view__details__title'
			style={{ marginBottom: 0 }}>{CandidateScoreboardEnumsButtons.Rounds}</h5>
		{
			workflows?.map((item, index) => {
				if (item.status === RoundStatuses.Restarted) {
					return <div className="candidate-profile-view__details-rounds" key={index}>
						<div className="candidate-profile-view__details-rounds__top">
							Restarted the workflow
						</div>
					</div>
				}
				return <DetailsRoundsItem key={index} item={item} current={currentStep?.title === item.title} roundId={roundId} scoreboardId={scoreboardId} />
			})
		}
	</div>
})

const DetailsRoundsItem = memo(({ item, current, roundId, scoreboardId }: { item: ICandidateWorkflow, current: any, roundId: number, scoreboardId: number }) => {
	const workflows = useTypedSelector(getDataFuncExtra)

	const [open, setOpen] = useState(item.status === RoundStatuses.Completed || item.status === RoundStatuses.Rejected);
	return <div className='candidate-profile-view__details-rounds'>
		<div className={`candidate-profile-view__details-rounds__top ${open ? 'open' : ''}`} onClick={() => {
			setOpen(prev => !prev)
		}}>
			<p className='candidate-profile-view__details-rounds__title'>{item.title}</p>
			<Arrow />
		</div>
		{
			!open && <>
				<div className='candidate-profile-view__details-rounds__list'>
					<div className='candidate-profile-view__details-rounds__row'>
						<p className='candidate-profile-view__details-rounds__text-gray'>Status</p>
						<p className='candidate-profile-view__details-rounds__text'>{item.status}</p>
						{

							(item.status === RoundStatuses.Progress) && current &&
							<AuthGuard module='job-post' permission='edit'>
								<RoundAction item={item} roundId={roundId} scoreboardId={scoreboardId} />
							</AuthGuard>
						}
					</div>
					{
						item.date?.start && <div className='candidate-profile-view__details-rounds__row'>
							<p className='candidate-profile-view__details-rounds__text-gray'>Date</p>
							<p className='candidate-profile-view__details-rounds__text'>
								{item.date?.start ? new Date(item.date?.start)?.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', minute: 'numeric', year: 'numeric', hour: 'numeric' }) : ''} - {item.date?.end ? new Date(item.date?.end)?.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', minute: 'numeric', year: 'numeric', hour: 'numeric' }) : ''}
							</p>
						</div>
					}
					{
						item.score && <div className='candidate-profile-view__details-rounds__row'>
							<p className='candidate-profile-view__details-rounds__text-gray'>Score</p>
							<p className='candidate-profile-view__details-rounds__text'>{item.score}</p>
						</div>
					}
					{
						item.interviewers?.length > 0 && <div className='candidate-profile-view__details-rounds__row'>
							<p className='candidate-profile-view__details-rounds__text-gray'>Interviewer(s)</p>
							<div className='candidate-profile-view__details-rounds__interviewers-row'>
								{
									item.interviewers.map((item, index) => {
										return <p className='candidate-profile-view__details-rounds__interviewers'
											key={index}>{item.label || item.name || ""}</p>
									})
								}
							</div>
						</div>
					}
				</div>
				{
					item.comments && <div className='candidate-profile-view__details-rounds__comment'>
						<h6 className='candidate-profile-view__details-rounds__comment-title'>Interviewer Comments</h6>
						<p className='candidate-profile-view__details-rounds__text-gray'>{item.comments}</p>
					</div>
				}
			</>
		}

	</div>
})

const DownloadCV = memo(() => {
	const { cvKey, cvName } = useTypedSelector(getDataFunc)
	const [link, setLink] = useState('');
	const onDownloadHandler = async () => {
		await postData(`${API_RECRUITMENT}/api/file/get-private`, { key: cvKey }).then(res => setLink(res))
	}
	const showCV = () => {
		window.open(link, '_blank')
	}
	return cvKey && <button onClick={link === '' ? onDownloadHandler : showCV} className='candidate-info__actions__item'>
		<img src={link === '' ? downloadcw : cw} alt='action' className='candidate-info__actions__image' />
		<p style={{ minWidth: '110px' }}>{link === '' ? 'Download CV' : 'Look at CV'}</p>
	</button>
})
const transformData = (data: any) => {
	return {
		name: `${data.user.firstname} ${data.user.middlename || ''} ${data.user.lastname}`,
		avatar: data.user.avatar,
		degree: data.user.candidate.degree,
		experienceYears: `${data.user.candidate.experience} years`,
		location: data.user.candidate.location?.city ? `${data.user.candidate.location?.city}, ${data.user.candidate.location?.state}` : '',
		email: data.user.email,
		phone: data.user.phone,
		skills: data.user.candidate.skills,
		position: data.user.candidate.position?.label,
		cvKey: data.user.candidate.cvKey,
		cvName: data.user.candidate.cvName
	}
}

const transformWorkflows = (rounds: Array<ICandidateWorkflow>, workflows: any, userId: string, jobId: string, id): Array<ICandidateWorkflow> => {
	if (rounds) return rounds
	const workflowData = {
		jobId,
		userId,
		id,
		rounds: workflows.map(item => {
			return {
				title: item.name,
				status: RoundStatuses.Pending,
				date: { start: null, end: null },
				interviewers: [],
				score: null,
				comments: ''
			}
		})
	}
	return workflowData.rounds
}

const AcceptedSvg = () => {
	return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2 9.3L4.76923 12L14 3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
}
const ClockSvg = () => {
	return <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.0013 13.3327C10.223 13.3327 12.8346 10.721 12.8346 7.49935C12.8346 4.27769 10.223 1.66602 7.0013 1.66602C3.77964 1.66602 1.16797 4.27769 1.16797 7.49935C1.16797 10.721 3.77964 13.3327 7.0013 13.3327Z"
			stroke="#343B43" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M7 4V7.5L9.33333 8.66667" stroke="#343B43" stroke-width="1.2" stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>

}
const Arrow = () => {
	return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.99869 4.375H2.98613C2.20659 4.375 1.81619 5.3175 2.36741 5.86872L6.37998 9.88128C6.72168 10.223 7.2757 10.223 7.61741 9.88128L11.63 5.86872C12.1812 5.3175 11.7908 4.375 11.0113 4.375H6.99869Z"
			fill="#999EA5" />
	</svg>

}

const RoundAction = ({ item, roundId, scoreboardId }: { item: ICandidateWorkflow, roundId: number, scoreboardId: number }) => {
	const navigate = useNavigate();
	const { jobid, id } = useParams();
	const [domainResultId, setDomainResultId] = useState();

	useEffect(() => {
		const getDomainResultId = async () => {
			try {
				const response = await fetchData(`${API_ASSESSMENT}/api/assignment/job/${jobid}`);

				if (!response?.domainId) return null;
				const domainResultData = await fetchData(`${API_ASSESSMENT}/api/domain-result/candidate/${jobid}/${response.domainId}/${id}`);

				if (!domainResultData?.id) return null;
				setDomainResultId(domainResultData?.id);
			}
			catch (error) {
				console.log(error);
			}

		}
		if (item.title === RoundType.FUNCTIONAL_DOMAIN_ASSESSMENT)
			getDomainResultId();

	}, [])
	let clickListner=()=>{}, buttonText='';


	if (item.title === RoundType.FUNCTIONAL_DOMAIN_ASSESSMENT) {
		const { name } = useTypedSelector(getDataFunc);
		if (domainResultId) {
			clickListner = () => navigate(`/recruitment/answer-sheet/${domainResultId}/${name}`);
			buttonText = "Review & Score";
		}
	}
	else {
		clickListner = () => store.dispatch(setUpdateRoundPopupCandidateScoreboard({
			currentWorkflow: item,
			open: true,
			id: roundId,
			scoreboardId: scoreboardId
		}));
		buttonText = 'Update'
	}
	return (
		<>
			<button className='candidate-profile-view__details-rounds__update-btn'
				disabled={item.title === RoundType.FUNCTIONAL_DOMAIN_ASSESSMENT && !Boolean(domainResultId)}
				onClick={clickListner}
			>{buttonText}
			</button>
		</>
	);
}
