import React,{useState} from "react";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { store } from "../../store";
import { OfferKeys, OfferLetterStatus } from "../../enums/usersOffers/UsersOffersEnums";
import {handleSendRequest} from "../../screen/OfferPreviewScreen";
const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
};

export const SaveTemplate =({setModal,offerId})=>{
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    const handleSendForApproval = async () =>{
        setModal("sendForApproval")
    }
    return(
        <>
        <div className="modal__body__signature">
          <p className="modal__body__text">Would you like to send offer to candidate or send it for approval first?</p>
        </div>
        <div className="modal__actions">
        <button className="modal__actions__cancel__text" onClick={()=>setModal(false)}>Cancel</button>
              <button className="modal__actions__approval " onClick={handleSendForApproval}>
                <SendForApprovalIcon/>
                <p className="modal__actions__approval__text" >Send For Approval</p>
              </button>
              <button className="modal__actions__send" onClick={()=>handleSendRequest(offerId, setModal)}>Save & Send</button>
        </div>
    
        </> 
    );
}

const SendForApprovalIcon = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2668_39857)">
<path d="M18.3327 1.6665L9.16602 10.8332" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3327 1.6665L12.4993 18.3332L9.16602 10.8332L1.66602 7.49984L18.3327 1.6665Z" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2668_39857">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>);


