import {ChangeEvent, memo, useEffect, useState} from "react";
import searchIcon from "../../images/icons/search_ic.svg";
import "./_search.scss";
import {ISearch} from "../../types/table-types";

const SearchField: ({searchValue, setSearchValue, placeholder}: ISearch) => JSX.Element = ({searchValue, setSearchValue, placeholder}) => {
	const [search, setSearch] = useState(searchValue);

	const onFormSubmit = (e) => {
		e.preventDefault()
		setSearchValue(search)
	}
	useEffect(() => {
		setSearch(searchValue)

	}, [searchValue]);
	return (
		<form className="table__search" onSubmit={onFormSubmit}>
			<input
				type="text"
				className="table__search__input"
				placeholder={placeholder}
				value={search}
				onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)} />
			<button className="table__search__button">
				<img src={searchIcon} alt="search icon" className="table__search__icon"/>
			</button>
		</form>
	)
}

export default memo(SearchField)