export const deadlineList:any = [
	{
		label: '12 Hrs',
		value: 12
	},
	{
		label: '24 Hrs',
		value: 24
	}, {
		label: '36 Hrs',
		value: 36
	},
	{
		label: '48 Hrs',
		value: 48
	},
	{
		label: '60 Hrs',
		value: 60
	},
	{
		label: '72 Hrs',
		value: 72
	}
]

export const durationTime=[
	{
		label: '1 Hrs',
		value: 1
	},
	{
		label: '2 Hrs',
		value: 2
	}, {
		label: '3 Hrs',
		value: 3
	},
	{
		label: '4 Hrs',
		value: 4
	},
	{
		label: '5 Hrs',
		value: 5
	},
	{
		label: '6 Hrs',
		value: 6
	},
]

export const daysOptions = [
	{
		value: 'Select all days',
		label: 'Select all days'
	},
	{
		value: 'Monday',
		label: 'Monday'
	},
	{
		value: 'Tuesday',
		label: 'Tuesday'
	},
	{
		value: 'Wednesday',
		label: 'Wednesday'
	},
	{
		value: 'Thursday',
		label: 'Thursday'
	},
	{
		value: 'Friday',
		label: 'Friday'
	},
	{
		value: 'Saturday',
		label: 'Saturday'
	},
	{
		value: 'Sunday',
		label: 'Sunday'
	},
]

export const timeOptions = [
	{
		label: '12:00 am',
		value: '12:00 am'
	},
	{
		label: '01:00 am',
		value: '01:00 am'
	},
	{
		label: '02:00 am',
		value: '02:00 am'
	},
	{
		label: '03:00 am',
		value: '03:00 am'
	},
	{
		label: '04:00 am',
		value: '04:00 am'
	},
	{
		label: '05:00 am',
		value: '05:00 am'
	},
	{
		label: '06:00 am',
		value: '06:00 am'
	},
	{
		label: '07:00 am',
		value: '07:00 am'
	},
	{
		label: '08:00 am',
		value: '08:00 am'
	},
	{
		label: '09:00 am',
		value: '09:00 am'
	},
	{
		label: '10:00 am',
		value: '10:00 am'
	},
	{
		label: '11:00 am',
		value: '11:00 am'
	},
	{
		label: '12:00 pm',
		value: '12:00 pm'
	},
	{
		label: '01:00 pm',
		value: '01:00 pm'
	},
	{
		label: '02:00 pm',
		value: '02:00 pm'
	},
	{
		label: '03:00 pm',
		value: '03:00 pm'
	},
	{
		label: '04:00 pm',
		value: '04:00 pm'
	},
	{
		label: '05:00 pm',
		value: '05:00 pm'
	},
	{
		label: '06:00 pm',
		value: '06:00 pm'
	},
	{
		label: '07:00 pm',
		value: '07:00 pm'
	},
	{
		label: '08:00 pm',
		value: '08:00 pm'
	},
	{
		label: '09:00 pm',
		value: '09:00 pm'
	},
	{
		label: '10:00 pm',
		value: '10:00 pm'
	},
	{
		label: '11:00 pm',
		value: '11:00 pm'
	},
]

export const educationOption = [
	{
		value: 'High School',
		label: 'High School'
	},
	{
		value: 'Associate degree',
		label: 'Associate degree'
	},
	{
		value: 'Bachelor\'s degree',
		label: 'Bachelor\'s degree'
	},
	{
		value: 'Master\'s degree',
		label: 'Master\'s degree'
	},
	{
		value: 'Doctorate degree',
		label: 'Doctorate degree'
	},
	{
		value: 'Professional degree',
		label: 'Professional degree'
	},
]

export const timeDurationList = [
	{ value: "15 min", label: "15 min", },
	{ value: "30 min", label: "30 min", },
	{ value: "1 Hr", label: "1 Hr", },
	{ value: "1 Hr 30 min", label: "1 Hr 30 min", },
	{ value: "2 Hrs 30 min", label: "2 Hrs 30 min", },
	{ value: "3 Hrs", label: "3 Hrs", },
	{ value: "3 Hrs 30 min", label: "3 Hrs 30 min", },
	{ value: "4 Hrs", label: "4 Hrs", },
	{ value: "4 Hrs 30 min", label: "4 Hrs 30 min", },
	{ value: "5 Hrs", label: "5 Hrs", },
  ];


  export const AssessmentType=[
	{value:"domain", label: "Domain Assessment"},
	{value:"coding", label: "Coding Assessment"},
  ]
  

  export const LanguagesSet = 
[
  {
      "id": 45,
      "name": "Assembly (NASM 2.14.02)"
  },
  {
      "id": 46,
      "name": "Bash (5.0.0)"
  },
  {
      "id": 47,
      "name": "Basic (FBC 1.07.1)"
  },
  {
      "id": 75,
      "name": "C (Clang 7.0.1)"
  },
  {
      "id": 76,
      "name": "C++ (Clang 7.0.1)"
  },
  {
      "id": 48,
      "name": "C (GCC 7.4.0)"
  },
  {
      "id": 52,
      "name": "C++ (GCC 7.4.0)"
  },
  {
      "id": 49,
      "name": "C (GCC 8.3.0)"
  },
  {
      "id": 53,
      "name": "C++ (GCC 8.3.0)"
  },
  {
      "id": 50,
      "name": "C (GCC 9.2.0)"
  },
  {
      "id": 54,
      "name": "C++ (GCC 9.2.0)"
  },
  {
      "id": 86,
      "name": "Clojure (1.10.1)"
  },
  {
      "id": 51,
      "name": "C# (Mono 6.6.0.161)"
  },
  {
      "id": 77,
      "name": "COBOL (GnuCOBOL 2.2)"
  },
  {
      "id": 55,
      "name": "Common Lisp (SBCL 2.0.0)"
  },
  {
      "id": 90,
      "name": "Dart (2.19.2)"
  },
  {
      "id": 56,
      "name": "D (DMD 2.089.1)"
  },
  {
      "id": 57,
      "name": "Elixir (1.9.4)"
  },
  {
      "id": 58,
      "name": "Erlang (OTP 22.2)"
  },
  {
      "id": 44,
      "name": "Executable"
  },
  {
      "id": 87,
      "name": "F# (.NET Core SDK 3.1.202)"
  },
  {
      "id": 59,
      "name": "Fortran (GFortran 9.2.0)"
  },
  {
      "id": 60,
      "name": "Go (1.13.5)"
  },
  {
      "id": 95,
      "name": "Go (1.18.5)"
  },
  {
      "id": 88,
      "name": "Groovy (3.0.3)"
  },
  {
      "id": 61,
      "name": "Haskell (GHC 8.8.1)"
  },
  {
      "id": 91,
      "name": "Java (JDK 17.0.6)"
  },
  {
      "id": 62,
      "name": "Java (OpenJDK 13.0.1)"
  },
  {
      "id": 63,
      "name": "JavaScript (Node.js 12.14.0)"
  },
  {
      "id": 93,
      "name": "JavaScript (Node.js 18.15.0)"
  },
  {
      "id": 78,
      "name": "Kotlin (1.3.70)"
  },
  {
      "id": 64,
      "name": "Lua (5.3.5)"
  },
  {
      "id": 89,
      "name": "Multi-file program"
  },
  {
      "id": 79,
      "name": "Objective-C (Clang 7.0.1)"
  },
  {
      "id": 65,
      "name": "OCaml (4.09.0)"
  },
  {
      "id": 66,
      "name": "Octave (5.1.0)"
  },
  {
      "id": 67,
      "name": "Pascal (FPC 3.0.4)"
  },
  {
      "id": 85,
      "name": "Perl (5.28.1)"
  },
  {
      "id": 68,
      "name": "PHP (7.4.1)"
  },
  {
      "id": 43,
      "name": "Plain Text"
  },
  {
      "id": 69,
      "name": "Prolog (GNU Prolog 1.4.5)"
  },
  {
      "id": 70,
      "name": "Python (2.7.17)"
  },
  {
      "id": 92,
      "name": "Python (3.11.2)"
  },
  {
      "id": 71,
      "name": "Python (3.8.1)"
  },
  {
      "id": 80,
      "name": "R (4.0.0)"
  },
  {
      "id": 72,
      "name": "Ruby (2.7.0)"
  },
  {
      "id": 73,
      "name": "Rust (1.40.0)"
  },
  {
      "id": 81,
      "name": "Scala (2.13.2)"
  },
  {
      "id": 82,
      "name": "SQL (SQLite 3.27.2)"
  },
  {
      "id": 83,
      "name": "Swift (5.2.3)"
  },
  {
      "id": 74,
      "name": "TypeScript (3.7.4)"
  },
  {
      "id": 94,
      "name": "TypeScript (5.0.3)"
  },
  {
      "id": 84,
      "name": "Visual Basic.Net (vbnc 0.0.0.5943)"
  }
]