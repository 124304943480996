export enum BackgroundCandidatesTableHeader{
  CANDIDATE_NAME = "Canidate Name",
  CANDIDATE_PHONE_NUMBER = "Candidate Phone Number",
  THIRD_PARTY_VENDOR = "3rd Party Vendor",
  DATE_BGV_COMPLETED = "Date BGV Completed",
  RECRUITER_COMMENTS = "Recruiter Comments",
  CANDIDATE_SCORE_BOARD = "Candidate Score Board",
  CANDIDATE_BGV_STATUS = "Candidate BGV Status",
  ACTIONS = "Actions"
}
export enum BackgroundCandidatesFilterTabs{
  DATE_RANGE_OF_BGV_COMPLETED = "Date Range of BGV Completed",
  CANDIDATE_BGV_STATUS = "Candidate BGV Status",
}

export enum BGVStatus{
  NOT_INITIATED = "Not Initiated",
  IN_PROGRESS = "In Progress",
  REPORT_ON_HOLD = "Report On Hold",
  READY_FOR_REVIEW = "Ready For Review",
  VERIFIED = "Verified",
  REJECTED = "Rejected",
  REVIEWED = "Reviewed",
}


