import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SingleItem from "../../Global/table/SingleMobileItem";
import { store } from "../../../store";
import { WorkflowTabs } from "../../../enums/workflow/WorkFlowEnums";
import editIcon from "../../../image/icon/edit_ic.svg";
import deleteIcon from "../../../image/icon/delete_ic.svg";
import {
	setAssignPopupWorkflow,
	setDeletePopupWorkflow,
	setEditPopupWorkflow,
	setPopupWithListWorkflow
} from "../../../store/reducers/positionWorkFlowReducer";
import add from "../../../image/icon/workflow_add.svg";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const MobileTableWorkflows = ({item}) => {

	const [openItem, setOpenItem] = useState(false);
	const navigate = useNavigate()
	return <div className='table-mobile__item'>
		<div className={`table-mobile__top ${openItem ? 'active' : ''}`} onClick={() => {
			setOpenItem(prev => !prev)
		}}>
			<div className='table-mobile__top__left'>
				<p className='table-mobile__top__name'>{item.title}</p>
			</div>
			<ArrowSvg/>
		</div>
		<div className={`table-mobile__item__body ${openItem ? 'open' : ''}`}>
			{
				<div className='table-mobile__item__wrap'>
					<p className='table-mobile__item__title'>{WorkflowTabs.Workflow}:</p>
					<p className={'table-mobile__item__text'}>Contains {item.workflow.length} workflows
							<button className='manage-team__text manage-team__text--green manage-team__text__button'
											onClick={() => store.dispatch(setPopupWithListWorkflow({
												title: item.title,
												open: true,
												id: item.id,
												workflows: item.workflow
											}))}>
								more
							</button>
					</p>
				</div>
			}
			<SingleItem title={WorkflowTabs.CreatedBy + ':'} text={item.createdBy}
			            textStyle={'table-mobile__item__text table-mobile__item__text--green'}/>
			<SingleItem title={WorkflowTabs.CreatedOn + ':'} text={item.createdAt} textStyle={'table-mobile__item__text'}/>
			{
				<div className="table-mobile__item__body__action">
					<AuthGuard module={'job-post'} permission='add'>
							<button className='table-mobile__item__action-button table-mobile__item__action-button--green'
											onClick={() => {
												store.dispatch(setAssignPopupWorkflow(true))
											}}>
									<img src={add} alt="delete-icon"/>
									Assign workflow
							</button>
						</AuthGuard>
						<AuthGuard module={'job-post'} permission='edit'>
							<button
									className={ 'table-mobile__item__action-button table-mobile__item__action-button--half' }
									onClick={() => {
										store.dispatch(setEditPopupWorkflow({ id: item.id, open: true }))
									}}
							>
									<img src={editIcon} alt="delete-icon"/>
									Edit
							</button>
						</AuthGuard>
						<AuthGuard module={'job-post'} permission='delete'>
							<button className='table-mobile__item__action-button table-mobile__item__action-button--half'
											onClick={() => store.dispatch(setDeletePopupWorkflow({
												title: item.title,
												id: item.id,
												open: true
											}))}
							>
									<img src={deleteIcon} alt="delete-icon"/>
									Delete
							</button>
						</AuthGuard>
        </div>
			}
		</div>
	</div>
}
export default memo(MobileTableWorkflows)

const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
			</clipPath>
		</defs>
	</svg>
}