import { store, useTypedSelector } from "../../store";
import { memo } from "react";
//TODO do not delete

import defaultAvatar from "../../image/icon/avatar.svg";
import eye from "../../image/icon/eye-on.svg";
import resend from '../../image/icon/resent invite_ic.svg';
import fileIcon from '../../image/icon/file_ic.svg';

import { useNavigate } from "react-router-dom";
import { AuthGuard, ShowToolTipComponent, SubscriptionGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { setResendPopupManageCandidate, setShowAssignPopupManageCandidate } from "../../store/reducers/manageCandidate";


const tableItemsFunc = state => state.manage_candidate.tableItems
const tableTabsFunc = state => state.manage_candidate.tabFilter
const isAppliedFun = state => state.manage_candidate.isApplied


const ManageCandidatesTBody = ({ horizontalScrollState }: { horizontalScrollState: boolean }) => {

	const tableItems = useTypedSelector(tableItemsFunc)
	const tableTabs = useTypedSelector(tableTabsFunc)
	const isApplied = useTypedSelector(isAppliedFun)
	const navigate = useNavigate()

	return <>
		<tbody className="table__tbody">
			{
				tableItems.filter(i => i !== undefined).map(item => {
					return <tr className="table__tr" key={item.id}>
						<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__title`} onClick={() => navigate(`/recruitment/candidate-profile/${item.userId}`)}>
							<div className='manage-team__name'>
								<img src={item.avatar || defaultAvatar} alt='avatar' className='manage-team__name__avatar' />
								<p className='manage-team__name__text manage-team__text--link'>{`${item.firstname} ${item.middlename || ''} ${item.lastname}`}</p>
							</div>
						</td>
						{
							tableTabs.filter(i => i.active).map(value => {
								if (value.id === 2 && isApplied) {
									return item.job &&
										<td className={`table__td actions manage-team__column__title`} key={value.displayName}>
											<div className='manage-team__name'>
												<p className='manage-team__name__text'>{`${item.job?.title || ''} `}</p>
											</div>
										</td>
								}
								if (value.id === 3) {
									return <td className="table__td actions manage-team__column__location" key={value.displayName}>
											<p className='manage-team__text--gray7 manage-team__text'>{item.location}</p>
									</td>
								}
								if (value.id === 4) {
									return <td className="table__td actions manage-team__column__experience" key={value.displayName}>
											{
												(item.experience || item.experience === 0) && <p className='manage-team__text--gray7 manage-team__text '>{item.experience} years</p>
											}
									</td>
								}
								if (value.id === 5) {
									return <td className="table__td actions manage-team__column__salary" key={value.displayName}>
											{
												item.salary && item.salary !== 0 ? <p className='manage-team__text--gray7 manage-team__text '>${item.salary}</p> : <p className='manage-team__text--gray7 manage-team__text '>1000000-1000000</p>
											}
									</td>
								}
								if (value.id === 6) {
									return <td className="table__td actions manage-team__column__contact" key={value.displayName}>
											<div>
												<p className='manage-team__text--green manage-team__text'>{item.phone}</p>
												<p className='manage-team__text--green manage-team__text'>{item.email}</p>
											</div>
									</td>
								}
								if (value.id === 8) {
									return <td className="table__td actions manage-team__column__status" key={value.displayName}>
										<div className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button ' key={value.displayName}>
											<p className={`table__status-block--${item?.status === 'Active' ? 'green' : 'yellow'}`}>{item.status}</p>
										</div>
									</td>
								}
								
								if (value.id === 9) {
									return <td className="table__td actions manage-team__column__status" key={value.displayName}>
										<div className='manage-team__text manage-team__text--black' key={value.displayName}>
											{item.assignTask?.name || "-"}
										</div>
									</td>
								}

								if (value.id === 11) {
									return <td className="table__td actions manage-team__column__status" key={value.displayName}>
										<ShowToolTipComponent text='View Profile'>
											<img src={eye} alt="delete-icon" className="manageAssignment__table__icon"
												onClick={(e) => {
													const url = item.job && isApplied ? `/recruitment/candidate-profile/${item.userId}/${item.job.id}` : `/recruitment/candidate-profile/${item.userId}`
													navigate(url)
												}}
											/>
										</ShowToolTipComponent>
										{ <ShowToolTipComponent text='Resend activation link'>
											<img src={resend} alt="resend-icon" className="manageAssignment__table__icon" onClick={() => {
												store.dispatch(setResendPopupManageCandidate({ id: item.id, open: true }))
											}} />
										</ShowToolTipComponent>}
											<SubscriptionGuard planPackageType={'assessment'}>
										{  <AuthGuard module={"recruiter"}>
												<ShowToolTipComponent text={`${item.hasActiveAssignment ? "You can only assign one assignment at a time." : 'Assign Assignment'}`}>
												<img
													src={fileIcon}
													alt="Assign-assignment-icon"
													className="manageAssignment__table__icon"
													style={{ cursor: item.hasActiveAssignment ? 'not-allowed' : 'pointer' }}
													onClick={() => {
														!item.hasActiveAssignment && store.dispatch(setShowAssignPopupManageCandidate({ userName: item.firstname + " " + item.lastname, open: true, userId: item.userId }))
													}} />
											</ShowToolTipComponent>
											</AuthGuard>}
											</SubscriptionGuard>
									</td>
								}
							})
						}
					</tr>
				})
			}
		</tbody>
	</>
}
export default memo(ManageCandidatesTBody)

