const SmallLoader = () => {
	return (
		<div className="small-loader">
			<div className="loadingio-spinner-reload-vsgu923dyfd">
				<div className="ldio-kxw7jsh2agf">
					<div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SmallLoader