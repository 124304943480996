import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICandidatesDrugTable } from "../../types/redux/drug-screening";
import { IAsyncCheckBoxList } from "../../types/global/global";
import { IColReorder } from "@urecruits/recruitments/types/global/global";
import { DrugCandidatesFilterTabs } from "../../enums";

export const defaultFilters = {
  searchValue: "",
  sortBy: "id",
  sortType: "DESC",
  vendor: "",
  date: {
    from: null,
    to: null,
  },
  status: [],
};

const initialState: ICandidatesDrugTable = {
  candidates: [],
  colReorder: [],
  filters: defaultFilters,
  pagination: {
    currentPage: 1,
    limit: 10,
    totalCount: 0,
  },
  filterInfo: {
    tabs: [
      DrugCandidatesFilterTabs.DATE_RANGE_OF_DS_COMPLETED,
      DrugCandidatesFilterTabs.CANDIDATE_DS_STATUS,
    ],
  },
  modal : null,
  modalData : null
};

const drugCandidatesSlice = createSlice({
  name: "drugCandidates",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.pagination.currentPage = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.pagination.totalCount = action.payload;
    },
    setSortTypeFilter: (state, action: PayloadAction<string>) => {
      state.filters.sortType = action.payload;
    },
    setSortByFilter: (state, action: PayloadAction<string>) => {
      state.filters.sortBy = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.filters.searchValue = action.payload;
    },
    setVendorFilter: (state, action: PayloadAction<string>) => {
      state.filters.vendor = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<Array<string>>) => {
      state.filters.status = action.payload;
    },
    setOrderColumns: (state, action: PayloadAction<Array<IColReorder>>) => {
      state.colReorder = action.payload;
    },
    setDateRangeFilter: (
      state,
      action: PayloadAction<{ from: string | null; to: string | null }>
    ) => {
      state.filters.date = action.payload;
    },
    setModal:(state, action:PayloadAction<boolean | string | null>)=>{
      state.modal = action.payload;
    },
    setModalData:(state, action:PayloadAction<any>)=>{
      state.modalData = action.payload;
   }
  },
});

export const {
  setCurrentPage,
  setDateRangeFilter,
  setLimit,
  setOrderColumns,
  setSearchValue,
  setSortByFilter,
  setSortTypeFilter,
  setStatusFilter,
  setTotalCount,
  setVendorFilter,
  setModal,
  setModalData
} = drugCandidatesSlice.actions;

export default drugCandidatesSlice.reducer;
