import { memo, useState } from 'react';
import { store, useTypedSelector } from "../../../../store";
import { setResendPopupMTM } from "../../../../store/reducers/manageTeamMembersReducer";
import { getEnv } from "@urecruits/api";
import { patchData } from "../../../../hook/http";
import { setUsersHandlerMTM } from "../../../../screen/ManageTeamMembers";
import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
const gettableInfo=state => state.team_members
const ResendPopup = () => {
	const [errorNotification, setErrorNotification] = useState('')
	const {API_RECRUITMENT} = getEnv()

	const table = useTypedSelector(gettableInfo)
	const onSubmit = () => {
		patchData(`${API_RECRUITMENT}/api/company/send-verification`, {
			recruiterId: table.resendMail.id,
		}).then((res) => {
				if(res==='') {
					setErrorNotification('Something went wrong, try again later')
					return
				}
				store.dispatch(setResendPopupMTM({open: false, id: -1}))
				setUsersHandlerMTM(1,table.pagination.limit,table.filters)
			}, error => {
			setErrorNotification('Something went wrong, try again later')
			}
		)
	}

	return (
		<AuthGuard module='team-members' permission='edit'>
		<div className="popup">

			<div className="popup__step">
				<div className="popup__head">
					<p className="popup__head__headline">
						Resend Email
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						onClick={() => store.dispatch(setResendPopupMTM({open: false, id: -1}))}
						className="popup__head__close"
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						      strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
				<div className="popup__body">
					<p className="popup__body__text">
						Do you really want to resend email to team member?
					</p>
				</div>
				<div className="popup__bottom end">
					<button
						onClick={() => store.dispatch(setResendPopupMTM({open: false, id: -1}))}
						className="popup__bottom__cancel button--empty"
					>
						Cancel
					</button>
					<button
						className="popup__bottom__publish button--filled"
						onClick={() => onSubmit()}
					>
						Resend
					</button>
					{
						errorNotification.length > 0 && (
							<p className="error-message">{errorNotification}</p>
						)
					}
				</div>
			</div>
		</div>
		</AuthGuard>
	)
}

export default memo(ResendPopup)
