export enum JobTabs{
	JOB_TITLE='Job Title',
	EMPLOYER='Employer',
	JOB_LOCATION='Job Location',
	SALARY_RANGE='Salary Range',
	POSTED_ON='Posted On',
	POSTED_BY='Posted By',
	STATUS='Status',
	POSITION_WORKFLOW='Position workflow',
	ACTIONS='Actions',
	DATE='Date'
}

export enum JobStatus{
	PUBLISH='Published',
	Pending='Pending',
	ON_HOLD='On-Hold',
	DRAFT='Draft',
	CLOSED='Closed',
	REJECTED='Rejected'
}