import { memo, useEffect, useState } from "react";
import { store, useTypedSelector } from "../../store";
import { getEnv, selectSearchFuncLocationLimit } from "@urecruits/api";

import {
	CustomRangeComponent,
	FilterWithLimitComponent,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { educationOption } from "../../utils/constants";
import MultiSelect from "../Global/MultySelectSkills/MultiSelect";
import axios from "axios";
import { ManageCandidateFilters } from "../../enums/manage-candidate/ManageCandidateEnums";
import {
	setEducationalManageCandidate,
	setExperienceManageCandidate,
	setLocationManageCandidate,
	setSalaryYearManageCandidate, setSkillsFilterManageCandidate
} from "../../store/reducers/manageCandidate";

const getFiltersFunc = (state) => state.manage_candidate.filters
const getFiltersSkillsFunc = (state) => state.manage_candidate.filters.skills
const setSkillsDispatch = (value) => store.dispatch(setSkillsFilterManageCandidate(value))
const FilterPopupManageCandidate = ({ activeTab }) => {
	const filter = useTypedSelector(getFiltersFunc)
	const [defaultSkills, setDefaultSkills] = useState([]);
	const { API_RECRUITMENT } = getEnv()

	useEffect(() => {
		const func = async () => {
			try {
				await axios.get(`${API_RECRUITMENT}/api/skills/`).then(res => setDefaultSkills(res.data))
			} catch (e) {
				console.error('Fetch error', e)
			}
		}
		func().then()
	}, []);

	return <>
		{
			activeTab === ManageCandidateFilters.Location &&
			<div className='table-filter-popup__right__content'>
				<FilterWithLimitComponent
					isMulti
					filter={{
						onChangeValue: (option) => {
							store.dispatch(setLocationManageCandidate(option))
						},
						value: filter.location
					}}
					placeHolder="Search by job location"
					setLimitSearchfunction={selectSearchFuncLocationLimit}
				/>
				{/* <AsyncSelect
					components={{
						Option,
					}}
					cacheOptions
					loadOptions={(inputValue) =>
						inputValue.length > 0 ? selectSearchFuncLocation(inputValue) : selectSearchFuncLocation('')
					}
					defaultOptions
					value={filter.location}
					onChange={(option: any) => {
						store.dispatch(setLocationManageCandidate(option))
					}}
					hideSelectedOptions={false}
					closeMenuOnSelect={false}
					placeholder='Search by job location'
					styles={selectCustomStyle}
					id="locationSelect"
					instanceId="locationSelect"
				/> */}
			</div>
		}
		{
			activeTab === ManageCandidateFilters.Experience && <div className='table-filter-popup__right__content'>
				<label className='table__range__label'>Experience<span>(years)</span></label>
				<CustomRangeComponent rangeValue={filter.experience} max={50} min={0} setData={(state) => {
					store.dispatch(setExperienceManageCandidate(state))
				}} />
			</div>
		}
		{
			activeTab === ManageCandidateFilters.Salary && <div className='table-filter-popup__right__content'>
				<label className='table__range__label'>Salary Range<span>(per year)</span></label>
				<CustomRangeComponent rangeValue={filter.salary_year} max={1000000} min={0} dollar={true} setData={(state) => {
					store.dispatch(setSalaryYearManageCandidate(state))
				}} />
			</div>
		}
		{
			activeTab === ManageCandidateFilters.Education && <div className='table-filter-popup__right__content'>
				{
					<ul className='filters-popup-mtm-buttons-list'>
						{
							educationOption.map(item => {
								return <li
									className={`${filter.education.find(x => x === item.value) ? 'filters-popup-mtm-buttons-list__item active' : 'filters-popup-mtm-buttons-list__item'}`}
									key={item.value}
									onClick={() => store.dispatch(setEducationalManageCandidate(filter.education.find(x => x === item.value) ? filter.education.filter(x => x !== item.value) : [...filter.education, item.value]))}
								>
									{item.value}
								</li>
							})
						}
					</ul>
				}
			</div>
		}
		{
			activeTab === ManageCandidateFilters.Key_Skills && <MultiSelect defaultSkills={defaultSkills} dispatchFunc={setSkillsDispatch} getReduxDataFunc={getFiltersSkillsFunc} />
		}
	</>
}
export default memo(FilterPopupManageCandidate)
