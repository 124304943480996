export enum InterviewsJobsTableHeader{
    JOB_TITLE = "Job Title",
    OPENINGS = "Openings",
    JOB_LOCATION = "Job Location",
    PENDING_TO_SCHEDULE = "Pending To Schedule",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed",
    REJECTED = "Rejected",
    WORKFLOW_ASSIGNED  = "Workflow Assigned",
  }
  
  export enum InterviewsJobsTableFilter{
    JOB_LOCATION = "Job Location",
  }



