import { useState } from "react"
import DomainAssessmentReviewAndScoreScreen from "./DomainAssessmentReviewAndScoreScreen"
import ReviewAndScoreScreen from "./ReviewAndScoreScreen"
import { useDispatch } from "react-redux"
import { setAssessmentTypeFilterReviewAndScore } from "../store/reducers/reviewAndScoreReducer"
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle"

const ReviewScreen = ({ add, edit }) => {
	const assessment = [
		{ label: "Domain Assessment", value: "domain" },
		{ label: "Live Coding", value: "live-coding" },
		{ label: "Take Home", value: "take-home-task" }]

	const dispatch = useDispatch()
	const [type, setType] = useState({ label: "Domain Assessment", value: "domain" })
	return (
		<AuthGuard module='assessment' permission='view'>
			<div className="table-screen-top__wrapper" style={{ marginBottom: "1rem" }}>
				<h2>Review & Score</h2>
				<AuthGuard module={['assessment', 'job-post']} permission='view' option="AND">
					<div className="table-screen-top__buttons-group">
						<button className={`table-screen-top__buttons-group__item ${type?.value === "domain" ? "active" : ""}`}
							onClick={() => {
								setType({ label: "Domain Assessment", value: "domain" })
							}}>
							Domain Assessment
						</button>
						<button className={`table-screen-top__buttons-group__item ${type?.value === "live-coding" ? "active" : ""}`}
							onClick={() => {
								setType({ label: "Live Coding", value: "live-coding" },)
								dispatch(setAssessmentTypeFilterReviewAndScore("live-coding"))

							}}>
							Live Coding
						</button>
						<button className={`table-screen-top__buttons-group__item ${type?.value === "take-home-task" ? "active" : ""}`}
							onClick={() => {
								setType({ label: "Take Home", value: "take-home-task" })
								dispatch(setAssessmentTypeFilterReviewAndScore("take-home-task"))
							}}>
							Take Home
						</button>
					</div>
				</AuthGuard>
			</div>
			<AuthGuard module={['assessment', 'job-post']} permission='view' option="AND">
			<div>
				{type?.value === "domain" && <DomainAssessmentReviewAndScoreScreen />}
				{type?.value === "live-coding" && <ReviewAndScoreScreen />}
				{type?.value === "take-home-task" && <ReviewAndScoreScreen />}
			</div>
			</AuthGuard>
		</AuthGuard>
	)
}

export default ReviewScreen