import {memo} from "react";

const DetailsRow=({item})=>{
	return <p className='candidate-profile-view__row__item'>{item.title}
		{
			item.span!==''&&<span>{item.span}</span>
		}
	</p>
}

export default memo(DetailsRow)