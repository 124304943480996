import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import DNDOrderPopupItem from "./DNDOrderPopupItem";
import {memo} from "react";
import {ITabsFilter} from "../../../../types/global/global";


const DNDOrderPopupWrapper=({item,setOrderArray}:{item:ITabsFilter,setOrderArray:(value:Array<ITabsFilter>)=>void})=>{
	const {
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({id: item.id});
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	return <div ref={setNodeRef} style={style} className='order-popup-mtm__item'>
		<DNDOrderPopupItem listeners={listeners} item={item} setOrderArray={setOrderArray}/>
	</div>
}

export default memo(DNDOrderPopupWrapper)