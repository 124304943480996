import {CSSTransition} from "react-transition-group";
import {useRef, useState} from "react";
import useClickOutside from "../../../hook/useClickOutside";


const TopFilterButton = ({children}) => {
	const [filterPopup, setFilterPopup] = useState(false);
	const filterPopupRef = useRef<null | HTMLDivElement>(null);

	useClickOutside(filterPopupRef, setFilterPopup)

	return <div className={`table__top__filter ${filterPopup ? 'open' : ''}`} ref={filterPopupRef}>
		<button className='table__top__filter-wrap table__top__filter-wrap--desc'
		        onClick={() => setFilterPopup((prev) => !prev)}>
			<p>Filter by</p>
			<DesktopSvg/>
		</button>
		<button className='table__top__filter-wrap table__top__filter-wrap--mobile'
		        onClick={() => setFilterPopup((prev) => !prev)}>
			<MobileSvg/>
			<p>Filter</p>
		</button>
		{
			<CSSTransition in={filterPopup} timeout={300} classNames={'table-filter-popup'} unmountOnExit mountOnEnter>
				{children(setFilterPopup)}
			</CSSTransition>
		}
	</div>
}

export default TopFilterButton


const MobileSvg = () => {
	return <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6760_22553)">
			<path d="M3.16699 14.0007V9.33398" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
			      strokeLinejoin="round"/>
			<path d="M3.16699 6.66667V2" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8.5 14V8" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8.5 5.33333V2" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.833 13.9993V10.666" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.833 8V2" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M1.16699 9.33398H5.16699" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
			      strokeLinejoin="round"/>
			<path d="M6.5 5.33398H10.5" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M11.833 10.666H15.833" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</g>
		<defs>
			<clipPath id="clip0_6760_22553">
				<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
			</clipPath>
		</defs>
	</svg>
}
const DesktopSvg=()=>{
	return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.00016 4.375H2.9876C2.20805 4.375 1.81766 5.3175 2.36888 5.86872L6.38144 9.88128C6.72315 10.223 7.27717 10.223 7.61888 9.88128L11.6314 5.86872C12.1827 5.3175 11.7923 4.375 11.0127 4.375H7.00016Z"
			fill="#999EA5"/>
	</svg>
}