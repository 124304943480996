import { Fragment, memo, useEffect, useState } from "react";
import { store, useTypedSelector } from "../../store";

import { getEnv } from "@urecruits/api";
import { setPopupWithAssessmentType } from "../../store/reducers/manageAssignmentReducer";
import fetchData from "../../hook/http";
import { useDispatch } from "react-redux";
import {
  setOpenPreview,
  setPopupWithInstruction,
} from "../../store/reducers/domainAssessmentReducer";

import upArrow from "../../image/icon/dropdown_up.svg";
import downArrow from "../../image/icon/dropdown_down.svg";
import bookOpen from "../../image/icon/book-open.svg";
import { AuthGuard, ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_ASSESSMENT, API_RECRUITMENT } = getEnv();

const PopupWithAssessmentType = () => {
  const dispatch = useDispatch();
  const popupWithList = useTypedSelector(
    (state) => state.manage_assignment.popupWithInstruction
  );

  const [industryData, setIndustryData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [languagesData, setLanguagesData] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [openStates, setOpenStates] = useState(
    Array(popupWithList?.data?.length).fill(false)
  );
  const [dataStates, setDataStates] = useState(
    Array(popupWithList?.data?.length).fill(null)
  );

  const toggleOpen = async (index) => {
    setOpenStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  const fetchDataAndSetState = async (apiEndpoint, setDataFunction) => {
    try {
      const data = await fetchData(apiEndpoint);
      if (data) {
        setDataFunction(data);
      }
    } catch (error) {
      console.error(`Error fetching data from ${apiEndpoint}`, error);
    }
  };

  useEffect(() => {
    fetchDataAndSetState(
      `${API_RECRUITMENT}/api/industry/label/`,
      setIndustryData
    );
    fetchDataAndSetState(
      `${API_RECRUITMENT}/api/department/label/`,
      setDepartmentData
    );
    fetchDataAndSetState(`${API_ASSESSMENT}/api/languages`, setLanguagesData);
    fetchDataAndSetState(
      `${API_ASSESSMENT}/api/live-coding/packages`,
      setPackagesData
    );
  }, []);

  const handleLabel = (data, item, idKey, labelKey) => {
    const label =
      data.length > 0 && data.find((info) => info[idKey] === item)?.[labelKey];
    return label;
  };

  const handleIndustry = (item) =>
    handleLabel(industryData, item, "id", "label");
  const handleDepartment = (item) =>
    handleLabel(departmentData, item, "id", "label");
  const handleLanguage = (item) =>
    handleLabel(languagesData, item, "id", "name");
  const handlePackages = (item) =>
    handleLabel(packagesData, item, "id", "title");

  const handleData = async (index) => {
    try {
      if (dataStates[index] === null) {
        const label = popupWithList?.ids[index]?.label;
        const typeMap = {
          "Live Coding": "live-coding",
          "Take Home Task": "take-home-task",
          "Domain Assessment": "domain-questions",
        };
        const type = typeMap[label];
        let result = await fetchData(
          `${API_ASSESSMENT}/api/${type}/${popupWithList?.ids[index]?.id}`
        );
        setDataStates((prevDataStates) =>
          prevDataStates.map((data, i) => (i === index ? result : data))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openHandler = (info, item) => {
    let languages = [];
    let packages = [];
    if (info?.assessmentType === "take-home") {
      const uniqueLanguages = new Set();
      const uniquePackages = new Set();

      info?.questions?.map((res) => {
        const name =
          res?.languageId !== null && handleLanguage(res?.languageId);
        name && uniqueLanguages.add(name);

        const title = res?.packageId !== null && handlePackages(res?.packageId);
        title && uniquePackages.add(title);
      });

      languages = Array.from(uniqueLanguages);
      packages = Array.from(uniquePackages);
    }

    return (
      <>
        {item === "Domain Assessment" ? (
          <div className="assessment__content">
            <span>
              <b className={"assessment__content__title"}>{info?.name} </b>
              <b
                className={"manage-team__text--green"}
                onClick={() => {
                  dispatch(setOpenPreview({ isOpen: true, id: info?.id }));
                }}
                style={{ cursor: "pointer" }}>
                - List of question
              </b>
            </span>
            <div className={"assessment__content__item"}>
              <span>
                Industry : <b>{handleIndustry(info?.industryId)}</b>
              </span>
              <span>
                Department : <b>{handleDepartment(info?.departmentId)}</b>
              </span>
            </div>
            <div className={"assessment__content__item"}>
              <span>
                Time Duration : <b>{info?.duration}</b>
              </span>
              <span>
                Score : <b>{`>${info?.passing}`}</b>
              </span>
            </div>
            <div
              onClick={() => {
                store.dispatch(
                  setPopupWithInstruction({
                    open: true,
                    id: info?.id,
                    instruction: info?.instruction || null,
                    title: info?.name,
                  })
                );
              }}
              style={{ cursor: "pointer" }}>
              <img src={bookOpen} alt="" style={{ width: "25px" }} />
              <span className={"manage-team__text--green"}> Instruction</span>
            </div>
          </div>
        ) : (
          <div className="assessment__content">
            <b className={"assessment__content__title"}>{info?.name}</b>
            {info?.assessmentType === "live-task" ? (
              <>
                <div className={"assessment__content__item"}>
                  <span>
                    Question Type : <b>Live Task</b>
                  </span>
                  <span>
                    Language : <b>{handleLanguage(info?.languageId)}</b>
                  </span>
                </div>
                {info?.packageId && <div>
                  Packages : <b>{handlePackages(info?.packageId)}</b>
                </div>}
              </>
            ) : (
              <>
                <div>
                  Question Type : <b>Take Home</b>
                </div>
                <div>
                  Language :{" "}
                  {languages?.map((name, index) => (
                    <Fragment key={index}>
                      <b>{name}</b>
                      {index < languages.length - 1 && ", "}
                    </Fragment>
                  ))}
                </div>
                {packages?.length > 0 &&<div>
                  Packages :
                  {packages?.map((name, index) => (
                    <Fragment key={index}>
                      <b>{name === "sqlite-jdbc-3.27.2.1" ? "Sqlite" : name}</b>
                      {index < packages.length - 1 && ", "}
                    </Fragment>
                  ))}
                </div>}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <AuthGuard module='assessment' permission='view'>
    <div className="assessment">
      <div className="assessment__step">
        <div className="assessment__head">
          <div className="assessment__head__headline">
            Assessment Type -{" "}
            <span className="manage-team__text--green">
              <b>{popupWithList?.title}</b>
            </span>
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              store.dispatch(
                setPopupWithAssessmentType({
                  open: false,
                  id: -1,
                  data: null,
                  title: null,
                  ids: null,
                })
              )
            }
            className="assessment__head__close">
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="assessment_body">
          {popupWithList?.data?.map((item, index) => (
            <div key={index}>
              <div className="assessment__title">
                <h6
                  className={`${
                    openStates[index]
                      ? "manage-team__text--green"
                      : "manage-team__text--gray7"
                  }`}>
                  {item}
                </h6>
                {openStates[index] ? (
                  <ShowToolTipComponent text='Click to close details'>
                  <img
                    src={upArrow}
                    alt=""
                    style={{ width: "2rem" }}
                    onClick={() => {
                      toggleOpen(index);
                      openStates[index] === false && handleData(index);
                    }}
                  />
                  </ShowToolTipComponent>
                ) : (
                  <ShowToolTipComponent text='Click to see details'>
                  <img
                    src={downArrow}
                    alt=""
                    style={{ width: "2rem" }}
                    onClick={() => {
                      toggleOpen(index);
                      openStates[index] === false && handleData(index);
                    }}
                  />
                  </ShowToolTipComponent>
                )}
              </div>
              {openStates[index] === true
                ? dataStates[index] ? openHandler(dataStates[index], item) : <Loader/>
                : ""}
              {popupWithList?.data?.length - 1 !== index && <hr />}
            </div>
          ))}
        </div>
        <div className="assessment__bottom end"></div>
      </div>
    </div>
    </AuthGuard>
  );
};

export default memo(PopupWithAssessmentType);


const Loader = () => (
  <div className="assessment__loader">
  </div>
);