import { AuthGuard, SmallLoaderComponent, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { getEnv } from "@urecruits/api";
import axios from 'axios';
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import ActionPopupReviewScreening from "../components/BackgroundScreening/ActionPopupReviewScreening";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { BGVStatus } from "../enums";

const { API_RECRUITMENT } = getEnv()
const token = localStorage.getItem("token");
const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
};

export const ViewScreeningReport = ({ type }: { type: 'background' | 'drug' }) => {
    const { jobId, screeningId } = useParams()
    const {checkUserPermission} = useHasPermission()
    const [reviewPopup, setReviewPopup] = useState({ visible: false, statusType: '', screeningType: type })

    const [pdfContent, setPdfContent] = useState<null | string>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [toastNotification, setToastNotification] = useState({ state: false, message: "" })
    const [screeningData, setScreeningData] = useState<any>()
    const [userId,setUserId] = useState(0)

    useEffect(() => {
        const fetchScreeningData = async () => {
            try {
                setIsLoading(true);
                const data = await getScreeningData(type, screeningId);
                setScreeningData(data);
                setUserId(data.userId)
            } catch (error) {
                console.error("Error fetching screening data", error);
                setToastNotification({ state: true, message: "Failed to load screening data." });
            } finally {
                setIsLoading(false);
            }
        };

        const fetchPdfPreview = async () => {
            try {
                const pdfBlob = await fetchPdf(type, userId);
                const blob = new Blob([pdfBlob], { type: 'application/pdf' });
                setPdfContent(URL.createObjectURL(blob));
            } catch (error) {
                console.error("Error fetching PDF", error);
                setToastNotification({ state: true, message: "Failed to load PDF." });
            }
        };
            if (checkUserPermission(type, 'view')) {
                fetchScreeningData();
                if(userId){
                    fetchPdfPreview();
                }
            }

        return () => {
            if (pdfContent) {
                URL.revokeObjectURL(pdfContent);
            }
        };
    }, [screeningId, jobId, userId]);

    const handleVerifyAction = () => {
        setReviewPopup(prev => ({ ...prev, visible: true, statusType: BGVStatus.VERIFIED, screeningType: type }))
    }
    const handleRejectAction = () => {
        setReviewPopup(prev => ({ ...prev, visible: true, statusType: BGVStatus.REJECTED, screeningType: type }))
    }

    const handleScreeningStatus =({ status }) => {
        try {
            if (status && checkUserPermission(type,'edit')) {
                axios.patch(`${API_RECRUITMENT}/api/${type}/report/change-status`,
                    { status,jobId:+jobId,userId },
                    config)
                setReviewPopup(prev => ({ ...prev, visible: false }))
            }
        } catch (error) {
            setToastNotification({ state: true, message: "Error While updating the status" });
            console.log("Error While changing the status", error)
        }
    }

    const handleToastNotificationClose = () => {
        setToastNotification({
            state: false,
            message: "",
        });
    };

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleToastNotificationClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    return <>
        <Snackbar
            open={toastNotification.state}
            onClose={handleToastNotificationClose}
            message={toastNotification.message}
            action={action}
            sx={{
                "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
                    background:
                        "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
                    color: "white",
                },
            }}
        />
        <section className="view-screening-report">
            <div className="view-screening-report__head">
                <div className="view-screening-report__head__left">
                    <h2 className="view-screening-report__head__headline">
                        {type} Screening
                    </h2>
                </div>
                <div className="view-screening-report__head__right">
                    <Link to={`/recruitment/${type}-screening/candidates/${jobId}`} >
                        <p className="view-screening-report__head button">
                            {/* <CloseSVG /> */}
                            <CloseIcon />
                            <span>
                                Close
                            </span>
                        </p>
                    </Link>
                </div>
            </div>
            <div className="view-screening-report__content">
                {isLoading ? <SmallLoaderComponent />
                    : pdfContent &&
                    <embed
                        src={pdfContent}
                        type="application/pdf"
                        width="100%"
                    />
                }
            </div>
            {screeningData && screeningData[type === 'background' ? 'BGV_status':'DS_status'] === BGVStatus.REVIEWED && <AuthGuard module={type} permission={'edit'}>
                <div className="view-screening-report__footer">
                    <button className="view-screening-report__footer__action reject" onClick={handleRejectAction}>Reject</button>
                    <button className="view-screening-report__footer__action" onClick={handleVerifyAction}>Verify</button>
                </div>
            </AuthGuard>}
        </section>
        <AuthGuard module={type} permission={'edit'}>
        {screeningData && screeningData[type === 'background' ? 'BGV_status':'DS_status'] === BGVStatus.REVIEWED && reviewPopup.visible && (
            <ActionPopupReviewScreening
                type={reviewPopup.statusType}
                screeningType={reviewPopup.screeningType}
                jobId={jobId}
                screeningId={screeningId}
                onReject={() =>handleScreeningStatus({ status: BGVStatus.REJECTED })}
                onVerify={() => handleScreeningStatus({status:BGVStatus.VERIFIED})}
                onClosePopup={() => setReviewPopup(prev => ({ ...prev, visible: false }))}
            />
        )}
        </AuthGuard>
    </>
}


const fetchPdf = async (type,userId) => {
    const url = `${API_RECRUITMENT}/api/${type}/report/${userId}`;
    const response = await axios.get(url, {
        ...config,
        responseType: "blob",
    });
    return response.data;
};

const getScreeningData = async (type, screeningId) => {
    const response = await axios.get(`${API_RECRUITMENT}/api/${type}/${screeningId}`, config)
    return response.data
}

const CloseSVG = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L10 10M5 15L10 10M10 10L5 5L15 15" stroke="#737980" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

}