import { IMobileTable } from "../../../types/table-types";
import MobileTableItem from "./MobileTableItem";
import { memo } from "react";

const MobileTable = ({ columns, rowData, disableRow, actions }: IMobileTable) => {

	return (
		<div className="table-mobile">
			{
				rowData.map(row => {
					return <MobileTableItem key={row.id} columns={columns} row={row} disableRow={disableRow} actions={actions} />;
				})
			}
		</div>
	);
};
export default memo(MobileTable);