import React,{memo} from "react";
import { useTypedSelector } from "../../store";

const getInterviewType = store => store.interviews_jobs.filters.interviewType;
const Button = ({interviewType, clickListner}) =>{
    const selectedType = useTypedSelector(getInterviewType);
    return (<button className={`table-screen-top__buttons-group__item ${selectedType===interviewType ? 'active' : ''}`} onClick={()=>clickListner(interviewType)}>
    {interviewType}
  </button>);
}

export const InterviewTypeButton = memo(Button);