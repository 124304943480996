import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { store } from "../../../store";
import { setAssignPopupWorkflow } from "../../../store/reducers/positionWorkFlowReducer";
import { useNavigate } from "react-router-dom";

const PopupAssignWorkflow=()=>{
	const navigate=useNavigate()
	return		<div className="popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
					Assign position workflow
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => store.dispatch(setAssignPopupWorkflow(false))}
					className="popup__head__close"
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
					      strokeLinecap="round"
					      strokeLinejoin="round"/>
				</svg>
			</div>
			<div className="popup__body">
				<p className="popup__body__text">
					Do you want assign position workflow to existing job or to new job?
				</p>
			</div>
			<div className="popup__bottom end">
				<AuthGuard module='job-post' permission='add'>
					<button
						className="popup__bottom__cancel button--empty"
						onClick={()=>{
							store.dispatch(setAssignPopupWorkflow(false))
							navigate('/job/create')
						}}
						>
						Create New job
					</button>
				</AuthGuard>
				<AuthGuard module='job-post' permission='edit'>
					<button
						className="popup__bottom__publish button--filled"
						onClick={()=>{
							navigate('/recruitment/jobs')
							store.dispatch(setAssignPopupWorkflow(false))
						}}
					>
						Assign to existing
					</button>
				</AuthGuard>
			</div>
		</div>
	</div>
}
export default PopupAssignWorkflow