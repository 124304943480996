import axios from "axios";

const token: string = localStorage.getItem('token')
const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        
    },
}
const fetchData = async (apiString) => {
    const res = await axios.get(apiString, config)
    if (res && res.data) {
        return res.data
    }
}
export const postData=async (apiString,data)=>{
    const res = await axios.post(apiString,data, config)
    if (res && res.data) {
        return res.data
    }
}
export const deleteData=async (apiString,data)=>{
    const res = await axios.delete(apiString, {...config,data:data})

    if (res && res.data) {
        return res.data
    }
}
export const patchData=async (apiString,data)=>{
    const res = await axios.patch(apiString, data,config)
    if (res && res.data) {
        return res.data
    }
}
export default fetchData
