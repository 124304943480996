import { useRef } from "react";
import { postData } from "../../hook/http";
import { getEnv } from "@urecruits/api";
import { ScreeningType } from "../../enums";
import useSnackbar from "../../hook/useSnackbar";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } = getEnv();

export const SelectScreeningModal = ({ setModal, modalData }) => {
  const comboRef = useRef(null);
  const { APIExecutor, ErrorElement } = useSnackbar();

  const handleSendForScreening = async () => {
    let { userId, jobId, screeningType } = modalData;

    if (comboRef?.current?.checked) {
      screeningType = ScreeningType.COMBO;
    }

    const APIBody = {
      userId,
      jobId,
      screeningType,
      vendor: "Universal",
    };

    const response = await APIExecutor(() => postData(
      `${API_RECRUITMENT}/api/universal/initiate-screening`,
      APIBody
    ));

    if (response) {
      setModal("success");
    }

  };

  return (
    <>
      <ErrorElement />
      <p className="modal__body__text">
        Are you sure you want to send this candidate for screening? This action
        cannot be undone.
      </p>
      <AuthGuard module={['background', 'drug']} permission='add' option="AND">
        <div className="modal__body__option">
          <input ref={comboRef} type="checkbox" />
          <span>
            Do you also want to send this Candidate for{" "}
            {`${modalData.screeningType === ScreeningType.BACKGROUND
                ? "Drug Screening"
                : "Background Screening"
              }`}{" "}
            along with current screening?
          </span>
        </div>

        <p className="modal__body__note">
          Note: By clicking above checkbox, both screenings will be initiated
          simulteniously. No matter other screening is a part of the workflow
          assigned or not.
        </p>
      </AuthGuard>

      <div className="modal__actions">
        <button
          className="modal__actions__cancel__outlined"
          onClick={() => setModal(false)}
        >
          Cancel
        </button>
        <button
          className={`modal__actions__send`}
          onClick={handleSendForScreening}
        >
          Submit
        </button>
      </div>
    </>
  );
};
