export enum ManageTeamMembersTableTabsEnums{
	NAME='Name',
	ID='ID',
	EMAIL='Email',
	PHONE='Phone',
	JOB_TITLE='HR Position',
	DEPARTMENT='Department',
	JOB_LOCATION='Job Location',
	EDUCATION='Education',
	DATE_OF_JOINING='Date of Joining',
	DATE_OF_BIRTH='Date of Birth',
	ROLE='Role',
	ADDRESS='Address',
	STATUS='Status',
	ACTIONS='Actions',

}