import {memo} from "react";
import noRes from '../../../image/icon/table/empty-table.svg'

const TableEmpty=({handler,desc,title,cta})=>{


		return <div className='table-empty'>
			<img src={noRes} alt={'no-results'} className='table-empty__image'/>
			<h2 className='table-empty__title'>{title}</h2>
			<p className='table-empty__desc'>{desc}</p>
			{cta !== "" &&
			<button className='button--filled table-empty__button' onClick={()=>handler()}>
			{cta}
		</button>}
		</div>
}
export default memo(TableEmpty)