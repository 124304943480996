export enum DomainReviewAndScoreTabs{
	Name='Assessment Name',
	Job='Job Title',
	Industry='Industry',
	Department='Department',
	Candidates='No. of Candidate',
	AverageScore='Average Score',
}

export enum DomainAssessmentFilterTabs{
	Job='Job Title',
}