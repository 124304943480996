import {memo} from "react";
import DetailsRow from "./DetailsRow";
import {transformDate} from "../../utils/transformDate";

const DetailsBlock=({item,array})=>{
	return <div className='candidate-profile-view__details__wrap'>
		<p className='candidate-profile-view__details__name'>{item}</p>
		<div className='candidate-profile-view__row'>
		{
			array.map(
				(value,index)=>value.title&&<DetailsRow key={index} item={value}/>
			)
		}
		</div>
	</div>
}
export default memo(DetailsBlock)