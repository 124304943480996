import {memo, useEffect, useRef, useState} from "react";

const useTableClickAndDragScroll=(ref)=>{
	const isDown = useRef(false);
	const startX = useRef(0);
	const scrollLeft=useRef(0);
	const mouseDown=(e)=>{
		if(ref.current?.contains(e.target)){
			isDown.current=true
			document.getElementsByTagName('body')[0].classList.add('grabbing')
		}
		startX.current= e.pageX - ref.current?.offsetLeft
		scrollLeft.current=ref.current?.scrollLeft
	}
	const mouseLeave=()=>{
		isDown.current=false
		document.getElementsByTagName('body')[0].classList.remove('grabbing')
	}
	const mouseUp=()=>{
		isDown.current=false
		document.getElementsByTagName('body')[0].classList.remove('grabbing')
	}
	const mouseMove=(e)=>{
		if(!isDown.current) return;
		e.preventDefault();
		const x = e.pageX - ref.current?.offsetLeft;
		const walk = (x - startX.current) * 3; //scroll-fast
		ref.current.scrollLeft = scrollLeft.current - walk;
	}
	useEffect(() => {
		document.addEventListener('mousedown',mouseDown)
		document.addEventListener('mouseleave',mouseLeave)
		document.addEventListener('mouseup',mouseUp)
		document.addEventListener('mousemove',mouseMove)
		return 	()=>{
			document.removeEventListener('mousedown',mouseDown)
			document.removeEventListener('mouseleave',mouseLeave)
			document.removeEventListener('mouseup',mouseUp)
			document.removeEventListener('mousemove',mouseMove)
		}
	}, []);

}

export default useTableClickAndDragScroll