import {IJobsInterviews} from "../../types/redux/interviews";
import {InterviewsJobsTableFilter,InterviewsFilter} from "../../enums";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAsyncCheckBoxList} from "../../types/global/global";
import { IColReorder } from "@urecruits/recruitments/types/global/global";

export const defaultFilters = {
	locations: [],
  interviewType: InterviewsFilter.ALL_INTERVIEW,
	searchValue: "",
	sortBy: "id",
	sortType: "DESC",
};

const initialState: IJobsInterviews = {
  jobs: [],
  colReorder:[],
	filters: defaultFilters,
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0,
	},
  filterInfo: {
		tabs: [
			InterviewsJobsTableFilter.JOB_LOCATION,
		],
	},
};

const interviewsJobsSlice = createSlice({
    name: "interviews_jobs",
    initialState,
    reducers: {
      setCurrentPage: (state, action: PayloadAction<number>) => {
        state.pagination.currentPage = action.payload;
      },
      setLimit: (state, action: PayloadAction<number>) => {
        state.pagination.limit = action.payload;
      },
      setTotalCount: (state, action: PayloadAction<number>) => {
        state.pagination.totalCount = action.payload;
      },
      setSortTypeFilter: (state, action: PayloadAction<string>) => {
        state.filters.sortType = action.payload;
      },
      setSortByFilter: (state, action: PayloadAction<string>) => {
        state.filters.sortBy = action.payload;
      },
      setSearchValue: (state, action: PayloadAction<string>) => {
        state.filters.searchValue = action.payload;
      },
      setLocationFilter: (
        state,
        action: PayloadAction<Array<IAsyncCheckBoxList>>
      ) => {
        state.filters.locations = action.payload;
      },
      setTableItems: (state, action: PayloadAction<any>) => {
          state.jobs = action.payload;
      },
     setOrderColumns: (state, action: PayloadAction<Array<IColReorder>>) => {
      state.colReorder = action.payload;
    },
    setInterviewType:(state, action:PayloadAction<string>) =>{
      state.filters.interviewType = action.payload
    }
    },
});

export const {
    setCurrentPage,
    setLimit,
    setTotalCount,
    setSortTypeFilter,
    setSortByFilter,
    setSearchValue,
    setLocationFilter,
    setTableItems,
    setOrderColumns,
    setInterviewType
  } = interviewsJobsSlice.actions;

  export default interviewsJobsSlice.reducer;

