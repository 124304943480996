import { isInterview } from "./checks";
import { RoundStatus } from "../../enums";

export const findFirstInterview = (rounds) => {
  return rounds.find((round) => isInterview(round));
};

export const findLastInterview = (rounds) => {
  return rounds.findLast((round) => isInterview(round));
};

export const findRejectedInterview = (rounds) => {
  return rounds.find(
    (round) => isInterview(round) && round.status === RoundStatus.REJECTED
  );
};
