import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Loader from "../Loader/Loader";
import { Dispatch } from "react";
import { IsMailPreviewOpenType } from "../../screen/MailBoxScreen";

import { SetStateAction } from "react";

interface Data {
  id: number;
  subject: string;
  date: string;
  from: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "from",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
];
type Order = "asc" | "desc";
interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            {/* {headCell.label} */}
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              IconComponent={ArrowDropUpIcon}
              sx={{
                "& .css-1ku2c56-MuiTableSortLabel-icon,.css-1hi7cb7-MuiTableSortLabel-icon":
                  {
                    fontSize: "30px",
                  },
              }}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

interface TableProps {
  setSelectedMailId: Dispatch<SetStateAction<readonly any[]>>;
  selectedMailId: readonly any[];
  mailList: any;
  totalCount: number;
  loading: boolean;
  sortOrder: Order;
  sortOrderBy: keyof Data;
  pageNo: number;
  rowPerPage: number;
  tabState:any;
  setSortOrder: Dispatch<SetStateAction<string>>;
  setSortOrderBy: Dispatch<SetStateAction<string>>;
  setPageNo: Dispatch<SetStateAction<number>>;
  setRowPerPage: Dispatch<SetStateAction<number>>;
  setIsMailPreviewOpen: Dispatch<SetStateAction<IsMailPreviewOpenType>>;
}

export default function EnhancedTable({
  setSelectedMailId: setSelected,
  selectedMailId: selected,
  mailList: rows,
  totalCount,
  loading,
  sortOrder: order,
  sortOrderBy: orderBy,
  pageNo: page,
  rowPerPage: rowsPerPage,
  setSortOrder: setOrder,
  setSortOrderBy: setOrderBy,
  setPageNo: setPage,
  setRowPerPage: setRowsPerPage,
  setIsMailPreviewOpen,
  tabState
}: TableProps) {
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => {
        return {
          id: n.id,
          flag: n.isRead,
        };
      });
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: number, flag: boolean) => {
    const selectedIndex = selected.findIndex((item: any) => item.id === id);
    let newSelected: readonly any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, { id: id, flag });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: number) =>
    selected.findIndex((item: any) => item.id === id) !== -1;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%" }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer sx={{ height: "100%", position: "relative" }}>
          <Table
            // sx={{ minWidth: 750, }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            stickyHeader>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}>
                  <Loader />
                </div>
              )}
              {rows?.map((row, index) => {
                const isItemSelected = isSelected(row.id as number);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    sx={{
                      "& .css-6ir8f8-MuiTable-root,.css-u8trlj-MuiPaper-root": {
                        height: "fit-content !important",
                      },

                      cursor: "pointer",
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        onChange={(event) =>
                          handleClick(row.id as number, row.isRead)
                        }
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      onClick={() => {
                        setIsMailPreviewOpen({
                          state: true,
                          mailId: row.id,
                          isDraft:tabState==="draft"
                        });
                      }}
                      align="center"
                      sx={{ color: row.isRead ? "" : "#099C73" }}
                      scope="row"
                      padding="none">
                      {`${(row.subject as string).slice(0, 20)}${
                        (row.subject as string).length > 20 ? "..." : ""
                      }`}
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">
                      {`${(row.from as string).slice(0, 30)}${
                        (row.from as string).length > 20 ? "..." : ""
                      }`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
