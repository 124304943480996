import Alert from '@mui/material/Alert';

function WarningBox({message,className}:{message:string,className?:string|null}) {
  return (
    <Alert severity="warning" variant='outlined' className={className}>
      {message}
    </Alert>
  )
}

export default WarningBox;