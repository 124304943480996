export enum DrugJobsTableHeader{
    JOB_TITLE = "Job Title",
    JOB_LOCATION = "Job Location",
    OPENINGS = "Openings",
    SELECTED_DS = "Verified from Drug Screening",
    SENT_DS = "Sent for Drug Screening",
    CANDIDATE_YET_TO_SEND_DS = "Yet to Send for Drug Screening",
    REJETED_FROM_DS  = "Rejected from Drug Screening",
  }
  
  export enum DrugJobsTableFilter{
    JOB_LOCATION = "Job Location"
  }