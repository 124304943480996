import {memo} from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin  from '@fullcalendar/timegrid'
export default memo(() => {
	return <section style={{height: 'calc(100vh - 108px)'}}>
		<FullCalendar
			plugins={[dayGridPlugin,timeGridPlugin ]}
			initialView="dayGridMonth"
			height='100%'
			views={{
				timeGridWeek:{
					allDaySlot:false
				},
				timeGridDay:{
					allDaySlot:false
				}
			}}
			headerToolbar={{
				start: 'prev,next', // will normally be on the left. if RTL, will be on the right
				center: 'title',
				end: 'today dayGridMonth,timeGridWeek,timeGridDay' // will normally be on the right. if RTL, will be on the left
			}}
		/>
	</section>
})