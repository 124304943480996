import deleteIcon from '../../../../image/icon/delete_ic.svg';
import editIcon from '../../../../image/icon/edit_ic.svg';
import resend from '../../../../image/icon/resent invite_ic.svg';
import { store, useTypedSelector } from "../../../../store";
import defaultAvatar from '../../../../image/icon/avatar.svg';
import { memo } from "react";
import { setDeletePopupMTM, setEditMemberPopupMTM, setResendPopupMTM } from "../../../../store/reducers/manageTeamMembersReducer";
import { transformDate } from "../../../../utils/transformDate";
import DropdownRoleMTM from "./DropdownRoleMTM";
import { useNavigate } from "react-router-dom";
import { AuthGuard, ShowToolTipComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const teamMembersFunc = state => state.team_members
const TBodyInnerMTM = ({ horizontalScrollState }: { horizontalScrollState: boolean }) => {

	const table = useTypedSelector(teamMembersFunc)
	const navigate = useNavigate()
	return <tbody className="table__tbody">
		{
			table.users?.map(item => {
				return <tr className="table__tr" key={item.id}>
					<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
						<div className='manage-team__name'>
							<img src={item.avatar || defaultAvatar} alt='avatar' className='manage-team__name__avatar' />
							<p className='manage-team__name__text'>{`${item.firstname} ${item.middlename || ''} ${item.lastname}`}</p>
						</div>
					</td>
					{
						table.userTabsFilter.map(value => {
							if (value.id === 2) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray5'>{item.id}</p>
									</td>
							}
							if (value.id === 3) {
								return value.active &&
									<td className="table__td manage-team__column__middle" key={value.displayName}>
										<p className='manage-team__text manage-team__text--green'>{item.email}</p>
									</td>
							}
							if (value.id === 4) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--green'>{item.phone}</p>
									</td>
							}
							if (value.id === 5) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray7'>{item.jobTitle}</p>
									</td>
							}
							if (value.id === 6) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray7'>{item.department}</p>

									</td>
							}
							if (value.id === 7) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray7'>{item.jobLocation}</p>
									</td>
							}
							if (value.id === 8) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray7'>{item.educational}</p>
									</td>
							}
							if (value.id === 9) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray5'>{transformDate(item.dateOfJoining)}</p>
									</td>
							}
							if (value.id === 10) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray5'>{transformDate(item.dateOfBirth)}</p>
									</td>
							}
							if (value.id === 11) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<DropdownRoleMTM role={item.role?.map(i => i.split('_')[0])} />
									</td>
							}
							if (value.id === 12) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p className='manage-team__text manage-team__text--gray7'>{item.address}</p>
									</td>
							}
							if (value.id === 13) {
								return value.active &&
									<td className="table__td manage-team__column__default" key={value.displayName}>
										<p
											className={`${item.status === 'Pending' ?
												'table__status-block table__status-block--yellow manage-team__status' :
												'table__status-block table__status-block--green manage-team__status'}`}
										>{item.status}</p>
									</td>
							}
							if (value.id === 14) {
								return value.active &&
									<td className="table__td actions manage-team__column__default" key={value.displayName}>
										{
											item.status === 'Pending' ?
												<AuthGuard module='team-members' permission='edit'>
													<ShowToolTipComponent text='Resend account activation link'>
														<img src={resend} alt="resend-icon" className="manageAssignment__table__icon" onClick={() => {
															store.dispatch(setResendPopupMTM({
																open: true,
																id: item.id
															}))
														}} />
													</ShowToolTipComponent>
												</AuthGuard>
												:
												<div className="manageAssignment__table__icon manageAssignment__table__icon--no-pointer" />
										}
										{
											!item.role.includes('Company Owner') && <>
												<AuthGuard module={'team-members'} permission={'edit'}>
													<ShowToolTipComponent text='Edit details'>
														<img src={editIcon} alt="delete-icon" className="manageAssignment__table__icon"
															onClick={(e) => {
																store.dispatch(setEditMemberPopupMTM({id:+item.id,open:true}))
															}}
														/>
													</ShowToolTipComponent>
												</AuthGuard>
												<AuthGuard module={'team-members'} permission={'delete'}>
													<ShowToolTipComponent text='Delete'>
														<img src={deleteIcon} alt="delete-icon" className="manageAssignment__table__icon"
															onClick={(e) => {
																store.dispatch(setDeletePopupMTM({
																	open: true,
																	name: `${item.firstname} ${item.middlename ? item.middlename : ''} ${item.lastname}`,
																	id: parseInt(item.userId)
																}))
															}}
														/>
													</ShowToolTipComponent>
												</AuthGuard>
											</>
										}
									</td>
							}
						})
					}
				</tr>
			})
		}
	</tbody>
}

export default memo(TBodyInnerMTM)