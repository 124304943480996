import React from "react";
import loaderImg from "../../image/page-loader.svg";

const PageLoader = () => {
    return (
        <div className='table-empty'>
            <img src={loaderImg} alt="Loading Page.."  className='table-empty__image'/>
            <h2 className='table-empty__title'>Please wait...</h2>
            <p className='table-empty__desc'>We are loading the page. Wait a couple of moments.</p>
        </div>
    );
}

export default PageLoader;

