import React, { useState, useEffect } from "react";
import { OfferLetterStatus, OfferKeys } from "../../enums/usersOffers/UsersOffersEnums";
import { store, useTypedSelector } from "../../store";
import clockIc from "../../image/icon/clock_ic.svg";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import OfferAccordion from "./OfferAccordion";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../hook/useSnackbar";

const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const OfferPreview = ({
  offer,
  resent = false,
  isOpen = false,
  setModal,
  setSelectedOffer,
}) => {
  const [pdfContent, setPdfContent] = useState<null | string>(null);
  const [stepsDetails, setStepsDetails] = useState([]);
  const [signatureRequest, setSignatureRequest] = useState(null);
  const { ErrorElement, APIExecutor } = useSnackbar();

  const navigate = useNavigate();

  useEffect(() => {
    const setPdfPreview = async () => {
      const pdfBlob = await APIExecutor(() => fetchPdf(offer.id));
      if (pdfBlob) {
        Promise.resolve(setPdfContent(URL.createObjectURL(pdfBlob)));
      }
    };
    setPdfPreview();
    return () => URL.revokeObjectURL(pdfContent as string);
  }, [offer.id]);

  useEffect(() => {
    const fetchSignatureData = async () => {
      if (offer.hellosignSignatureRequestId) {
        setModal(false);
        const signatureData = await APIExecutor(() =>
          getSignatureRequestData(offer.hellosignSignatureRequestId)
        );
        if (signatureData) {
          setSignatureRequest(signatureData);
        }
      }
    };

    fetchSignatureData();
  }, [offer.hellosignSignatureRequestId]);

  useEffect(() => {
    if (!resent && offer.letterStatus === OfferLetterStatus.GENERATED) {
      setSelectedOffer(offer);
      setModal("saveTemplate");
    }
  }, [resent, offer.letterStatus]);

  useEffect(() => {
    const initialSteps = [
      {
        id: 1,
        name: "Offer Created",
        user: "",
        createAt: offer.createdAt,
        isDone: offer.id ? true : false,
      },
      ...(offer.approvedBy
        ? [
            {
              id: 2,
              name: "Sent To Approver",
              user: offer.approver.firstname,
              isDone:
                offer.letterStatus === OfferLetterStatus.PENDING ||
                offer.letterStatus === OfferLetterStatus.APPROVED ||
                offer.letterStatus === OfferLetterStatus.REJECTED || offer.approvedBy
                  ? true
                  : false,
                  signed_at:offer.updatedAt
            },
            {
              id: 3,
              name: "Approved",
              user: offer.approver.firstname,
              isDone:
                offer.letterStatus === OfferLetterStatus.APPROVED || offer.approvedBy
                  ? true
                  : false,
                  signed_at:offer.updatedAt

            },
          ]
        : []),
    ];

    if (offer.hellosignSignatureRequestId) {
      initialSteps.push({
        id: 4,
        name: "Sent To Candidate",
        user: "",
        isDone: !!signatureRequest?.signature_request_id ? true : false,
        createAt: signatureRequest?.created_at,
      });
    }
    setStepsDetails([
      ...initialSteps,
      ...(signatureRequest ? signatureRequest?.signatures : []),
      ...(offer.letterStatus === OfferLetterStatus.CLOSED
        ? [
            {
              id: 5,
              name: "Offer Closed",
              user: "",
              isDone: true,
              createAt: offer.updatedAt,
            },
          ]
        : []),
    ]);
  }, [signatureRequest?.signatures, offer.createdAt]);

  let WrapperElement: React.ComponentType<{ children: React.ReactNode }> = ({
    children,
  }) => {
    if (resent)
      return (
        <OfferAccordion
          isOpen={isOpen}
          offer={offer}
          setModal={setModal}
          setSelectedOffer={setSelectedOffer}
        >
          {children}
        </OfferAccordion>
      );
    return <>{children}</>;
  };

  return (
    <>
      <WrapperElement>
        <ErrorElement />
        <section className="offer-preview">
          <div className="offer-preview__inner">
            <div className={`offer-body ${resent ? "resent" : ""}`}>
              <div className="offer-body__item">
                <p className="offer-body__headline">Candidate Details</p>
                <ul className="offer-body__list">
                  <li className="offer-body__list__item">
                    <span>Candidate Name</span>
                    <span>
                      {offer.candidateFirstname} {offer.candidateLastname}
                    </span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Candidate Email</span>
                    <span>{offer.candidateEmail}</span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Candidate Phone</span>
                    <span>{offer.candidatePhone}</span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>CTC</span>
                    <span>${offer.ctc}</span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Candidate Address</span>
                    <span>{offer.candidateAddress}</span>
                  </li>
                </ul>
              </div>
              <div className="offer-body__item">
                <p className="offer-body__headline">Offer Details</p>
                <ul className="offer-body__list">
                  <li className="offer-body__list__item">
                    <span>Job Title</span>
                    <span>{offer.jobTitle}</span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Company</span>
                    <span>{offer.companyName}</span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Job location</span>
                    <span>
                      {offer.location?.city + " " + offer.location?.state}
                    </span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Joining Date</span>
                    <span>No data</span>
                  </li>
                  <li className="offer-body__list__item">
                    <span>Signers</span>
                    <ul>
                      {offer?.signer?.map((signer) => (
                        <li key={signer.id}>{signer.label}</li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="offer-body__item">
                <p className="offer-body__headline">Offer body</p>
                <p className="offer-body__item__title">Assign the Assessment</p>
                <div className="offer-body__from">
                  <p className="offer-body__from__label">Subject</p>
                  <p className="offer-body__from__text">
                    {signatureRequest?.subject}
                  </p>
                </div>
                {pdfContent && (
                  <embed
                    src={pdfContent}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                  />
                )}
              </div>
            </div>
            <div className={`stages ${resent ? "resent" : ""}`}>
              <p className="stages__headline">Offer Stages</p>
              <ul className="stages__list">
                {stepsDetails?.map((item: any) => (
                  <Steps key={item.signature_id || item.id} item={item} />
                ))}
              </ul>
            </div>
          </div>
        </section>
      </WrapperElement>
    </>
  );
};
export default OfferPreview;

export const Steps = ({ item }) => {
  return (
    <li
      className={`stages__item ${
        item?.status_code == "signed" || item.isDone ? "completed" : ""
      }`}
    >
      <div className="stages__item__left">
        <div
          className={`stages__item__dot ${
            item?.status_code == "signed" || item.isDone ? "filled" : "circle"
          }`}
        ></div>
      </div>
      <div className="stages__item__right">
        <p className="stages__item__name">
          {(item?.signer_name && item?.signer_name + " Signed Offer") ||
            item?.name}
        </p>
        <div className="stages__info">
          {(item.user || item?.signer_name) && (
            <p className="stages__info__item">{item.user || item?.signer_name}</p>
          )}
            {(item.isDone && item?.signed_at || item?.updatedAt) && (
          <p className="stages__info__item">
              <>
              <img src={clockIc} alt="clock icon" />
              {getFormattedDate(item?.signed_at || item?.updatedAt)}
              </>
          </p>
            )}
        </div>
      </div>
    </li>
  )
}

const getFormattedDate = (milliseconds) => {
  if (!milliseconds) return;

  if (typeof milliseconds === "number") milliseconds *= 1000;

  const date = new Date(milliseconds);
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedDate = formatter.format(date);
  return formattedDate.replace(/ at /i, " ");
};

const getSignatureRequestData = async (signatureRequestId) => {
  const response = await axios.get(
    `${API_RECRUITMENT}/api/offer/hellosign/signature-request/${signatureRequestId}`,
    config
  );
  return response.data.signature_request;
};

const fetchPdf = async (offerId) => {
  const url = `${API_RECRUITMENT}/api/offer/hellosign/signature-document/${offerId}`;
  const response = await axios.get(url, {
    ...config,
    responseType: "blob",
  });
  return response.data;
};




