import { useNavigate } from "react-router-dom";
import { BGVStatus } from "../../enums";


const ActionPopupReviewScreening = ({ type, jobId, screeningId, onReject,onVerify,screeningType,onClosePopup }) => {

    const navigate = useNavigate();
    const popupActionLabel = type === BGVStatus.VERIFIED ? "Verify" : type === BGVStatus.REJECTED ? "Reject" : "";

    const handleSubmitAction = () => {
        if (type === "Rejected") {
            onReject()
        } else if (type === 'Verified') {
            onVerify()
        }
        navigate(`/recruitment/${screeningType}-screening/candidates/${jobId}`)
    }

    return (
        <div className="popup apply-popup">
            <div className="popup__step">
                <div className="popup__head">
                    <p className="popup__head__headline">
                        {popupActionLabel} {screeningType.toUpperCase()} Screening
                    </p>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="popup__head__close"
                        onClick={onClosePopup}
                    >
                        <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="popup__body">
                    <p className="popup__body__text">
                        If you click {popupActionLabel} button, you will {type} the screening of the candidate. Are you sure about your actions? Action cannot be undone.
                    </p>

                </div>
                <div className="popup__bottom end">
                    <button
                        className="popup__bottom__cancel button--empty"
                        onClick={onClosePopup}
                    >
                        Cancel
                    </button>
                    <button
                        className="popup__bottom__button button--filled"
                        onClick={handleSubmitAction}
                    >
                        {popupActionLabel}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ActionPopupReviewScreening;