import { memo, useState } from 'react';
import { store, useTypedSelector } from "../../store/index";
import { setShowAssignPopupManageCandidate } from '../../store/reducers/manageCandidate';
import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import { default as ReactSelect } from "react-select";
import { selectCustomStyle } from '@ucrecruits/globalstyle/styles/selectCustomStyle';
import { postData } from '../../hook/http';
import { getEnv } from '@urecruits/api';
import { setManageCandidatesHandler } from '../../screen/ManageCandidatesScreen';
const{API_RECRUITMENT} = getEnv()

const gettableInfo = state => state.manage_candidate
const ShowAssignPopup = () => {
    const [errorNotification, setErrorNotification] = useState('')

    const [selectedAssignment, setSelectedAssignment] = useState<any>()

    const { assignmentList: assignments, showAssignPopup,filters,pagination } = useTypedSelector(gettableInfo)
    const onAssign=()=>{
        if(selectedAssignment){
            postData(`${API_RECRUITMENT}/api/subscribe/assign/workflow`,{jobId:selectedAssignment.jobId,userId:showAssignPopup.userId,isAssessment:true}).then(data=>{
                store.dispatch(setShowAssignPopupManageCandidate({open:false,userId:null,userName:''}))
                setManageCandidatesHandler(1, pagination.limit, filters)
            })
        }else{
            setErrorNotification("You must select one assignment to assign it to candidate.")
        }
    }

    return (
        <AuthGuard module='team-members' permission='edit'>
            <div className="popup">
                <div className="popup__step medium">
                    <div className="popup__head">
                        <p className="popup__head__headline">
                                Assign assignment to {showAssignPopup.userName}
                        </p>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => store.dispatch(setShowAssignPopupManageCandidate({open:false,userName:'',userId:null}))}
                            className="popup__head__close"
                        >
                            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="popup__body">
                        <ReactSelect
                            options={assignments||[]}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={(currentValue: any) => {
                                setSelectedAssignment(currentValue)
                            }}
                            value={selectedAssignment||""}
                            getOptionLabel={(option: any) => option.title}
                            placeholder={`Select Assignment to assign`}
                            styles={selectCustomStyle}
                            id="Assignments"
                            instanceId="assignments"
                        />
                    </div>
                    <div className="popup__bottom end">
                        <button
                                onClick={() => store.dispatch(setShowAssignPopupManageCandidate({open:false,jobId:null,userId:null}))}
                            className="popup__bottom__cancel button--empty"
                        >
                            Cancel
                        </button>
                        <button
                            className="popup__bottom__publish button--filled"
                            onClick={onAssign}
                        >
                            Assign
                        </button>
                        {
                            errorNotification.length > 0 && (
                                <p className="error-message">{errorNotification}</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </AuthGuard>
    )
}

export default memo(ShowAssignPopup)
