import { memo, useState } from "react";
import { store, useTypedSelector } from "../../../../../store";
import { setPopupWithInstruction } from "../../../../../store/reducers/AnswerSheetReducer";
import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { patchData } from "../../../../../hook/http";
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

const getPopupWithList = (state) => state.answer_sheet.popupWithInstruction;

const PopupWithFeedback = () => {
  const popupWithList = useTypedSelector(getPopupWithList);
  const [data, setData] = useState(null);

  const handleSubmit=async()=>{
    patchData(`${API_ASSESSMENT}/api/domain-result/${popupWithList?.id}`,{
      feedback:data
    }).then((res)=>{
      res?.status && store.dispatch(setPopupWithInstruction({ open: false, id: -1, instruction:null }))
    })
    
  }
  return (
    <div className="feedback_popup" >
      <div className="feedback_popup__step feedback-popup">
        <div className="feedback_popup__head">
          <p className="feedback_popup__head__headline">Add Feedback</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              store.dispatch(setPopupWithInstruction({ open: false, id: -1, instruction:null }))
            }
            className="feedback_popup__head__close"
          >
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="feedback_popup_body">
          <textarea
            defaultValue={popupWithList?.instruction} 
            placeholder="Add Feedback" 
            className="feedback-popup__textarea"
            onChange={(e)=>{
              setData(e?.target?.value)
            }}></textarea>
		</div>
	  <div className="feedback_popup__bottom end">
       <button
						className="popup__bottom__cancel button--empty"
						onClick={()=>{
              store.dispatch(setPopupWithInstruction({ open: false, id: -1, instruction:null }))
            }}
					>
						Cancel
					</button>
					<button
						className="popup__bottom__button button--filled"
						onClick={handleSubmit}
					>
						Submit
					</button>
			</div>
      </div>
    </div>
  );
};


export default memo(PopupWithFeedback);
