import {memo, useEffect, useState} from "react";
import {store, useTypedSelector} from "../../store";
import AsyncSelect from "react-select/async";
import Option from "../Global/SelectOptions";
import {getEnv, selectSearchFuncDepartment, selectSearchFuncIndustry} from "@urecruits/api";
import {selectCustomStyle} from "../../styles/selectCustomStyle";
import {timeDurationList} from "../../utils/constants";
import React from "react";
import { setDepartmentDomainAssessment, setIndustryDomainAssessment, setTimeFilterDomainAssessment } from "../../store/reducers/domainAssessmentReducer";
import { DomainAssessmentFilterTabs } from "../../enums/domain-assessment/domain-assessment";
import CustomRange from "./filter/InputRange";
const {API_RECRUITMENT} = getEnv()

const getFiltersFunc = (state) => state.domain_assessment.filters
const FilterPopupManageDomainAssessment = ({activeTab}) => {
	const filter = useTypedSelector(getFiltersFunc)

	return <>
		 {
			activeTab === DomainAssessmentFilterTabs.Industry &&
			<div className='table-filter-popup__right__content'>
			<AsyncSelect
				isMulti
				components={{
								  Option,
							  }}
				cacheOptions
				loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncIndustry(inputValue) : selectSearchFuncIndustry('')
							  }
				defaultOptions
				value={filter.industry}
				onChange={(option: any) => {
								  store.dispatch(setIndustryDomainAssessment(option))
							  }}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				placeholder='Search by Industry'
				styles={selectCustomStyle}
				id="locationSelect"
				instanceId="locationSelect"
			/>
		</div>
		} 
		{
			activeTab === DomainAssessmentFilterTabs.Department &&
			<div className='table-filter-popup__right__content'>
			<AsyncSelect
				isMulti
				components={{
								  Option,
							  }}
				cacheOptions
				loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncDepartment(inputValue) : selectSearchFuncDepartment('')
							  }
				defaultOptions
				value={filter.department}
				onChange={(option: any) => {
								  store.dispatch(setDepartmentDomainAssessment(option))
							  }}
				hideSelectedOptions={false}
				closeMenuOnSelect={false}
				placeholder='Search by Department'
				styles={selectCustomStyle}
				id="locationSelect"
				instanceId="locationSelect"
			/>
		</div>
		} 
		{
			activeTab === DomainAssessmentFilterTabs.Score && <div className='table-filter-popup__right__content'>
          <label className='table__range__label'>Score Range</label>
		  <CustomRange
                            values={filter.score}
                            MAX={100}
                        />
      </div>
		} 
		{
			activeTab===DomainAssessmentFilterTabs.Time&&<div className='table-filter-popup__right__content'>
				{
					<ul className='filters-popup-mtm-buttons-list'>
						{
							timeDurationList.map((item,index) => {
								return <li
									className={`${filter?.time.find(x => x === item.value) ? 'filters-popup-mtm-buttons-list__item active' : 'filters-popup-mtm-buttons-list__item'}`}
									key={index}
									onClick={() => store.dispatch(setTimeFilterDomainAssessment(filter.time.find(x => x === item.value) ? filter.time.filter(x => x !== item.value) : [...filter.time, item.value]))}
								>
									{item?.value}
								</li>
							})
						}
					</ul>
				}
      </div>
		}
	</>
}
export default memo(FilterPopupManageDomainAssessment)
